<template>
    <div v-if="page_loading">
        <loading :page_loading="page_loading" />
    </div>
    <div v-else>
        <loading :loading="loading" />
        <!-- <div v-if="data_customer.length != 0"> -->
        <Photo v-if="ShowPhoto" :showphoto="ShowPhoto" :showphotolink="ShowPhotoLink" @emitshowphoto="ClosePhoto" />

        <v-card elevation="1" class="v-card-bottom-30 hidden-xs-only">
            <v-card-title style="height: 60px;">
                <a @click="toCustomer()">
                    <h4> {{ use_language.customer }} </h4>
                </a>
                <v-icon>mdi-chevron-right</v-icon>
                <h4>{{ use_language.customer_info }}</h4>
                <!-- <v-icon>mdi-chevron-right</v-icon><span class="font-weight-regular body-1">ลูกค้า</span> -->
                <v-spacer />
            </v-card-title>
        </v-card>

        <!-- #region  ข้อมูลลูกค้า -->

        <v-card class="v-card-bottom-30">
            <v-card-title>
                <v-icon size="20" color="primary darken-3">fa-address-card</v-icon><span class="ml-3">
                    {{ use_language.customer_info }}</span>
                <v-spacer />
                <v-btn icon v-if="EditCustomerDisibled == true"
                    @click="EditCustomerDisibled = !EditCustomerDisibled"><v-icon size="18">fa-edit</v-icon></v-btn>
                <v-btn icon v-else @click="EditCustomerDisibled = !EditCustomerDisibled"><v-icon
                        size="18">fa-edit</v-icon></v-btn>
            </v-card-title>
            <v-divider />
            <v-card-text>
                <v-row>
                    <v-col class="pb-1" cols="12" md="2">
                        <h3 class="fn-14"> {{ use_language.customer_code }} : </h3>
                    </v-col>
                    <v-col class="pb-1" cols="12" md="4">
                        <v-text-field :value="customer.customerCode" @change="value => customer.customerCode = value"
                            autocomplete="off" ref="refCustomerCode" outlined hide-details dense
                            :disabled="EditCustomerDisibled" v-if="EditCustomerDisibled == false" />
                        <span v-else-if="customer.customerCode != null">{{ customer.customerCode }}</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pb-1" cols="12" md="2">
                        <h3 class="fn-14"> {{ use_language.name }} : </h3>
                    </v-col>
                    <v-col class="pb-1" cols="12" md="4">
                        <v-text-field :value="customer.name" @change="value => customer.name = value" autocomplete="off"
                            ref="refCustomerName" outlined hide-details dense :disabled="EditCustomerDisibled"
                            v-if="EditCustomerDisibled == false" />
                        <span v-else-if="customer.name != null">{{ customer.name }}</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pb-1" cols="12" md="2">
                        <h3 class="fn-14"> {{ use_language.tel }} : </h3>
                    </v-col>
                    <v-col class="pb-1" cols="12" md="4">
                        <v-text-field :value="customer.phone" @change="value => customer.phone = value"
                            autocomplete="off" ref="refCustomerPhone" outlined hide-details dense
                            :disabled="EditCustomerDisibled" v-if="EditCustomerDisibled == false" />
                        <span v-else-if="customer.phone != null">{{ customer.phone }}</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pb-1" cols="12" md="2">
                        <h3 class="fn-14"> {{ use_language.email }} : </h3>
                    </v-col>
                    <v-col class="pb-1" cols="12" md="4">
                        <v-text-field :value="customer.email" @change="value => customer.email = value"
                            autocomplete="off" ref="refCustomerEmail" outlined hide-details dense
                            :disabled="EditCustomerDisibled" v-if="EditCustomerDisibled == false" />
                        <span v-else-if="customer.email != null">{{ customer.email }}</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pb-1" cols="12" md="2">
                        <h3 class="fn-14"> {{ use_language.facebook }} : </h3>
                    </v-col>
                    <v-col class="pb-1" cols="12" md="4">
                        <v-text-field :value="customer.facebook" @change="value => customer.facebook = value"
                            autocomplete="off" ref="refCustomerFacebook" outlined hide-details dense
                            :disabled="EditCustomerDisibled" v-if="EditCustomerDisibled == false" />
                        <span v-else-if="customer.facebook != null">{{ customer.facebook }}</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pb-1" cols="12" md="2">
                        <h3 class="fn-14"> {{ use_language.line }} : </h3>
                    </v-col>
                    <v-col class="pb-1" cols="12" md="4">
                        <v-text-field :value="customer.line" @change="value => customer.line = value" autocomplete="off"
                            ref="refCustomerLine" outlined hide-details dense :disabled="EditCustomerDisibled"
                            v-if="EditCustomerDisibled == false" />
                        <span v-else-if="customer.line != null">{{ customer.line }}</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pb-1" cols="12" md="2">
                        <h3 class="fn-14"> {{ use_language.note }} : </h3>
                    </v-col>
                    <v-col class="pb-1" cols="12" md="4">
                        <v-text-field :value="customer.remark" @change="value => customer.remark = value"
                            autocomplete="off" ref="refCustomerRemark" outlined hide-details dense
                            :disabled="EditCustomerDisibled" v-if="EditCustomerDisibled == false" />
                        <span v-else-if="customer.remark != null">{{ customer.remark }}</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pb-1" cols="12" md="2">
                        <h3 class="fn-14"> {{ use_language.credit_balance }} : </h3>
                    </v-col>
                    <v-col class="pb-1" cols="12" md="4">

                        <span style="color:green" v-if="customer.balance > 0">{{ format_price(customer.balance) }}</span>
                        <span v-else-if="customer.balance == 0">{{ format_price(customer.balance) }}</span>
                        <span style="color:red" v-else>{{ format_price(customer.balance) }}</span>
                    </v-col>
                </v-row>
                <v-row v-if="EditCustomerDisibled == false">
                    <v-col class="pb-1" cols="12" md="2">
                       
                    </v-col>
                    <v-col class="pb-1" cols="12" md="4">
                        <v-btn dark color="primary" @click="EditCustomer()" class="mr-1"><v-icon
                                left>mdi-content-save</v-icon> {{ use_language.record }}</v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <!-- #endregion -->


        <v-card class="v-card-bottom-30">
            <v-tabs v-model="tab">
                <v-tabs-slider color="red"></v-tabs-slider>
                <!-- <v-tab v-for="item in tab_items" :key="item" @click="SeeDetails(item)"> {{ item }} </v-tab> -->
                <v-tab v-for="item in tab_items" :key="item"> {{ use_language[item] }} </v-tab>
                <v-spacer />
            </v-tabs>

            <v-divider />

            <v-tabs-items v-model="tab" touchless>
                <v-tab-item v-for="item in tab_items" :key="item">


                    <!-- #region รายการออเดอร์ฺ -->
                    <div v-if="item == 'order_list'">
                        <v-card>

                            <v-card-text>
                                <v-row>
                                    <v-col>
                                        <v-data-table :headers="header_data_order_customer" :items="data_order_customer"
                                            :items-per-page="9999999999" class="elevation-0 packhai-border-table"
                                            hide-default-footer>
                                            <template v-slot:item.orderID="{ item }">
                                                <v-btn style="text-decoration: underline" color="primary" text
                                                    @click="detail_page(item.orderID)">{{ format_order_id(item.orderID)
                                                    }}</v-btn><br><span v-if="item.isPreOrder == 1"
                                                    class="text-info"><b>{{ use_language.pre_order }}</b></span>
                                            </template>
                                            <template v-slot:item.totalPrice="{ item }">
                                                {{ format_price(item.totalPrice) }}
                                            </template>
                                            <template v-slot:item.shippingStatusName="{ item }">
                                                <span v-if="item.shippingStatusID == 10" style="color: black;">{{
                                                    item.shippingStatusName }}</span>
                                                <span v-if="item.shippingStatusID == 20" style="color: #E40078;">{{
                                                    item.shippingStatusName }}</span>
                                                <span v-if="item.shippingStatusID == 30" style="color: #ff7ac0;">{{
                                                    item.shippingStatusName }}</span>
                                                <span v-if="item.shippingStatusID == 40" style="color: orange;">{{
                                                    item.shippingStatusName }}</span>
                                                <span v-if="item.shippingStatusID == 50" style="color: blue;">{{
                                                    item.shippingStatusName }}</span>
                                                <span v-if="item.shippingStatusID == 60" style="color: #9acd32;">{{
                                                    item.shippingStatusName }}</span>
                                                <span v-if="item.shippingStatusID == 70" style="color: green;">{{
                                                    item.shippingStatusName }}</span>
                                                <span v-if="item.shippingStatusID == 90" style="color: red;">{{
                                                    item.shippingStatusName }}</span>
                                            </template>
                                            <template v-slot:item.createDatetime="{ item }">
                                                {{ set_format_date_time(item.createDatetime) }}
                                            </template>
                                            <template v-slot:item.expressCompanyName="{ item }">
                                                <!-- <v-avatar size="25px"> -->
                                                <img style="width: 60px" :src="item.expressCompanyLogo"
                                                    @click="image_click(item.expressCompanyLogo)" />
                                                <!-- </v-avatar> -->
                                            </template>
                                            <template v-slot:item.channelName="{ item }">
                                                <v-avatar size="25px">
                                                    <img :src="item.channelLogo"
                                                        @click="image_click(item.channelLogo)" />
                                                </v-avatar>
                                            </template>
                                        </v-data-table>
                                        <v-row class="packhai-card-padding">
                                            <v-col cols="12" class="pa-3">
                                                <div class="text-center" @click="changePage()">
                                                    <v-pagination v-model="page" :length="pageLength"
                                                        :total-visible="pageTotalVisible" color="error"></v-pagination>
                                                </div>
                                            </v-col>
                                        </v-row>

                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </div>
                    <!-- #endregion -->

                    <!-- #region รายการเติมเครดิต -->
                    <div v-if="item == 'credit_list'">
                        <v-card>

                            <v-card-text>
                                <v-row>
                                    <v-col cols="12" md="2">
                                        <v-menu v-model="search_menu_date_from" :close-on-content-click="false"
                                            transition="scale-transition" offset-y max-width="290px" min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field :value="computed_search_date_from"
                                                    @change="value => computed_search_date_from = value"
                                                    autocomplete="off" label="เริ่ม" outlined
                                                    prepend-inner-icon="mdi-calendar" readonly hide-details v-on="on">
                                                    <template v-slot:label> <span>{{ use_language.start_ }}</span>
                                                    </template>
                                                </v-text-field>
                                            </template>
                                            <v-date-picker v-model="search_date_from" no-title
                                                @input="search_menu_date_from = false">
                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <v-menu v-model="search_menu_date_to" :close-on-content-click="false"
                                            transition="scale-transition" offset-y max-width="290px" min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field :value="computed_search_date_to"
                                                    @change="value => computed_search_date_to = value"
                                                    autocomplete="off" label="สิ้นสุด" outlined
                                                    prepend-inner-icon="mdi-calendar" readonly hide-details v-on="on">
                                                    <template v-slot:label> <span>{{ use_language.end }}</span>
                                                    </template>
                                                </v-text-field>
                                            </template>
                                            <v-date-picker v-model="search_date_to" @input="search_menu_date_to = false"
                                                no-title :allowed-dates="allowedDates">
                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <v-btn color="primary"
                                            @click="btnSearchShopCustomerBalance()">{{ use_language.search }}</v-btn>
                                    </v-col>

                                    <v-col cols="12" md="4" align="right">
                                        <!-- <v-btn color="success" outlined @click="Export_to_excel()">Export to Excel</v-btn> -->
                                        <table style="width:100%">
                                            <tr>
                                                <td class="text-right">
                                                    <vue-excel-xlsx v-if="data_credit.length" :data="data_credit"
                                                        :columns="excel_topupHistory_header"
                                                        file-name='รายงานเติมเครดิต' sheet-name='รายงานเติมเครดิต'>
                                                        <v-btn color="success"
                                                            outlined>{{ use_language.export_excel }}</v-btn>
                                                    </vue-excel-xlsx>
                                                </td>
                                                <td class="text-right" style="width:100px">
                                                    <v-btn color="danger" @click="btnAddUpdateShopCustomerBalance()"
                                                        dark>เพิ่มข้อมูล</v-btn>
                                                </td>
                                            </tr>
                                        </table>


                                    </v-col>

                                </v-row>
                                <v-row>
                                    <v-col cols="12">


                                        <v-data-table :headers="header_data_credit" :items="data_credit"
                                            hide-default-footer :items-per-page="10000"
                                            class="elevation-0 packhai-border-table">
                                            <template v-slot:item.description="{ item }">
                                                <v-row v-if="item.description != null">
                                                    <v-col cols="12" align="left" v-if="item.income != null">
                                                        <span>{{ item.description }}</span>
                                                    </v-col>
                                                    <v-col cols="12" align="left" style="padding-left: 50px;" v-else>
                                                        {{ item.description }}
                                                        <!-- <span style="color: red" v-if="item.invoiceReference != null">( อ้างอิงเลขที่ {{ item.invoiceReference }} )</span> -->
                                                    </v-col>
                                                </v-row>
                                            </template>
                                            <template v-slot:item.created="{ item }">
                                                {{ set_format_date_time(item.created) }}<br>
                                            </template>
                                            <template v-slot:item.income="{ item }">
                                                <font v-if="item.income != null" class="text-success"><b>+
                                                        {{ format_price(item.income) }}</b></font>
                                            </template>
                                            <template v-slot:item.expense="{ item }">
                                                <font v-if="item.expense != null" class="text-danger"><b>-
                                                        {{ format_price(item.expense) }}</b></font>
                                            </template>
                                            <template v-slot:item.balance="{ item }">
                                                <font><b>{{ format_price(item.balance) }}</b></font>
                                            </template>
                                            <template v-slot:item.edit="{ item }">
                                                <a @click="btnEditUpdateShopCustomerBalance(item)"><v-icon size="16"
                                                        color="orange darken-3">fa-edit</v-icon></a>
                                            </template>
                                            <template v-slot:item.delete="{ item, index }">
                                                <a @click="btnDeleteShopCustomerBalance(item)">
                                                    <v-icon size="16" color="red darken-3">fa-trash-alt</v-icon>
                                                </a>
                                            </template>
                                            <template v-slot:item.photoLink="{ item }">
                                                <a v-if="item.photoLink != null && item.photoLink != ''" icon
                                                    @click="OpenPhoto(item.photoLink)">
                                                    <img :src="item.photoLink" class="picsize mt-1">
                                                </a>
                                            </template>
                                            <template v-slot:item.updated="{ item }">
                                                {{ set_format_date_time(item.updated) }}<br>
                                            </template>

                                        </v-data-table>


                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </div>

                    <!-- #region ข้อมูลลูกค้า -->
                    <div v-if="item == 'customer_info'">

                        <!-- ที่อยู่จัดส่ง -->
                        <v-card>

                            <v-card-text>
                                <v-row>
                                    <v-col cols="6">
                                        <v-icon size="20" color="warning darken-3">fa-map-marked-alt</v-icon><span
                                            class="ml-3">{{ use_language.delivery_address }}</span>
                                    </v-col>
                                    <v-col cols="6" class="text-right">
                                        <v-btn dark color="primary" @click="EditShipping(0)"><v-icon
                                                left>mdi-plus</v-icon>
                                            {{ use_language.add }}</v-btn>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-data-table :headers="header_data_shippping" :items="data_shippping"
                                            :items-per-page="9999999999" class=" packhai-border-table"
                                            hide-default-footer>
                                            <!-- i.Discount == true ? true : flase -->
                                            <!-- <template v-slot:item.No="{ item }">{{ (data_customer.indexOf(item)+1) }}</template> -->
                                            <template v-slot:item.Name="{ item }">
                                                <span v-if="item.name != null">{{ item.name }}</span>
                                            </template>
                                            <template v-slot:item.Tel="{ item }">
                                                <span v-if="item.phone != null">{{ item.phone }}</span>
                                            </template>
                                            <template v-slot:item.FullAddress="{ item }">
                                                <span v-if="item.fullAddress != null">{{ item.fullAddress }}
                                                    <v-btn v-if="item.isDefault" class="ml-2" height="20" color="teal"
                                                        dark rounded elevation="0">{{ use_language.default }}</v-btn>
                                                </span>

                                            </template>
                                            <template v-slot:item.edit="{ item }">
                                                <v-btn @click="EditShipping(item.id)" icon><v-icon
                                                        size="18">fa-edit</v-icon></v-btn>
                                            </template>
                                            <template v-slot:item.delete="{ item }">
                                                <v-btn icon v-if="item.isDefault" disabled><v-icon size="18"
                                                        color="danger">fa-trash-alt</v-icon></v-btn>
                                                <v-btn @click="DeleteShipping(item.id)" icon v-else><v-icon size="18"
                                                        color="danger">fa-trash-alt</v-icon></v-btn>
                                            </template>
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                                <br>
                                <br>
                                <v-row>
                                    <v-col cols="6">
                                        <v-icon size="20" color="success darken-3">fa-map-marked-alt</v-icon><span
                                            class="ml-3">{{ use_language.tax_invoice_address }}</span>
                                    </v-col>
                                    <v-col cols="6" class="text-right">
                                        <v-btn dark color="primary" @click="EditInvoice(0)"><v-icon
                                                left>mdi-plus</v-icon>
                                            {{ use_language.add }}</v-btn>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">

                                        <v-data-table :headers="header_data_invoice" :items="data_invoice"
                                            class="elevation-0 packhai-border-table" hide-default-footer>
                                            <!-- i.Discount == true ? true : flase -->
                                            <!-- <template v-slot:item.No="{ item }">{{ (data_customer.indexOf(item)+1) }}</template> -->
                                            <template v-slot:item.tax="{ item }">
                                                <span v-if="item.taxID != null">{{ item.taxID }}</span>
                                            </template>
                                            <template v-slot:item.Name="{ item }">
                                                <span v-if="item.name != null">{{ item.name }}</span>
                                            </template>
                                            <template v-slot:item.Tel="{ item }">
                                                <span v-if="item.phone != null">{{ item.phone }}</span>
                                            </template>
                                            <template v-slot:item.FullAddress="{ item }">
                                                <span v-if="item.fullAddress != null">{{ item.fullAddress }}
                                                    <v-btn v-if="item.isDefault" class="ml-2" height="20" color="teal"
                                                        dark rounded elevation="0">{{ use_language.default }}</v-btn>
                                                </span>
                                            </template>
                                            <template v-slot:item.edit="{ item }">
                                                <v-btn @click="EditInvoice(item.id)" icon><v-icon
                                                        size="18">fa-edit</v-icon></v-btn>
                                            </template>
                                            <template v-slot:item.delete="{ item }">
                                                <v-btn icon v-if="item.isDefault" disabled><v-icon size="18"
                                                        color="danger">fa-trash-alt</v-icon></v-btn>
                                                <v-btn @click="DeleteInvoice(item.id)" icon v-else><v-icon size="18"
                                                        color="danger">fa-trash-alt</v-icon></v-btn>
                                            </template>
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                        <!-- ที่อยู่ใบกำกับภาษี -->

                    </div>
                    <!-- #endregion -->
                </v-tab-item>
            </v-tabs-items>

        </v-card>




        <!-- #region  v-dialog -->
        <v-dialog v-model="dialog_shipping" :fullscreen="isFullscreen" scrollable max-width="45%" persistent>
            <v-card>
                <v-card-title>
                    <span v-if="shipping.id != 0">{{ use_language.edit_address }}</span>
                    <span v-else>{{ use_language.add_address }}</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="dialog_shipping = false, loading = false"><v-icon
                            color="danger">mdi-close</v-icon></v-btn>
                </v-card-title>
                <v-divider />
                <v-card-text>
                    <v-row row class="pt-2">
                        <v-col cols="12" md="4" lg="3" class="py-0 pt-1">
                            <h3 class="fn-14"> {{ use_language.name }} : </h3>
                        </v-col>
                        <v-col cols="12" md="8" lg="9" class="py-0 pt-1">
                            <v-text-field :value="shipping.name" @change="value => shipping.name = value"
                                autocomplete="off" ref="refShippingName" outlined hide-details dense />
                        </v-col>
                    </v-row>
                    <v-row row class="pt-2">
                        <v-col cols="12" md="4" lg="3" class="py-0 pt-1">
                            <h3 class="fn-14"> {{ use_language.tel }} : </h3>
                        </v-col>
                        <v-col cols="12" md="8" lg="9" class="py-0 pt-1">
                            <v-text-field :value="shipping.phone" @change="value => shipping.phone = value"
                                autocomplete="off" ref="refShippingPhone" outlined hide-details dense maxlength="10"
                                @keypress="isNumberWNoDot($event)" />
                        </v-col>
                    </v-row>
                    <v-row row class="pt-2">
                        <v-col cols="12" md="4" lg="3" class="py-0 pt-1">
                            <h3 class="fn-14"> {{ use_language.address }} : </h3>
                        </v-col>
                        <v-col cols="12" md="8" lg="9" class="py-0 pt-1">
                            <v-text-field :value="shipping.address" @change="value => shipping.address = value"
                                autocomplete="off" ref="refShippingTextAddress" outlined hide-details dense />
                        </v-col>
                    </v-row>
                    <v-row row class="pt-2">
                        <v-col class="pb-0" cols="12" md="4" lg="3">
                            <h3>{{ use_language.zipcode }} :</h3>
                        </v-col>
                        <v-col class="pb-0" cols="12" md="8" lg="9">
                            <v-autocomplete :value="shipping.postcodeMappingID"
                                @change="value => shipping.postcodeMappingID = value" ref="refShippingPostcode"
                                :items="postcode_mapping"
                                :item-text="item => item.postcode + ' ' + item.province + ' ' + item.district + ' ' + item.subDistrict"
                                outlined dense hide-details autocomplete="off" required return-object>
                                <!-- แก้จากเดิมเป็น 3 ภาษา -->
                                <template v-slot:label> <span>{{ use_language.all_address }}</span> </template>

                            </v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row row>
                        <v-col class="pb-0" cols="12" md="4" lg="3">
                            <h3>{{ use_language.set_default }} :</h3>
                        </v-col>
                        <v-col class="pb-0" cols="12" md="8" lg="9">
                            <v-checkbox :readonly="IsReadonly" v-model="shipping.isDefault"></v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="pb-0" cols="12" md="12" align="right">
                            <v-btn dark color="primary" @click="EditShippingConfirm()" class="mr-1"> <v-icon
                                    left>mdi-content-save</v-icon> {{ use_language.record }} </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialog_invoice" :fullscreen="isFullscreen" scrollable max-width="45%" persistent>
            <v-card>
                <v-card-title>
                    <span v-if="invoice.id != 0">{{ use_language.edit_tax_invoice_address }}</span>
                    <span v-else>{{ use_language.add_tax_invoice_address }}</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="dialog_invoice = false"><v-icon color="danger">mdi-close</v-icon></v-btn>
                </v-card-title>
                <v-divider />
                <v-card-text>
                    <v-row row class="pt-2">
                        <v-col cols="12" md="4" lg="3" class="py-0 pt-1">
                            <h3 class="fn-14"> {{ use_language.tax_invoice_number }} : </h3>
                        </v-col>
                        <v-col cols="12" md="8" lg="9" class="py-0 pt-1">
                            <v-text-field :value="invoice.taxID" @change="value => invoice.taxID = value"
                                autocomplete="off" required ref="refInvoiceTaxID" outlined hide-details maxlength="13"
                                dense @keypress="isNumberWNoDot($event)" />
                        </v-col>
                    </v-row>
                    <v-row row class="pt-2">
                        <v-col cols="12" md="4" lg="3" class="py-0 pt-1">
                            <h3 class="fn-14"> {{ use_language.name }} : </h3>
                        </v-col>
                        <v-col cols="12" md="8" lg="9" class="py-0 pt-1">
                            <v-text-field :value="invoice.name" @change="value => invoice.name = value"
                                autocomplete="off" required ref="refInvoiceName" outlined hide-details dense />
                        </v-col>
                    </v-row>
                    <v-row row class="pt-2">
                        <v-col cols="12" md="4" lg="3" class="py-0 pt-1">
                            <h3 class="fn-14"> {{ use_language.tel }} : </h3>
                        </v-col>
                        <v-col cols="12" md="8" lg="9" class="py-0 pt-1">
                            <v-text-field :value="invoice.phone" @change="value => invoice.phone = value"
                                autocomplete="off" ref="refInvoicePhone" outlined hide-details dense maxlength="10"
                                @keypress="isNumberWNoDot($event)" />
                        </v-col>
                    </v-row>
                    <v-row row class="pt-2">
                        <v-col cols="12" md="4" lg="3" class="py-0 pt-1">
                            <h3 class="fn-14"> {{ use_language.address }} : </h3>
                        </v-col>
                        <v-col cols="12" md="8" lg="9" class="py-0 pt-1">
                            <v-text-field :value="invoice.address" @change="value => invoice.address = value"
                                autocomplete="off" ref="refInvoiceTextAddress" outlined hide-details dense />
                        </v-col>
                    </v-row>
                    <v-row row class="pt-2">
                        <v-col class="pb-0" cols="12" md="4" lg="3">
                            <h3>{{ use_language.zipcode }} :</h3>
                        </v-col>
                        <v-col class="pb-0" cols="12" md="8" lg="9">
                            <v-autocomplete v-model="invoice.postcodeMappingID" ref="refInvoiceAddress"
                                placeholder="รหัสไปรษณีย์ / จังหวัด / (เขต/อำเภอ) / (แขวง/ตำบล)"
                                :items="postcode_mapping"
                                :item-text="item => item.postcode + ' ' + item.province + ' ' + item.district + ' ' + item.subDistrict"
                                outlined required return-object hide-details dense>
                                <template v-slot:label> <span>{{ use_language.all_address }}</span> </template>
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row row>
                        <v-col class="pb-0" cols="12" md="4" lg="3">
                            <h3>{{ use_language.set_default }} :</h3>
                        </v-col>
                        <v-col class="pb-0" cols="12" md="8" lg="9">
                            <v-checkbox :readonly="IsReadonly" v-model="invoice.isDefault"></v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row row>
                        <v-col class="pb-0" cols="12" md="12" align="right">
                            <v-btn dark color="primary" @click="EditInvoiceConfirm()" class="mr-1"><v-icon
                                    left>mdi-content-save</v-icon> บันทึก</v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>



        <!-- เพิ่มประวัติการชำระเงิน -->
        <v-dialog v-model="dialog_credit" :fullscreen="isFullscreen" scrollable max-width="50%" persistent>


            <v-card>
                <v-card-title>
                    <span v-if="customerBalance.id == 0">เพิ่มรายการเติมเครดิต</span>
                    <span v-else>แก้ไขรายการเติมเครดิต</span>

                    <v-spacer />
                    <v-btn icon @click="dialog_credit = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider />
                <v-card-text>

                    <v-row>
                        <v-col cols="12" md="2" lg="2" class="pb-0">
                            <h4>วันที่-เวลา :</h4>
                        </v-col>
                        <v-col cols="12" sm="10" lg="8" class="pb-0">
                            <date-time-picker v-model="customerBalance.created" :isLabel="'วันที่-เวลา'" :isTime="true"
                                style="width:100%" />
                        </v-col>


                    </v-row>

                    <v-row>
                        <v-col cols="12" sm="2" lg="2" class="pb-0">
                            <h4>รายละเอียด :</h4>
                        </v-col>
                        <v-col cols="12" sm="10" lg="8" class="pb-0">
                            <v-text-field v-model="customerBalance.description" autocomplete="off" outlined hide-details
                                dense></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="2" lg="2" class="pb-0">
                            <h4>เข้า :</h4>
                        </v-col>
                        <v-col cols="12" sm="10" lg="8" class="pb-0">
                            <v-text-field v-model.number="customerBalance.income" autocomplete="off" type="number"
                                outlined hide-details dense></v-text-field>
                        </v-col>

                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="2" lg="2" class="pb-0">
                            <h4>ออก :</h4>
                        </v-col>
                        <v-col cols="12" sm="10" lg="8" class="pb-0">
                            <v-text-field v-model.number="customerBalance.expense" autocomplete="off" type="number"
                                outlined hide-details dense></v-text-field>
                        </v-col>

                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="2" lg="2" class="pb-0">
                            <h4>แนบไฟล์ :</h4>
                        </v-col>
                        <v-col cols="12" sm="10" lg="8" class="pb-0">
                            <v-file-input v-model="customerBalance.files" outlined hide-details="auto" dense
                                prepend-icon="" prepend-inner-icon="mdi-file-image">
                                <template v-slot:selection="{ text }">
                                    <v-chip small label color="primary">
                                        {{ text }}
                                    </v-chip>
                                </template>
                            </v-file-input>
                        </v-col>
                    </v-row>


                </v-card-text>
                <v-divider />
                <v-card-actions class="py-5" style="justify-content: flex-end;">
                    <v-btn outlined color="primary" @click="btnSubmitUpdateShopCustomerBalance()">
                        {{ use_language.save }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- #endregion -->
    </div>
</template>
<script>
import Loading from '@/website/components/Loading'
import axios from 'axios'
import { generalService_dotnet, shopService_dotnet } from '@/website/global'
import { isNumberWNoDot, get_languages, format_number, format_order_id, format_price, set_format_date_time,getPostcodeMapping } from '@/website/global_function'
import { token_api } from '@/website/backend/token'
import { AlertSuccess, AlertError } from "@/website/global_alert";
import dateTimePicker from "@/website/components/dateTimePicker"
import moment from "moment";
import Swal from "sweetalert2";
import Photo from "@/website/components/photo";

export default {
    components: {
        Loading,
        dateTimePicker,
        Photo,
    },
    data: () => ({
        header_token: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('authorization_token') },
        page_loading: true,
        loading: false,

        use_language: null,
        set_language: null,

        StaffShopID: localStorage.getItem("staff_id"),
        // แสดงภาพ
        ShowPhoto: false,
        ShowPhotoLink: '',

        tab: null,
        customerID: null,
        tab_items: ['order_list', 'credit_list', 'customer_info'],
        //#region  order_list

        header_data_order_customer: [
            { text: 'No', align: 'center', value: 'no', sortable: false, width: '60px' },
            { text: 'OrderID', align: 'center', value: 'orderID', sortable: false, width: '150px' },
            { text: 'วันที่เปิดออเดอร์', align: 'left', value: 'createDatetime', sortable: false, width: '200px' },
            { text: 'ที่อยู่', align: 'center', value: 'address', sortable: false },
            { text: 'ยอดเงิน', align: 'right', value: 'totalPrice', sortable: false, width: '70px' },
            { text: 'ช่องทางการขาย', align: 'center', value: 'channelName', sortable: false, width: '120px' },
            { text: 'ขนส่ง', align: 'center', value: 'expressCompanyName', sortable: false, width: '50px' },
            { text: 'สถานะ', align: 'center', value: 'shippingStatusName', sortable: false, width: '100px' },
        ],
        data_order_customer: [],

        //#endregion

        //#region  credit_list


        customerBalance:
        {
            "id": 0,
            "shopCustomerID": 0,
            "created": null,
            "description": "",
            "income": null,
            "expense": null,
            "photoLink": null,
            "updatedBy": null
        },
        dialog_credit: false,


        search_menu_date_from: false,
        search_date_from: null,

        search_menu_date_to: false,
        search_date_to: null,

        balance: 0,

        data_credit: [],
        header_data_credit: [
            { text: 'แก้ไข', align: 'center', sortable: false, value: 'edit', width: '30px' },
            { text: 'ลบ', align: 'center', sortable: false, value: 'delete', width: '30px' },
            { text: 'ภาพ', align: 'center', value: 'photoLink', sortable: false, width: '100px' },
            { text: 'วันที่', align: 'center', value: 'created', sortable: false, width: '140px' },
            { text: 'รายละเอียด', align: 'left', value: 'description', sortable: false, width: '300px' },
            { text: 'เข้า', align: 'right', value: 'income', sortable: false, width: '80px' },
            { text: 'ออก', align: 'right', value: 'expense', sortable: false, width: '80px' },
            { text: 'คงเหลือ', align: 'right', value: 'balance', sortable: false, width: '120px' },
            { text: 'วันที่อัพเดท', align: 'center', value: 'updated', sortable: false, width: '120px' },
            { text: 'อัพเดทโดย', align: 'center', value: 'updatedByName', sortable: false, width: '100px' },

        ],










        //excel
        excel_topupHistory_header: [
            { label: "วันที่", field: "created", width: 20, dataFormat: set_format_date_time },
            { label: "รายละเอียด", field: "description", width: 35 },
            { label: "เข้า", field: "income", width: 15 },
            { label: "ออก", field: "expense", width: 15 },
            { label: "คงเหลือ", field: "balance", width: 15 },
            { label: "วันที่อัพเดท", field: "updated", width: 15 },
            { label: "อัพเดทโดย", field: "updatedByName", width: 15 }
        ],
        // #endregion

        //#region  customer_info
        EditCustomerDisibled: true,
        dialog_shipping: false,
        dialog_invoice: false,
        dialog_addcustomer: false,
        isFullscreen: false,
        IsReadonly: false,
        ShopID: null,
        postcode_mapping: [],
        date: null,
        countcustomer: null,
        new_customer: {
            "CustomerName": null,
            "CustomerPhone": null,
            "CustomerEmail": null,
            "CustomerFacebook": null,
            "CustomerLine": null,
            "CustomerRemark": null,
            "ShippingName": null,
            "ShippingPhone": null,
            "ShippingTextAddress": null,
            "ShippingAddress": null,
            "InvoiceTaxID": null,
            "InvoiceName": null,
            "InvoicePhone": null,
            "InvoiceTextAddress": null,
            "InvoiceAddress": null,
        },
        customer: {
            "id": 0,
            "customerCode": null,
            "name": null,
            "phone": null,
            "email": null,
            "facebook": null,
            "line": null,
            "remark": null,
            "shopID": null,
            "created": null
        },
        shipping: {
            "id": 0,
            "name": null,
            "phone": null,
            "address": null,
            "shopCustomerID": 0,
            "shopID": null,
            "isDefault": false,
            "postcodeMappingID": 0
        },
        invoice: {
            "id": 0,
            "taxID": null,
            "name": null,
            "phone": null,
            "address": null,
            "shopCustomerID": 0,
            "isDefault": false,
            "shopID": null,
            "postcodeMappingID": 0
        },
        search: {
            "name": null,
            "tel": null,
            "line": null,
            "email": null,
            "facebook": null,
            "address": null,
        },
        data_customer: [],
        data_shippping: [],
        data_invoice: [],
        header_data_customer: [
            { text: 'ลำดับ', align: 'center', value: 'No', sortable: false, width: '50px' },
            { text: 'รหัสลูกค้า', align: 'center', value: 'customerCode', sortable: false, width: '50px' },
            { text: 'ชื่อ', align: 'center', value: 'Name', sortable: false, width: '150px' },
            { text: 'เบอร์โทร', align: 'center', value: 'Tel', sortable: false, width: '50px' },
            { text: 'ที่อยู่', align: 'center', value: 'Address', sortable: false, width: '200px' },
            { text: 'อีเมลล์', align: 'center', value: 'Email', sortable: false, width: '100px' },
            { text: 'facebook', align: 'center', value: 'Facebook', sortable: false, width: '100px' },
            { text: 'line', align: 'center', value: 'Line', sortable: false, width: '100px' },
            { text: 'รายละเอียด', align: 'center', value: 'detail', sortable: false, width: '100px' }
        ],
        header_data_shippping: [
            // { text: 'ลำดับ', align: 'center', value: 'No', sortable: false,width: '50px' },
            { text: 'ชื่อ', align: 'left', value: 'Name', sortable: false, width: '100px' },
            { text: 'เบอร์โทร', align: 'left', value: 'Tel', sortable: false, width: '100px' },
            { text: 'ที่อยู่', align: 'left', value: 'FullAddress', sortable: false, width: '200px' },
            { text: 'แก้ไข', align: 'center', value: 'edit', sortable: false, width: '50px' },
            { text: 'ลบ', align: 'center', value: 'delete', sortable: false, width: '50px' },
        ],
        header_data_invoice: [
            // { text: 'ลำดับ', align: 'center', value: 'No', sortable: false,width: '50px' },
            { text: 'เลขผู้เสียภาษี', align: 'left', value: 'tax', sortable: false, width: '100px' },
            { text: 'ชื่อ', align: 'left', value: 'Name', sortable: false, width: '100px' },
            { text: 'เบอร์โทร', align: 'left', value: 'Tel', sortable: false, width: '100px' },
            { text: 'ที่อยู่', align: 'left', value: 'FullAddress', sortable: false, width: '200px' },
            { text: 'แก้ไข', align: 'center', value: 'edit', sortable: false, width: '50px' },
            { text: 'ลบ', align: 'center', value: 'delete', sortable: false, width: '50px' },
        ],

        // :rules
        rules: {
            customername: [],
            customeremail: [],
            customerphone: [],
            customerfacebook: [],
            customerline: [],
            shippingname: [],
            shippingphone: [],
            shippingaddress: []
        },
        // paginations
        page: 1,
        pageTemp: 1,
        pageLength: 1,
        pageTotalVisible: 9,
        limits: 10,
        offsets: 0,
        // #endregion




    }),
    computed: {
        computed_search_date_from() {
            console.log();

            return this.formatDates(this.search_date_from)
        },
        computed_search_date_to() {
            return this.formatDates(this.search_date_to)
        },
    },
    watch: {

    },
    async created() {

        //เก็บภาษามาไว้ใน use_language
        this.postcode_mapping = await this.getPostcodeMapping();
        this.use_language = await this.get_languages(localStorage.getItem("set_language"));





        let customerID = this.$route.query.id

        if (customerID) {
            this.customerID = customerID
            this.ShopID = localStorage.getItem("shop_id")
            this.date = new Date().toISOString()
      


            let creditList = await this.getShopCustomerBalance();
            let customerinfo = await this.getDetailCustomer();




            this.search_date_from = creditList.dateFrom
            this.search_date_to = creditList.dateTo
            this.data_credit = creditList.items


            this.customer = customerinfo.customer
            this.data_shippping = customerinfo.shippingAddressList
            this.data_invoice = customerinfo.invoiceAddressList




            let orderCustomer = await this.getOrderCustomerDetail();
            this.data_order_customer = orderCustomer.items
            this.pageLength = (Math.ceil(orderCustomer.resultCount / this.limits))
            if (this.pageLength <= 0) { this.pageLength = 1 }
            this.pageTemp = this.page

            this.page_loading = false
            this.loading = false


        } else {
            localStorage.clear();

        }

        this.page_loading = false
        this.loading = false

        //#region  credit_list

        // #endregion



        //ประกาศสร้าง หัวข้อ ใช้ 3 ภาษา
        this.created_header_data_table();
    },
    methods: {
        getPostcodeMapping,
        async load_data() {
            this.loading = true


            let creditList = await this.getShopCustomerBalance();
            let customerinfo = await this.getDetailCustomer();




            this.search_date_from = creditList.dateFrom
            this.search_date_to = creditList.dateTo
            this.data_credit = creditList.items


            this.customer = customerinfo.customer
            this.data_shippping = customerinfo.shippingAddressList
            this.data_invoice = customerinfo.invoiceAddressList



            let orderCustomer = this.getOrderCustomerDetail();
            this.data_order_customer = await orderCustomer
            this.pageLength = (Math.ceil(orderCustomer.resultCount / this.limits))
            if (this.pageLength <= 0) { this.pageLength = 1 }
            this.pageTemp = this.page

            this.loading = false
        },


        //#region  order_list
        async getOrderCustomerDetail(body) {
            return await axios
                .post(
                    shopService_dotnet + "Customer/get-order-customer-detail",
                    {
                        "customerID": this.customerID,
                        "createDateFrom": "",
                        "createDateTo": "",
                        "shopID": this.ShopID,
                        "skip": this.offsets,
                        "take": this.limits
                    },
                    { headers: this.header_token }
                )
                .then(async (res) => {
                    return res.data
                })
                .catch((error) => {
                    this.AlertError();
                    return false;
                });
        },
        detail_page(id) {
            window.open('order-detail?id=' + id, '_blank');
        },

        async changePage() {
            if (this.pageLength != 1 && this.pageTemp != this.page) {
                this.loading = true
                this.offsets = (this.page * this.limits) - this.limits
                let res = await this.getOrderCustomerDetail();
                this.data_order_customer = res.items
                this.pageTemp = this.page
                this.loading = false
            }
        },
        //#endregion

        //#region  credit_list

        async getShopCustomerBalance() {
            this.loading = true;
            return await axios
                .post(
                    shopService_dotnet + "Customer/get-shop-customer-balance",
                    {
                        "shopCustomerID": this.customerID,
                        "dateFrom": this.search_date_from,
                        "dateTo": this.search_date_to,
                    },
                    { headers: this.header_token }
                )
                .then(async (res) => {
                    this.loading = false;
                    return res.data
                })
                .catch((error) => {
                    this.loading = false;
                    this.AlertError();
                    return false;
                });
        },
        async updateShopCustomerBalance() {

            return await axios
                .post(
                    shopService_dotnet + "Customer/update-shop-customer-balance",
                    {
                        "id": this.customerBalance.id,
                        "shopCustomerID": parseInt(this.customerID),
                        "created": this.formatDateTimeToISO(this.customerBalance.created),
                        "description": this.customerBalance.description,
                        "income": parseInt(this.customerBalance.income),
                        "expense": parseInt(this.customerBalance.expense),
                        "balance": this.customerBalance.balance,
                        "orderMasterID": this.customerBalance.orderMasterID,
                        "photoLink": this.customerBalance.photoLink,
                        "updatedBy": parseInt(this.StaffShopID)
                    },
                    { headers: this.header_token }
                )
                .then(async (res) => {
                    this.loading = false;
                    return res.data
                })
                .catch((error) => {
                    this.loading = false;
                    this.AlertError();
                    return false;
                });
        },
        async deleteShopCustomerBalance(body) {
            this.loading = true;
            return await axios
                .get(
                    shopService_dotnet + "Customer/delete-shop-customer-balance?id=" + body.id,
                    { headers: this.header_token }
                )
                .then(async (res) => {
                    return res.data
                })
                .catch((error) => {
                    this.loading = false;
                    this.AlertError();
                    return false;
                });
        },
        async btnAddUpdateShopCustomerBalance() {
            this.customerBalance.created = moment().format("DD-MM-YYYY HH:mm");
            this.customerBalance.id = 0;
            this.customerBalance.orderMasterID = null;
            this.customerBalance.description = "";
            this.customerBalance.income = null;
            this.customerBalance.expense = null;
            this.customerBalance.balance = 0;
            this.customerBalance.photoLink = null;
            this.dialog_credit = true;

        },
        async btnEditUpdateShopCustomerBalance(item) {

            this.customerBalance.created = moment(item.created, "YYYY-MM-DDTHH:mm:ss.SS").format("DD-MM-YYYY HH:mm");
            this.customerBalance.id = item.id;
            this.customerBalance.orderMasterID = item.orderMasterID;
            this.customerBalance.description = item.description;
            this.customerBalance.income = item.income;
            this.customerBalance.expense = item.expense;
            this.customerBalance.balance = item.balance;
            this.customerBalance.photoLink = item.photoLink;
            this.dialog_credit = true;

        },

        async btnSubmitUpdateShopCustomerBalance() {
            this.loading = true;

            if (this.customerBalance.created == null) {
                Swal.fire({
                    text: "กรุณาระบุวันที่-เวลา",
                    type: "warning",
                });
                this.loading = false;
                return;
            }
            if (!this.customerBalance.income && !this.customerBalance.expense) {
                Swal.fire({
                    // title: "The Internet?",
                    text: "กรุณาระบุ เข้า หรือ ออก",
                    type: "warning",
                });
                this.loading = false;
                return;
            }


            if (this.customerBalance.files) {
                let resUpload = await this.UploadFile(this.customerBalance.files)
                if (resUpload.status == "success") {
                    this.customerBalance.photoLink = resUpload.file_path
                }
                else { this.alertStop(); }
            }




            let res = await this.updateShopCustomerBalance()

            if (res.status == "success") {
                this.search_date_from = null
                this.search_date_to = null
                let creditList = await this.getShopCustomerBalance();
                this.search_date_from = creditList.dateFrom
                this.search_date_to = creditList.dateTo
                this.data_credit = creditList.items
                let customerinfo = await this.getDetailCustomer();
                this.customer = customerinfo.customer

                this.alertSuccess();
                this.dialog_credit = false
            } else {
                this.alertError(res.error_description)
            }


            this.loading = false;


        },
        async btnDeleteShopCustomerBalance(item) {
            this.loading = true;
            Swal.fire({

                type: 'question',
                title: 'ต้องการลบ ?',
                text: item.description + ' ยอด : ' + format_price((item.income == null ? item.expense : item.income)),
                confirmButtonText: 'ยืนยัน',
                cancelButtonText: 'ยกเลิก',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                allowOutsideClick: false
            }).then(async (result) => {
                if (result.value) {

                    let res = await this.deleteShopCustomerBalance(item)
                    if (res.status == "success") {
                        this.search_date_from = null
                        this.search_date_to = null

                        let creditList = await this.getShopCustomerBalance();
                        this.search_date_from = creditList.dateFrom
                        this.search_date_to = creditList.dateTo
                        this.data_credit = creditList.items

                        let customerinfo = await this.getDetailCustomer();
                        this.customer = customerinfo.customer

                        this.alertSuccess();
                    }

                }
            });
            this.loading = false;
        },
        async btnSearchShopCustomerBalance() {
            let creditList = await this.getShopCustomerBalance();
            this.data_credit = creditList.items

        },
        // #endregion


        //#region  customer_info

        async getDetailCustomer() {
            return await axios
                .post(
                    shopService_dotnet + "Customer/get-customer-info",
                    {
                        "ShopCustomerID": this.customerID,
                    },
                    { headers: this.header_token }
                )
                .then(async (res) => {
                    return res.data
                })
                .catch((error) => {
                    this.loading = false;
                    this.AlertError();
                    return false;
                });
        },
        async EditCustomer() {
            this.loading = true
            axios.all([
                axios.post(shopService_dotnet + 'Customer/update-customer-info', {
                    "id": this.customer.id,
                    "customerCode": this.customer.customerCode,
                    "name": this.customer.name,
                    "phone": this.customer.phone,
                    "email": this.customer.email,
                    "facebook": this.customer.facebook,
                    "line": this.customer.line,
                    "remark": this.customer.remark
                }, { headers: this.header_token }),
            ])
                .then(axios.spread((customer) => {
                    this.loading = false
                    if (customer.status == 200) {
                        this.$swal({
                            toast: true,
                            timer: 1500,
                            showConfirmButton: false,
                            position: 'top',
                            type: 'success',
                            title: customer.data
                        })
                        this.EditCustomerDisibled = true
                    } else {
                        this.loading = false

                        this.$swal({
                            type: 'warning',
                            title: 'มีข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้ง!!',
                        })
                    }
                })).catch((error) => {
                    this.loading = false;
                    this.AlertError();
                    return false;
                });

        },
        async EditShipping(shippingid) {
            this.loading = true
            if (shippingid == 0) {
                this.clearshipping()
                this.shipping.shopCustomerID = this.customer.id
                this.shipping.IsDefault = false
                this.dialog_shipping = true
            }
            else {

                axios.all([
                    axios.post(shopService_dotnet + 'Customer/get-customer-address', {
                        'id': shippingid,
                    }, { headers: this.header_token }),
                ])
                    .then(axios.spread((shipping) => {
                        if (shipping.status == 200) {
                            this.loading = false

                            this.shipping = shipping.data
                            for (var p in this.postcode_mapping) {
                                if (this.postcode_mapping[p]['id'] == this.shipping.postcodeMappingID) {
                                    this.shipping.postcodeMappingID = {
                                        "id": this.postcode_mapping[p]['id'],
                                        "subDistrict": this.postcode_mapping[p]['subDistrict'],
                                        "district": this.postcode_mapping[p]['district'],
                                        "province": this.postcode_mapping[p]['province'],
                                        "postcode": this.postcode_mapping[p]['postcode']
                                    }
                                }
                            }
                            this.dialog_shipping = true
                        } else {
                            this.loading = false

                            this.$swal({
                                type: 'warning',
                                title: 'มีข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้ง!!',
                            })
                        }
                    }));
            }
        },
        async EditShippingConfirm() {
            this.loading = true;
            if (this.shipping.name == null || this.shipping.name == '') {
                this.$refs.refShippingName.focus()
                this.loading = false;

                return
            } else if (this.shipping.phone == null || this.shipping.phone == '') {
                this.$refs.refShippingPhone.focus()
                this.loading = false;
                return
            }
            this.loading = true;
            axios.all([
                axios.post(shopService_dotnet + 'Customer/update-customer-address', {
                    "id": this.shipping.id,
                    "name": this.shipping.name,
                    "phone": this.shipping.phone,
                    "address": this.shipping.address,
                    "shopCustomerID": this.shipping.shopCustomerID,
                    "IsDefault": this.shipping.isDefault,
                    "postcodeMappingID": this.shipping.postcodeMappingID.id
                }, { headers: this.header_token }),
            ])
                .then(axios.spread((shipping) => {
                    this.loading = true;

                    if (shipping.status == 200) {
                        this.data_shippping = shipping.data
                        this.loading = false;

                        this.dialog_shipping = false
                    } else {
                        this.loading = false;

                        this.$swal({
                            type: 'warning',
                            title: 'มีข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้ง!!',
                        })
                    }
                })).catch((error) => {
                    this.loading = false;
                    this.AlertError();
                    return false;
                });

        },
        async DeleteShipping(shippingid) {



            this.loading = true;
            this.$swal({
                position: "center",
                type: 'warning',
                title: 'ลบ ใช่หรือไม่ !!',
                confirmButtonText: 'ยืนยัน',
                cancelButtonText: 'ยกเลิก',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                allowOutsideClick: false
            }).then(async (result) => {
                this.loading = true;
                if (result.value) {
                    axios.all([
                        axios.post(shopService_dotnet + 'Customer/delete-customer-address', {
                            'id': shippingid,
                        }, { headers: this.header_token }),
                    ])
                        .then(axios.spread((shipping) => {
                            if (shipping.status == 200) {
                                this.data_shippping = shipping.data
                                this.loading = false;

                            } else {
                                this.$swal({
                                    type: 'warning',
                                    title: 'มีข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้ง!!',
                                })
                                this.loading = false;

                            }
                        }));
                }
                this.loading = false;
            })
            this.loading = false;

        },
        async EditInvoice(invoiceid) {
            this.loading = true
            if (invoiceid == 0) {
                this.clearinvoice()
                this.invoice.shopCustomerID = this.customer.id
                this.invoice.IsDefault = false
                this.dialog_invoice = true
            }
            else {
                this.clearinvoice()
                axios.all([
                    axios.get(shopService_dotnet + 'Customer/get-customer-invoice-address-by-address-id?InvoiceAddressID=' + invoiceid, { headers: this.header_token }),
                ])
                    .then(axios.spread((invoice) => {
                        if (invoice.status == 200) {
                            this.invoice = invoice.data
                            for (var p in this.postcode_mapping) {
                                if (this.postcode_mapping[p]['id'] == this.invoice.postcodeMappingID) {
                                    this.invoice.postcodeMappingID = {
                                        "id": this.postcode_mapping[p]['id'],
                                        "subDistrict": this.postcode_mapping[p]['subDistrict'],
                                        "district": this.postcode_mapping[p]['district'],
                                        "province": this.postcode_mapping[p]['province'],
                                        "postcode": this.postcode_mapping[p]['postcode']
                                    }
                                }
                            }
                            this.dialog_invoice = true
                        }
                    }));
            }
            this.loading = false
        },
        async EditInvoiceConfirm() {
            this.loading = true
            try {



                if (this.invoice.name == null || this.invoice.name == '') {
                    this.$refs.refInvoiceName.focus()
                    return
                } else if (this.invoice.taxID == null || this.invoice.taxID == '') {
                    this.$refs.refInvoiceTaxID.focus()
                    return
                }
                this.loading = true
                axios.all([
                    axios.post(shopService_dotnet + 'Customer/update-customer-invoice-address', {
                        "id": this.invoice.id,
                        "taxID": this.invoice.taxID,
                        "name": this.invoice.name,
                        "phone": this.invoice.phone,
                        "address": this.invoice.address,
                        "shopCustomerID": this.invoice.shopCustomerID,
                        "isDefault": this.invoice.isDefault,
                        "postcodeMappingID": this.invoice.postcodeMappingID.id
                    }, { headers: this.header_token }),
                ])
                    .then(axios.spread((invoice) => {
                        if (invoice.status == 200) {
                            this.loading = false;
                            this.data_invoice = invoice.data
                            this.dialog_invoice = false
                        } else {
                            this.loading = false;
                            this.$swal({
                                type: 'warning',
                                title: 'มีข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้ง!!',
                            })
                        }
                    })).catch((error) => {
                        this.loading = false;
                        this.AlertError();
                        return false;
                    });
            } catch (error) {
                this.AlertError();
                this.loading = false;
            }
        },
        async DeleteInvoice(invoiceid) {
            this.loading = true;
            this.$swal({
                position: "center",
                type: 'warning',
                title: 'ลบ ใช่หรือไม่ !!',
                confirmButtonText: 'ยืนยัน',
                cancelButtonText: 'ยกเลิก',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                allowOutsideClick: false
            }).then(async (result) => {
                if (result.value) {
                    this.loading = true;
                    axios.all([
                        axios.post(shopService_dotnet + 'Customer/delete-customer-invoice-address?InvoiceAddressID=' + invoiceid, {}, { headers: this.header_token }),
                    ])
                        .then(axios.spread((invoice) => {
                            if (invoice.status == 200) {
                                this.loading = false;

                                this.data_invoice = invoice.data
                            } else {
                                this.loading = false;
                                this.$swal({
                                    type: 'warning',
                                    title: 'มีข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้ง!!',
                                })
                            }
                        })).catch((error) => {
                            this.loading = false;
                            this.AlertError();
                            return false;
                        });
                }
                this.loading = false;
            })
            this.loading = false;
        },
      
        async rulesnewcustomer() {
            this.rules.customername = [v => !!v || "กรุณาระบุ"]
            this.rules.customeremail = [v => !!v || "กรุณาระบุ"]
            this.rules.customerphone = [v => !!v || "กรุณาระบุ"]
            this.rules.customerfacebook = [v => !!v || "กรุณาระบุ"]
            this.rules.customerline = [v => !!v || "กรุณาระบุ"]
            this.rules.shippingname = [v => !!v || "กรุณาระบุ"]
            this.rules.shippingphone = [v => !!v || "กรุณาระบุ"]
            this.rules.shippingaddress = [v => !!v || "กรุณาระบุ"]
        },

        clearshipping() {
            this.shipping = {
                "id": 0,
                "name": null,
                "phone": null,
                "address": null,
                "postcodeMappingID": null,
                "shopCustomerID": null,
                "isDefault": false,
            }
        },
        clearinvoice() {
            this.invoice = {
                "id": 0,
                "taxID": null,
                "name": null,
                "phone": null,
                "address": null,
                "shopCustomerID": null,
                "IsDefault": null,
                "postcodeMappingID": null
            }
        },
        created_header_data_table() {
            this.header_data_customer = [
                { text: this.use_language.number, align: 'center', value: 'No', sortable: false, width: '50px' },
                { text: this.use_language.customerCode, align: 'center', value: 'customerCode', sortable: false, width: '100px' },
                { text: this.use_language.name, align: 'center', value: 'Name', sortable: false, width: '150px' },
                { text: this.use_language.tel, align: 'center', value: 'Tel', sortable: false, width: '50px' },
                { text: this.use_language.address, align: 'center', value: 'Address', sortable: false, width: '200px' },
                { text: this.use_language.email, align: 'center', value: 'Email', sortable: false, width: '100px' },
                { text: this.use_language.facebook, align: 'center', value: 'Facebook', sortable: false, width: '100px' },
                { text: this.use_language.line, align: 'center', value: 'Line', sortable: false, width: '100px' },

                { text: this.use_language.details, align: 'center', value: 'detail', sortable: false, width: '100px' }
            ]
            this.header_data_shippping = [
                // { text: 'ลำดับ', align: 'center', value: 'No', sortable: false,width: '50px' },
                { text: this.use_language.name, align: 'left', value: 'Name', sortable: false, width: '100px' },
                { text: this.use_language.tel, align: 'left', value: 'Tel', sortable: false, width: '100px' },
                { text: this.use_language.address, align: 'left', value: 'FullAddress', sortable: false, width: '200px' },
                { text: this.use_language.edit, align: 'center', value: 'edit', sortable: false, width: '50px' },
                { text: this.use_language.delete, align: 'center', value: 'delete', sortable: false, width: '50px' },
            ]
            this.header_data_invoice = [
                // { text: 'ลำดับ', align: 'center', value: 'No', sortable: false,width: '50px' },
                { text: this.use_language.tax_invoice_number, align: 'left', value: 'tax', sortable: false, width: '100px' },
                { text: this.use_language.name, align: 'left', value: 'Name', sortable: false, width: '100px' },
                { text: this.use_language.tel, align: 'left', value: 'Tel', sortable: false, width: '100px' },
                { text: this.use_language.address, align: 'left', value: 'FullAddress', sortable: false, width: '200px' },
                { text: this.use_language.edit, align: 'center', value: 'edit', sortable: false, width: '50px' },
                { text: this.use_language.delete, align: 'center', value: 'delete', sortable: false, width: '50px' },
            ]



        },
        // #endregion

        //#region  import
        toCustomer() {
            this.$router.push('/manage-customer')
        },
        async UploadFile(files) {
            let bodyUploadFile = [];


            var from_data = new FormData();
            from_data.append("bucket", "Doc_file");
            from_data.append("file", files);
            bodyUploadFile = from_data;

            return await axios
                .post(
                    generalService_dotnet + "General/upload-file-with-bucket",
                    bodyUploadFile,
                    { headers: this.header_token }
                )
                .then(async (res) => {
                    return res.data
                })
                .catch((error) => {
                    this.loading = false;
                    this.AlertError();
                    return false;
                });
        },
        formatDate(date) {
            if (date.length == 0) return null

            if (date.length == 1) {
                const [year, month, day] = date[0].split('-')
                return `${day}-${month}-${year}`
            } else {
                const [year_from, month_from, day_from] = date[0].split('-')
                const [year_to, month_to, day_to] = date[1].split('-')
                if (date[0] < date[1]) {
                    return `${day_from}-${month_from}-${year_from} ~ ${day_to}-${month_to}-${year_to}`
                } else {
                    return `${day_to}-${month_to}-${year_to} ~ ${day_from}-${month_from}-${year_from}`
                }
            }
        },

        formatDates(date) {
            if (date == null) {
                return null
            } else {
                const [year, month, day] = date.split('-')
                return `${day}-${month}-${year}`
            }
        },
        formatDateTimeToISO(dateTime) {
            return moment(dateTime, "DD-MM-YYYY HH:mm:ss").format("YYYY-MM-DDTHH:mm:ss.SS");
        },
        async alertStop() {
            Swal.fire({
                // title: "The Internet?",
                text: "ไม่สามารถทำรายการได้",
                type: "error",
            });
        },
        async alertError(error_description) {
            Swal.fire({
                text: error_description,
                title: "ไม่สามารถทำรายการได้",
                type: "error",
            });
        },
        async alertSuccess(error_description) {
            Swal.fire({
                text: error_description,
                title: "ทำรายการสำเร็จ",
                type: "success",
                toast: true,
                timer: 1500,
                position: 'top'
            });
        },

        OpenPhoto(Photo) {
            this.ShowPhotoLink = Photo
            this.ShowPhoto = true
        },
        ClosePhoto(value) {
            this.ShowPhoto = value;
            this.ShowPhotoLink = "";
        },

        allowedDates: val => val <= new Date().toISOString().substr(0, 10),
        // #endregion
        //#region  import
        isNumberWNoDot,
        get_languages,
        format_number,
        format_order_id,
        format_price,
        set_format_date_time,
        AlertError,
        AlertSuccess,

        // #endregion
    }
}
</script>
