<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />
    <!-- แสดงภาพ -->
    <Photo v-if="ShowPhoto" :showphoto="ShowPhoto" :showphotolink="ShowPhotoLink" @emitshowphoto="ClosePhoto" />
    <div>
      <v-row>
        <v-col cols="4" class="d-flex px-0">
          <div class="pr-2">โหลดข้อมูลใหม่เมื่อเปลี่ยนแท็บ : </div>
          <v-switch v-model="isReloadTab" color="success" @change="isReloadData"></v-switch>
        </v-col>
        <v-col cols="4"></v-col>
        <v-col cols="4" class="pa-0">
          <v-select class="white" v-model="warehouse_select" :items="warehouse_list" item-value="id" item-text="name"
            persistent-hint return-object dense hide-details outlined @input="btn_warehouse()">
          </v-select>
        </v-col>
      </v-row>

      <v-tabs v-model="set_TabListHeader">
        <v-tabs-slider color="red"></v-tabs-slider>
        <v-tab v-for="item in tabListHeaders" class="box-shadow" :key="item.id" @click="ChangeTab(item.id)"
          :href="'#' + item.id">
          {{ use_language[item.keyName] }}
          <span>
            ({{ item.packageCount }})
          </span>
        </v-tab>

      </v-tabs>
      <v-card>
        <v-card v-if="set_TabListHeader == '0'" class="v-card-bottom-30 mx-0">
          <v-card-text>

            <v-row class="pb-3">
              <!-- <v-col cols="12" md="6" lg="2" class="pb-0 pl-0">
                <v-text-field 
                  :value="dataset_all.search.SHorderID"
                  @change="value => dataset_all.search.SHorderID = value"
                  autocomplete="off" label="เลขออเดอร์" outlined hide-details dense
                >
                  <template v-slot:label> <span>{{ use_language.order_number }}</span> </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="2" class="pb-0">
                <v-text-field
                  :value="dataset_all.search.SH_ReferenceNumber"
                  @change="value => dataset_all.search.SH_ReferenceNumber = value"
                  autocomplete="off" label="เลขอ้างอิง" outlined hide-details dense
                >
                  <template v-slot:label> <span>{{ use_language.reference_number }}</span> </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="2" class="pb-0">
                <v-text-field 
                  :value="dataset_all.search.SHtrackingNo"
                  @change="value => dataset_all.search.SHtrackingNo = value"
                  autocomplete="off" label="TrackingNo" hide-details dense outlined
                >
                  <template v-slot:label> <span>{{ use_language.tracking_no }}</span> </template>
                </v-text-field>
              </v-col> -->
              <v-col cols="12" md="5" lg="5">
                <v-row class="box-shadow-picker">
                  <v-col class="py-0" cols="6">
                    <v-select v-model="dataset_all.search.select_topic"
                      :items="dataset_all.search.select_header" persistent-hint
                      return-object dense hide-details flat solo item-text="name" item-value="value"></v-select>
                  </v-col>
                  <v-col class="py-0" cols="6">
                    <v-text-field v-model="dataset_all.search.text_topic"
                      placeholder="ค้นหา" flat solo hide-details dense />
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" md="7" lg="7" class="pb-0">
                <v-btn @click="selected_order = [],singleSelect = false,btnSearchOrder('0')" color="primary" class="mr-3">{{ use_language.search }}</v-btn>
                <v-btn outlined color="primary" class="mr-3" @click="resetSearchAll()">{{ use_language.reset }}</v-btn>
                <v-btn outlined class="mr-3" @click="btnOpenAdvanceSearchDialog()">
                  <v-icon>mdi-filter-outline</v-icon>ขั้นสูง
                </v-btn>
                <v-btn outlined color="success" @click="dialog_export_excel()">
                  <v-icon size="18" class="ml-1" left>fa-download</v-icon>
                    excel
                </v-btn>
              </v-col>
            </v-row>

            <!-- <v-row class="mb-1">
              <v-col cols="6" align="left" class="px-0">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn  outlined v-bind="attrs" v-on="on" class="mr-1 pa-2">
                      <span class="pl-2">{{use_language.carry_out}}</span>
                      <v-icon right>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="inv_pre_print()">
                      <v-list-item-title>{{use_language.print_document}}</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="cover_pages()">
                      <v-list-item-title>{{use_language.print_cover_sheet}}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row> -->

            <v-data-table 
              :headers="dataset_all.headers"
              :items="dataset_all.dataTable"
              class="elevation-0 packhai-border-table" 
              item-key="id" 
              hide-default-footer
              :items-per-page="10000"
            >
              <template v-slot:item.startPackingDate="{ item }">
                    {{ formatDatetime(item.startPackingDate) }}
                  </template>
                  <template v-slot:item.packedDate="{ item }">
                    {{ formatDatetime(item.packedDate) }}
                  </template>
                  <template v-slot:item.createdDatetime="{ item }">
                    {{ formatDatetime(item.createdDatetime) }}
                  </template>
                  <template v-slot:item.readyToPackDate="{ item }">
                    {{ formatDatetime(item.readyToPackDate) }}
                  </template>
                  <template v-slot:item.endPackingDate="{ item }">
                    {{ formatDatetime(item.endPackingDate) }}
                  </template>
                  <template v-slot:item.receiverName="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" style="width: 250px" class="text-truncate">
                          <span>{{ item.receiverName }}</span>
                        </div>
                      </template>
                      <span>
                        <span>{{ item.receiverName }}</span>
                      </span>
                    </v-tooltip>
                  </template>
                  <template v-slot:item.channelName="{ item }">
                    <v-avatar size="25px" tile>
                      <v-img v-if="item.channelLogo != null && item.channelLogo != ''" :src="item.channelLogo" />
                    </v-avatar>
                  </template>
                  <template v-slot:item.expressCompanyName="{ item }">
                    <img v-if="item.expressLogo != null && item.expressLogo != ''" width="60px"
                      :src="item.expressLogo" />
                  </template>
                  <template v-slot:item.isInit="{ item }">
                    <v-icon v-if="item.isInit != null && item.isInit" size="10"
                      color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else-if="item.isInit != null && !item.isInit" size="10"
                      color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.isPrintPackingList="{ item }">
                    <v-icon v-if="item.isPrintPackingList" size="10" color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else size="10" color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.isPrintAWB="{ item }">
                    <v-icon v-if="item.isPrintAWB" size="10" color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else size="10" color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.id="{ item }">
                    <a @click="ShowDetailOrder(item.token)">{{
                      FormatOrder(item.id)
                    }}</a>
                  </template>
                  <template v-for="(header, index) in dataTable.header" v-slot:[`header.${header.value}`]="{ header }">
                    <v-tooltip :key="index" v-slot:activator="{ on }">
                      <span>{{ header.text }}</span>
                    </v-tooltip>
                  </template>
              <!-- v-model="selected_order"
              :single-select="singleSelect"
              show-select -->
              <!-- <template v-slot:item.num="{ item }">
                {{ gen_num_data_table(dataset_all.dataTable.indexOf(item)) }}
              </template>
              <template v-slot:item.id="{ item }">
                <a @click="ShowDetailOrder(item.token)">{{ FormatOrder(item.id)}}</a>
                <br>
                <span v-if="item.isPreOrder == 1" class="text-info">
                  <b>{{use_language.pre_order}}</b>
                </span>
              </template>
              <template v-slot:item.channelLogo="{ item }">
                  <v-avatar size="25px">
                    <img :src="item.channelLogo"/>
                  </v-avatar>
              </template>
              <template v-slot:item.expressCompanyLogo="{ item }">
                <img style="width: 60px" :src="item.expressCompanyLogo" @click="image_click(item.expressCompanyLogo)"/>
              </template>
              <template v-slot:item.createdDatetime="{ item }">
                {{ set_format_date_time(item.createdDatetime) }}
              </template>
              <template v-slot:item.receiverName="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div  v-bind="attrs"  v-on="on" style="width: 250px" class="text-truncate">
                      <span v-if=" item.receiverName != null">{{ item.receiverName }}</span>
                      <span v-else>{{ item.tempAddress }}</span>
                    </div>

                  </template>
                  <span>
                    <span v-if=" item.receiverName != null">{{ item.receiverName }}</span>
                    <span v-else>{{ item.tempAddress }}</span>
                  </span>
                </v-tooltip>


              </template>
              <template v-slot:item.orderTotalAmount="{ item }">
                {{ format_price( item.orderTotalAmount ) }}
              </template>
              <template v-slot:item.createdByStaffName="{ item }">
                {{ item.createdByStaffShopName }}
              </template>
              <template v-slot:item.salemanName="{ item }">
                {{ item.salemanName }}
              </template>
              <template v-slot:item.isCod="{ item }">
                <span v-if="item.isCOD == false"><v-chip color="#0951e5" outlined>{{use_language.ordinary}}</v-chip></span> <span v-else ><v-chip color="primary" style="width: 70px; padding-left: 20px" dark>{{use_language.cod}}</v-chip></span>
              </template>
              <template v-slot:item.cancleorder="{ item }">
                <span v-if="item.shippingStatusID == 10 || item.shippingStatusID == 20 ||item.shippingStatusID == 30 ">
                  <v-btn color="danger" dark outlined>{{use_language.cancel}}</v-btn>
                </span>
              </template>
              <template v-slot:item.referenceNumber="{ item }">
                <span v-if="item.referenceSourceID == 6">
                  {{item.realReferenceNumber}}
                </span>
                <span v-else>
                  {{ item.referenceNumber}}
                </span>
              </template>
              <template v-slot:item.shippingStatusName="{ item }">
                <span v-if="item.shippingStatusID == 10" style="color: black;">{{ item.shippingStatusName }}</span>
                <span v-if="item.shippingStatusID == 20" style="color: #E40078;">{{ item.shippingStatusName }}</span>
                <span v-if="item.shippingStatusID == 30" style="color: #ff7ac0;">{{ item.shippingStatusName }}</span>
                <span v-if="item.shippingStatusID == 40" style="color: orange;">{{ item.shippingStatusName }}</span>
                <span v-if="item.shippingStatusID == 50" style="color: blue;">{{ item.shippingStatusName }}</span>
                <span v-if="item.shippingStatusID == 60" style="color: #9acd32;">{{ item.shippingStatusName }}</span>
                <span v-if="item.shippingStatusID == 70" style="color: green;">{{ item.shippingStatusName }}</span>
                <span v-if="item.shippingStatusID == 90" style="color: red;">{{ item.shippingStatusName }}</span>
              </template>
              <template v-slot:item.parcelStatusName="{ item }">
                <span v-if="item.parcelStatusID == 0" style="color: purple;">{{ item.parcelStatusName }}</span>
                <span v-else-if="item.parcelStatusID == 1" style="color: orange;">{{ item.parcelStatusName }}</span>
                <span v-else-if="item.parcelStatusID == 2" style="color: red;">{{ item.parcelStatusName }}</span>
                <span v-else-if="item.parcelStatusID == 3" style="color: #ff7ac0 ;">{{ item.parcelStatusName }}</span>
                <span v-else-if="item.parcelStatusID == 4" style="color: #ff7ac0;">{{ item.parcelStatusName }}</span>
                <span v-else-if="item.parcelStatusID == 5" style="color:green;">{{ item.parcelStatusName }}</span>
                <span v-else-if="item.parcelStatusID ==6" style="color:red;">{{ item.parcelStatusName }}</span>
                <span v-else>{{ item.parcelStatusName }}</span>
              </template> -->
            </v-data-table>
            <v-row class="mt-2">
              <v-col cols="2">
                <v-select v-model="dataset_all.limits" hide-details outlined
                  :items="dataset_all.search.select_per" item-text="name" item-value="value"
                  @change="btnChangeLimits(set_TabListHeader)">
                </v-select>
              </v-col>
              <v-col cols="8" class="pa-0" center @click="btnChangePage(set_TabListHeader)">
                <v-pagination v-model="dataset_all.page" :length="dataset_all.pageLength"
                  :total-visible="9" color="info"></v-pagination>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card v-if="set_TabListHeader == '1-5'" class="v-card-bottom-30 mx-0">
          <v-card>
            <v-card-text>


              <template v-if="showSubheader.length > 0">
                <v-tabs v-model="set_TabListSubHeader_wait_payment">
                  <v-tabs-slider color="red"></v-tabs-slider>
                  <v-tab v-for="subItem of showSubheader" class="box-shadow" :key="subItem.id"
                    @click="ChangeTabSub(subItem.id)" :href="'#' + subItem.id">
                    {{ use_language[subItem.keyName] }}
                    <span>
                      ({{ subItem.packageCount }})

                    </span>
                  </v-tab>
                </v-tabs>
              </template>

              <template v-if="set_TabListSubHeader_wait_payment == '1'">
                <v-card class="v-card-bottom-30 mx-0">
                  <v-card-text>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="5">
                        <v-row class="box-shadow-picker">
                          <v-col class="py-0" cols="6">
                            <v-select v-model="dataset_wait_payment_wait_payment.search.select_topic"
                              :items="dataset_wait_payment_wait_payment.search.select_header" persistent-hint
                              return-object dense hide-details flat solo item-text="name" item-value="value"></v-select>
                          </v-col>
                          <v-col class="py-0" cols="6">
                            <v-text-field v-model="dataset_wait_payment_wait_payment.search.text_topic"
                              placeholder="ค้นหา" flat solo hide-details dense />
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col md="6" lg="3" class="hidden-md-and-down py-1">
                        <v-btn color="primary" class="mr-3"
                          @click="btnSearchOrder(set_TabListSubHeader_wait_payment)">{{ use_language.search }}</v-btn>
                      </v-col>
                      <v-col md="6" lg="4" class="hidden-md-and-down py-1">
                        <v-select v-model="dataset_wait_payment_wait_payment.search.select_channel"
                          :items="dataset_wait_payment_wait_payment.search.list_channel" item-text="channelName"
                          item-value="channelID" persistent-hint return-object dense outlined label="ช่องทาง"
                          @input="btnChannelSource(set_TabListSubHeader_wait_payment, dataset_wait_payment_wait_payment.search.select_channel)"
                          hide-details>
                          <template slot="selection" slot-scope="data">
                            <img v-if="data.item.channelLogo != null" :src="data.item.channelLogo" class="mr-2"
                              style="width: 25px" />
                            {{ length60(data.item.channelName) }}
                            <v-badge v-if="data.item.channelCount" inline color="error"
                              :content="data.item.channelCount" />
                          </template>
                          <template slot="item" slot-scope="data">
                            <img v-if="data.item.channelLogo != null" :src="data.item.channelLogo" class="mr-2"
                              style="width: 25px" />
                            {{ length60(data.item.channelName) }}
                            <v-badge v-if="data.item.channelCount" inline color="error"
                              :content="data.item.channelCount" />
                          </template>
                        </v-select>
                      </v-col>

                    </v-row>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="12" md="6" lg="1"></v-col>
                      <v-col class="py-1" md="6" lg="7">
                      </v-col>
                      <v-col class="py-1" md="6" lg="4">

                        <v-select v-model="dataset_wait_payment_wait_payment.search.select_express"
                          :items="dataset_wait_payment_wait_payment.search.list_express" persistent-hint return-object
                          item-text="expressCompanyName" item-value="expressCompanyID" dense outlined label="ขนส่ง"
                          @input="btnExprees(set_TabListSubHeader_wait_payment, dataset_wait_payment_wait_payment.search.select_express)"
                          hide-details>
                          <template slot="selection" slot-scope="data">
                            <img v-if="data.item.expressCompanyLogo != null" :src="data.item.expressCompanyLogo"
                              class="mr-2" style="width: 45px" />
                            {{ length60(data.item.expressCompanyName) }}
                            <v-badge v-if="data.item.expressCount" inline color="error"
                              :content="data.item.expressCount" />
                          </template>
                          <template slot="item" slot-scope="data">
                            <img v-if="data.item.expressCompanyLogo != null" :src="data.item.expressCompanyLogo"
                              class="mr-2" style="width: 45px" />
                            {{ length60(data.item.expressCompanyName) }}
                            <v-badge v-if="data.item.expressCount" inline color="error"
                              :content="data.item.expressCount" />
                          </template>
                        </v-select>

                      </v-col>
                    </v-row>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="8">



                      </v-col>
                      <v-col class="py-1" cols="4">
                        <v-select v-model="dataset_wait_payment_wait_payment.search.select_print"
                          :items="dataset_wait_payment_wait_payment.search.list_print" item-text="name" item-value="id"
                          label="ประเภทการปริ้น" dense return-object hide-details outlined
                          @input="btnSearchPrintType(set_TabListSubHeader_wait_payment, dataset_wait_payment_wait_payment.search.select_print)">
                          <template slot="selection" slot-scope="data">
                            {{ length60(data.item.name) }}
                            <v-badge v-if="data.item.count" inline color="error" :content="data.item.count" />
                          </template>
                          <template slot="item" slot-scope="data">
                            {{ length60(data.item.name) }}
                            <v-badge v-if="data.item.count" inline color="error" :content="data.item.count" />
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-row class="item-center px-0 py-1">
                  <v-col class="px-0" cols="8">

                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn :elevation="1" outlined v-bind="attrs" v-on="on">
                          <span class="pl-1">ดำเนินการ</span>
                          <v-icon right>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item v-for="item in dataset_wait_payment_wait_payment.manageMenuListType"
                          :key="item.name" @click="handleClickOutside(item, set_TabListSubHeader_wait_payment)">
                          <v-list-item-title>{{ item.name }}</v-list-item-title>
                        </v-list-item>


                      </v-list>
                    </v-menu>

                  </v-col>
                  <v-col class="py-1" cols="4">

                  </v-col>
                </v-row>
                <v-row class="text-center" align="center" v-if="dataset_wait_payment_wait_payment.selected.length > 1">
                  <v-col cols="12">
                    <div style="background: rgb(235 235 235)">
                      <b>คุณเลือกออเดอร์ {{ dataset_wait_payment_wait_payment.selected.length }} รายการในหน้านี้
                      </b>
                      <v-btn text @click="btnSelectedTarget(set_TabListSubHeader_wait_payment)"
                        v-if="dataset_wait_payment_wait_payment.selected.length != dataset_wait_payment_wait_payment.total && dataset_wait_payment_wait_payment.limits != 999999">
                        <a>เลือกออเดอร์ทั้ง {{ dataset_wait_payment_wait_payment.total }} ในรายการหลัก</a>
                      </v-btn>
                      <v-btn text @click="dataset_wait_payment_wait_payment.selected = []" v-else>
                        <a>ล้างการเลือก</a>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
                <v-data-table v-model="dataset_wait_payment_wait_payment.selected"
                  :headers="dataset_wait_payment_wait_payment.headers"
                  :items="dataset_wait_payment_wait_payment.dataTable" class="elevation-0 packhai-border-table"
                  item-key="packageID" show-select hide-default-footer :items-per-page="10000">
                  <template v-slot:item.startPackingDate="{ item }">
                    {{ formatDatetime(item.startPackingDate) }}
                  </template>
                  <template v-slot:item.packedDate="{ item }">
                    {{ formatDatetime(item.packedDate) }}
                  </template>
                  <template v-slot:item.createdDatetime="{ item }">
                    {{ formatDatetime(item.createdDatetime) }}
                  </template>
                  <template v-slot:item.readyToPackDate="{ item }">
                    {{ formatDatetime(item.readyToPackDate) }}
                  </template>
                  <template v-slot:item.endPackingDate="{ item }">
                    {{ formatDatetime(item.endPackingDate) }}
                  </template>
                  <template v-slot:item.receiverName="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" style="width: 250px" class="text-truncate">
                          <span>{{ item.receiverName }}</span>
                        </div>
                      </template>
                      <span>
                        <span>{{ item.receiverName }}</span>
                      </span>
                    </v-tooltip>
                  </template>
                  <template v-slot:item.channelName="{ item }">
                    <v-avatar size="25px" tile>
                      <v-img v-if="item.channelLogo != null && item.channelLogo != ''" :src="item.channelLogo" />
                    </v-avatar>
                  </template>
                  <template v-slot:item.expressCompanyName="{ item }">
                    <img v-if="item.expressLogo != null && item.expressLogo != ''" width="60px"
                      :src="item.expressLogo" />
                  </template>
                  <template v-slot:item.isInit="{ item }">
                    <v-icon v-if="item.isInit != null && item.isInit" size="10"
                      color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else-if="item.isInit != null && !item.isInit" size="10"
                      color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.isPrintPackingList="{ item }">
                    <v-icon v-if="item.isPrintPackingList" size="10" color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else size="10" color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.isPrintAWB="{ item }">
                    <v-icon v-if="item.isPrintAWB" size="10" color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else size="10" color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.id="{ item }">
                    <a @click="ShowDetailOrder(item.token)">{{
                      FormatOrder(item.id)
                    }}</a>
                  </template>
                  <template v-for="(header, index) in dataTable.header" v-slot:[`header.${header.value}`]="{ header }">
                    <v-tooltip :key="index" v-slot:activator="{ on }">
                      <span>{{ header.text }}</span>
                    </v-tooltip>
                  </template>
                </v-data-table>

                <v-row class="mt-2">
                  <v-col cols="2">
                    <v-select v-model="dataset_wait_payment_wait_payment.limits" hide-details outlined
                      :items="dataset_wait_payment_wait_payment.search.select_per" item-text="name" item-value="value"
                      @change="btnChangeLimits(set_TabListSubHeader_wait_payment)">
                    </v-select>
                  </v-col>
                  <v-col cols="8" class="pa-0" center @click="btnChangePage(set_TabListSubHeader_wait_payment)">
                    <v-pagination v-model="dataset_wait_payment_wait_payment.page"
                      :length="dataset_wait_payment_wait_payment.pageLength" :total-visible="9"
                      color="info"></v-pagination>
                  </v-col>
                </v-row>

              </template>
              <template v-if="set_TabListSubHeader_wait_payment == '5'">
                <v-card class="v-card-bottom-30 mx-0">
                  <v-card-text>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="5">
                        <v-row class="box-shadow-picker">
                          <v-col class="py-0" cols="6">
                            <v-select v-model="dataset_wait_payment_check_payment.search.select_topic"
                              :items="dataset_wait_payment_check_payment.search.select_header" persistent-hint
                              return-object dense hide-details flat solo item-text="name" item-value="value"></v-select>
                          </v-col>
                          <v-col class="py-0" cols="6">
                            <v-text-field v-model="dataset_wait_payment_check_payment.search.text_topic"
                              placeholder="ค้นหา" flat solo hide-details dense />
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col md="6" lg="3" class="hidden-md-and-down py-1">
                        <v-btn color="primary" class="mr-3"
                          @click="btnSearchOrder(set_TabListSubHeader_wait_payment)">{{ use_language.search }}</v-btn>
                      </v-col>
                      <v-col md="6" lg="4" class="hidden-md-and-down py-1">
                        <v-select v-model="dataset_wait_payment_check_payment.search.select_channel"
                          :items="dataset_wait_payment_check_payment.search.list_channel" item-text="channelName"
                          item-value="channelID" persistent-hint return-object dense outlined label="ช่องทาง"
                          @input="btnChannelSource(set_TabListSubHeader_wait_payment, dataset_wait_payment_check_payment.search.select_channel)"
                          hide-details>
                          <template slot="selection" slot-scope="data">
                            <img v-if="data.item.channelLogo != null" :src="data.item.channelLogo" class="mr-2"
                              style="width: 25px" />
                            {{ length60(data.item.channelName) }}
                            <v-badge v-if="data.item.channelCount" inline color="error"
                              :content="data.item.channelCount" />
                          </template>
                          <template slot="item" slot-scope="data">
                            <img v-if="data.item.channelLogo != null" :src="data.item.channelLogo" class="mr-2"
                              style="width: 25px" />
                            {{ length60(data.item.channelName) }}
                            <v-badge v-if="data.item.channelCount" inline color="error"
                              :content="data.item.channelCount" />
                          </template>
                        </v-select>
                      </v-col>

                    </v-row>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="12" md="6" lg="1"></v-col>
                      <v-col class="py-1" md="6" lg="7">
                      </v-col>
                      <v-col class="py-1" md="6" lg="4">

                        <v-select v-model="dataset_wait_payment_check_payment.search.select_express"
                          :items="dataset_wait_payment_check_payment.search.list_express" persistent-hint return-object
                          item-text="expressCompanyName" item-value="expressCompanyID" dense outlined label="ขนส่ง"
                          @input="btnExprees(set_TabListSubHeader_wait_payment, dataset_wait_payment_check_payment.search.select_express)"
                          hide-details>
                          <template slot="selection" slot-scope="data">
                            <img v-if="data.item.expressCompanyLogo != null" :src="data.item.expressCompanyLogo"
                              class="mr-2" style="width: 45px" />
                            {{ length60(data.item.expressCompanyName) }}
                            <v-badge v-if="data.item.expressCount" inline color="error"
                              :content="data.item.expressCount" />
                          </template>
                          <template slot="item" slot-scope="data">
                            <img v-if="data.item.expressCompanyLogo != null" :src="data.item.expressCompanyLogo"
                              class="mr-2" style="width: 45px" />
                            {{ length60(data.item.expressCompanyName) }}
                            <v-badge v-if="data.item.expressCount" inline color="error"
                              :content="data.item.expressCount" />
                          </template>
                        </v-select>

                      </v-col>
                    </v-row>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="8">



                      </v-col>
                      <v-col class="py-1" cols="4">
                        <v-select v-model="dataset_wait_payment_check_payment.search.select_print"
                          :items="dataset_wait_payment_check_payment.search.list_print" item-text="name" item-value="id"
                          label="ประเภทการปริ้น" dense return-object hide-details outlined
                          @input="btnSearchPrintType(set_TabListSubHeader_wait_payment, dataset_wait_payment_check_payment.search.select_print)">
                          <template slot="selection" slot-scope="data">
                            {{ length60(data.item.name) }}
                            <v-badge v-if="data.item.count" inline color="error" :content="data.item.count" />
                          </template>
                          <template slot="item" slot-scope="data">
                            {{ length60(data.item.name) }}
                            <v-badge v-if="data.item.count" inline color="error" :content="data.item.count" />
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-row class="item-center px-0 py-1">
                  <v-col class="px-0" cols="8">

                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn :elevation="1" outlined v-bind="attrs" v-on="on">
                          <span class="pl-1">ดำเนินการ</span>
                          <v-icon right>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item v-for="item in dataset_wait_payment_check_payment.manageMenuListType"
                          :key="item.name" @click="handleClickOutside(item, set_TabListSubHeader_wait_payment)">
                          <v-list-item-title>{{ item.name }}</v-list-item-title>
                        </v-list-item>


                      </v-list>
                    </v-menu>

                  </v-col>
                  <v-col class="py-1" cols="4">

                  </v-col>
                </v-row>
                <v-row class="text-center" align="center" v-if="dataset_wait_payment_check_payment.selected.length > 1">
                  <v-col cols="12">
                    <div style="background: rgb(235 235 235)">
                      <b>คุณเลือกออเดอร์ {{ dataset_wait_payment_check_payment.selected.length }} รายการในหน้านี้
                      </b>
                      <v-btn text @click="btnSelectedTarget(set_TabListSubHeader_wait_payment)"
                        v-if="dataset_wait_payment_check_payment.selected.length != dataset_wait_payment_check_payment.total && dataset_wait_payment_check_payment.limits != 999999">
                        <a>เลือกออเดอร์ทั้ง {{ dataset_wait_payment_check_payment.total }} ในรายการหลัก</a>
                      </v-btn>
                      <v-btn text @click="dataset_wait_payment_check_payment.selected = []" v-else>
                        <a>ล้างการเลือก</a>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
                <v-data-table v-model="dataset_wait_payment_check_payment.selected"
                  :headers="dataset_wait_payment_check_payment.headers"
                  :items="dataset_wait_payment_check_payment.dataTable" class="elevation-0 packhai-border-table"
                  item-key="packageID" show-select hide-default-footer :items-per-page="10000">
                  <template v-slot:item.startPackingDate="{ item }">
                    {{ formatDatetime(item.startPackingDate) }}
                  </template>
                  <template v-slot:item.packedDate="{ item }">
                    {{ formatDatetime(item.packedDate) }}
                  </template>
                  <template v-slot:item.createdDatetime="{ item }">
                    {{ formatDatetime(item.createdDatetime) }}
                  </template>
                  <template v-slot:item.readyToPackDate="{ item }">
                    {{ formatDatetime(item.readyToPackDate) }}
                  </template>
                  <template v-slot:item.endPackingDate="{ item }">
                    {{ formatDatetime(item.endPackingDate) }}
                  </template>
                  <template v-slot:item.receiverName="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" style="width: 250px" class="text-truncate">
                          <span>{{ item.receiverName }}</span>
                        </div>
                      </template>
                      <span>
                        <span>{{ item.receiverName }}</span>
                      </span>
                    </v-tooltip>
                  </template>
                  <template v-slot:item.channelName="{ item }">
                    <v-avatar size="25px" tile>
                      <v-img v-if="item.channelLogo != null && item.channelLogo != ''" :src="item.channelLogo" />
                    </v-avatar>
                  </template>
                  <template v-slot:item.expressCompanyName="{ item }">
                    <img v-if="item.expressLogo != null && item.expressLogo != ''" width="60px"
                      :src="item.expressLogo" />
                  </template>
                  <template v-slot:item.isInit="{ item }">
                    <v-icon v-if="item.isInit != null && item.isInit" size="10"
                      color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else-if="item.isInit != null && !item.isInit" size="10"
                      color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.isPrintPackingList="{ item }">
                    <v-icon v-if="item.isPrintPackingList" size="10" color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else size="10" color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.isPrintAWB="{ item }">
                    <v-icon v-if="item.isPrintAWB" size="10" color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else size="10" color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.id="{ item }">
                    <a @click="ShowDetailOrder(item.token)">{{
                      FormatOrder(item.id)
                    }}</a>
                  </template>
                  <template v-for="(header, index) in dataTable.header" v-slot:[`header.${header.value}`]="{ header }">
                    <v-tooltip :key="index" v-slot:activator="{ on }">
                      <span>{{ header.text }}</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <v-row class="mt-2">
                  <v-col cols="2">
                    <v-select v-model="dataset_wait_payment_check_payment.limits" hide-details outlined
                      :items="dataset_wait_payment_check_payment.search.select_per" item-text="name" item-value="value"
                      @change="btnChangeLimits(set_TabListSubHeader_wait_payment)">
                    </v-select>
                  </v-col>
                  <v-col cols="8" class="pa-0" center @click="btnChangePage(set_TabListSubHeader_wait_payment)">
                    <v-pagination v-model="dataset_wait_payment_check_payment.page"
                      :length="dataset_wait_payment_check_payment.pageLength" :total-visible="9"
                      color="info"></v-pagination>
                  </v-col>
                </v-row>
              </template>

            </v-card-text>

          </v-card>
        </v-card>
        <v-card v-if="set_TabListHeader == '10-20-30-40-60'" class="v-card-bottom-30 mx-0">

          <v-card>
            <v-card-text>
              <template v-if="showSubheader.length > 0">
                <v-tabs v-model="set_TabListSubHeader_Process">
                  <v-tabs-slider color="red"></v-tabs-slider>
                  <v-tab v-for="subItem of showSubheader" class="box-shadow" :key="subItem.id"
                    @click="ChangeTabSub(subItem.id)" :href="'#' + subItem.id">
                    {{ use_language[subItem.keyName] }}
                    <span>
                      ({{ subItem.packageCount }})
                    </span>
                  </v-tab>
                </v-tabs>
              </template>

              <template v-if="set_TabListSubHeader_Process == '10'">
                <v-card class="v-card-bottom-30 mx-0">
                  <v-card-text>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="5">
                        <v-row class="box-shadow-picker">
                          <v-col class="py-0" cols="6">
                            <v-select v-model="dataset_process_map_product.search.select_topic"
                              :items="dataset_process_map_product.search.select_header" persistent-hint return-object
                              dense hide-details flat solo item-text="name" item-value="value"></v-select>
                          </v-col>
                          <v-col class="py-0" cols="6">
                            <v-text-field v-model="dataset_process_map_product.search.text_topic" placeholder="ค้นหา"
                              flat solo hide-details dense />
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col md="6" lg="3" class="hidden-md-and-down py-1">
                        <v-btn color="primary" class="mr-3" @click="btnSearchOrder(set_TabListSubHeader_Process)">{{
                          use_language.search }}</v-btn>
                      </v-col>
                      <v-col md="6" lg="4" class="hidden-md-and-down py-1">
                        <v-select v-model="dataset_process_map_product.search.select_channel"
                          :items="dataset_process_map_product.search.list_channel" item-text="channelName"
                          item-value="channelID" persistent-hint return-object dense outlined label="ช่องทาง"
                          @input="btnChannelSource(set_TabListSubHeader_Process, dataset_process_map_product.search.select_channel)"
                          hide-details>
                          <template slot="selection" slot-scope="data">
                            <img v-if="data.item.channelLogo != null" :src="data.item.channelLogo" class="mr-2"
                              style="width: 25px" />
                            {{ length60(data.item.channelName) }}
                            <v-badge v-if="data.item.channelCount" inline color="error"
                              :content="data.item.channelCount" />
                          </template>
                          <template slot="item" slot-scope="data">
                            <img v-if="data.item.channelLogo != null" :src="data.item.channelLogo" class="mr-2"
                              style="width: 25px" />
                            {{ length60(data.item.channelName) }}
                            <v-badge v-if="data.item.channelCount" inline color="error"
                              :content="data.item.channelCount" />
                          </template>
                        </v-select>
                      </v-col>

                    </v-row>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="12" md="6" lg="1"></v-col>
                      <v-col class="py-1" md="6" lg="7">
                      </v-col>
                      <v-col class="py-1" md="6" lg="4">

                        <v-select v-model="dataset_process_map_product.search.select_express"
                          :items="dataset_process_map_product.search.list_express" persistent-hint return-object
                          item-text="expressCompanyName" item-value="expressCompanyID" dense outlined label="ขนส่ง"
                          @input="btnExprees(set_TabListSubHeader_Process, dataset_process_map_product.search.select_express)"
                          hide-details>
                          <template slot="selection" slot-scope="data">
                            <img v-if="data.item.expressCompanyLogo != null" :src="data.item.expressCompanyLogo"
                              class="mr-2" style="width: 45px" />
                            {{ length60(data.item.expressCompanyName) }}
                            <v-badge v-if="data.item.expressCount" inline color="error"
                              :content="data.item.expressCount" />
                          </template>
                          <template slot="item" slot-scope="data">
                            <img v-if="data.item.expressCompanyLogo != null" :src="data.item.expressCompanyLogo"
                              class="mr-2" style="width: 45px" />
                            {{ length60(data.item.expressCompanyName) }}
                            <v-badge v-if="data.item.expressCount" inline color="error"
                              :content="data.item.expressCount" />
                          </template>
                        </v-select>

                      </v-col>
                    </v-row>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="8">



                      </v-col>
                      <v-col class="py-1" cols="4">
                        <v-select v-model="dataset_process_map_product.search.select_print"
                          :items="dataset_process_map_product.search.list_print" item-text="name" item-value="id"
                          label="ประเภทการปริ้น" dense return-object hide-details outlined
                          @input="btnSearchPrintType(set_TabListSubHeader_Process, dataset_process_map_product.search.select_print)">
                          <template slot="selection" slot-scope="data">
                            {{ length60(data.item.name) }}
                            <v-badge v-if="data.item.count" inline color="error" :content="data.item.count" />
                          </template>
                          <template slot="item" slot-scope="data">
                            {{ length60(data.item.name) }}
                            <v-badge v-if="data.item.count" inline color="error" :content="data.item.count" />
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-row class="item-center px-0 py-1">
                  <v-col class="px-0" cols="8">

                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn :elevation="1" outlined v-bind="attrs" v-on="on">
                          <span class="pl-1">ดำเนินการ</span>
                          <v-icon right>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item v-for="item in dataset_process_map_product.manageMenuListType" :key="item.name"
                          @click="handleClickOutside(item, set_TabListSubHeader_Process)">
                          <v-list-item-title>{{ item.name }}</v-list-item-title>
                        </v-list-item>

                      </v-list>
                    </v-menu>

                  </v-col>
                  <v-col class="py-1" cols="4">

                  </v-col>
                </v-row>
                <v-row class="text-center" align="center" v-if="dataset_process_map_product.selected.length > 1">
                  <v-col cols="12">
                    <div style="background: rgb(235 235 235)">
                      <b>คุณเลือกออเดอร์ {{ dataset_process_map_product.selected.length }} รายการในหน้านี้
                      </b>
                      <v-btn text @click="btnSelectedTarget(set_TabListSubHeader_Process)"
                        v-if="dataset_process_map_product.selected.length != dataset_process_map_product.total && dataset_process_map_product.limits != 999999">
                        <a>เลือกออเดอร์ทั้ง {{ dataset_process_map_product.total }} ในรายการหลัก</a>
                      </v-btn>
                      <v-btn text @click="dataset_process_map_product.selected = []" v-else>
                        <a>ล้างการเลือก</a>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
                <v-data-table v-model="dataset_process_map_product.selected"
                  :headers="dataset_process_map_product.headers" :items="dataset_process_map_product.dataTable"
                  class="elevation-0 packhai-border-table" item-key="packageID" show-select hide-default-footer
                  :items-per-page="10000">
                  <template v-slot:item.startPackingDate="{ item }">
                    {{ formatDatetime(item.startPackingDate) }}
                  </template>
                  <template v-slot:item.packedDate="{ item }">
                    {{ formatDatetime(item.packedDate) }}
                  </template>
                  <template v-slot:item.createdDatetime="{ item }">
                    {{ formatDatetime(item.createdDatetime) }}
                  </template>
                  <template v-slot:item.readyToPackDate="{ item }">
                    {{ formatDatetime(item.readyToPackDate) }}
                  </template>
                  <template v-slot:item.endPackingDate="{ item }">
                    {{ formatDatetime(item.endPackingDate) }}
                  </template>
                  <template v-slot:item.receiverName="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" style="width: 250px" class="text-truncate">
                          <span>{{ item.receiverName }}</span>
                        </div>
                      </template>
                      <span>
                        <span>{{ item.receiverName }}</span>
                      </span>
                    </v-tooltip>
                  </template>
                  <template v-slot:item.channelName="{ item }">
                    <v-avatar size="25px" tile>
                      <v-img v-if="item.channelLogo != null && item.channelLogo != ''" :src="item.channelLogo" />
                    </v-avatar>
                  </template>
                  <template v-slot:item.expressCompanyName="{ item }">
                    <img v-if="item.expressLogo != null && item.expressLogo != ''" width="60px"
                      :src="item.expressLogo" />
                  </template>
                  <template v-slot:item.isInit="{ item }">
                    <v-icon v-if="item.isInit != null && item.isInit" size="10"
                      color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else-if="item.isInit != null && !item.isInit" size="10"
                      color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.isPrintPackingList="{ item }">
                    <v-icon v-if="item.isPrintPackingList" size="10" color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else size="10" color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.isPrintAWB="{ item }">
                    <v-icon v-if="item.isPrintAWB" size="10" color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else size="10" color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.id="{ item }">
                    <a @click="ShowDetailOrder(item.token)">{{
                      FormatOrder(item.id)
                    }}</a>
                  </template>
                  <template v-for="(header, index) in dataTable.header" v-slot:[`header.${header.value}`]="{ header }">
                    <v-tooltip :key="index" v-slot:activator="{ on }">
                      <span>{{ header.text }}</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <v-row class="mt-2">
                  <v-col cols="2">
                    <v-select v-model="dataset_process_map_product.limits" hide-details outlined
                      :items="dataset_process_map_product.search.select_per" item-text="name" item-value="value"
                      @change="btnChangeLimits(set_TabListSubHeader_Process)">
                    </v-select>
                  </v-col>
                  <v-col cols="8" class="pa-0" center @click="btnChangePage(set_TabListSubHeader_Process)">
                    <v-pagination v-model="dataset_process_map_product.page"
                      :length="dataset_process_map_product.pageLength" :total-visible="9" color="info"></v-pagination>
                  </v-col>
                </v-row>
              </template>
              <template v-if="set_TabListSubHeader_Process == '20'">
                <v-card class="v-card-bottom-30 mx-0">
                  <v-card-text>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="5">
                        <v-row class="box-shadow-picker">
                          <v-col class="py-0" cols="6">
                            <v-select v-model="dataset_process_allocate_stock.search.select_topic"
                              :items="dataset_process_allocate_stock.search.select_header" persistent-hint return-object
                              dense hide-details flat solo item-text="name" item-value="value"></v-select>
                          </v-col>
                          <v-col class="py-0" cols="6">
                            <v-text-field v-model="dataset_process_allocate_stock.search.text_topic" placeholder="ค้นหา"
                              flat solo hide-details dense />
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col md="6" lg="3" class="hidden-md-and-down py-1">
                        <v-btn color="primary" class="mr-3" @click="btnSearchOrder(set_TabListSubHeader_Process)">{{
                          use_language.search }}</v-btn>
                      </v-col>
                      <v-col md="6" lg="4" class="hidden-md-and-down py-1">
                        <v-select v-model="dataset_process_allocate_stock.search.select_channel"
                          :items="dataset_process_allocate_stock.search.list_channel" item-text="channelName"
                          item-value="channelID" persistent-hint return-object dense outlined label="ช่องทาง"
                          @input="btnChannelSource(set_TabListSubHeader_Process, dataset_process_allocate_stock.search.select_channel)"
                          hide-details>
                          <template slot="selection" slot-scope="data">
                            <img v-if="data.item.channelLogo != null" :src="data.item.channelLogo" class="mr-2"
                              style="width: 25px" />
                            {{ length60(data.item.channelName) }}
                            <v-badge v-if="data.item.channelCount" inline color="error"
                              :content="data.item.channelCount" />
                          </template>
                          <template slot="item" slot-scope="data">
                            <img v-if="data.item.channelLogo != null" :src="data.item.channelLogo" class="mr-2"
                              style="width: 25px" />
                            {{ length60(data.item.channelName) }}
                            <v-badge v-if="data.item.channelCount" inline color="error"
                              :content="data.item.channelCount" />
                          </template>
                        </v-select>
                      </v-col>

                    </v-row>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="12" md="6" lg="1"></v-col>
                      <v-col class="py-1" md="6" lg="7">
                      </v-col>
                      <v-col class="py-1" md="6" lg="4">

                        <v-select v-model="dataset_process_allocate_stock.search.select_express"
                          :items="dataset_process_allocate_stock.search.list_express" persistent-hint return-object
                          item-text="expressCompanyName" item-value="expressCompanyID" dense outlined label="ขนส่ง"
                          @input="btnExprees(set_TabListSubHeader_Process, dataset_process_allocate_stock.search.select_express)"
                          hide-details>
                          <template slot="selection" slot-scope="data">
                            <img v-if="data.item.expressCompanyLogo != null" :src="data.item.expressCompanyLogo"
                              class="mr-2" style="width: 45px" />
                            {{ length60(data.item.expressCompanyName) }}
                            <v-badge v-if="data.item.expressCount" inline color="error"
                              :content="data.item.expressCount" />
                          </template>
                          <template slot="item" slot-scope="data">
                            <img v-if="data.item.expressCompanyLogo != null" :src="data.item.expressCompanyLogo"
                              class="mr-2" style="width: 45px" />
                            {{ length60(data.item.expressCompanyName) }}
                            <v-badge v-if="data.item.expressCount" inline color="error"
                              :content="data.item.expressCount" />
                          </template>
                        </v-select>

                      </v-col>
                    </v-row>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="8">



                      </v-col>
                      <v-col class="py-1" cols="4">
                        <v-select v-model="dataset_process_allocate_stock.search.select_print"
                          :items="dataset_process_allocate_stock.search.list_print" item-text="name" item-value="id"
                          label="ประเภทการปริ้น" dense return-object hide-details outlined
                          @input="btnSearchPrintType(set_TabListSubHeader_Process, dataset_process_allocate_stock.search.select_print)">
                          <template slot="selection" slot-scope="data">
                            {{ length60(data.item.name) }}
                            <v-badge v-if="data.item.count" inline color="error" :content="data.item.count" />
                          </template>
                          <template slot="item" slot-scope="data">
                            {{ length60(data.item.name) }}
                            <v-badge v-if="data.item.count" inline color="error" :content="data.item.count" />
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-row class="item-center px-0 py-1">
                  <v-col class="px-0" cols="8">

                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn :elevation="1" outlined v-bind="attrs" v-on="on">
                          <span class="pl-1">ดำเนินการ</span>
                          <v-icon right>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item v-for="item in dataset_process_allocate_stock.manageMenuListType" :key="item.name"
                          @click="handleClickOutside(item, set_TabListSubHeader_Process)">
                          <v-list-item-title>{{ item.name }}</v-list-item-title>
                        </v-list-item>



                      </v-list>
                    </v-menu>

                  </v-col>
                  <v-col class="py-1" cols="4">

                  </v-col>
                </v-row>
                <v-row class="text-center" align="center" v-if="dataset_process_allocate_stock.selected.length > 1">
                  <v-col cols="12">
                    <div style="background: rgb(235 235 235)">
                      <b>คุณเลือกออเดอร์ {{ dataset_process_allocate_stock.selected.length }} รายการในหน้านี้
                      </b>
                      <v-btn text @click="btnSelectedTarget(set_TabListSubHeader_Process)"
                        v-if="dataset_process_allocate_stock.selected.length != dataset_process_allocate_stock.total && dataset_process_allocate_stock.limits != 999999">
                        <a>เลือกออเดอร์ทั้ง {{ dataset_process_allocate_stock.total }} ในรายการหลัก</a>
                      </v-btn>
                      <v-btn text @click="dataset_process_allocate_stock.selected = []" v-else>
                        <a>ล้างการเลือก</a>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
                <v-data-table v-model="dataset_process_allocate_stock.selected"
                  :headers="dataset_process_allocate_stock.headers" :items="dataset_process_allocate_stock.dataTable"
                  class="elevation-0 packhai-border-table" item-key="packageID" show-select hide-default-footer
                  :items-per-page="10000">
                </v-data-table>
                <v-row class="mt-2">
                  <v-col cols="2">
                    <v-select v-model="dataset_process_allocate_stock.limits" hide-details outlined
                      :items="dataset_process_allocate_stock.search.select_per" item-text="name" item-value="value"
                      @change="btnChangeLimits(set_TabListSubHeader_Process)">
                    </v-select>
                  </v-col>
                  <v-col cols="8" class="pa-0" center @click="btnChangePage(set_TabListSubHeader_Process)">
                    <v-pagination v-model="dataset_process_allocate_stock.page"
                      :length="dataset_process_allocate_stock.pageLength" :total-visible="9"
                      color="info"></v-pagination>
                  </v-col>
                </v-row>
              </template>
              <template v-if="set_TabListSubHeader_Process == '30'">
                <v-card class="v-card-bottom-30 mx-0">
                  <v-card-text>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="5">
                        <v-row class="box-shadow-picker">
                          <v-col class="py-0" cols="6">
                            <v-select v-model="dataset_process_ready_to_ship.search.select_topic"
                              :items="dataset_process_ready_to_ship.search.select_header" persistent-hint return-object
                              dense hide-details flat solo item-text="name" item-value="value"></v-select>
                          </v-col>
                          <v-col class="py-0" cols="6">
                            <v-text-field v-model="dataset_process_ready_to_ship.search.text_topic" placeholder="ค้นหา"
                              flat solo hide-details dense />
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col md="6" lg="3" class="hidden-md-and-down py-1">
                        <v-btn color="primary" class="mr-3" @click="btnSearchOrder(set_TabListSubHeader_Process)">{{
                          use_language.search }}</v-btn>
                      </v-col>
                      <v-col md="6" lg="4" class="hidden-md-and-down py-1">
                        <v-select v-model="dataset_process_ready_to_ship.search.select_channel"
                          :items="dataset_process_ready_to_ship.search.list_channel" item-text="channelName"
                          item-value="channelID" persistent-hint return-object dense outlined label="ช่องทาง"
                          @input="btnChannelSource(set_TabListSubHeader_Process, dataset_process_ready_to_ship.search.select_channel)"
                          hide-details>
                          <template slot="selection" slot-scope="data">
                            <img v-if="data.item.channelLogo != null" :src="data.item.channelLogo" class="mr-2"
                              style="width: 25px" />
                            {{ length60(data.item.channelName) }}
                            <v-badge v-if="data.item.channelCount" inline color="error"
                              :content="data.item.channelCount" />
                          </template>
                          <template slot="item" slot-scope="data">
                            <img v-if="data.item.channelLogo != null" :src="data.item.channelLogo" class="mr-2"
                              style="width: 25px" />
                            {{ length60(data.item.channelName) }}
                            <v-badge v-if="data.item.channelCount" inline color="error"
                              :content="data.item.channelCount" />
                          </template>
                        </v-select>
                      </v-col>

                    </v-row>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="12" md="6" lg="1"></v-col>
                      <v-col class="py-1" md="6" lg="7">
                      </v-col>
                      <v-col class="py-1" md="6" lg="4">

                        <v-select v-model="dataset_process_ready_to_ship.search.select_express"
                          :items="dataset_process_ready_to_ship.search.list_express" persistent-hint return-object
                          item-text="expressCompanyName" item-value="expressCompanyID" dense outlined label="ขนส่ง"
                          @input="btnExprees(set_TabListSubHeader_Process, dataset_process_ready_to_ship.search.select_express)"
                          hide-details>
                          <template slot="selection" slot-scope="data">
                            <img v-if="data.item.expressCompanyLogo != null" :src="data.item.expressCompanyLogo"
                              class="mr-2" style="width: 45px" />
                            {{ length60(data.item.expressCompanyName) }}
                            <v-badge v-if="data.item.expressCount" inline color="error"
                              :content="data.item.expressCount" />
                          </template>
                          <template slot="item" slot-scope="data">
                            <img v-if="data.item.expressCompanyLogo != null" :src="data.item.expressCompanyLogo"
                              class="mr-2" style="width: 45px" />
                            {{ length60(data.item.expressCompanyName) }}
                            <v-badge v-if="data.item.expressCount" inline color="error"
                              :content="data.item.expressCount" />
                          </template>
                        </v-select>

                      </v-col>
                    </v-row>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="8">



                      </v-col>
                      <v-col class="py-1" cols="4">
                        <v-select v-model="dataset_process_ready_to_ship.search.select_print"
                          :items="dataset_process_ready_to_ship.search.list_print" item-text="name" item-value="id"
                          label="ประเภทการปริ้น" dense return-object hide-details outlined
                          @input="btnSearchPrintType(set_TabListSubHeader_Process, dataset_process_ready_to_ship.search.select_print)">
                          <template slot="selection" slot-scope="data">
                            {{ length60(data.item.name) }}
                            <v-badge v-if="data.item.count" inline color="error" :content="data.item.count" />
                          </template>
                          <template slot="item" slot-scope="data">
                            {{ length60(data.item.name) }}
                            <v-badge v-if="data.item.count" inline color="error" :content="data.item.count" />
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-row class="item-center px-0 py-1">
                  <v-col class="px-0" cols="8">

                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn :elevation="1" outlined v-bind="attrs" v-on="on">
                          <span class="pl-1">ดำเนินการ</span>
                          <v-icon right>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item v-for="item in dataset_process_ready_to_ship.manageMenuListType" :key="item.name"
                          @click="handleClickOutside(item, set_TabListSubHeader_Process)">
                          <v-list-item-title>{{ item.name }}</v-list-item-title>
                        </v-list-item>


                      </v-list>
                    </v-menu>

                  </v-col>
                  <v-col class="py-1" cols="4">

                  </v-col>
                </v-row>
                <v-row class="text-center" align="center" v-if="dataset_process_ready_to_ship.selected.length > 1">
                  <v-col cols="12">
                    <div style="background: rgb(235 235 235)">
                      <b>คุณเลือกออเดอร์ {{ dataset_process_ready_to_ship.selected.length }} รายการในหน้านี้
                      </b>
                      <v-btn text @click="btnSelectedTarget(set_TabListSubHeader_Process)"
                        v-if="dataset_process_ready_to_ship.selected.length != dataset_process_ready_to_ship.total && dataset_process_ready_to_ship.limits != 999999">
                        <a>เลือกออเดอร์ทั้ง {{ dataset_process_ready_to_ship.total }} ในรายการหลัก</a>
                      </v-btn>
                      <v-btn text @click="dataset_process_ready_to_ship.selected = []" v-else>
                        <a>ล้างการเลือก</a>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
                <v-data-table v-model="dataset_process_ready_to_ship.selected"
                  :headers="dataset_process_ready_to_ship.headers" :items="dataset_process_ready_to_ship.dataTable"
                  class="elevation-0 packhai-border-table" item-key="packageID" show-select hide-default-footer
                  :items-per-page="10000">
                  <template v-slot:item.startPackingDate="{ item }">
                    {{ formatDatetime(item.startPackingDate) }}
                  </template>
                  <template v-slot:item.packedDate="{ item }">
                    {{ formatDatetime(item.packedDate) }}
                  </template>
                  <template v-slot:item.createdDatetime="{ item }">
                    {{ formatDatetime(item.createdDatetime) }}
                  </template>
                  <template v-slot:item.readyToPackDate="{ item }">
                    {{ formatDatetime(item.readyToPackDate) }}
                  </template>
                  <template v-slot:item.endPackingDate="{ item }">
                    {{ formatDatetime(item.endPackingDate) }}
                  </template>
                  <template v-slot:item.receiverName="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" style="width: 250px" class="text-truncate">
                          <span>{{ item.receiverName }}</span>
                        </div>
                      </template>
                      <span>
                        <span>{{ item.receiverName }}</span>
                      </span>
                    </v-tooltip>
                  </template>
                  <template v-slot:item.channelName="{ item }">
                    <v-avatar size="25px" tile>
                      <v-img v-if="item.channelLogo != null && item.channelLogo != ''" :src="item.channelLogo" />
                    </v-avatar>
                  </template>
                  <template v-slot:item.expressCompanyName="{ item }">
                    <img v-if="item.expressLogo != null && item.expressLogo != ''" width="60px"
                      :src="item.expressLogo" />
                  </template>
                  <template v-slot:item.isInit="{ item }">
                    <v-icon v-if="item.isInit != null && item.isInit" size="10"
                      color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else-if="item.isInit != null && !item.isInit" size="10"
                      color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.isPrintPackingList="{ item }">
                    <v-icon v-if="item.isPrintPackingList" size="10" color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else size="10" color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.isPrintAWB="{ item }">
                    <v-icon v-if="item.isPrintAWB" size="10" color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else size="10" color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.id="{ item }">
                    <a @click="ShowDetailOrder(item.token)">{{
                      FormatOrder(item.id)
                    }}</a>
                  </template>
                  <template v-for="(header, index) in dataTable.header" v-slot:[`header.${header.value}`]="{ header }">
                    <v-tooltip :key="index" v-slot:activator="{ on }">
                      <span>{{ header.text }}</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <v-row class="mt-2">
                  <v-col cols="2">
                    <v-select v-model="dataset_process_ready_to_ship.limits" hide-details outlined
                      :items="dataset_process_ready_to_ship.search.select_per" item-text="name" item-value="value"
                      @change="btnChangeLimits(set_TabListSubHeader_Process)">
                    </v-select>
                  </v-col>
                  <v-col cols="8" class="pa-0" center @click="btnChangePage(set_TabListSubHeader_Process)">
                    <v-pagination v-model="dataset_process_ready_to_ship.page"
                      :length="dataset_process_ready_to_ship.pageLength" :total-visible="9" color="info"></v-pagination>
                  </v-col>
                </v-row>
              </template>
              <template v-if="set_TabListSubHeader_Process == '40'">
                <v-card class="v-card-bottom-30 mx-0">
                  <v-card-text>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="5">
                        <v-row class="box-shadow-picker">
                          <v-col class="py-0" cols="6">
                            <v-select v-model="dataset_process_to_pack.search.select_topic"
                              :items="dataset_process_to_pack.search.select_header" persistent-hint return-object dense
                              hide-details flat solo item-text="name" item-value="value"></v-select>
                          </v-col>
                          <v-col class="py-0" cols="6">
                            <v-text-field v-model="dataset_process_to_pack.search.text_topic" placeholder="ค้นหา" flat
                              solo hide-details dense />
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col md="6" lg="3" class="hidden-md-and-down py-1">
                        <v-btn color="primary" class="mr-3" @click="btnSearchOrder(set_TabListSubHeader_Process)">{{
                          use_language.search }}</v-btn>
                      </v-col>
                      <v-col md="6" lg="4" class="hidden-md-and-down py-1">
                        <v-select v-model="dataset_process_to_pack.search.select_channel"
                          :items="dataset_process_to_pack.search.list_channel" item-text="channelName"
                          item-value="channelID" persistent-hint return-object dense outlined label="ช่องทาง"
                          @input="btnChannelSource(set_TabListSubHeader_Process, dataset_process_to_pack.search.select_channel)"
                          hide-details>
                          <template slot="selection" slot-scope="data">
                            <img v-if="data.item.channelLogo != null" :src="data.item.channelLogo" class="mr-2"
                              style="width: 25px" />
                            {{ length60(data.item.channelName) }}
                            <v-badge v-if="data.item.channelCount" inline color="error"
                              :content="data.item.channelCount" />
                          </template>
                          <template slot="item" slot-scope="data">
                            <img v-if="data.item.channelLogo != null" :src="data.item.channelLogo" class="mr-2"
                              style="width: 25px" />
                            {{ length60(data.item.channelName) }}
                            <v-badge v-if="data.item.channelCount" inline color="error"
                              :content="data.item.channelCount" />
                          </template>
                        </v-select>
                      </v-col>

                    </v-row>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="12" md="6" lg="1"></v-col>
                      <v-col class="py-1" md="6" lg="7">
                      </v-col>
                      <v-col class="py-1" md="6" lg="4">

                        <v-select v-model="dataset_process_to_pack.search.select_express"
                          :items="dataset_process_to_pack.search.list_express" persistent-hint return-object
                          item-text="expressCompanyName" item-value="expressCompanyID" dense outlined label="ขนส่ง"
                          @input="btnExprees(set_TabListSubHeader_Process, dataset_process_to_pack.search.select_express)"
                          hide-details>
                          <template slot="selection" slot-scope="data">
                            <img v-if="data.item.expressCompanyLogo != null" :src="data.item.expressCompanyLogo"
                              class="mr-2" style="width: 45px" />
                            {{ length60(data.item.expressCompanyName) }}
                            <v-badge v-if="data.item.expressCount" inline color="error"
                              :content="data.item.expressCount" />
                          </template>
                          <template slot="item" slot-scope="data">
                            <img v-if="data.item.expressCompanyLogo != null" :src="data.item.expressCompanyLogo"
                              class="mr-2" style="width: 45px" />
                            {{ length60(data.item.expressCompanyName) }}
                            <v-badge v-if="data.item.expressCount" inline color="error"
                              :content="data.item.expressCount" />
                          </template>
                        </v-select>

                      </v-col>
                    </v-row>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="8">

                      </v-col>
                      <v-col class="py-1" cols="4">
                        <v-select v-model="dataset_process_to_pack.search.select_print"
                          :items="dataset_process_to_pack.search.list_print" item-text="name" item-value="id"
                          label="ประเภทการปริ้น" dense return-object hide-details outlined
                          @input="btnSearchPrintType(set_TabListSubHeader_Process, dataset_process_to_pack.search.select_print)">
                          <template slot="selection" slot-scope="data">
                            {{ length60(data.item.name) }}
                            <v-badge v-if="data.item.count" inline color="error" :content="data.item.count" />
                          </template>
                          <template slot="item" slot-scope="data">
                            {{ length60(data.item.name) }}
                            <v-badge v-if="data.item.count" inline color="error" :content="data.item.count" />
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-row class="item-center px-0 py-1">
                  <v-col class="px-0" cols="8">

                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn :elevation="1" outlined v-bind="attrs" v-on="on">
                          <span class="pl-1">ดำเนินการ</span>
                          <v-icon right>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item v-for="item in dataset_process_to_pack.manageMenuListType" :key="item.name"
                          @click="handleClickOutside(item, set_TabListSubHeader_Process)">
                          <v-list-item-title>{{ item.name }}</v-list-item-title>
                        </v-list-item>



                      </v-list>
                    </v-menu>

                  </v-col>
                  <v-col class="py-1" cols="4">

                  </v-col>
                </v-row>
                <v-row class="text-center" align="center" v-if="dataset_process_to_pack.selected.length > 1">
                  <v-col cols="12">
                    <div style="background: rgb(235 235 235)">
                      <b>คุณเลือกออเดอร์ {{ dataset_process_to_pack.selected.length }} รายการในหน้านี้
                      </b>
                      <v-btn text @click="btnSelectedTarget(set_TabListSubHeader_Process)"
                        v-if="dataset_process_to_pack.selected.length != dataset_process_to_pack.total && dataset_process_to_pack.limits != 999999">
                        <a>เลือกออเดอร์ทั้ง {{ dataset_process_to_pack.total }} ในรายการหลัก</a>
                      </v-btn>
                      <v-btn text @click="dataset_process_to_pack.selected = []" v-else>
                        <a>ล้างการเลือก</a>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
                <v-data-table v-model="dataset_process_to_pack.selected" :headers="dataset_process_to_pack.headers"
                  :items="dataset_process_to_pack.dataTable" class="elevation-0 packhai-border-table"
                  item-key="packageID" show-select hide-default-footer :items-per-page="10000">
                  <template v-slot:item.startPackingDate="{ item }">
                    {{ formatDatetime(item.startPackingDate) }}
                  </template>
                  <template v-slot:item.packedDate="{ item }">
                    {{ formatDatetime(item.packedDate) }}
                  </template>
                  <template v-slot:item.createdDatetime="{ item }">
                    {{ formatDatetime(item.createdDatetime) }}
                  </template>
                  <template v-slot:item.readyToPackDate="{ item }">
                    {{ formatDatetime(item.readyToPackDate) }}
                  </template>
                  <template v-slot:item.endPackingDate="{ item }">
                    {{ formatDatetime(item.endPackingDate) }}
                  </template>
                  <template v-slot:item.receiverName="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" style="width: 250px" class="text-truncate">
                          <span>{{ item.receiverName }}</span>
                        </div>
                      </template>
                      <span>
                        <span>{{ item.receiverName }}</span>
                      </span>
                    </v-tooltip>
                  </template>
                  <template v-slot:item.channelName="{ item }">
                    <v-avatar size="25px" tile>
                      <v-img v-if="item.channelLogo != null && item.channelLogo != ''" :src="item.channelLogo" />
                    </v-avatar>
                  </template>
                  <template v-slot:item.expressCompanyName="{ item }">
                    <img v-if="item.expressLogo != null && item.expressLogo != ''" width="60px"
                      :src="item.expressLogo" />
                  </template>
                  <template v-slot:item.isInit="{ item }">
                    <v-icon v-if="item.isInit != null && item.isInit" size="10"
                      color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else-if="item.isInit != null && !item.isInit" size="10"
                      color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.isPrintPackingList="{ item }">
                    <v-icon v-if="item.isPrintPackingList" size="10" color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else size="10" color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.isPrintAWB="{ item }">
                    <v-icon v-if="item.isPrintAWB" size="10" color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else size="10" color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.id="{ item }">
                    <a @click="ShowDetailOrder(item.token)">{{
                      FormatOrder(item.id)
                    }}</a>
                  </template>
                  <template v-for="(header, index) in dataTable.header" v-slot:[`header.${header.value}`]="{ header }">
                    <v-tooltip :key="index" v-slot:activator="{ on }">
                      <span>{{ header.text }}</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <v-row class="mt-2">
                  <v-col cols="2">
                    <v-select v-model="dataset_process_to_pack.limits" hide-details outlined
                      :items="dataset_process_to_pack.search.select_per" item-text="name" item-value="value"
                      @change="btnChangeLimits(set_TabListSubHeader_Process)">
                    </v-select>
                  </v-col>
                  <v-col cols="8" class="pa-0" center @click="btnChangePage(set_TabListSubHeader_Process)">
                    <v-pagination v-model="dataset_process_to_pack.page" :length="dataset_process_to_pack.pageLength"
                      :total-visible="9" color="info"></v-pagination>
                  </v-col>
                </v-row>
              </template>
              <template v-if="set_TabListSubHeader_Process == '60'">
                <v-card class="v-card-bottom-30 mx-0">
                  <v-card-text>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="5">
                        <v-row class="box-shadow-picker">
                          <v-col class="py-0" cols="6">
                            <v-select v-model="dataset_process_packing.search.select_topic"
                              :items="dataset_process_packing.search.select_header" persistent-hint return-object dense
                              hide-details flat solo item-text="name" item-value="value"></v-select>
                          </v-col>
                          <v-col class="py-0" cols="6">
                            <v-text-field v-model="dataset_process_packing.search.text_topic" placeholder="ค้นหา" flat
                              solo hide-details dense />
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col md="6" lg="3" class="hidden-md-and-down py-1">
                        <v-btn color="primary" class="mr-3" @click="btnSearchOrder(set_TabListSubHeader_Process)">{{
                          use_language.search }}</v-btn>
                      </v-col>
                      <v-col md="6" lg="4" class="hidden-md-and-down py-1">
                        <v-select v-model="dataset_process_packing.search.select_channel"
                          :items="dataset_process_packing.search.list_channel" item-text="channelName"
                          item-value="channelID" persistent-hint return-object dense outlined label="ช่องทาง"
                          @input="btnChannelSource(set_TabListSubHeader_Process, dataset_process_packing.search.select_channel)"
                          hide-details>
                          <template slot="selection" slot-scope="data">
                            <img v-if="data.item.channelLogo != null" :src="data.item.channelLogo" class="mr-2"
                              style="width: 25px" />
                            {{ length60(data.item.channelName) }}
                            <v-badge v-if="data.item.channelCount" inline color="error"
                              :content="data.item.channelCount" />
                          </template>
                          <template slot="item" slot-scope="data">
                            <img v-if="data.item.channelLogo != null" :src="data.item.channelLogo" class="mr-2"
                              style="width: 25px" />
                            {{ length60(data.item.channelName) }}
                            <v-badge v-if="data.item.channelCount" inline color="error"
                              :content="data.item.channelCount" />
                          </template>
                        </v-select>
                      </v-col>

                    </v-row>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="12" md="6" lg="1"></v-col>
                      <v-col class="py-1" md="6" lg="7">
                      </v-col>
                      <v-col class="py-1" md="6" lg="4">

                        <v-select v-model="dataset_process_packing.search.select_express"
                          :items="dataset_process_packing.search.list_express" persistent-hint return-object
                          item-text="expressCompanyName" item-value="expressCompanyID" dense outlined label="ขนส่ง"
                          @input="btnExprees(set_TabListSubHeader_Process, dataset_process_packing.search.list_express)"
                          hide-details>
                          <template slot="selection" slot-scope="data">
                            <img v-if="data.item.expressCompanyLogo != null" :src="data.item.expressCompanyLogo"
                              class="mr-2" style="width: 45px" />
                            {{ length60(data.item.expressCompanyName) }}
                            <v-badge v-if="data.item.expressCount" inline color="error"
                              :content="data.item.expressCount" />
                          </template>
                          <template slot="item" slot-scope="data">
                            <img v-if="data.item.expressCompanyLogo != null" :src="data.item.expressCompanyLogo"
                              class="mr-2" style="width: 45px" />
                            {{ length60(data.item.expressCompanyName) }}
                            <v-badge v-if="data.item.expressCount" inline color="error"
                              :content="data.item.expressCount" />
                          </template>
                        </v-select>

                      </v-col>
                    </v-row>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="8">



                      </v-col>
                      <v-col class="py-1" cols="4">
                        <v-select v-model="dataset_process_packing.search.select_print"
                          :items="dataset_process_packing.search.list_print" item-text="name" item-value="id"
                          label="ประเภทการปริ้น" dense return-object hide-details outlined
                          @input="btnSearchPrintType(set_TabListSubHeader_Process, dataset_process_packing.search.select_print)">
                          <template slot="selection" slot-scope="data">
                            {{ length60(data.item.name) }}
                            <v-badge v-if="data.item.count" inline color="error" :content="data.item.count" />
                          </template>
                          <template slot="item" slot-scope="data">
                            {{ length60(data.item.name) }}
                            <v-badge v-if="data.item.count" inline color="error" :content="data.item.count" />
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-row class="item-center px-0 py-1">
                  <v-col class="px-0" cols="8">

                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn :elevation="1" outlined v-bind="attrs" v-on="on">
                          <span class="pl-1">ดำเนินการ</span>
                          <v-icon right>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item v-for="item in dataset_process_packing.manageMenuListType" :key="item.name"
                          @click="handleClickOutside(item, set_TabListSubHeader_Process)">
                          <v-list-item-title>{{ item.name }}</v-list-item-title>
                        </v-list-item>

                      </v-list>
                    </v-menu>

                  </v-col>
                  <v-col class="py-1" cols="4">

                  </v-col>
                </v-row>
                <v-row class="text-center" align="center" v-if="dataset_process_packing.selected.length > 1">
                  <v-col cols="12">
                    <div style="background: rgb(235 235 235)">
                      <b>คุณเลือกออเดอร์ {{ dataset_process_packing.selected.length }} รายการในหน้านี้
                      </b>
                      <v-btn text @click="btnSelectedTarget(set_TabListSubHeader_Process)"
                        v-if="dataset_process_packing.selected.length != dataset_process_packing.total && dataset_process_packing.limits != 999999">
                        <a>เลือกออเดอร์ทั้ง {{ dataset_process_packing.total }} ในรายการหลัก</a>
                      </v-btn>
                      <v-btn text @click="dataset_process_packing.selected = []" v-else>
                        <a>ล้างการเลือก</a>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
                <v-data-table v-model="dataset_process_packing.selected" :headers="dataset_process_packing.headers"
                  :items="dataset_process_packing.dataTable" class="elevation-0 packhai-border-table"
                  item-key="packageID" show-select hide-default-footer :items-per-page="10000">
                  <template v-slot:item.startPackingDate="{ item }">
                    {{ formatDatetime(item.startPackingDate) }}
                  </template>
                  <template v-slot:item.packedDate="{ item }">
                    {{ formatDatetime(item.packedDate) }}
                  </template>
                  <template v-slot:item.createdDatetime="{ item }">
                    {{ formatDatetime(item.createdDatetime) }}
                  </template>
                  <template v-slot:item.readyToPackDate="{ item }">
                    {{ formatDatetime(item.readyToPackDate) }}
                  </template>
                  <template v-slot:item.endPackingDate="{ item }">
                    {{ formatDatetime(item.endPackingDate) }}
                  </template>
                  <template v-slot:item.receiverName="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" style="width: 250px" class="text-truncate">
                          <span>{{ item.receiverName }}</span>
                        </div>
                      </template>
                      <span>
                        <span>{{ item.receiverName }}</span>
                      </span>
                    </v-tooltip>
                  </template>
                  <template v-slot:item.channelName="{ item }">
                    <v-avatar size="25px" tile>
                      <v-img v-if="item.channelLogo != null && item.channelLogo != ''" :src="item.channelLogo" />
                    </v-avatar>
                  </template>
                  <template v-slot:item.expressCompanyName="{ item }">
                    <img v-if="item.expressLogo != null && item.expressLogo != ''" width="60px"
                      :src="item.expressLogo" />
                  </template>
                  <template v-slot:item.isInit="{ item }">
                    <v-icon v-if="item.isInit != null && item.isInit" size="10"
                      color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else-if="item.isInit != null && !item.isInit" size="10"
                      color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.isPrintPackingList="{ item }">
                    <v-icon v-if="item.isPrintPackingList" size="10" color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else size="10" color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.isPrintAWB="{ item }">
                    <v-icon v-if="item.isPrintAWB" size="10" color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else size="10" color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.id="{ item }">
                    <a @click="ShowDetailOrder(item.token)">{{
                      FormatOrder(item.id)
                    }}</a>
                  </template>
                  <template v-for="(header, index) in dataTable.header" v-slot:[`header.${header.value}`]="{ header }">
                    <v-tooltip :key="index" v-slot:activator="{ on }">
                      <span>{{ header.text }}</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <v-row class="mt-2">
                  <v-col cols="2">
                    <v-select v-model="dataset_process_packing.limits" hide-details outlined
                      :items="dataset_process_packing.search.select_per" item-text="name" item-value="value"
                      @change="btnChangeLimits(set_TabListSubHeader_Process)">
                    </v-select>
                  </v-col>
                  <v-col cols="8" class="pa-0" center @click="btnChangePage(set_TabListSubHeader_Process)">
                    <v-pagination v-model="dataset_process_packing.page" :length="dataset_process_packing.pageLength"
                      :total-visible="9" color="info"></v-pagination>
                  </v-col>
                </v-row>
              </template>
            </v-card-text>

          </v-card>
        </v-card>
        <v-card v-if="set_TabListHeader == '80'" class="v-card-bottom-30 mx-0">
          <v-card>
            <v-card-text>
              <template>
                <v-card class="v-card-bottom-30 mx-0">
                  <v-card-text>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="5">
                        <v-row class="box-shadow-picker">
                          <v-col class="py-0" cols="6">
                            <v-select v-model="dataset_process_wait_pickup.search.select_topic"
                              :items="dataset_process_wait_pickup.search.select_header" persistent-hint return-object
                              dense hide-details flat solo item-text="name" item-value="value"></v-select>
                          </v-col>
                          <v-col class="py-0" cols="6">
                            <v-text-field v-model="dataset_process_wait_pickup.search.text_topic" placeholder="ค้นหา"
                              flat solo hide-details dense />
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col md="6" lg="3" class="hidden-md-and-down py-1">
                        <v-btn color="primary" class="mr-3" @click="btnSearchOrder(set_TabListHeader)">{{
                          use_language.search }}</v-btn>
                      </v-col>
                      <v-col md="6" lg="4" class="hidden-md-and-down py-1">
                        <v-select v-model="dataset_process_wait_pickup.search.select_channel"
                          :items="dataset_process_wait_pickup.search.list_channel" item-text="channelName"
                          item-value="channelID" persistent-hint return-object dense outlined label="ช่องทาง"
                          @input="btnChannelSource(set_TabListHeader, dataset_process_wait_pickup.search.select_channel)"
                          hide-details>
                          <template slot="selection" slot-scope="data">
                            <img v-if="data.item.channelLogo != null" :src="data.item.channelLogo" class="mr-2"
                              style="width: 25px" />
                            {{ length60(data.item.channelName) }}
                            <v-badge v-if="data.item.channelCount" inline color="error"
                              :content="data.item.channelCount" />
                          </template>
                          <template slot="item" slot-scope="data">
                            <img v-if="data.item.channelLogo != null" :src="data.item.channelLogo" class="mr-2"
                              style="width: 25px" />
                            {{ length60(data.item.channelName) }}
                            <v-badge v-if="data.item.channelCount" inline color="error"
                              :content="data.item.channelCount" />
                          </template>
                        </v-select>
                      </v-col>

                    </v-row>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="12" md="6" lg="1"></v-col>
                      <v-col class="py-1" md="6" lg="7">
                      </v-col>
                      <v-col class="py-1" md="6" lg="4">

                        <v-select v-model="dataset_process_wait_pickup.search.select_express"
                          :items="dataset_process_wait_pickup.search.list_express" persistent-hint return-object
                          item-text="expressCompanyName" item-value="expressCompanyID" dense outlined label="ขนส่ง"
                          @input="btnExprees(set_TabListHeader, dataset_process_wait_pickup.search.select_express)"
                          hide-details>
                          <template slot="selection" slot-scope="data">
                            <img v-if="data.item.expressCompanyLogo != null" :src="data.item.expressCompanyLogo"
                              class="mr-2" style="width: 45px" />
                            {{ length60(data.item.expressCompanyName) }}
                            <v-badge v-if="data.item.expressCount" inline color="error"
                              :content="data.item.expressCount" />
                          </template>
                          <template slot="item" slot-scope="data">
                            <img v-if="data.item.expressCompanyLogo != null" :src="data.item.expressCompanyLogo"
                              class="mr-2" style="width: 45px" />
                            {{ length60(data.item.expressCompanyName) }}
                            <v-badge v-if="data.item.expressCount" inline color="error"
                              :content="data.item.expressCount" />
                          </template>
                        </v-select>

                      </v-col>
                    </v-row>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="8">

                      </v-col>
                      <v-col class="py-1" cols="4">
                        <v-select v-model="dataset_process_wait_pickup.search.select_print"
                          :items="dataset_process_wait_pickup.search.list_print" item-text="name" item-value="id"
                          label="ประเภทการปริ้น" dense return-object hide-details outlined
                          @input="btnSearchPrintType(set_TabListHeader, dataset_process_wait_pickup.search.select_print)">
                          <template slot="selection" slot-scope="data">
                            {{ length60(data.item.name) }}
                            <v-badge v-if="data.item.count" inline color="error" :content="data.item.count" />
                          </template>
                          <template slot="item" slot-scope="data">
                            {{ length60(data.item.name) }}
                            <v-badge v-if="data.item.count" inline color="error" :content="data.item.count" />
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-row class="item-center px-0 py-1">
                  <v-col class="px-0" cols="8">

                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn :elevation="1" outlined v-bind="attrs" v-on="on">
                          <span class="pl-1">ดำเนินการ</span>
                          <v-icon right>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item v-for="item in dataset_process_wait_pickup.manageMenuListType" :key="item.name"
                          @click="handleClickOutside(item, set_TabListHeader)">
                          <v-list-item-title>{{ item.name }}</v-list-item-title>
                        </v-list-item>


                      </v-list>
                    </v-menu>

                  </v-col>
                  <v-col class="py-1" cols="4">

                  </v-col>
                </v-row>
                <v-row class="text-center" align="center" v-if="dataset_process_wait_pickup.selected.length > 1">
                  <v-col cols="12">
                    <div style="background: rgb(235 235 235)">
                      <b>คุณเลือกออเดอร์ {{ dataset_process_wait_pickup.selected.length }} รายการในหน้านี้
                      </b>
                      <v-btn text @click="btnSelectedTarget(set_TabListHeader)"
                        v-if="dataset_process_wait_pickup.selected.length != dataset_process_wait_pickup.total && dataset_process_wait_pickup.limits != 999999">
                        <a>เลือกออเดอร์ทั้ง {{ dataset_process_wait_pickup.total }} ในรายการหลัก</a>
                      </v-btn>
                      <v-btn text @click="dataset_process_wait_pickup.selected = []" v-else>
                        <a>ล้างการเลือก</a>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
                <v-data-table v-model="dataset_process_wait_pickup.selected"
                  :headers="dataset_process_wait_pickup.headers" :items="dataset_process_wait_pickup.dataTable"
                  class="elevation-0 packhai-border-table" item-key="packageID" show-select hide-default-footer
                  :items-per-page="10000">
                  <template v-slot:item.startPackingDate="{ item }">
                    {{ formatDatetime(item.startPackingDate) }}
                  </template>
                  <template v-slot:item.packedDate="{ item }">
                    {{ formatDatetime(item.packedDate) }}
                  </template>
                  <template v-slot:item.createdDatetime="{ item }">
                    {{ formatDatetime(item.createdDatetime) }}
                  </template>
                  <template v-slot:item.readyToPackDate="{ item }">
                    {{ formatDatetime(item.readyToPackDate) }}
                  </template>
                  <template v-slot:item.endPackingDate="{ item }">
                    {{ formatDatetime(item.endPackingDate) }}
                  </template>
                  <template v-slot:item.receiverName="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" style="width: 250px" class="text-truncate">
                          <span>{{ item.receiverName }}</span>
                        </div>
                      </template>
                      <span>
                        <span>{{ item.receiverName }}</span>
                      </span>
                    </v-tooltip>
                  </template>
                  <template v-slot:item.channelName="{ item }">
                    <v-avatar size="25px" tile>
                      <v-img v-if="item.channelLogo != null && item.channelLogo != ''" :src="item.channelLogo" />
                    </v-avatar>
                  </template>
                  <template v-slot:item.expressCompanyName="{ item }">
                    <img v-if="item.expressLogo != null && item.expressLogo != ''" width="60px"
                      :src="item.expressLogo" />
                  </template>
                  <template v-slot:item.isInit="{ item }">
                    <v-icon v-if="item.isInit != null && item.isInit" size="10"
                      color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else-if="item.isInit != null && !item.isInit" size="10"
                      color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.isPrintPackingList="{ item }">
                    <v-icon v-if="item.isPrintPackingList" size="10" color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else size="10" color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.isPrintAWB="{ item }">
                    <v-icon v-if="item.isPrintAWB" size="10" color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else size="10" color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.id="{ item }">
                    <a @click="ShowDetailOrder(item.token)">{{
                      FormatOrder(item.id)
                    }}</a>
                  </template>
                  <template v-for="(header, index) in dataTable.header" v-slot:[`header.${header.value}`]="{ header }">
                    <v-tooltip :key="index" v-slot:activator="{ on }">
                      <span>{{ header.text }}</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <v-row class="mt-2">
                  <v-col cols="2">
                    <v-select v-model="dataset_process_wait_pickup.limits" hide-details outlined
                      :items="dataset_process_wait_pickup.search.select_per" item-text="name" item-value="value"
                      @change="btnChangeLimits(set_TabListHeader)">
                    </v-select>
                  </v-col>
                  <v-col cols="8" class="pa-0" center @click="btnChangePage(set_TabListHeader)">
                    <v-pagination v-model="dataset_process_wait_pickup.page"
                      :length="dataset_process_wait_pickup.pageLength" :total-visible="9" color="info"></v-pagination>
                  </v-col>
                </v-row>
              </template>
            </v-card-text>

          </v-card>
        </v-card>
        <v-card v-if="set_TabListHeader == '90'" class="v-card-bottom-30 mx-0">
          <v-card>
            <v-card-text>

              <template>
                <v-card class="v-card-bottom-30 mx-0">
                  <v-card-text>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="5">
                        <v-row class="box-shadow-picker">
                          <v-col class="py-0" cols="6">
                            <v-select v-model="dataset_delivering.search.select_topic"
                              :items="dataset_delivering.search.select_header" persistent-hint return-object dense
                              hide-details flat solo item-text="name" item-value="value"></v-select>
                          </v-col>
                          <v-col class="py-0" cols="6">
                            <v-text-field v-model="dataset_delivering.search.text_topic" placeholder="ค้นหา" flat solo
                              hide-details dense />
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col md="6" lg="3" class="hidden-md-and-down py-1">
                        <v-btn color="primary" class="mr-3" @click="btnSearchOrder(set_TabListHeader)">{{
                          use_language.search }}</v-btn>
                      </v-col>
                      <v-col md="6" lg="4" class="hidden-md-and-down py-1">
                        <v-select v-model="dataset_delivering.search.select_channel"
                          :items="dataset_delivering.search.list_channel" item-text="channelName" item-value="channelID"
                          persistent-hint return-object dense outlined label="ช่องทาง"
                          @input="btnChannelSource(set_TabListHeader, dataset_delivering.search.select_channel)"
                          hide-details>
                          <template slot="selection" slot-scope="data">
                            <img v-if="data.item.channelLogo != null" :src="data.item.channelLogo" class="mr-2"
                              style="width: 25px" />
                            {{ length60(data.item.channelName) }}
                            <v-badge v-if="data.item.channelCount" inline color="error"
                              :content="data.item.channelCount" />
                          </template>
                          <template slot="item" slot-scope="data">
                            <img v-if="data.item.channelLogo != null" :src="data.item.channelLogo" class="mr-2"
                              style="width: 25px" />
                            {{ length60(data.item.channelName) }}
                            <v-badge v-if="data.item.channelCount" inline color="error"
                              :content="data.item.channelCount" />
                          </template>
                        </v-select>
                      </v-col>

                    </v-row>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="12" md="6" lg="1"></v-col>
                      <v-col class="py-1" md="6" lg="7">
                      </v-col>
                      <v-col class="py-1" md="6" lg="4">

                        <v-select v-model="dataset_delivering.search.select_express"
                          :items="dataset_delivering.search.list_express" persistent-hint return-object
                          item-text="expressCompanyName" item-value="expressCompanyID" dense outlined label="ขนส่ง"
                          @input="btnExprees(set_TabListHeader, dataset_delivering.search.select_express)" hide-details>
                          <template slot="selection" slot-scope="data">
                            <img v-if="data.item.expressCompanyLogo != null" :src="data.item.expressCompanyLogo"
                              class="mr-2" style="width: 45px" />
                            {{ length60(data.item.expressCompanyName) }}
                            <v-badge v-if="data.item.expressCount" inline color="error"
                              :content="data.item.expressCount" />
                          </template>
                          <template slot="item" slot-scope="data">
                            <img v-if="data.item.expressCompanyLogo != null" :src="data.item.expressCompanyLogo"
                              class="mr-2" style="width: 45px" />
                            {{ length60(data.item.expressCompanyName) }}
                            <v-badge v-if="data.item.expressCount" inline color="error"
                              :content="data.item.expressCount" />
                          </template>
                        </v-select>

                      </v-col>
                    </v-row>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="8">

                      </v-col>
                      <v-col class="py-1" cols="4">
                        <v-select v-model="dataset_delivering.search.select_print"
                          :items="dataset_delivering.search.list_print" item-text="name" item-value="id"
                          label="ประเภทการปริ้น" dense return-object hide-details outlined
                          @input="btnSearchPrintType(set_TabListSubHeader_Process, dataset_delivering.search.select_print)">
                          <template slot="selection" slot-scope="data">
                            {{ length60(data.item.name) }}
                            <v-badge v-if="data.item.count" inline color="error" :content="data.item.count" />
                          </template>
                          <template slot="item" slot-scope="data">
                            {{ length60(data.item.name) }}
                            <v-badge v-if="data.item.count" inline color="error" :content="data.item.count" />
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-row class="item-center px-0 py-1">
                  <v-col class="px-0" cols="8">

                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn :elevation="1" outlined v-bind="attrs" v-on="on">
                          <span class="pl-1">ดำเนินการ</span>
                          <v-icon right>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item v-for="item in dataset_delivering.manageMenuListType" :key="item.name"
                          @click="handleClickOutside(item, set_TabListSubHeader_Process)">
                          <v-list-item-title>{{ item.name }}</v-list-item-title>
                        </v-list-item>


                      </v-list>
                    </v-menu>

                  </v-col>
                  <v-col class="py-1" cols="4">

                  </v-col>
                </v-row>
                <v-row class="text-center" align="center" v-if="dataset_delivering.selected.length > 1">
                  <v-col cols="12">
                    <div style="background: rgb(235 235 235)">
                      <b>คุณเลือกออเดอร์ {{ dataset_delivering.selected.length }} รายการในหน้านี้
                      </b>
                      <v-btn text @click="btnSelectedTarget(set_TabListHeader)"
                        v-if="dataset_delivering.selected.length != dataset_delivering.total && dataset_delivering.limits != 999999">
                        <a>เลือกออเดอร์ทั้ง {{ dataset_delivering.total }} ในรายการหลัก</a>
                      </v-btn>
                      <v-btn text @click="dataset_delivering.selected = []" v-else>
                        <a>ล้างการเลือก</a>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
                <v-data-table v-model="dataset_delivering.selected" :headers="dataset_delivering.headers"
                  :items="dataset_delivering.dataTable" class="elevation-0 packhai-border-table" item-key="packageID"
                  show-select hide-default-footer :items-per-page="10000">
                  <template v-slot:item.startPackingDate="{ item }">
                    {{ formatDatetime(item.startPackingDate) }}
                  </template>
                  <template v-slot:item.packedDate="{ item }">
                    {{ formatDatetime(item.packedDate) }}
                  </template>
                  <template v-slot:item.createdDatetime="{ item }">
                    {{ formatDatetime(item.createdDatetime) }}
                  </template>
                  <template v-slot:item.readyToPackDate="{ item }">
                    {{ formatDatetime(item.readyToPackDate) }}
                  </template>
                  <template v-slot:item.endPackingDate="{ item }">
                    {{ formatDatetime(item.endPackingDate) }}
                  </template>
                  <template v-slot:item.receiverName="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" style="width: 250px" class="text-truncate">
                          <span>{{ item.receiverName }}</span>
                        </div>
                      </template>
                      <span>
                        <span>{{ item.receiverName }}</span>
                      </span>
                    </v-tooltip>
                  </template>
                  <template v-slot:item.channelName="{ item }">
                    <v-avatar size="25px" tile>
                      <v-img v-if="item.channelLogo != null && item.channelLogo != ''" :src="item.channelLogo" />
                    </v-avatar>
                  </template>
                  <template v-slot:item.expressCompanyName="{ item }">
                    <img v-if="item.expressLogo != null && item.expressLogo != ''" width="60px"
                      :src="item.expressLogo" />
                  </template>
                  <template v-slot:item.isInit="{ item }">
                    <v-icon v-if="item.isInit != null && item.isInit" size="10"
                      color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else-if="item.isInit != null && !item.isInit" size="10"
                      color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.isPrintPackingList="{ item }">
                    <v-icon v-if="item.isPrintPackingList" size="10" color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else size="10" color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.isPrintAWB="{ item }">
                    <v-icon v-if="item.isPrintAWB" size="10" color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else size="10" color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.id="{ item }">
                    <a @click="ShowDetailOrder(item.token)">{{
                      FormatOrder(item.id)
                    }}</a>
                  </template>
                  <template v-for="(header, index) in dataTable.header" v-slot:[`header.${header.value}`]="{ header }">
                    <v-tooltip :key="index" v-slot:activator="{ on }">
                      <span>{{ header.text }}</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <v-row class="mt-2">
                  <v-col cols="2">
                    <v-select v-model="dataset_delivering.limits" hide-details outlined
                      :items="dataset_delivering.search.select_per" item-text="name" item-value="value"
                      @change="btnChangeLimits(set_TabListHeader)">
                    </v-select>
                  </v-col>
                  <v-col cols="8" class="pa-0" center @click="btnChangePage(set_TabListHeader)">
                    <v-pagination v-model="dataset_delivering.page" :length="dataset_delivering.pageLength"
                      :total-visible="9" color="info"></v-pagination>
                  </v-col>
                </v-row>
              </template>

            </v-card-text>

          </v-card>
        </v-card>
        <v-card v-if="set_TabListHeader == '130'" class="v-card-bottom-30 mx-0">
          <v-card>
            <v-card-text>

              <template>
                <v-card class="v-card-bottom-30 mx-0">
                  <v-card-text>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="5">
                        <v-row class="box-shadow-picker">
                          <v-col class="py-0" cols="6">
                            <v-select v-model="dataset_success.search.select_topic"
                              :items="dataset_success.search.select_header" persistent-hint return-object dense
                              hide-details flat solo item-text="name" item-value="value"></v-select>
                          </v-col>
                          <v-col class="py-0" cols="6">
                            <v-text-field v-model="dataset_success.search.text_topic" placeholder="ค้นหา" flat solo
                              hide-details dense />
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col md="6" lg="3" class="hidden-md-and-down py-1">
                        <v-btn color="primary" class="mr-3" @click="btnSearchOrder(set_TabListHeader)">{{
                          use_language.search }}</v-btn>
                      </v-col>
                      <v-col md="6" lg="4" class="hidden-md-and-down py-1">
                        <v-select v-model="dataset_success.search.select_channel"
                          :items="dataset_success.search.list_channel" item-text="channelName" item-value="channelID"
                          persistent-hint return-object dense outlined label="ช่องทาง"
                          @input="btnChannelSource(set_TabListHeader, dataset_success.search.select_channel)"
                          hide-details>
                          <template slot="selection" slot-scope="data">
                            <img v-if="data.item.channelLogo != null" :src="data.item.channelLogo" class="mr-2"
                              style="width: 25px" />
                            {{ length60(data.item.channelName) }}
                            <v-badge v-if="data.item.channelCount" inline color="error"
                              :content="data.item.channelCount" />
                          </template>
                          <template slot="item" slot-scope="data">
                            <img v-if="data.item.channelLogo != null" :src="data.item.channelLogo" class="mr-2"
                              style="width: 25px" />
                            {{ length60(data.item.channelName) }}
                            <v-badge v-if="data.item.channelCount" inline color="error"
                              :content="data.item.channelCount" />
                          </template>
                        </v-select>
                      </v-col>

                    </v-row>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="12" md="6" lg="1"></v-col>
                      <v-col class="py-1" md="6" lg="7">
                      </v-col>
                      <v-col class="py-1" md="6" lg="4">

                        <v-select v-model="dataset_success.search.select_express"
                          :items="dataset_success.search.list_express" persistent-hint return-object
                          item-text="expressCompanyName" item-value="expressCompanyID" dense outlined label="ขนส่ง"
                          @input="btnExprees(set_TabListHeader, dataset_success.search.select_express)" hide-details>
                          <template slot="selection" slot-scope="data">
                            <img v-if="data.item.expressCompanyLogo != null" :src="data.item.expressCompanyLogo"
                              class="mr-2" style="width: 45px" />
                            {{ length60(data.item.expressCompanyName) }}
                            <v-badge v-if="data.item.expressCount" inline color="error"
                              :content="data.item.expressCount" />
                          </template>
                          <template slot="item" slot-scope="data">
                            <img v-if="data.item.expressCompanyLogo != null" :src="data.item.expressCompanyLogo"
                              class="mr-2" style="width: 45px" />
                            {{ length60(data.item.expressCompanyName) }}
                            <v-badge v-if="data.item.expressCount" inline color="error"
                              :content="data.item.expressCount" />
                          </template>
                        </v-select>

                      </v-col>
                    </v-row>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="8">



                      </v-col>
                      <v-col class="py-1" cols="4">
                        <v-select v-model="dataset_success.search.select_print"
                          :items="dataset_success.search.list_print" item-text="name" item-value="id"
                          label="ประเภทการปริ้น" dense return-object hide-details outlined
                          @input="btnSearchPrintType(set_TabListHeader, dataset_success.search.select_print)">
                          <template slot="selection" slot-scope="data">
                            {{ length60(data.item.name) }}
                            <v-badge v-if="data.item.count" inline color="error" :content="data.item.count" />
                          </template>
                          <template slot="item" slot-scope="data">
                            {{ length60(data.item.name) }}
                            <v-badge v-if="data.item.count" inline color="error" :content="data.item.count" />
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-row class="item-center px-0 py-1">
                  <v-col class="px-0" cols="8">

                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn :elevation="1" outlined v-bind="attrs" v-on="on">
                          <span class="pl-1">ดำเนินการ</span>
                          <v-icon right>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item v-for="item in dataset_success.manageMenuListType" :key="item.name"
                          @click="handleClickOutside(item, set_TabListHeader)">
                          <v-list-item-title>{{ item.name }}</v-list-item-title>
                        </v-list-item>


                      </v-list>
                    </v-menu>

                  </v-col>
                  <v-col class="py-1" cols="4">

                  </v-col>
                </v-row>
                <v-row class="text-center" align="center" v-if="dataset_success.selected.length > 1">
                  <v-col cols="12">
                    <div style="background: rgb(235 235 235)">
                      <b>คุณเลือกออเดอร์ {{ dataset_success.selected.length }} รายการในหน้านี้
                      </b>
                      <v-btn text @click="btnSelectedTarget(set_TabListHeader)"
                        v-if="dataset_success.selected.length != dataset_success.total && dataset_success.limits != 999999">
                        <a>เลือกออเดอร์ทั้ง {{ dataset_success.total }} ในรายการหลัก</a>
                      </v-btn>
                      <v-btn text @click="dataset_success.selected = []" v-else>
                        <a>ล้างการเลือก</a>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
                <v-data-table v-model="dataset_success.selected" :headers="dataset_success.headers"
                  :items="dataset_success.dataTable" class="elevation-0 packhai-border-table" item-key="packageID"
                  show-select hide-default-footer :items-per-page="10000">
                  <template v-slot:item.startPackingDate="{ item }">
                    {{ formatDatetime(item.startPackingDate) }}
                  </template>
                  <template v-slot:item.packedDate="{ item }">
                    {{ formatDatetime(item.packedDate) }}
                  </template>
                  <template v-slot:item.createdDatetime="{ item }">
                    {{ formatDatetime(item.createdDatetime) }}
                  </template>
                  <template v-slot:item.readyToPackDate="{ item }">
                    {{ formatDatetime(item.readyToPackDate) }}
                  </template>
                  <template v-slot:item.endPackingDate="{ item }">
                    {{ formatDatetime(item.endPackingDate) }}
                  </template>
                  <template v-slot:item.receiverName="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" style="width: 250px" class="text-truncate">
                          <span>{{ item.receiverName }}</span>
                        </div>
                      </template>
                      <span>
                        <span>{{ item.receiverName }}</span>
                      </span>
                    </v-tooltip>
                  </template>
                  <template v-slot:item.channelName="{ item }">
                    <v-avatar size="25px" tile>
                      <v-img v-if="item.channelLogo != null && item.channelLogo != ''" :src="item.channelLogo" />
                    </v-avatar>
                  </template>
                  <template v-slot:item.expressCompanyName="{ item }">
                    <img v-if="item.expressLogo != null && item.expressLogo != ''" width="60px"
                      :src="item.expressLogo" />
                  </template>
                  <template v-slot:item.isInit="{ item }">
                    <v-icon v-if="item.isInit != null && item.isInit" size="10"
                      color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else-if="item.isInit != null && !item.isInit" size="10"
                      color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.isPrintPackingList="{ item }">
                    <v-icon v-if="item.isPrintPackingList" size="10" color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else size="10" color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.isPrintAWB="{ item }">
                    <v-icon v-if="item.isPrintAWB" size="10" color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else size="10" color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.id="{ item }">
                    <a @click="ShowDetailOrder(item.token)">{{
                      FormatOrder(item.id)
                    }}</a>
                  </template>
                  <template v-for="(header, index) in dataTable.header" v-slot:[`header.${header.value}`]="{ header }">
                    <v-tooltip :key="index" v-slot:activator="{ on }">
                      <span>{{ header.text }}</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <v-row class="mt-2">
                  <v-col cols="2">
                    <v-select v-model="dataset_success.limits" hide-details outlined
                      :items="dataset_success.search.select_per" item-text="name" item-value="value"
                      @change="btnChangeLimits(set_TabListHeader)">
                    </v-select>
                  </v-col>
                  <v-col cols="8" class="pa-0" center @click="btnChangePage(set_TabListHeader)">
                    <v-pagination v-model="dataset_success.page" :length="dataset_success.pageLength" :total-visible="9"
                      color="info"></v-pagination>
                  </v-col>
                </v-row>
              </template>

            </v-card-text>

          </v-card>
        </v-card>
        <v-card v-if="set_TabListHeader == '100-110-120'" class="v-card-bottom-30 mx-0">
          <v-card>

            <v-card-text>
              <template v-if="showSubheader.length > 0">
                <v-tabs v-model="set_TabListSubHeader_Return">
                  <v-tabs-slider color="red"></v-tabs-slider>
                  <v-tab v-for="subItem of showSubheader" class="box-shadow" :key="subItem.id"
                    @click="ChangeTabSub(subItem.id)" :href="'#' + subItem.id">
                    {{ use_language[subItem.keyName] }}
                    <span>
                      ({{ subItem.packageCount }})

                    </span>
                  </v-tab>
                </v-tabs>
              </template>

              <template v-if="set_TabListSubHeader_Return == '100'">
                <v-card class="v-card-bottom-30 mx-0">
                  <v-card-text>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="5">
                        <v-row class="box-shadow-picker">
                          <v-col class="py-0" cols="6">
                            <v-select v-model="dataset_Return_Returnning.search.select_topic"
                              :items="dataset_Return_Returnning.search.select_header" persistent-hint return-object
                              dense hide-details flat solo item-text="name" item-value="value"></v-select>
                          </v-col>
                          <v-col class="py-0" cols="6">
                            <v-text-field v-model="dataset_Return_Returnning.search.text_topic" placeholder="ค้นหา" flat
                              solo hide-details dense />
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col md="6" lg="3" class="hidden-md-and-down py-1">
                        <v-btn color="primary" class="mr-3" @click="btnSearchOrder(set_TabListSubHeader_Return)">{{
                          use_language.search }}</v-btn>
                      </v-col>
                      <v-col md="6" lg="4" class="hidden-md-and-down py-1">
                        <v-select v-model="dataset_Return_Returnning.search.select_channel"
                          :items="dataset_Return_Returnning.search.list_channel" item-text="channelName"
                          item-value="channelID" persistent-hint return-object dense outlined label="ช่องทาง"
                          @input="btnChannelSource(set_TabListSubHeader_Return, dataset_Return_Returnning.search.select_channel)"
                          hide-details>
                          <template slot="selection" slot-scope="data">
                            <img v-if="data.item.channelLogo != null" :src="data.item.channelLogo" class="mr-2"
                              style="width: 25px" />
                            {{ length60(data.item.channelName) }}
                            <v-badge v-if="data.item.channelCount" inline color="error"
                              :content="data.item.channelCount" />
                          </template>
                          <template slot="item" slot-scope="data">
                            <img v-if="data.item.channelLogo != null" :src="data.item.channelLogo" class="mr-2"
                              style="width: 25px" />
                            {{ length60(data.item.channelName) }}
                            <v-badge v-if="data.item.channelCount" inline color="error"
                              :content="data.item.channelCount" />
                          </template>
                        </v-select>
                      </v-col>

                    </v-row>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="12" md="6" lg="1"></v-col>
                      <v-col class="py-1" md="6" lg="7">
                      </v-col>
                      <v-col class="py-1" md="6" lg="4">

                        <v-select v-model="dataset_Return_Returnning.search.select_express"
                          :items="dataset_Return_Returnning.search.list_express" persistent-hint return-object
                          item-text="expressCompanyName" item-value="expressCompanyID" dense outlined label="ขนส่ง"
                          @input="btnExprees(set_TabListSubHeader_Return, dataset_Return_Returnning.search.select_express)"
                          hide-details>
                          <template slot="selection" slot-scope="data">
                            <img v-if="data.item.expressCompanyLogo != null" :src="data.item.expressCompanyLogo"
                              class="mr-2" style="width: 45px" />
                            {{ length60(data.item.expressCompanyName) }}
                            <v-badge v-if="data.item.expressCount" inline color="error"
                              :content="data.item.expressCount" />
                          </template>
                          <template slot="item" slot-scope="data">
                            <img v-if="data.item.expressCompanyLogo != null" :src="data.item.expressCompanyLogo"
                              class="mr-2" style="width: 45px" />
                            {{ length60(data.item.expressCompanyName) }}
                            <v-badge v-if="data.item.expressCount" inline color="error"
                              :content="data.item.expressCount" />
                          </template>
                        </v-select>

                      </v-col>
                    </v-row>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="8">

                      </v-col>
                      <v-col class="py-1" cols="4">
                        <v-select v-model="dataset_Return_Returnning.search.select_print"
                          :items="dataset_Return_Returnning.search.list_print" item-text="name" item-value="id"
                          label="ประเภทการปริ้น" dense return-object hide-details outlined
                          @input="btnSearchPrintType(set_TabListSubHeader_Return, dataset_Return_Returnning.search.select_print)">
                          <template slot="selection" slot-scope="data">
                            {{ length60(data.item.name) }}
                            <v-badge v-if="data.item.count" inline color="error" :content="data.item.count" />
                          </template>
                          <template slot="item" slot-scope="data">
                            {{ length60(data.item.name) }}
                            <v-badge v-if="data.item.count" inline color="error" :content="data.item.count" />
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-row class="item-center px-0 py-1">
                  <v-col class="px-0" cols="8">

                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn :elevation="1" outlined v-bind="attrs" v-on="on">
                          <span class="pl-1">ดำเนินการ</span>
                          <v-icon right>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item v-for="item in dataset_Return_Returnning.manageMenuListType" :key="item.name"
                          @click="handleClickOutside(item, set_TabListSubHeader_Return)">
                          <v-list-item-title>{{ item.name }}</v-list-item-title>
                        </v-list-item>


                      </v-list>
                    </v-menu>

                  </v-col>
                  <v-col class="py-1" cols="4">

                  </v-col>
                </v-row>
                <v-row class="text-center" align="center" v-if="dataset_Return_Returnning.selected.length > 1">
                  <v-col cols="12">
                    <div style="background: rgb(235 235 235)">
                      <b>คุณเลือกออเดอร์ {{ dataset_Return_Returnning.selected.length }} รายการในหน้านี้
                      </b>
                      <v-btn text @click="btnSelectedTarget(set_TabListSubHeader_Return)"
                        v-if="dataset_Return_Returnning.selected.length != dataset_Return_Returnning.total && dataset_Return_Returnning.limits != 999999">
                        <a>เลือกออเดอร์ทั้ง {{ dataset_Return_Returnning.total }} ในรายการหลัก</a>
                      </v-btn>
                      <v-btn text @click="dataset_Return_Returnning.selected = []" v-else>
                        <a>ล้างการเลือก</a>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
                <v-data-table v-model="dataset_Return_Returnning.selected" :headers="dataset_Return_Returnning.headers"
                  :items="dataset_Return_Returnning.dataTable" class="elevation-0 packhai-border-table"
                  item-key="packageID" show-select hide-default-footer :items-per-page="10000">
                  <template v-slot:item.startPackingDate="{ item }">
                    {{ formatDatetime(item.startPackingDate) }}
                  </template>
                  <template v-slot:item.packedDate="{ item }">
                    {{ formatDatetime(item.packedDate) }}
                  </template>
                  <template v-slot:item.createdDatetime="{ item }">
                    {{ formatDatetime(item.createdDatetime) }}
                  </template>
                  <template v-slot:item.readyToPackDate="{ item }">
                    {{ formatDatetime(item.readyToPackDate) }}
                  </template>
                  <template v-slot:item.endPackingDate="{ item }">
                    {{ formatDatetime(item.endPackingDate) }}
                  </template>
                  <template v-slot:item.receiverName="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" style="width: 250px" class="text-truncate">
                          <span>{{ item.receiverName }}</span>
                        </div>
                      </template>
                      <span>
                        <span>{{ item.receiverName }}</span>
                      </span>
                    </v-tooltip>
                  </template>
                  <template v-slot:item.channelName="{ item }">
                    <v-avatar size="25px" tile>
                      <v-img v-if="item.channelLogo != null && item.channelLogo != ''" :src="item.channelLogo" />
                    </v-avatar>
                  </template>
                  <template v-slot:item.expressCompanyName="{ item }">
                    <img v-if="item.expressLogo != null && item.expressLogo != ''" width="60px"
                      :src="item.expressLogo" />
                  </template>
                  <template v-slot:item.isInit="{ item }">
                    <v-icon v-if="item.isInit != null && item.isInit" size="10"
                      color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else-if="item.isInit != null && !item.isInit" size="10"
                      color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.isPrintPackingList="{ item }">
                    <v-icon v-if="item.isPrintPackingList" size="10" color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else size="10" color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.isPrintAWB="{ item }">
                    <v-icon v-if="item.isPrintAWB" size="10" color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else size="10" color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.id="{ item }">
                    <a @click="ShowDetailOrder(item.token)">{{
                      FormatOrder(item.id)
                    }}</a>
                  </template>
                  <template v-for="(header, index) in dataTable.header" v-slot:[`header.${header.value}`]="{ header }">
                    <v-tooltip :key="index" v-slot:activator="{ on }">
                      <span>{{ header.text }}</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <v-row class="mt-2">
                  <v-col cols="2">
                    <v-select v-model="dataset_Return_Returnning.limits" hide-details outlined
                      :items="dataset_Return_Returnning.search.select_per" item-text="name" item-value="value"
                      @change="btnChangeLimits(set_TabListSubHeader_Return)">
                    </v-select>
                  </v-col>
                  <v-col cols="8" class="pa-0" center @click="btnChangePage(set_TabListSubHeader_Return)">
                    <v-pagination v-model="dataset_Return_Returnning.page"
                      :length="dataset_Return_Returnning.pageLength" :total-visible="9" color="info"></v-pagination>
                  </v-col>
                </v-row>
              </template>
              <template v-if="set_TabListSubHeader_Return == '110'">
                <v-card class="v-card-bottom-30 mx-0">
                  <v-card-text>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="5">
                        <v-row class="box-shadow-picker">
                          <v-col class="py-0" cols="6">
                            <v-select v-model="dataset_Return_To_Return_Stock.search.select_topic"
                              :items="dataset_Return_To_Return_Stock.search.select_header" persistent-hint return-object
                              dense hide-details flat solo item-text="name" item-value="value"></v-select>
                          </v-col>
                          <v-col class="py-0" cols="6">
                            <v-text-field v-model="dataset_Return_To_Return_Stock.search.text_topic" placeholder="ค้นหา"
                              flat solo hide-details dense />
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col md="6" lg="3" class="hidden-md-and-down py-1">
                        <v-btn color="primary" class="mr-3" @click="btnSearchOrder(set_TabListSubHeader_Return)">{{
                          use_language.search }}</v-btn>
                      </v-col>
                      <v-col md="6" lg="4" class="hidden-md-and-down py-1">
                        <v-select v-model="dataset_Return_To_Return_Stock.search.select_channel"
                          :items="dataset_Return_To_Return_Stock.search.list_channel" item-text="channelName"
                          item-value="channelID" persistent-hint return-object dense outlined label="ช่องทาง"
                          @input="btnChannelSource(set_TabListSubHeader_Return, dataset_Return_To_Return_Stock.search.select_channel)"
                          hide-details>
                          <template slot="selection" slot-scope="data">
                            <img v-if="data.item.channelLogo != null" :src="data.item.channelLogo" class="mr-2"
                              style="width: 25px" />
                            {{ length60(data.item.channelName) }}
                            <v-badge v-if="data.item.channelCount" inline color="error"
                              :content="data.item.channelCount" />
                          </template>
                          <template slot="item" slot-scope="data">
                            <img v-if="data.item.channelLogo != null" :src="data.item.channelLogo" class="mr-2"
                              style="width: 25px" />
                            {{ length60(data.item.channelName) }}
                            <v-badge v-if="data.item.channelCount" inline color="error"
                              :content="data.item.channelCount" />
                          </template>
                        </v-select>
                      </v-col>

                    </v-row>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="12" md="6" lg="1"></v-col>
                      <v-col class="py-1" md="6" lg="7">
                      </v-col>
                      <v-col class="py-1" md="6" lg="4">

                        <v-select v-model="dataset_Return_To_Return_Stock.search.select_express"
                          :items="dataset_Return_To_Return_Stock.search.list_express" persistent-hint return-object
                          item-text="expressCompanyName" item-value="expressCompanyID" dense outlined label="ขนส่ง"
                          @input="btnExprees(set_TabListSubHeader_Return, dataset_Return_To_Return_Stock.search.select_express)"
                          hide-details>
                          <template slot="selection" slot-scope="data">
                            <img v-if="data.item.expressCompanyLogo != null" :src="data.item.expressCompanyLogo"
                              class="mr-2" style="width: 45px" />
                            {{ length60(data.item.expressCompanyName) }}
                            <v-badge v-if="data.item.expressCount" inline color="error"
                              :content="data.item.expressCount" />
                          </template>
                          <template slot="item" slot-scope="data">
                            <img v-if="data.item.expressCompanyLogo != null" :src="data.item.expressCompanyLogo"
                              class="mr-2" style="width: 45px" />
                            {{ length60(data.item.expressCompanyName) }}
                            <v-badge v-if="data.item.expressCount" inline color="error"
                              :content="data.item.expressCount" />
                          </template>
                        </v-select>

                      </v-col>
                    </v-row>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="8">


                      </v-col>
                      <v-col class="py-1" cols="4">
                        <v-select v-model="dataset_Return_To_Return_Stock.search.select_print"
                          :items="dataset_Return_To_Return_Stock.search.list_print" item-text="name" item-value="id"
                          label="ประเภทการปริ้น" dense return-object hide-details outlined
                          @input="btnSearchPrintType(set_TabListSubHeader_Return, dataset_Return_To_Return_Stock.search.select_print)">
                          <template slot="selection" slot-scope="data">
                            {{ length60(data.item.name) }}
                            <v-badge v-if="data.item.count" inline color="error" :content="data.item.count" />
                          </template>
                          <template slot="item" slot-scope="data">
                            {{ length60(data.item.name) }}
                            <v-badge v-if="data.item.count" inline color="error" :content="data.item.count" />
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-row class="item-center px-0 py-1">
                  <v-col class="px-0" cols="8">

                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn :elevation="1" outlined v-bind="attrs" v-on="on">
                          <span class="pl-1">ดำเนินการ</span>
                          <v-icon right>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item v-for="item in dataset_Return_To_Return_Stock.manageMenuListType" :key="item.name"
                          @click="handleClickOutside(item, set_TabListSubHeader_Return)">
                          <v-list-item-title>{{ item.name }}</v-list-item-title>
                        </v-list-item>


                      </v-list>
                    </v-menu>

                  </v-col>
                  <v-col class="py-1" cols="4">

                  </v-col>
                </v-row>
                <v-row class="text-center" align="center" v-if="dataset_Return_To_Return_Stock.selected.length > 1">
                  <v-col cols="12">
                    <div style="background: rgb(235 235 235)">
                      <b>คุณเลือกออเดอร์ {{ dataset_Return_To_Return_Stock.selected.length }} รายการในหน้านี้
                      </b>
                      <v-btn text @click="btnSelectedTarget(set_TabListSubHeader_Return)"
                        v-if="dataset_Return_To_Return_Stock.selected.length != dataset_Return_To_Return_Stock.total && dataset_Return_To_Return_Stock.limits != 999999">
                        <a>เลือกออเดอร์ทั้ง {{ dataset_Return_To_Return_Stock.total }} ในรายการหลัก</a>
                      </v-btn>
                      <v-btn text @click="dataset_Return_To_Return_Stock.selected = []" v-else>
                        <a>ล้างการเลือก</a>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
                <v-data-table v-model="dataset_Return_To_Return_Stock.selected"
                  :headers="dataset_Return_To_Return_Stock.headers" :items="dataset_Return_To_Return_Stock.dataTable"
                  class="elevation-0 packhai-border-table" item-key="packageID" show-select hide-default-footer
                  :items-per-page="10000">
                  <template v-slot:item.startPackingDate="{ item }">
                    {{ formatDatetime(item.startPackingDate) }}
                  </template>
                  <template v-slot:item.packedDate="{ item }">
                    {{ formatDatetime(item.packedDate) }}
                  </template>
                  <template v-slot:item.createdDatetime="{ item }">
                    {{ formatDatetime(item.createdDatetime) }}
                  </template>
                  <template v-slot:item.readyToPackDate="{ item }">
                    {{ formatDatetime(item.readyToPackDate) }}
                  </template>
                  <template v-slot:item.endPackingDate="{ item }">
                    {{ formatDatetime(item.endPackingDate) }}
                  </template>
                  <template v-slot:item.receiverName="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" style="width: 250px" class="text-truncate">
                          <span>{{ item.receiverName }}</span>
                        </div>
                      </template>
                      <span>
                        <span>{{ item.receiverName }}</span>
                      </span>
                    </v-tooltip>
                  </template>
                  <template v-slot:item.channelName="{ item }">
                    <v-avatar size="25px" tile>
                      <v-img v-if="item.channelLogo != null && item.channelLogo != ''" :src="item.channelLogo" />
                    </v-avatar>
                  </template>
                  <template v-slot:item.expressCompanyName="{ item }">
                    <img v-if="item.expressLogo != null && item.expressLogo != ''" width="60px"
                      :src="item.expressLogo" />
                  </template>
                  <template v-slot:item.isInit="{ item }">
                    <v-icon v-if="item.isInit != null && item.isInit" size="10"
                      color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else-if="item.isInit != null && !item.isInit" size="10"
                      color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.isPrintPackingList="{ item }">
                    <v-icon v-if="item.isPrintPackingList" size="10" color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else size="10" color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.isPrintAWB="{ item }">
                    <v-icon v-if="item.isPrintAWB" size="10" color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else size="10" color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.id="{ item }">
                    <a @click="ShowDetailOrder(item.token)">{{
                      FormatOrder(item.id)
                    }}</a>
                  </template>
                  <template v-for="(header, index) in dataTable.header" v-slot:[`header.${header.value}`]="{ header }">
                    <v-tooltip :key="index" v-slot:activator="{ on }">
                      <span>{{ header.text }}</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <v-row class="mt-2">
                  <v-col cols="2">
                    <v-select v-model="dataset_Return_To_Return_Stock.limits" hide-details outlined
                      :items="dataset_Return_To_Return_Stock.search.select_per" item-text="name" item-value="value"
                      @change="btnChangeLimits(set_TabListSubHeader_Return)">
                    </v-select>
                  </v-col>
                  <v-col cols="8" class="pa-0" center @click="btnChangePage(set_TabListSubHeader_Return)">
                    <v-pagination v-model="dataset_Return_To_Return_Stock.page"
                      :length="dataset_Return_To_Return_Stock.pageLength" :total-visible="9"
                      color="info"></v-pagination>
                  </v-col>
                </v-row>
              </template>
              <template v-if="set_TabListSubHeader_Return == '120'">
                <v-card class="v-card-bottom-30 mx-0">
                  <v-card-text>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="5">
                        <v-row class="box-shadow-picker">
                          <v-col class="py-0" cols="6">
                            <v-select v-model="dataset_Return_Stock_Returned.search.select_topic"
                              :items="dataset_Return_Stock_Returned.search.select_header" persistent-hint return-object
                              dense hide-details flat solo item-text="name" item-value="value"></v-select>
                          </v-col>
                          <v-col class="py-0" cols="6">
                            <v-text-field v-model="dataset_Return_Stock_Returned.search.text_topic" placeholder="ค้นหา"
                              flat solo hide-details dense />
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col md="6" lg="3" class="hidden-md-and-down py-1">
                        <v-btn color="primary" class="mr-3" @click="btnSearchOrder(set_TabListSubHeader_Return)">{{
                          use_language.search }}</v-btn>
                      </v-col>
                      <v-col md="6" lg="4" class="hidden-md-and-down py-1">
                        <v-select v-model="dataset_Return_Stock_Returned.search.select_channel"
                          :items="dataset_Return_Stock_Returned.search.list_channel" item-text="channelName"
                          item-value="channelID" persistent-hint return-object dense outlined label="ช่องทาง"
                          @input="btnChannelSource(set_TabListSubHeader_Return, dataset_Return_Stock_Returned.search.select_channel)"
                          hide-details>
                          <template slot="selection" slot-scope="data">
                            <img v-if="data.item.channelLogo != null" :src="data.item.channelLogo" class="mr-2"
                              style="width: 25px" />
                            {{ length60(data.item.channelName) }}
                            <v-badge v-if="data.item.channelCount" inline color="error"
                              :content="data.item.channelCount" />
                          </template>
                          <template slot="item" slot-scope="data">
                            <img v-if="data.item.channelLogo != null" :src="data.item.channelLogo" class="mr-2"
                              style="width: 25px" />
                            {{ length60(data.item.channelName) }}
                            <v-badge v-if="data.item.channelCount" inline color="error"
                              :content="data.item.channelCount" />
                          </template>
                        </v-select>
                      </v-col>

                    </v-row>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="12" md="6" lg="1"></v-col>
                      <v-col class="py-1" md="6" lg="7">
                      </v-col>
                      <v-col class="py-1" md="6" lg="4">

                        <v-select v-model="dataset_Return_Stock_Returned.search.select_express"
                          :items="dataset_Return_Stock_Returned.search.list_express" persistent-hint return-object
                          item-text="expressCompanyName" item-value="expressCompanyID" dense outlined label="ขนส่ง"
                          @input="btnExprees(set_TabListSubHeader_Return, dataset_Return_Stock_Returned.search.select_express)"
                          hide-details>
                          <template slot="selection" slot-scope="data">
                            <img v-if="data.item.expressCompanyLogo != null" :src="data.item.expressCompanyLogo"
                              class="mr-2" style="width: 45px" />
                            {{ length60(data.item.expressCompanyName) }}
                            <v-badge v-if="data.item.expressCount" inline color="error"
                              :content="data.item.expressCount" />
                          </template>
                          <template slot="item" slot-scope="data">
                            <img v-if="data.item.expressCompanyLogo != null" :src="data.item.expressCompanyLogo"
                              class="mr-2" style="width: 45px" />
                            {{ length60(data.item.expressCompanyName) }}
                            <v-badge v-if="data.item.expressCount" inline color="error"
                              :content="data.item.expressCount" />
                          </template>
                        </v-select>

                      </v-col>
                    </v-row>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="8">



                      </v-col>
                      <v-col class="py-1" cols="4">
                        <v-select v-model="dataset_Return_Stock_Returned.search.select_print"
                          :items="dataset_Return_Stock_Returned.search.list_print" item-text="name" item-value="id"
                          label="ประเภทการปริ้น" dense return-object hide-details outlined
                          @input="btnSearchPrintType(set_TabListSubHeader_Return, dataset_Return_Stock_Returned.search.select_print)">
                          <template slot="selection" slot-scope="data">
                            {{ length60(data.item.name) }}
                            <v-badge v-if="data.item.count" inline color="error" :content="data.item.count" />
                          </template>
                          <template slot="item" slot-scope="data">
                            {{ length60(data.item.name) }}
                            <v-badge v-if="data.item.count" inline color="error" :content="data.item.count" />
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-row class="item-center px-0 py-1">
                  <v-col class="px-0" cols="8">

                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn :elevation="1" outlined v-bind="attrs" v-on="on">
                          <span class="pl-1">ดำเนินการ</span>
                          <v-icon right>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item v-for="item in dataset_Return_Stock_Returned.manageMenuListType" :key="item.name"
                          @click="handleClickOutside(item, set_TabListSubHeader_Return)">
                          <v-list-item-title>{{ item.name }}</v-list-item-title>
                        </v-list-item>


                      </v-list>
                    </v-menu>

                  </v-col>
                  <v-col class="py-1" cols="4">

                  </v-col>
                </v-row>
                <v-row class="text-center" align="center" v-if="dataset_Return_Stock_Returned.selected.length > 1">
                  <v-col cols="12">
                    <div style="background: rgb(235 235 235)">
                      <b>คุณเลือกออเดอร์ {{ dataset_Return_Stock_Returned.selected.length }} รายการในหน้านี้
                      </b>
                      <v-btn text @click="btnSelectedTarget(set_TabListSubHeader_Return)"
                        v-if="dataset_Return_Stock_Returned.selected.length != dataset_Return_Stock_Returned.total && dataset_Return_Stock_Returned.limits != 999999">
                        <a>เลือกออเดอร์ทั้ง {{ dataset_Return_Stock_Returned.total }} ในรายการหลัก</a>
                      </v-btn>
                      <v-btn text @click="dataset_Return_Stock_Returned.selected = []" v-else>
                        <a>ล้างการเลือก</a>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
                <v-data-table v-model="dataset_Return_Stock_Returned.selected"
                  :headers="dataset_Return_Stock_Returned.headers" :items="dataset_Return_Stock_Returned.dataTable"
                  class="elevation-0 packhai-border-table" item-key="packageID" show-select hide-default-footer
                  :items-per-page="10000">
                  <template v-slot:item.startPackingDate="{ item }">
                    {{ formatDatetime(item.startPackingDate) }}
                  </template>
                  <template v-slot:item.packedDate="{ item }">
                    {{ formatDatetime(item.packedDate) }}
                  </template>
                  <template v-slot:item.createdDatetime="{ item }">
                    {{ formatDatetime(item.createdDatetime) }}
                  </template>
                  <template v-slot:item.readyToPackDate="{ item }">
                    {{ formatDatetime(item.readyToPackDate) }}
                  </template>
                  <template v-slot:item.endPackingDate="{ item }">
                    {{ formatDatetime(item.endPackingDate) }}
                  </template>
                  <template v-slot:item.receiverName="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" style="width: 250px" class="text-truncate">
                          <span>{{ item.receiverName }}</span>
                        </div>
                      </template>
                      <span>
                        <span>{{ item.receiverName }}</span>
                      </span>
                    </v-tooltip>
                  </template>
                  <template v-slot:item.channelName="{ item }">
                    <v-avatar size="25px" tile>
                      <v-img v-if="item.channelLogo != null && item.channelLogo != ''" :src="item.channelLogo" />
                    </v-avatar>
                  </template>
                  <template v-slot:item.expressCompanyName="{ item }">
                    <img v-if="item.expressLogo != null && item.expressLogo != ''" width="60px"
                      :src="item.expressLogo" />
                  </template>
                  <template v-slot:item.isInit="{ item }">
                    <v-icon v-if="item.isInit != null && item.isInit" size="10"
                      color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else-if="item.isInit != null && !item.isInit" size="10"
                      color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.isPrintPackingList="{ item }">
                    <v-icon v-if="item.isPrintPackingList" size="10" color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else size="10" color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.isPrintAWB="{ item }">
                    <v-icon v-if="item.isPrintAWB" size="10" color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else size="10" color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.id="{ item }">
                    <a @click="ShowDetailOrder(item.token)">{{
                      FormatOrder(item.id)
                    }}</a>
                  </template>
                  <template v-for="(header, index) in dataTable.header" v-slot:[`header.${header.value}`]="{ header }">
                    <v-tooltip :key="index" v-slot:activator="{ on }">
                      <span>{{ header.text }}</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <v-row class="mt-2">
                  <v-col cols="2">
                    <v-select v-model="dataset_Return_Stock_Returned.limits" hide-details outlined
                      :items="dataset_Return_Stock_Returned.search.select_per" item-text="name" item-value="value"
                      @change="btnChangeLimits(set_TabListSubHeader_Return)">
                    </v-select>
                  </v-col>
                  <v-col cols="8" class="pa-0" center @click="btnChangePage(set_TabListSubHeader_Return)">
                    <v-pagination v-model="dataset_Return_Stock_Returned.page"
                      :length="dataset_Return_Stock_Returned.pageLength" :total-visible="9" color="info"></v-pagination>
                  </v-col>
                </v-row>
              </template>

            </v-card-text>
          </v-card>
        </v-card>
        <v-card v-if="set_TabListHeader == '140'" class="v-card-bottom-30 mx-0">
          <v-card>
            <v-card-text>
              <template>
                <v-card class="v-card-bottom-30 mx-0">
                  <v-card-text>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="5">
                        <v-row class="box-shadow-picker">
                          <v-col class="py-0" cols="6">
                            <v-select v-model="dataset_cancel.search.select_topic"
                              :items="dataset_cancel.search.select_header" persistent-hint return-object dense
                              hide-details flat solo item-text="name" item-value="value"></v-select>
                          </v-col>
                          <v-col class="py-0" cols="6">
                            <v-text-field v-model="dataset_cancel.search.text_topic" placeholder="ค้นหา" flat solo
                              hide-details dense />
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col md="6" lg="3" class="hidden-md-and-down py-1">
                        <v-btn color="primary" class="mr-3" @click="btnSearchOrder(set_TabListHeader)">{{
                          use_language.search }}</v-btn>
                      </v-col>
                      <v-col md="6" lg="4" class="hidden-md-and-down py-1">
                        <v-select v-model="dataset_cancel.search.select_channel"
                          :items="dataset_cancel.search.list_channel" item-text="channelName" item-value="channelID"
                          persistent-hint return-object dense outlined label="ช่องทาง"
                          @input="btnChannelSource(set_TabListHeader, dataset_cancel.search.select_channel)"
                          hide-details>
                          <template slot="selection" slot-scope="data">
                            <img v-if="data.item.channelLogo != null" :src="data.item.channelLogo" class="mr-2"
                              style="width: 25px" />
                            {{ length60(data.item.channelName) }}
                            <v-badge v-if="data.item.channelCount" inline color="error"
                              :content="data.item.channelCount" />
                          </template>
                          <template slot="item" slot-scope="data">
                            <img v-if="data.item.channelLogo != null" :src="data.item.channelLogo" class="mr-2"
                              style="width: 25px" />
                            {{ length60(data.item.channelName) }}
                            <v-badge v-if="data.item.channelCount" inline color="error"
                              :content="data.item.channelCount" />
                          </template>
                        </v-select>
                      </v-col>

                    </v-row>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="12" md="6" lg="1"></v-col>
                      <v-col class="py-1" md="6" lg="7">
                      </v-col>
                      <v-col class="py-1" md="6" lg="4">

                        <v-select v-model="dataset_cancel.search.select_express"
                          :items="dataset_cancel.search.list_express" persistent-hint return-object
                          item-text="expressCompanyName" item-value="expressCompanyID" dense outlined label="ขนส่ง"
                          @input="btnExprees(set_TabListHeader, dataset_cancel.search.select_express)" hide-details>
                          <template slot="selection" slot-scope="data">
                            <img v-if="data.item.expressCompanyLogo != null" :src="data.item.expressCompanyLogo"
                              class="mr-2" style="width: 45px" />
                            {{ length60(data.item.expressCompanyName) }}
                            <v-badge v-if="data.item.expressCount" inline color="error"
                              :content="data.item.expressCount" />
                          </template>
                          <template slot="item" slot-scope="data">
                            <img v-if="data.item.expressCompanyLogo != null" :src="data.item.expressCompanyLogo"
                              class="mr-2" style="width: 45px" />
                            {{ length60(data.item.expressCompanyName) }}
                            <v-badge v-if="data.item.expressCount" inline color="error"
                              :content="data.item.expressCount" />
                          </template>
                        </v-select>

                      </v-col>
                    </v-row>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="8">



                      </v-col>
                      <v-col class="py-1" cols="4">
                        <v-select v-model="dataset_cancel.search.select_print" :items="dataset_cancel.search.list_print"
                          item-text="name" item-value="id" label="ประเภทการปริ้น" dense return-object hide-details
                          outlined @input="btnSearchPrintType(set_TabListHeader, dataset_cancel.search.select_print)">
                          <template slot="selection" slot-scope="data">
                            {{ length60(data.item.name) }}
                            <v-badge v-if="data.item.count" inline color="error" :content="data.item.count" />
                          </template>
                          <template slot="item" slot-scope="data">
                            {{ length60(data.item.name) }}
                            <v-badge v-if="data.item.count" inline color="error" :content="data.item.count" />
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-row class="item-center px-0 py-1">
                  <v-col class="px-0" cols="8">

                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn :elevation="1" outlined v-bind="attrs" v-on="on">
                          <span class="pl-1">ดำเนินการ</span>
                          <v-icon right>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item v-for="item in dataset_cancel.manageMenuListType" :key="item.name"
                          @click="handleClickOutside(item, set_TabListHeader)">
                          <v-list-item-title>{{ item.name }}</v-list-item-title>
                        </v-list-item>


                      </v-list>
                    </v-menu>

                  </v-col>
                  <v-col class="py-1" cols="4">

                  </v-col>
                </v-row>
                <v-row class="text-center" align="center" v-if="dataset_cancel.selected.length > 1">
                  <v-col cols="12">
                    <div style="background: rgb(235 235 235)">
                      <b>คุณเลือกออเดอร์ {{ dataset_cancel.selected.length }} รายการในหน้านี้
                      </b>
                      <v-btn text @click="btnSelectedTarget(set_TabListHeader)"
                        v-if="dataset_cancel.selected.length != dataset_cancel.total && dataset_cancel.limits != 999999">
                        <a>เลือกออเดอร์ทั้ง {{ dataset_cancel.total }} ในรายการหลัก</a>
                      </v-btn>
                      <v-btn text @click="dataset_cancel.selected = []" v-else>
                        <a>ล้างการเลือก</a>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
                <v-data-table v-model="dataset_cancel.selected" :headers="dataset_cancel.headers"
                  :items="dataset_cancel.dataTable" class="elevation-0 packhai-border-table" item-key="packageID"
                  show-select hide-default-footer :items-per-page="10000">
                  <template v-slot:item.startPackingDate="{ item }">
                    {{ formatDatetime(item.startPackingDate) }}
                  </template>
                  <template v-slot:item.packedDate="{ item }">
                    {{ formatDatetime(item.packedDate) }}
                  </template>
                  <template v-slot:item.createdDatetime="{ item }">
                    {{ formatDatetime(item.createdDatetime) }}
                  </template>
                  <template v-slot:item.readyToPackDate="{ item }">
                    {{ formatDatetime(item.readyToPackDate) }}
                  </template>
                  <template v-slot:item.endPackingDate="{ item }">
                    {{ formatDatetime(item.endPackingDate) }}
                  </template>
                  <template v-slot:item.receiverName="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" style="width: 250px" class="text-truncate">
                          <span>{{ item.receiverName }}</span>
                        </div>
                      </template>
                      <span>
                        <span>{{ item.receiverName }}</span>
                      </span>
                    </v-tooltip>
                  </template>
                  <template v-slot:item.channelName="{ item }">
                    <v-avatar size="25px" tile>
                      <v-img v-if="item.channelLogo != null && item.channelLogo != ''" :src="item.channelLogo" />
                    </v-avatar>
                  </template>
                  <template v-slot:item.expressCompanyName="{ item }">
                    <img v-if="item.expressLogo != null && item.expressLogo != ''" width="60px"
                      :src="item.expressLogo" />
                  </template>
                  <template v-slot:item.isInit="{ item }">
                    <v-icon v-if="item.isInit != null && item.isInit" size="10"
                      color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else-if="item.isInit != null && !item.isInit" size="10"
                      color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.isPrintPackingList="{ item }">
                    <v-icon v-if="item.isPrintPackingList" size="10" color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else size="10" color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.isPrintAWB="{ item }">
                    <v-icon v-if="item.isPrintAWB" size="10" color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else size="10" color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.id="{ item }">
                    <a @click="ShowDetailOrder(item.token)">{{
                      FormatOrder(item.id)
                    }}</a>
                  </template>
                  <template v-for="(header, index) in dataTable.header" v-slot:[`header.${header.value}`]="{ header }">
                    <v-tooltip :key="index" v-slot:activator="{ on }">
                      <span>{{ header.text }}</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <v-row class="mt-2">
                  <v-col cols="2">
                    <v-select v-model="dataset_cancel.limits" hide-details outlined
                      :items="dataset_cancel.search.select_per" item-text="name" item-value="value"
                      @change="btnChangeLimits(set_TabListHeader)">
                    </v-select>
                  </v-col>
                  <v-col cols="8" class="pa-0" center @click="btnChangePage(set_TabListHeader)">
                    <v-pagination v-model="dataset_cancel.page" :length="dataset_cancel.pageLength" :total-visible="9"
                      color="info"></v-pagination>
                  </v-col>
                </v-row>
              </template>
            </v-card-text>

          </v-card>
        </v-card>

      </v-card>


      <!-- กรองสินค้า -->
      <v-dialog v-model="filterOrderPopup.dialog" scrollable :max-width="filterOrderPopup.MaxWidth" persistent
        :fullscreen="$vuetify.breakpoint.xsOnly">
        <v-card elevation="1" class="ma-0">
          <v-card-title class="height-57 pa-0">
            <div class="v-list-item">

              <v-row class="v-list-item__content ma-0">

                <v-col cols="6">
                  <span>
                    <h5>ตัวกรองสินค้า</h5>
                  </span>
                </v-col>
                <v-col cols="6" class="text-right">

                  <v-btn :elevation="1" outlined color="success" @click="btnExportExcelFilteOrder()">
                    <v-icon size="16" class="pr-1">fa-file-excel</v-icon>
                    <span class="fn-12"> Export Excel </span>
                  </v-btn>
                  <vue-excel-xlsx :data="filteOrderDataSet.dataset" :columns="filteOrderDataSet.headerExcel"
                    v-show="false" :file-name="GetDate() + '_รายงานกรองสินค้า'" :sheet-name="'filterOrderExcel'"
                    class="mr-2">
                  </vue-excel-xlsx>
                </v-col>

              </v-row>
              <div>
                <v-btn icon @click="filterOrderPopup.dialog = false"><v-icon color="danger">mdi-close</v-icon></v-btn>
              </div>
            </div>
          </v-card-title>

          <v-card class="overflow-x-auto hidden-xs-only">
            <v-card-text class="pa-0 ma-0">
              <v-tabs v-model="filteOrderDataSet.tab">
                <v-tabs-slider color="red"></v-tabs-slider>
                <v-tab v-for="i in filteOrderDataSet.tab_list" :key="i.id" @click="filteOrderDataSet.tab = i.id">
                  {{ i.name }}
                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="filteOrderDataSet.tab" touchless>
                <v-tab-item v-for="i in filteOrderDataSet.tab_list" :key="i.id">
                  <v-card flat>
                    <v-card-text>
                      <div v-if="i.id == 0">
                        <table>
                          <tr>
                            <td class="pl-1">จำนวน SKU ในออเดอร์ :</td>
                            <td class="pl-1">
                              <v-radio-group v-model="filteOrderDataSet.radio_sku" row hide-details>
                                <v-radio :label="items.name" v-for="items in filteOrderDataSet.radio" :key="items.id"
                                  :value="items.id"></v-radio>
                              </v-radio-group>
                            </td>
                            <td class="pl-1">
                              <v-text-field class="text-center" style="width: 100px; text-align: center" type="number"
                                v-model="filteOrderDataSet.sku_start" placeholder="จาก" outlined hide-details dense
                                :disabled="!filteOrderDataSet.radio_sku" />
                            </td>
                            <td class="pl-1">
                              <span>ถึง</span>
                            </td>
                            <td class="pl-1">
                              <v-text-field class="text-center" style="width: 100px; text-align: center" type="number"
                                v-model="filteOrderDataSet.sku_end" placeholder="ถึง" outlined hide-details dense
                                :disabled="!filteOrderDataSet.radio_sku" />
                            </td>
                            <td class="pl-1">
                              <span>SKU</span>
                            </td>
                          </tr>
                          <tr>
                            <td class="pl-1">จำนวน ชิ้น ในออเดอร์ :</td>
                            <td class="pl-1">
                              <v-radio-group v-model="filteOrderDataSet.radio_item" row hide-details>
                                <v-radio :label="items.name" v-for="items in filteOrderDataSet.radio" :key="items.id"
                                  :value="items.id"></v-radio>
                              </v-radio-group>
                            </td>
                            <td class="pl-1">
                              <v-text-field class="text-center" style="width: 100px; text-align: center" type="number"
                                v-model="filteOrderDataSet.item_start" placeholder="จาก" outlined hide-details dense
                                :disabled="!filteOrderDataSet.radio_item" />
                            </td>
                            <td class="pl-1">
                              <span>ถึง</span>
                            </td>
                            <td class="pl-1">
                              <v-text-field class="text-center" type="number" style="width: 100px; text-align: center"
                                v-model="filteOrderDataSet.item_end" placeholder="จาก" outlined hide-details dense
                                :disabled="!filteOrderDataSet.radio_item" />
                            </td>
                            <td class="pl-1">
                              <span>ชิ้น</span>
                            </td>
                          </tr>
                        </table>

                        <v-data-table :headers="filteOrderDataSet.header" :items="filteOrderDataSet.dataset"
                          :items-per-page="9999999999" item-key="no" hide-default-footer
                          class="elevation-0 packhai-checkbox-select box-shadow" height="50vh"
                          v-model="filteOrderDataSet.selected" show-select fixed-header>
                          <template v-slot:item.outStock="{ item }">
                            <span class="red--text" v-if="item.outStock < 0">
                              {{ formatMoney(item.outStock) }}</span>
                          </template>
                        </v-data-table>
                      </div>
                      <div v-if="i.id == 1">
                        <v-data-table :headers="filteOrderDataSet.headerMapProductList" :key="0"
                          :items="filteOrderDataSet.mapProductList" hide-default-footer fixed-header
                          item-key="packageID" class="elevation-0 packhai-border-table">
                        </v-data-table>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-card-text>
          </v-card>

          <v-divider />
          <v-card-actions>
            <v-row>
              <v-col cols="6">
                เลือกทั้งหมด {{ filteOrderDataSet.selected.length }} รายการ
              </v-col>
              <v-col cols="6" align="right">
                <v-btn color="primary" @click="btnSumitFilteOrder()"> ดำเนินการ </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- เปลี่ยนสถานะ -->
      <v-dialog v-model="changeStatusPopup.dialog" scrollable :max-width="changeStatusPopup.MaxWidth" persistent
        :fullscreen="$vuetify.breakpoint.xsOnly">
        <v-card elevation="1" class="ma-0">
          <v-card-title class="height-57 pa-0">
            <div class="v-list-item">

              <v-row class="v-list-item__content  ma-0">

                <v-col cols="6">
                  <v-row class="ma-0">
                    <v-col cols="3" class="px-0">
                      <h5>เปลี่ยนสถานะ</h5>
                    </v-col>
                    <v-col cols="9" class="pa-0">
                      <v-select v-model="changeStatusDataSet.changeStatus"
                        :items="changeStatusDataSet.showManageChangeStatusList" label="-- กรุณาเลือกสถานะ --"
                        persistent-hint return-object dense hide-details flat outlined item-text="Name"
                        item-value="ID" />

                    </v-col>

                  </v-row>

                </v-col>

                <v-col cols="6" class="text-right"> </v-col>
              </v-row>

              <div>
                <v-btn v-if="changeStatusDataSet.loading" icon @click="
                  (changeStatusPopup.dialog = false)
                  "><v-icon color="danger">mdi-close</v-icon></v-btn>
                <v-btn v-else icon @click="changeStatusPopup.dialog = false"><v-icon
                    color="danger">mdi-close</v-icon></v-btn>
              </div>
            </div>
          </v-card-title>

          <v-card class="overflow-x-auto hidden-xs-only">
            <v-card-text class="pa-0 ma-0">
              <v-card flat>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" class="py-0">
                      <v-tabs v-model="changeStatusPopup.tab">
                        <v-tabs-slider color="red"></v-tabs-slider>

                        <v-tab v-for="i in changeStatusPopup.tab_list" class="box-shadow" :key="i.id"
                          @click="changeStatusPopup.tab = i.id">
                          {{ i.name }}
                          <v-badge v-if="i.id == 1" color="error" inline>
                            <template v-slot:badge>
                              <span>{{ changeStatusDataSet.error }} </span>
                            </template>
                          </v-badge>
                        </v-tab>
                      </v-tabs>
                    </v-col>
                  </v-row>

                  <v-tabs-items v-model="changeStatusPopup.tab" touchless>
                    <v-tab-item v-for="i in changeStatusPopup.tab_list" :key="i.id">
                      <div v-if="i.id == 0">
                        <v-row class="ma-0 pa-0">
                          <v-col cols="12">
                            <div>
                              <v-data-table :headers="changeStatusDataSet.header" :items="changeStatusDataSet.dataset"
                                :items-per-page="9999999999" item-key="packageID" hide-default-footer
                                class="elevation-0 packhai-checkbox-select box-shadow" height="50vh" fixed-header>
                                <template v-slot:item.referenceSourceLogo="{ item }">
                                  <v-avatar size="25px" tile>
                                    <v-img v-if="
                                      item.referenceSourceLogo != null &&
                                      item.referenceSourceLogo != ''
                                    " style="border-radius: 10px" :src="item.referenceSourceLogo" />
                                  </v-avatar>
                                </template>
                                <template v-slot:item.expressCompanyLogo="{ item }">
                                  <img v-if="
                                    item.expressCompanyLogo != null &&
                                    item.expressCompanyLogo != ''
                                  " width="60px" :src="item.expressCompanyLogo" />
                                </template>
                                <template v-slot:item.processing="{ item }">
                                  <div v-if="item.processing == 'success'">
                                    <v-icon size="20" color="success">mdi-check-circle
                                    </v-icon>
                                    <span>เปลี่ยนสถาะสำเร็จ </span>
                                  </div>

                                  <div v-else-if="item.processing == 'error'">
                                    <v-icon size="25" color="danger">mdi-close</v-icon>
                                    <span>{{ item.error_description }} </span>
                                    <!-- <span 
                                  >ไม่สำเร็จ
                                </span> -->
                                  </div>
                                </template>
                              </v-data-table>
                            </div>
                          </v-col>
                        </v-row>
                      </div>
                      <div v-if="i.id == 1">
                        <v-row class="ma-0 pa-0">
                          <v-col cols="12">
                            <div>
                              <v-data-table :headers="changeStatusDataSet.header"
                                :items="changeStatusDataSet.datasetError" :items-per-page="9999999999"
                                item-key="packageID" hide-default-footer
                                class="elevation-0 packhai-checkbox-select box-shadow" height="50vh" fixed-header>
                                <template v-slot:item.referenceSourceLogo="{ item }">
                                  <v-avatar size="25px" tile>
                                    <v-img v-if="
                                      item.referenceSourceLogo != null &&
                                      item.referenceSourceLogo != ''
                                    " style="border-radius: 10px" :src="item.referenceSourceLogo" />
                                  </v-avatar>
                                </template>
                                <template v-slot:item.expressCompanyLogo="{ item }">
                                  <img v-if="
                                    item.expressCompanyLogo != null &&
                                    item.expressCompanyLogo != ''
                                  " width="60px" :src="item.expressCompanyLogo" />
                                </template>
                                <template v-slot:item.processing="{ item }">
                                  <div v-if="item.processing == 'success'">
                                    <v-icon size="20" color="success">mdi-check-circle
                                    </v-icon>
                                    <span>เปลี่ยนสถาะสำเร็จ </span>
                                  </div>

                                  <div v-else-if="item.processing == 'error'">
                                    <v-icon size="25" color="danger">mdi-close</v-icon>
                                    <span>ไม่สำเร็จ </span>
                                    <span>{{ item.error_description }} </span>
                                  </div>
                                </template>
                              </v-data-table>
                            </div>
                          </v-col>
                        </v-row>
                      </div>
                    </v-tab-item>
                  </v-tabs-items>
                </v-card-text>
              </v-card>
            </v-card-text>
          </v-card>

          <v-divider />

          <v-card-actions>
            <v-row>
              <v-col cols="6">
                <template v-if="changeStatusDataSet.loadingPercent == true">
                  <strong>ดำเนินการแล้ว{{ percentLoading }}% </strong>
                  <span> สำเร็จ {{ this.changeStatusDataSet.success }} รายการ , </span>
                  <span> ไม่สำเร็จ {{ this.changeStatusDataSet.error }} รายการ </span>
                </template>
              </v-col>
              <v-col cols="6" align="right">
                <v-btn color="primary" :disabled="changeStatusDataSet.loading || changeStatusDataSet.changeStatus == null
                  " @click="btnSubmitChamgeStatus()">
                  ดำเนินการ
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- ย้อนสถานะ -->
      <v-dialog v-model="backStatusPopup.dialog" scrollable :max-width="backStatusPopup.MaxWidth" persistent
        :fullscreen="$vuetify.breakpoint.xsOnly">
        <v-card elevation="1" class="ma-0">
          <v-card-title class="height-57 pa-0">
            <div class="v-list-item">

              <v-row class="v-list-item__content  ma-0">

                <v-col cols="6">
                  <v-row class="ma-0">
                    <v-col cols="3" class="px-0">
                      <h5>ย้อนสถานะ</h5>
                    </v-col>
                    <v-col cols="9" class="pa-0">
                      <v-select v-model="backStatusDataSet.changeStatus"
                        :items="backStatusDataSet.showManageBackStatusList" label="-- กรุณาเลือกสถานะ --"
                        persistent-hint return-object dense hide-details flat outlined item-text="Name"
                        item-value="ID" />

                    </v-col>

                  </v-row>

                </v-col>

                <v-col cols="6" class="text-right"> </v-col>
              </v-row>

              <div>
                <v-btn v-if="backStatusDataSet.loading" icon @click="
                  (backStatusPopup.dialog = false)
                  "><v-icon color="danger">mdi-close</v-icon></v-btn>
                <v-btn v-else icon @click="backStatusPopup.dialog = false"><v-icon
                    color="danger">mdi-close</v-icon></v-btn>
              </div>
            </div>
          </v-card-title>

          <v-card class="overflow-x-auto hidden-xs-only">
            <v-card-text class="pa-0 ma-0">
              <v-card flat>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" class="py-0">
                      <v-tabs v-model="backStatusPopup.tab">
                        <v-tabs-slider color="red"></v-tabs-slider>

                        <v-tab v-for="i in backStatusPopup.tab_list" class="box-shadow" :key="i.id"
                          @click="backStatusPopup.tab = i.id">
                          {{ i.name }}
                          <v-badge v-if="i.id == 1" color="error" inline>
                            <template v-slot:badge>
                              <span>{{ backStatusDataSet.error }} </span>
                            </template>
                          </v-badge>
                        </v-tab>
                      </v-tabs>
                    </v-col>
                  </v-row>
                  <v-tabs-items v-model="backStatusPopup.tab" touchless>
                    <v-tab-item v-for="i in backStatusPopup.tab_list" :key="i.id">
                      <div v-if="i.id == 0">
                        <v-row class="ma-0 pa-0">
                          <v-col cols="12">
                            <div>
                              <v-data-table :headers="backStatusDataSet.header" :items="backStatusDataSet.dataset"
                                :items-per-page="9999999999" item-key="packageID" hide-default-footer
                                class="elevation-0 packhai-checkbox-select box-shadow" height="50vh" fixed-header>
                                <template v-slot:item.referenceSourceLogo="{ item }">
                                  <v-avatar size="25px" tile>
                                    <v-img v-if="
                                      item.referenceSourceLogo != null &&
                                      item.referenceSourceLogo != ''
                                    " style="border-radius: 10px" :src="item.referenceSourceLogo" />
                                  </v-avatar>
                                </template>
                                <template v-slot:item.expressCompanyLogo="{ item }">
                                  <img v-if="
                                    item.expressCompanyLogo != null &&
                                    item.expressCompanyLogo != ''
                                  " width="60px" :src="item.expressCompanyLogo" />
                                </template>
                                <template v-slot:item.processing="{ item }">
                                  <div v-if="item.processing == 'success'">
                                    <v-icon size="20" color="success">mdi-check-circle
                                    </v-icon>
                                    <span>เปลี่ยนสถาะสำเร็จ </span>
                                  </div>

                                  <div v-else-if="item.processing == 'error'">
                                    <v-icon size="25" color="danger">mdi-close</v-icon>
                                    <!-- <span>{{ item.error_description }} </span> -->
                                    <span>ไม่สำเร็จ </span>
                                  </div>
                                </template>
                              </v-data-table>
                            </div>
                          </v-col>
                        </v-row>
                      </div>
                      <div v-if="i.id == 1">
                        <v-row class="ma-0 pa-0">
                          <v-col cols="12">
                            <div>
                              <v-data-table :headers="backStatusDataSet.header" :items="backStatusDataSet.datasetError"
                                :items-per-page="9999999999" item-key="packageID" hide-default-footer
                                class="elevation-0 packhai-checkbox-select box-shadow" height="50vh" fixed-header>
                                <template v-slot:item.referenceSourceLogo="{ item }">
                                  <v-avatar size="25px" tile>
                                    <v-img v-if="
                                      item.referenceSourceLogo != null &&
                                      item.referenceSourceLogo != ''
                                    " style="border-radius: 10px" :src="item.referenceSourceLogo" />
                                  </v-avatar>
                                </template>
                                <template v-slot:item.expressCompanyLogo="{ item }">
                                  <img v-if="
                                    item.expressCompanyLogo != null &&
                                    item.expressCompanyLogo != ''
                                  " width="60px" :src="item.expressCompanyLogo" />
                                </template>
                                <template v-slot:item.processing="{ item }">
                                  <div v-if="item.processing == 'success'">
                                    <v-icon size="20" color="success">mdi-check-circle
                                    </v-icon>
                                    <span>เปลี่ยนสถาะสำเร็จ </span>
                                  </div>

                                  <div v-else-if="item.processing == 'error'">
                                    <v-icon size="25" color="danger">mdi-close</v-icon>
                                    <!-- <span>{{ item.error_description }} </span> -->
                                    <span>ไม่สำเร็จ </span>
                                  </div>
                                </template>
                              </v-data-table>
                            </div>
                          </v-col>
                        </v-row>
                      </div>
                    </v-tab-item>
                  </v-tabs-items>
                </v-card-text>
              </v-card>
            </v-card-text>
          </v-card>

          <v-divider />

          <v-card-actions>
            <v-row>
              <v-col cols="6">
                <template v-if="backStatusDataSet.loadingPercent == true">
                  <strong>กำลังโหลด{{ percentLoading }}%</strong>
                  <span> สำเร็จ {{ this.backStatusDataSet.success }} รายการ , </span>
                  <span> ไม่สำเร็จ {{ this.backStatusDataSet.error }} รายการ </span>
                </template>
              </v-col>
              <v-col cols="6" align="right">
                <v-btn color="primary" :disabled="backStatusDataSet.loading || backStatusDataSet.changeStatus == null
                  " @click="btnSubmitBackStatus()">
                  ดำเนินการ
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- updatatoMarketplace-->
      <v-dialog v-model="UpdateToMarketplacePopup.dialog" scrollable :max-width="UpdateToMarketplacePopup.MaxWidth"
        persistent :fullscreen="$vuetify.breakpoint.xsOnly">
        <v-card elevation="1" class="ma-0">
          <v-card-title class="height-57 pa-0">
            <div class="v-list-item">
              <div class="pa-2">
                <span>
                  <h5 v-if="
                    UpdateToMarketplacePopup.marketplaceName == 'lazada' &&
                    UpdateToMarketplacePopup.marketplaceName != null
                  ">
                    ดำเนินการ
                  </h5>
                  <h5 v-if="
                    UpdateToMarketplaceDataSet.tiktokType == 0 &&
                    UpdateToMarketplacePopup.marketplaceName == 'tiktok'
                  ">
                    เลือกประเภท
                  </h5>
                  <h5 v-if="
                    UpdateToMarketplaceDataSet.tiktokType == 1 &&
                    UpdateToMarketplacePopup.marketplaceName == 'tiktok' &&
                    UpdateToMarketplacePopup.marketplaceName != null
                  ">
                    ดำเนินการ
                  </h5>
                  <h5 v-if="
                    UpdateToMarketplaceDataSet.shopeeType == 0 &&
                    UpdateToMarketplacePopup.marketplaceName == 'shopee'
                  ">
                    เลือกประเภท
                  </h5>
                  <h5 v-if="
                    UpdateToMarketplaceDataSet.shopeeType == 1 &&
                    UpdateToMarketplacePopup.marketplaceName == 'shopee'
                  ">
                    เลือกที่อยู่
                  </h5>
                  <h5 v-if="
                    UpdateToMarketplaceDataSet.shopeeType == 2 &&
                    UpdateToMarketplacePopup.marketplaceName == 'shopee' &&
                    UpdateToMarketplacePopup.marketplaceName != null
                  ">
                    ดำเนินการ
                  </h5>
                </span>
              </div>
              <div class="v-list-item__content pa-2">
                <v-row>
                  <v-col cols="3"> </v-col>
                  <v-col cols="9" class="text-right"> </v-col>
                </v-row>
              </div>
              <div>
                <v-btn v-if="UpdateToMarketplaceDataSet.loading" icon @click="
                  refreshWindow('UpdateToMarketplacePopup'),
                  (UpdateToMarketplacePopup.dialog = false)
                  "><v-icon color="danger">mdi-close</v-icon></v-btn>
                <v-btn v-else icon @click="UpdateToMarketplacePopup.dialog = false"><v-icon
                    color="danger">mdi-close</v-icon></v-btn>
              </div>
            </div>
          </v-card-title>

          <v-card class="overflow-x-auto hidden-xs-only">
            <v-card-text class="pa-0 ma-0">
              <v-card flat>
                <v-card-text>
                  <v-row class="pl-4" v-if="
                    UpdateToMarketplacePopup.marketplaceName == 'lazada' ||
                    (UpdateToMarketplacePopup.marketplaceName == 'tiktok' &&
                      UpdateToMarketplaceDataSet.tiktokType == 1) ||
                    (UpdateToMarketplacePopup.marketplaceName == 'shopee' &&
                      UpdateToMarketplaceDataSet.shopeeType == 2)
                  ">
                    <v-col cols="12">
                      <v-tabs v-model="UpdateToMarketplacePopup.tab" hide-slider>
                        <v-tab v-for="i in UpdateToMarketplacePopup.tab_list" class="box-shadow" :key="i.id"
                          @click="UpdateToMarketplacePopup.tab = i.id">
                          {{ i.name }}
                          <v-badge v-if="i.id == 1" color="error" inline>
                            <template v-slot:badge>
                              <span>{{ UpdateToMarketplaceDataSet.error }} </span>
                            </template>
                          </v-badge>
                        </v-tab>
                      </v-tabs>
                    </v-col>
                  </v-row>
                  <v-tabs-items v-model="UpdateToMarketplacePopup.tab" touchless>
                    <v-tab-item v-for="i in UpdateToMarketplacePopup.tab_list" :key="i.id">
                      <div v-if="i.id == 0">
                        <template v-if="
                          UpdateToMarketplacePopup.marketplaceName == 'lazada' &&
                          UpdateToMarketplacePopup.marketplaceName != null
                        ">
                          <v-row class="ma-0 pa-0">
                            <v-col cols="12">
                              <div>
                                <v-data-table :headers="UpdateToMarketplaceDataSet.header"
                                  :items="UpdateToMarketplaceDataSet.dataset" :items-per-page="9999999999"
                                  item-key="packageID" hide-default-footer
                                  class="elevation-0 packhai-checkbox-select box-shadow" height="50vh" fixed-header>
                                  <template v-slot:item.orderMasterID="{ item }">
                                    <a>{{ FormatOrder(item.orderMasterID) }} </a>
                                  </template>
                                  <template v-slot:item.expressCompanyLogo="{ item }">
                                    <img v-if="
                                      item.expressCompanyLogo != null &&
                                      item.expressCompanyLogo != ''
                                    " width="60px" :src="item.expressCompanyLogo" />
                                  </template>
                                  <template v-slot:item.referenceSourceLogo="{ item }">
                                    <v-avatar size="25px" tile>
                                      <v-img v-if="
                                        item.referenceSourceLogo != null &&
                                        item.referenceSourceLogo != ''
                                      " style="border-radius: 10px" :src="item.referenceSourceLogo" />
                                    </v-avatar>
                                  </template>
                                  <template v-slot:item.processing="{ item }">
                                    <div v-if="item.processing == 'success'">
                                      <v-icon size="20" color="success">mdi-check-circle
                                      </v-icon>
                                      <span>เปลี่ยนสถาะสำเร็จ {{ item.message }} </span>
                                    </div>

                                    <div v-else-if="item.processing == 'error'">
                                      <v-icon size="25" color="danger">mdi-close</v-icon>
                                      <span>{{ item.error_description }} </span>
                                      <span>ไม่สำเร็จ </span>
                                    </div>
                                  </template>
                                </v-data-table>
                              </div>
                            </v-col>
                          </v-row>
                        </template>
                        <template v-if="
                          UpdateToMarketplacePopup.marketplaceName == 'tiktok' &&
                          UpdateToMarketplacePopup.marketplaceName != null
                        ">
                          <v-row class="ma-0 pa-0">
                            <v-col cols="12">
                              <div v-if="UpdateToMarketplaceDataSet.tiktokType == 0">
                                <v-row class="text-center" justify="center">
                                  <!-- PICK_UP  -->
                                  <v-col cols="4" @click="
                                    {
                                    {
                                      (UpdateToMarketplaceDataSet.tiktokType = 1),
                                        (UpdateToMarketplaceDataSet.handover_method = `PICK_UP`);
                                    }
                                  }
                                    ">
                                    <v-card color="warning">
                                      <v-card-title class="textChip-center">
                                        <v-icon size="100" color="white">mdi-map-marker
                                        </v-icon>
                                      </v-card-title>
                                      <v-card-text class="color-white">
                                        <h3>นัดรับสินค้า</h3>
                                        <h3>เรียกให้ขนส่งมารับสินค้า</h3>
                                      </v-card-text>
                                    </v-card>
                                  </v-col>
                                  <!-- DROP_OFF  -->
                                  <v-col cols="4" @click="
                                    {
                                    {
                                      (UpdateToMarketplaceDataSet.tiktokType = 1),
                                        (UpdateToMarketplaceDataSet.handover_method = `DROP_OFF`);
                                    }
                                  }
                                    ">
                                    <v-card color="success">
                                      <v-card-title class="textChip-center">
                                        <v-icon size="100" color="white"> mdi-home </v-icon>
                                      </v-card-title>
                                      <v-card-text class="color-white">
                                        <h3>ส่งที่สาขา</h3>
                                        <h3>ไปส่งที่สาขาด้วยตนเอง</h3>
                                      </v-card-text>
                                    </v-card>
                                  </v-col>
                                </v-row>
                              </div>

                              <div v-if="UpdateToMarketplaceDataSet.tiktokType == 1">
                                <v-data-table :headers="UpdateToMarketplaceDataSet.header"
                                  :items="UpdateToMarketplaceDataSet.dataset" :items-per-page="9999999999"
                                  item-key="packageID" hide-default-footer
                                  class="elevation-0 packhai-checkbox-select box-shadow" height="50vh" fixed-header>
                                  <template v-slot:item.orderMasterID="{ item }">
                                    <a>{{ FormatOrder(item.orderMasterID) }} </a>
                                  </template>
                                  <template v-slot:item.expressCompanyLogo="{ item }">
                                    <img v-if="
                                      item.expressCompanyLogo != null &&
                                      item.expressCompanyLogo != ''
                                    " width="60px" :src="item.expressCompanyLogo" />
                                  </template>
                                  <template v-slot:item.referenceSourceLogo="{ item }">
                                    <v-avatar size="25px" tile>
                                      <v-img v-if="
                                        item.referenceSourceLogo != null &&
                                        item.referenceSourceLogo != ''
                                      " style="border-radius: 10px" :src="item.referenceSourceLogo" />
                                    </v-avatar>
                                  </template>
                                  <template v-slot:item.processing="{ item }">
                                    <div v-if="item.processing == 'success'">
                                      <v-icon size="20" color="success">mdi-check-circle
                                      </v-icon>
                                      <span>เปลี่ยนสถาะสำเร็จ {{ item.message }} </span>
                                    </div>

                                    <div v-else-if="item.processing == 'error'">
                                      <v-icon size="25" color="danger">mdi-close</v-icon>
                                      <span>{{ item.error_description }} </span>
                                      <span>ไม่สำเร็จ </span>
                                    </div>
                                  </template>
                                </v-data-table>
                              </div>
                            </v-col>
                          </v-row>
                        </template>
                        <template v-if="
                          UpdateToMarketplacePopup.marketplaceName == 'shopee' &&
                          UpdateToMarketplacePopup.marketplaceName != null
                        ">
                          <v-row class="ma-0 pa-0">
                            <v-col cols="12">
                              <div v-if="UpdateToMarketplaceDataSet.shopeeType == 0">
                                <v-row class="text-center" justify="center">
                                  <!-- PICK_UP  -->
                                  <v-col cols="4" @click="UpdateToMarketplaceDataSet.shopeeType = 1">
                                    <v-card color="warning">
                                      <v-card-title class="textChip-center">
                                        <v-icon size="100" color="white">mdi-map-marker
                                        </v-icon>
                                      </v-card-title>
                                      <v-card-text class="color-white">
                                        <h3>นัดรับสินค้า</h3>
                                        <h3>เรียกให้ขนส่งมารับสินค้า</h3>
                                      </v-card-text>
                                    </v-card>
                                  </v-col>
                                  <!-- DROP_OFF  -->
                                  <v-col cols="4" @click="UpdateToMarketplaceDataSet.shopeeType = 2">
                                    <v-card color="success">
                                      <v-card-title class="textChip-center">
                                        <v-icon size="100" color="white"> mdi-home </v-icon>
                                      </v-card-title>
                                      <v-card-text class="color-white">
                                        <h3>ส่งที่สาขา</h3>
                                        <h3>ไปส่งที่สาขาด้วยตนเอง</h3>
                                      </v-card-text>
                                    </v-card>
                                  </v-col>
                                </v-row>
                              </div>
                              <div v-if="UpdateToMarketplaceDataSet.shopeeType == 1">
                                <v-card class="mb-3">
                                  <v-card-text>
                                    <v-row class="text-center">
                                      <v-col cols="12">
                                        <v-radio-group label="เลือกสถานที่ขนส่ง" v-model="UpdateToMarketplaceDataSet.selectedAddress
                                          " @change="handleAddressChange()" style="max-height: 35vh; overflow-y: auto">
                                          <v-radio v-for="(
                                            items, index
                                          ) of UpdateToMarketplaceDataSet.pickup" :key="items.id" :value="index">
                                            <template v-slot:label>
                                              <v-col cols="12">
                                                <strong>{{
                                                  items.address +
                                                  " " +
                                                  items.city +
                                                  " " +
                                                  items.district +
                                                  " " +
                                                  items.state +
                                                  " " +
                                                  items.zipcode
                                                  }}</strong>
                                                <div v-if="items.address_flag.length > 0">
                                                  <h4>
                                                    <v-chip color="success">
                                                      ค่าเริ่มต้น</v-chip>
                                                    <v-chip v-if="
                                                      items.address_flag ==
                                                      'default_address'
                                                    " color="success">
                                                      ค่าเริ่มต้น</v-chip>
                                                    <v-chip v-if="
                                                      items.address_flag ==
                                                      'pickup_address'
                                                    " color="warning">ที่อยู่รับสินค้า</v-chip>
                                                    <v-chip v-if="
                                                      items.address_flag ==
                                                      'return_address'
                                                    " color="question">ที่อยู่สำหรับคืนสินค้า</v-chip>
                                                  </h4>
                                                </div>
                                              </v-col>
                                            </template>
                                          </v-radio>
                                        </v-radio-group>
                                      </v-col>
                                    </v-row>
                                  </v-card-text>
                                </v-card>

                                <v-card>
                                  <v-card-text>
                                    <v-row>
                                      <v-col cols="12">
                                        <v-icon size="25" class="pr-1">mdi-update</v-icon>
                                        <span>เวลานัดรับ</span>
                                      </v-col>
                                    </v-row>
                                    <v-row class="text-center mt-2">
                                      <v-col cols="12">
                                        <v-select v-model="UpdateToMarketplaceDataSet.pickupTimeID" :items="UpdateToMarketplaceDataSet.selectedpickupTime
                                            .time_slot_list
                                          " persistent-hint label="-- กรุณาเลือกเวลาขนส่ง --" dense hide-details flat
                                          outlined :item-text="(item) =>
                                              formatDatetime(
                                                new Date(item.date * 1000).toISOString()
                                              )
                                            " item-value="pickup_time_id">
                                        </v-select>
                                      </v-col>
                                    </v-row>
                                  </v-card-text>
                                </v-card>
                              </div>
                              <div v-if="UpdateToMarketplaceDataSet.shopeeType == 2">
                                <v-data-table :headers="UpdateToMarketplaceDataSet.header"
                                  :items="UpdateToMarketplaceDataSet.dataset" :items-per-page="9999999999"
                                  item-key="packageID" hide-default-footer
                                  class="elevation-0 packhai-checkbox-select box-shadow" height="50vh" fixed-header>
                                  <template v-slot:item.orderMasterID="{ item }">
                                    <a>{{ FormatOrder(item.orderMasterID) }} </a>
                                  </template>
                                  <template v-slot:item.expressCompanyLogo="{ item }">
                                    <img v-if="
                                      item.expressCompanyLogo != null &&
                                      item.expressCompanyLogo != ''
                                    " width="60px" :src="item.expressCompanyLogo" />
                                  </template>
                                  <template v-slot:item.referenceSourceLogo="{ item }">
                                    <v-avatar size="25px" tile>
                                      <v-img v-if="
                                        item.referenceSourceLogo != null &&
                                        item.referenceSourceLogo != ''
                                      " style="border-radius: 10px" :src="item.referenceSourceLogo" />
                                    </v-avatar>
                                  </template>
                                  <template v-slot:item.processing="{ item }">
                                    <div v-if="item.processing == 'success'">
                                      <v-icon size="20" color="success">mdi-check-circle
                                      </v-icon>
                                      <span>เปลี่ยนสถาะสำเร็จ {{ item.message }} </span>
                                    </div>

                                    <div v-else-if="item.processing == 'error'">
                                      <v-icon size="25" color="danger">mdi-close</v-icon>
                                      <span>{{ item.error_description }} </span>
                                      <span>ไม่สำเร็จ </span>
                                    </div>
                                  </template>
                                </v-data-table>
                              </div>
                            </v-col>
                          </v-row>
                        </template>
                      </div>
                      <div v-if="i.id == 1">
                        <v-row class="ma-0 pa-0">
                          <v-col cols="12">
                            <div>
                              <v-data-table :headers="UpdateToMarketplaceDataSet.header"
                                :items="UpdateToMarketplaceDataSet.datasetError" :items-per-page="9999999999"
                                item-key="packageID" hide-default-footer
                                class="elevation-0 packhai-checkbox-select box-shadow" height="50vh" fixed-header>
                                <template v-slot:item.orderMasterID="{ item }">
                                  <a>{{ FormatOrder(item.orderMasterID) }} </a>
                                </template>
                                <template v-slot:item.expressCompanyLogo="{ item }">
                                  <img v-if="
                                    item.expressCompanyLogo != null &&
                                    item.expressCompanyLogo != ''
                                  " width="60px" :src="item.expressCompanyLogo" />
                                </template>
                                <template v-slot:item.referenceSourceLogo="{ item }">
                                  <v-avatar size="25px" tile>
                                    <v-img v-if="
                                      item.referenceSourceLogo != null &&
                                      item.referenceSourceLogo != ''
                                    " style="border-radius: 10px" :src="item.referenceSourceLogo" />
                                  </v-avatar>
                                </template>
                                <template v-slot:item.processing="{ item }">
                                  <div v-if="item.processing == 'success'">
                                    <v-icon size="20" color="success">mdi-check-circle
                                    </v-icon>
                                    <span>เปลี่ยนสถาะสำเร็จ </span>
                                  </div>

                                  <div v-else-if="item.processing == 'error'">
                                    <v-icon size="25" color="danger">mdi-close</v-icon>
                                    <span>{{ item.error_description }} </span>
                                    <span>ไม่สำเร็จ </span>
                                  </div>
                                </template>
                              </v-data-table>
                            </div>
                          </v-col>
                        </v-row>
                      </div>
                    </v-tab-item>
                  </v-tabs-items>
                </v-card-text>
                <v-card-actions>
                  <v-row>
                    <v-col cols="6">
                      <template v-if="UpdateToMarketplaceDataSet.loadingPercent == true">
                        <strong>ดำเนินการแล้ว{{ percentLoading }}%</strong>
                        <span>
                          สำเร็จ {{ this.UpdateToMarketplaceDataSet.success }} รายการ ,
                        </span>
                        <span>
                          ไม่สำเร็จ {{ this.UpdateToMarketplaceDataSet.error }} รายการ
                        </span>
                      </template>
                    </v-col>
                    <v-col cols="6" align="right">
                      <div v-if="
                        UpdateToMarketplacePopup.marketplaceName == 'lazada' &&
                        UpdateToMarketplacePopup.marketplaceName != null
                      ">
                        <v-btn :disabled="UpdateToMarketplaceDataSet.loading" color="primary"
                          @click="btnSubmitToMarketPlace('lazada')">
                          ดำเนินการ
                        </v-btn>
                      </div>
                      <div v-if="
                        UpdateToMarketplacePopup.marketplaceName == 'tiktok' &&
                        UpdateToMarketplacePopup.marketplaceName != null
                      ">
                        <v-btn v-if="UpdateToMarketplaceDataSet.tiktokType == 1" color="success" class="mr-3" outlined
                          :disabled="UpdateToMarketplaceDataSet.loading" @click="
                          {
                            {
                              (UpdateToMarketplaceDataSet.tiktokType = null),
                                (UpdateToMarketplaceDataSet.handover_method = null),
                                (UpdateToMarketplaceDataSet.tiktokType = 0);
                            }
                          }
                            ">
                          ย้อนกลับ
                        </v-btn>
                        <v-btn v-if="UpdateToMarketplaceDataSet.tiktokType == 1" :disabled="UpdateToMarketplaceDataSet.loading ||
                          UpdateToMarketplaceDataSet.handover_method == null
                          " color="primary" @click="btnSubmitToMarketPlace('tiktok')">
                          ดำเนินการ
                        </v-btn>
                      </div>
                      <div v-if="
                        UpdateToMarketplacePopup.marketplaceName == 'shopee' &&
                        UpdateToMarketplacePopup.marketplaceName != null
                      ">
                        <v-btn v-if="
                          UpdateToMarketplaceDataSet.shopeeType == 2 ||
                          UpdateToMarketplaceDataSet.shopeeType == 1
                        " color="success" class="mr-3" outlined :disabled="UpdateToMarketplaceDataSet.loading" @click="
                          {
                          {
                            (UpdateToMarketplaceDataSet.pickupTimeID = null),
                              (UpdateToMarketplaceDataSet.selectedAddress = []),
                              (UpdateToMarketplaceDataSet.shopeeType = 0);
                          }
                        }
                          ">
                          ย้อนกลับ
                        </v-btn>
                        <v-btn v-if="UpdateToMarketplaceDataSet.shopeeType == 1" color="primary"
                          :disabled="UpdateToMarketplaceDataSet.pickupTimeID == null"
                          @click="UpdateToMarketplaceDataSet.shopeeType = 2">
                          ถัดไป
                        </v-btn>
                        <v-btn v-if="UpdateToMarketplaceDataSet.shopeeType == 2" color="primary"
                          :disabled="UpdateToMarketplaceDataSet.loading" @click="btnSubmitToMarketPlace('shopee')">
                          ดำเนินการ
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-card-text>
          </v-card>
        </v-card>
      </v-dialog>
      <!-- รายงาน -->
      <v-dialog v-model="reportOrderPopup.dialog" scrollable :max-width="reportOrderPopup.MaxWidth" persistent
        :fullscreen="$vuetify.breakpoint.xsOnly">
        <v-card elevation="1" class="ma-0">
          <v-card-title class="height-57 pa-0">
            <div class="v-list-item">
              <div class="pa-2">
                <span v-if="set_TabListSubHeader_Process == '10' && set_TabListHeader == '10-20-30-40-60'">
                  <h5>รายการที่ต้องผูกสินค้า</h5>
                </span>
                <span v-else>
                  <h5>รายการสินค้า</h5>
                </span>
              </div>
              <div class="v-list-item__content pa-2">
                <v-row>
                  <v-col cols="6" />
                  <v-col cols="6" class="text-right">
                    <v-btn :elevation="1" outlined color="success" class="pa-2">
                      <v-icon size="16" class="pr-1">fa-file-excel</v-icon>
                      <vue-excel-xlsx :data="reportOrderDataSet.dataset" :columns="reportOrderDataSet.header"
                        :file-name="GetDate() + '_รายงานสินค้า'" :sheet-name="'reportOrderItemExcel'" class="mr-2">
                        Export to Excel
                      </vue-excel-xlsx>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
              <div>
                <v-btn icon @click="reportOrderPopup.dialog = false"><v-icon color="danger">mdi-close</v-icon></v-btn>
              </div>
            </div>
          </v-card-title>

          <v-card class="overflow-x-auto hidden-xs-only">
            <v-card-text class="pa-0 ma-0">
              <v-card flat>
                <v-card-text>
                  <div>
                    <v-data-table :headers="reportOrderDataSet.header" :items="reportOrderDataSet.dataset"
                      :items-per-page="9999999999" item-key="no" hide-default-footer
                      class="elevation-0 packhai-checkbox-select box-shadow" height="50vh" fixed-header>
                      <template v-slot:item.photoLink="{ item }">
                        <a v-if="item.photoLink != null && item.photoLink != ''" icon
                          @click="OpenPhoto(item.photoLink)">
                          <img :src="item.photoLink" class="picsize-50 mt-1" />
                        </a>
                      </template>
                    </v-data-table>
                  </div>
                </v-card-text>
              </v-card>
            </v-card-text>
          </v-card>

          <v-divider />
          <v-card-actions>
            <v-row>
              <v-col cols="6">
                ทั้งหมด {{ reportOrderDataSet.dataset.length }} รายการ
                <span>, จำนวน {{ countreportOrderDataSet }} ชิ้น</span>
              </v-col>
              <v-col cols="6" align="right"> </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- ค้นหาขั้นสูง -->
      <v-dialog v-model="advanceSearchDialog" scrollable :max-width="advanceDialogMaxWidth" persistent
        :fullscreen="$vuetify.breakpoint.xsOnly">
        <v-card elevation="1">
          <v-card-title>
            <v-row>
              <v-col cols="6">
                ค้นหาขั้นสูง
              </v-col>
              <v-col cols="6" class="text-right">
                <v-btn icon @click="advanceSearchDialog = false"><v-icon color="danger">mdi-close</v-icon></v-btn>
              </v-col>
            </v-row>

          </v-card-title>
          <v-divider />

          <v-card-text>
            <v-row>
              <v-col cols="12" md="6" lg="4" class="pb-0">
                <v-menu
                  ref="search_menu_date_range"
                  v-model="search_menu_date_range"
                  :close-on-content-click="false"
                  :return-value.sync="search_date_range"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :value="computed_search_date"
                      @change="value => computed_search_date = value"
                      autocomplete="off"
                      label="ช่วงวันที่เปิดออเดอร์"
                      prepend-icon=""
                      prepend-inner-icon="mdi-calendar"
                      ref="dateRangeText"
                      outlined readonly
                      hide-details
                      dense
                      v-on="on"
                    >
                      <template v-slot:label> 
                        <span>{{use_language.open_order_date}}</span> 
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker 
                    v-model="search_date_range" 
                    :allowed-dates="allowedDates"
                    range no-title scrollable class="my-0"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="search_date_range = []">{{use_language.reset}}</v-btn>
                    <v-btn text color="primary" @click="search_menu_date_range = false">{{use_language.cancel_1}}</v-btn>
                    <v-btn text color="primary" @click="$refs.search_menu_date_range.save(search_date_range)">{{use_language.ok}}</v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="pb-0">
                <v-text-field 
                  :value="dataset_all.search.SHshipName"
                  @change="value => dataset_all.search.SHshipName = value"
                  autocomplete="off" label="ชื่อผู้รับ" hide-details dense outlined
                >
                  <template v-slot:label> <span>{{ use_language.recipient_name }}</span> </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="pb-0">
                <v-combobox 
                  v-model="dataset_all.search.SH_shop_staff"
                  :items="dataset_all.search.SH_shop_staff_items"
                  label="สร้างโดย" item-key="text" item-value="value" outlined hide-details dense
                >
                  <template v-slot:label> <span>{{ use_language.create_by }}</span> </template>
                </v-combobox>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="pb-0">
                <v-menu
                  ref="menu_search_tranferred_date"
                  v-model="menu_search_tranferred_date"
                  :close-on-content-click="false"
                  :return-value.sync="transferred_date_range"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :value="computed_search_tranferred_date"
                      @change="value => computed_search_tranferred_date = value"
                      autocomplete="off"
                      label="วันที่แพ็คสินค้า"
                      outlined
                      prepend-icon=""
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      hide-details
                      dense
                      v-on="on"
                    >
                <template v-slot:label> <span>{{use_language.pack_date}}</span> </template>
                    </v-text-field>
                  </template>
                  <v-date-picker v-model="transferred_date_range" range no-title :allowed-dates="allowedDates" class="my-0">
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="transferred_date_range = []">{{use_language.reset}}</v-btn>
                    <v-btn text color="primary" @click="menu_search_tranferred_date = false">{{use_language.cancel_1}}</v-btn>
                    <v-btn text color="primary" @click="$refs.menu_search_tranferred_date.save(transferred_date_range)">{{use_language.ok}}</v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="pb-0">
                <v-text-field 
                  :value="dataset_all.search.SH_phone"
                  @change="value => dataset_all.search.SH_phone = value"
                  autocomplete="off" label="เบอร์โทรศัพท์" outlined hide-details dense
                >
                  <template v-slot:label> <span>{{ use_language.tel }}</span> </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="pb-0">
                <v-select
                  v-model="channelListSeleced"
                  :items="channelList" 
                  label="ช่องทาง"
                  multiple 
                  item-value="uniqueID"
                  item-text="name"
                  return-object
                  hide-details
                  dense
                  clearable
                  outlined
                >
                  <template v-slot:item="{item}">
                    <v-icon 
                      v-if="channelListSeleced !== null && channelListSeleced.includes(item)" 
                      color="primary" 
                      class="mr-3"
                    >
                      mdi-checkbox-marked
                    </v-icon>
                    <v-icon v-else class="mr-3">
                      mdi-checkbox-blank-outline
                    </v-icon>
                    <img :src="item.logo" width="20px;"/> 
                    <div class="ml-2">{{item.name}} </div> 
                  </template>
                  <template v-slot:selection="{ item }">
                    {{item.name}}
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="pb-0">
                <v-menu
                  ref="menu_search_sent_date"
                  v-model="menu_search_sent_date"
                  :close-on-content-click="false"
                  :return-value.sync="sent_date_range"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :value="computed_search_sent_date"
                      @change="value => computed_search_sent_date = value"
                      autocomplete="off"
                      label="วันที่ส่งของ"
                      outlined
                      prepend-icon=""
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      hide-details
                      dense
                      v-on="on"
                    >
                      <template v-slot:label> <span>{{use_language.delivery_date}}</span> </template>
                    </v-text-field>
                  </template>
                  <v-date-picker v-model="sent_date_range" range no-title :allowed-dates="allowedDates" class="my-0">
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="sent_date_range = []">{{use_language.reset}}</v-btn>
                    <v-btn text color="primary" @click="menu_search_sent_date = false">{{use_language.cancel_1}}</v-btn>
                    <v-btn text color="primary" @click="$refs.menu_search_sent_date.save(sent_date_range)">{{use_language.ok}}</v-btn>
                  </v-date-picker>
                </v-menu>

              </v-col>
              <v-col cols="12" md="6" lg="4" class="pb-0">
                <v-select 
                  :value="dataset_all.search.select_search_parcel_status_id" 
                  @change="value => dataset_all.search.select_search_parcel_status_id = value" 
                  :items="dataset_all.search.search_parcel_status_list"
                  autocomplete="off" item-text="name" item-value="id" outlined hide-details dense
                >
                  <template v-slot:label> 
                    <span>{{ use_language.parcel_delivery_status }}</span> 
                  </template>
                </v-select>
              </v-col>

            </v-row>

          </v-card-text>


          <v-divider />
          <v-card-actions>
            <v-row>
              <v-col cols="12" align="right">
                <v-btn color="primary" @click="btnSearchOrder('0')"> ค้นหา </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- export excel -->
      <v-dialog v-model="dialog_excel" scrollable max-width="600px" persistent >
        <v-card elevation="1" class="ma-0">
          <v-card-title class="primary">
            <span style="color:white;">{{use_language.export_excel}}</span>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="dialog_excel = false"><v-icon>mdi-close</v-icon></v-btn>
          </v-card-title>
          <v-divider/>
          <v-card-text style="height: 325px;">
            <v-row>
              <v-col cols="12" md="3" xl="3" lg="3"><span> <b> {{use_language.status}} : </b></span></v-col>
              <v-col cols="12" md="9" xl="9" lg="9">
                <v-select
                  :value="dialog_excel_select"
                  @change="value => dialog_excel_select = value"
                  autocomplete="off"
                  ref="dialog_excel_select"
                  :items="dialog_excel_items"
                  item-key="text"
                  item-value="value"
                  hide-details
                  dense
                  outlined
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3" xl="3" lg="3"> <span><b> {{use_language.start_date}} : </b></span></v-col>
              <v-col cols="12" md="4" lg="4" class="pb-0">
                <v-menu
                  ref="menuStartDate"
                  v-model="menuStartDate"
                  :close-on-content-click="false"
                  :return-value.sync="startDate"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                  max-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      outlined
                      :value="startDate"
                      @change="value => startDate = value"
                      autocomplete="off"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-on="on"
                      hide-details
                      style="width: 290px;"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="startDate" no-title scrollable :allowed-dates="allowedDates">
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="4" lg="4" class="pb-0">
                <v-menu
                  ref="menutime1"
                  v-model="menutime1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="time1"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="time1"
                      prepend-inner-icon="mdi-clock-time-four-outline"
                      readonly
                      hide-details
                      dense
                      outlined
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="menutime1"
                    v-model="time1"
                    full-width
                    @click:minute="$refs.menutime1.save(time1)"
                  ></v-time-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3" xl="3" lg="3"> <span><b> {{use_language.end_date}} : </b></span></v-col>
              <v-col cols="12" md="4" lg="4" class="pb-0">
                <v-menu
                  ref="menuEndDate"
                  v-model="menuEndDate"
                  :close-on-content-click="false"
                  :return-value.sync="endDate"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                  max-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      outlined
                      :value="endDate"
                      @change="value => endDate = value"
                      autocomplete="off"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-on="on"
                      hide-details
                      style="width: 290px;"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="endDate" no-title scrollable :allowed-dates="allowedDates">
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="4" lg="4" class="pb-0">
                <v-menu
                  ref="menutime2"
                  v-model="menutime2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="time2"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="time2"
                      prepend-inner-icon="mdi-clock-time-four-outline"
                      readonly
                      hide-details
                      dense
                      outlined
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="menutime2"
                    v-model="time2"
                    full-width
                    @click:minute="$refs.menutime2.save(time2)"
                  ></v-time-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3" xl="3" lg="3"  class="pb-0"></v-col>
              <v-col cols="12" md="9" xl="9" lg="9"  class="pb-0">
                <v-checkbox v-model="IsSkipSecondRow" label="ใส่ข้อมูลทุกแถวหากในออเดอร์นั้นมีสินค้ามากกว่า 1 รายการ">  </v-checkbox>
                
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3" xl="3" lg="3"></v-col>
              <v-col cols="12" md="9" xl="9" lg="9">
                <v-btn color="primary" @click="get_count_order_excel_new(), dialog_excel= false">{{use_language.excel}} </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- print invoice -->
      <v-dialog  v-model="inv_dialog_printinvoice" scrollable persistent max-width="45%" :fullscreen="$vuetify.breakpoint.xsOnly">
        <v-card>
          <v-card-title>
            <span > {{use_language.print_document}} </span>
            <v-spacer></v-spacer>
            <v-btn icon  @click="inv_dialog_printinvoice=false" > <v-icon>mdi-close</v-icon> </v-btn></v-card-title>
          <v-card-text>
            <v-row row align="center">
              <v-col class="pb-0" cols="12" md="3">
                <h4>{{use_language.section}} : </h4>
              </v-col>
              <v-col class="pb-0" cols="12" md="4">
                <v-select
                  ref="refinvtitleprintmain"
                  v-model="invoice_print.titleprint_main"
                  autocomplete="off"
                  :items="inv_titleprintselect"
                  item-text="text"
                  item-value="value"
                  label="เลือกหัวข้อ"
                  return-object
                  dense
                  hide-details
                  outlined
                  />
                     <template v-slot:label> <span>{{use_language.choose_topic}}</span> </template>

              </v-col>
              <v-col class="pb-0" cols="12" md="1">
                <h1 class="text-center">/</h1>
              </v-col>
              <v-col class="pb-0" cols="12" md="4">
                <v-select
                  ref="refinvtitleprintsecondary"
                  v-model="invoice_print.titleprint_secondary"
                  autocomplete="off"
                  :items="inv_titleprintselect"
                  item-text="text"
                  item-value="value"
                  label="เลือกหัวข้อ"
                  return-object
                  dense
                  hide-details
                  outlined
                  />
                  <template v-slot:label> <span>{{use_language.choose_topic}}</span> </template>

              </v-col>
            </v-row>
            <v-row row>
              <v-col class="pb-0" cols="12" md="3">
                <h4>{{use_language.choose_document_size}} : </h4>
              </v-col>
              <v-col class="pb-0" cols="12" md="9">
                <v-radio-group v-model="sizepage" row>
                  <v-radio label="ขนาด a4" value= "a4" ></v-radio>
                  <v-radio label="ขนาด 80 mm" value= "small"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row row >
              <v-col class="pb-0" cols="12" md="3">
                <h4>{{use_language.document_number}} : </h4>
              </v-col>
              <v-col class="pb-0" cols="12" md="9">
                <v-data-table
                    :headers="invorderhead"
                    :items="invoice_print.invorder"
                    class="elevation-0 packhai-border-table"
                    hide-default-footer
                    :items-per-page="50">
                    <template v-slot:item.orderid="{ item }">{{format_order_id(item.orderId)}}</template>
                    <template v-slot:item.dateString="{ item }">
                      <v-menu
                          ref="startDateMenu"
                          v-model="item.startDateMenu"
                          :close-on-content-click="true" 
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              :value="item.dateString"
                              @change="value => item.date = value"
                              autocomplete="off"
                              outlined
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              dense
                              hide-details
                              v-on="on"
                            >
                            </v-text-field>
                          </template>
                          <v-date-picker
                            v-model="item.date"
                            @input="item.startDateMenu = false;updateFormattedDate(item)"
                            no-title
                            class="my-0"
                          ></v-date-picker>
                        </v-menu>
                    </template>
                    <template v-slot:item.invoiceno="{ item }">
                        <v-text-field
                          ref="refinvorder"
                          :value="item.invoiceNo"
                          @change="value => item.InvoiceNo = value"
                          outlined
                          dense
                          hide-details
                          autocomplete="off"
                          />
                    </template>
                    <template v-slot:item.delete="{ item }">
                      <v-btn color="red" icon @click="remove_data_invoice(invoice_print.invorder.indexOf(item))">
                        <v-icon>
                          fa-trash-alt
                        </v-icon>
                      </v-btn>
                    </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
              <v-spacer/>
              <v-btn color="green " dark @click="inv_printinvoice()" > {{use_language.print_document}} </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- print cover page -->
      <v-dialog v-model="dialog_printcoverpage" scrollable persistent max-width="45%" :fullscreen="$vuetify.breakpoint.xsOnly">
        <v-card>
          <v-card-title>
            <span > {{use_language.print_cover_sheet}} </span>
            <v-spacer></v-spacer>
            <v-btn icon  @click="dialog_printcoverpage=false" > <v-icon>mdi-close</v-icon> </v-btn></v-card-title>
          <v-card-text>
            <v-row row align="center">
              <v-col class="pb-0" cols="12" md="3">
                <h4>{{use_language.choose_document_size}} : </h4>
              </v-col>
              <v-col class="pb-0" cols="12" md="9">
                <v-radio-group v-model="coverpages_print.sizepage" row hide-details>
                  <v-radio label="ขนาด a4" value= "a4" ></v-radio>
                  <v-radio label="ขนาด 10x10 cm" value= "small"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
              <v-spacer/>
              <v-btn color="green " dark @click="printcoverpage()" > {{use_language.print_document}} </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- ปริ้นใบปะหน้า -->
      <v-dialog v-model="printAwsPopup.dialog" scrollable persistent :max-width="printAwsPopup.MaxWidth" :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card elevation="1" class="ma-0">
        <v-card-title class="height-57 pa-0">
          <div class="v-list-item">
            <div class="pa-2">
              <span>
                <h5>ปริ้นใบปะหน้า</h5>
              </span>
            </div>
            <div class="v-list-item__content pa-2">
              <v-row>
                <v-col cols="3"> </v-col>
                <v-col cols="9" class="text-right"> </v-col>
              </v-row>
            </div>
            <div>
              <v-btn
                v-if="printAwsDataSet.loading"
                icon
                @click="refreshWindow('printAwsPopup'), (printAwsPopup.dialog = false)"
                ><v-icon color="danger">mdi-close</v-icon></v-btn
              >
              <v-btn v-else icon @click="printAwsPopup.dialog = false"
                ><v-icon color="danger">mdi-close</v-icon></v-btn
              >
            </div>
          </div>
        </v-card-title>

        <v-card class="overflow-x-auto hidden-xs-only">
          <v-card-text class="pa-0 ma-0">
            <v-card flat>
              <v-card-text>
                <v-row class="pl-4">
                  <v-col cols="12">
                    <v-tabs v-model="printAwsPopup.tab" hide-slider>
                      <v-tab
                        v-for="i in printAwsPopup.tab_list"
                        class="box-shadow"
                        :key="i.id"
                        @click="printAwsPopup.tab = i.id"
                      >
                        {{ i.name }}

                        <v-badge v-if="i.id == 1" color="error" inline>
                          <template v-slot:badge>
                            <span>{{ printAwsDataSet.error }} </span>
                          </template>
                        </v-badge>
                      </v-tab>
                    </v-tabs>
                  </v-col>
                </v-row>
                <v-tabs-items v-model="printAwsPopup.tab" touchless>
                  <v-tab-item v-for="i in printAwsPopup.tab_list" :key="i.id">
                    <div v-if="i.id == 0">
                      <v-row class="ma-0 pa-0">
                        <v-col cols="12">
                          <div>
                            <v-data-table
                              :headers="printAwsDataSet.header"
                              :items="printAwsDataSet.dataset"
                              :items-per-page="9999999999"
                              item-key="packageID"
                              hide-default-footer
                              class="elevation-0 packhai-checkbox-select box-shadow"
                              height="30vh"
                              fixed-header
                            >
                              <template v-slot:item.orderMasterID="{ item }">
                                <a>{{ FormatOrder(item.orderMasterID) }} </a>
                              </template>
                              <template v-slot:item.expressCompanyLogo="{ item }">
                                <img
                                  v-if="
                                    item.expressCompanyLogo != null &&
                                    item.expressCompanyLogo != ''
                                  "
                                  width="60px"
                                  :src="item.expressCompanyLogo"
                                />
                              </template>
                              <template v-slot:item.referenceSourceLogo="{ item }">
                                <v-avatar size="25px" tile>
                                  <v-img
                                    v-if="
                                      item.referenceSourceLogo != null &&
                                      item.referenceSourceLogo != ''
                                    "
                                    style="border-radius: 10px"
                                    :src="item.referenceSourceLogo"
                                  />
                                </v-avatar>
                              </template>
                              <template v-slot:item.processing="{ item }">
                                <div v-if="item.processing == 'success'">
                                  <v-icon size="20" color="success"
                                    >mdi-check-circle
                                  </v-icon>
                                  <span>จัดเตรียมใบปะหน้าสำเร็จ </span>
                                </div>

                                <div v-else-if="item.processing == 'error'">
                                  <v-icon size="25" color="danger">mdi-close</v-icon>
                                  <span>ไม่สำเร็จ </span>
                                  <span>{{ item.error_description }} </span>
                                </div>
                              </template>
                            </v-data-table>
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                    <div v-if="i.id == 1">
                      <v-row class="ma-0 pa-0">
                        <v-col cols="12">
                          <div>
                            <v-data-table
                              :headers="printAwsDataSet.header"
                              :items="printAwsDataSet.datasetError"
                              :items-per-page="9999999999"
                              item-key="packageID"
                              hide-default-footer
                              class="elevation-0 packhai-checkbox-select box-shadow"
                              height="30vh"
                              fixed-header
                            >
                              <template v-slot:item.orderMasterID="{ item }">
                                <a>{{ FormatOrder(item.orderMasterID) }} </a>
                              </template>
                              <template v-slot:item.expressCompanyLogo="{ item }">
                                <img
                                  v-if="
                                    item.expressCompanyLogo != null &&
                                    item.expressCompanyLogo != ''
                                  "
                                  width="60px"
                                  :src="item.expressCompanyLogo"
                                />
                              </template>
                              <template v-slot:item.referenceSourceLogo="{ item }">
                                <v-avatar size="25px" tile>
                                  <v-img
                                    v-if="
                                      item.referenceSourceLogo != null &&
                                      item.referenceSourceLogo != ''
                                    "
                                    style="border-radius: 10px"
                                    :src="item.referenceSourceLogo"
                                  />
                                </v-avatar>
                              </template>
                              <template v-slot:item.processing="{ item }">
                                <div v-if="item.processing == 'success'">
                                  <v-icon size="20" color="success"
                                    >mdi-check-circle
                                  </v-icon>
                                  <span>จัดเตรียมใบปะหน้าสำเร็จ </span>
                                </div>

                                <div v-else-if="item.processing == 'error'">
                                  <v-icon size="25" color="danger">mdi-close</v-icon>
                                  <span>ไม่สำเร็จ </span>
                                  <span>{{ item.error_description }} </span>
                                </div>
                              </template>
                            </v-data-table>
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </v-tab-item>
                </v-tabs-items>
              </v-card-text>
            </v-card>
          </v-card-text>
        </v-card>

        <v-divider />

        <v-card-actions>
          <v-row>
            <v-col cols="6">
              <template v-if="printAwsDataSet.loadingPercent == true">
                <strong>กำลังโหลด{{ percentLoading }}%</strong>
                <span> สำเร็จ {{ this.printAwsDataSet.success }} รายการ , </span>
                <span> ไม่สำเร็จ {{ this.printAwsDataSet.error }} รายการ </span>
              </template>
            </v-col>
            <v-col cols="6" align="right">
              <table style="width:100%;" >
                <tr>
                  <td>
                  </td>
                  <td style="width:180px" class="text-right">
                    <v-checkbox label="โชว์สินค้าในใบปะหน้า" v-if="printAwsDataSet.dataset!=null && printAwsDataSet.dataset.length>0 && printAwsDataSet.dataset[0].referenceSourceID != 3" v-model="isNeedItemWhenPrint"></v-checkbox >
                  </td>
                  <td style="width:110px"  class="text-right">
                    <v-btn
                      color="primary"
                      :disabled="printAwsDataSet.loading"
                      @click="btnSubmitAwb()" >
                      ดำเนินการ
                    </v-btn>
                  </td>
                </tr>
              </table>
              
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    </div>
  </div>
</template>

<script>
import Loading from '@/website/components/Loading'
import Photo from "@/website/components/photo";
import { AlertSuccess, AlertWarning, AlertError } from "@/website/global_alert";
import {
  get_languages,
  FormatOrder,
  DateNow,
  formatMoney,
  formatMoneyIsDecimal,
  Trim_value,
  Trim_rules,
  formatDatetime,
  length60,
  ChangeOrderId,
  GenPA,
  GetDate,
  SetToDateTime,
  format_price,
  format_number,
  format_order_id
} from '@/website/global_function';
import axios from 'axios'
import Swal from 'sweetalert2'
import { header_token } from '@/website/backend/token'
import {
  shopService_dotnet,
  orderService_dotnet,
  branchService_dotnet,
  stockService_dotnet,
  ecomService_dotnet,
  logistic_Service,
  reportService_dotnet
} from "@/website/global"; 
import { utils, writeFileXLSX } from 'xlsx';
import html2canvas from "html2canvas";
import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import jsPDF from "jspdf";
export default {
  components: {
    Loading
  },
  data: () => ({
    isReloadTab: true,
    advanceSearchDialog: false,
    advanceDialogMaxWidth:"1200px",

    // export_to_excel
    dialog_excel: false,
    dialog_excel_select: null,
    dialog_excel_items: [
      {text: 'ทั้งหมด', value: null},
      {text: 'เปิดออร์เดอร์', value: 10},
      {text: 'รอตรวจยอด', value: 20},
      {text: 'รอแพ็ค', value: 30},
      {text: 'กำลังแพ็ค', value: 40},
      {text: 'แพ็คเรียบร้อย', value: 50},
      {text: 'เตรียมจัดส่ง', value: 60},
      {text: 'จัดส่งแล้ว', value: 70},
      {text: 'ยกเลิก', value: 90},
    ],

    startDate: null,
    menuStartDate: false,
    checkStart: true,

    endDate: null,
    menuEndDate: false,
    checkEnd: true,

    singleSelect: false,
    selected_order:[],

    // time
    time1: "00:00",
    time2: "23:59",
    menutime1: false,
    menutime2: false,

    IsSkipSecondRow:false,

    datasetExcel_new: [],
    ExcelFileName: '',
    excel_row_header:[[
      "เลขออเดอร์",
      "เวลาที่สร้าง",
      "สถานะ",
      "ขนส่ง",
      "trackingNo",
      "เวลาที่ส่งให้ขนส่ง",
      "เวลาที่จัดส่งสำเร็จ",
      "สถานะจัดส่ง",
      "เบอร์ผู้ส่ง",
      "ชื่อผู้ส่ง",
      "ที่อยู่ผู้ส่ง",
      "ตำบลผู้ส่ง",
      "อำเภอผู้ส่ง",
      "จังหวัดผู้ส่ง",
      "รหัสไปรษณีผู้ส่ง",
      "เบอร์ผู้รับ",
      "ชื่อผู้รับ",
      "ที่อยู่ผู้รับ",
      "ตำบลผู้รับ",
      "อำเภอผู้รับ",//20
      "จังหวัดผู้รับ",
      "รหัสไปรษณีผู้รับ",
      "อีเมลผู้รับ",
      "FBผู้รับ",
      "Lineผู้รับ",
      "ที่อยู่ผู้ส่ง อ้างอิง",
      "ที่อยู่ผู้รับ อ้างอิง",
      "ช่องทางการขาย",
      "ชื่อร้านค้า",
      "ธนาคาร", //30
      "จำนวนเงินรับโอน", 
      "สร้างโดย", 
      "พนักงานขาย", 
      "พัสดุที่",
      "สินค้า",
      "SKU", 
      "Barcode", 
      "Brand",
      "ลักษณะ1", 
      "ลักษณะ2", //40
      "SerialNo",
      "จำนวน", 
      "ราคาขาย", 
      "ส่วนลดสินค้า",
      "ประเภท",
      "ยอด COD", 
      "ราคาสินค้ารวม",
      "ส่วนลด Seller",
      "ส่วนลด Platform", 
      "ส่วนลดออเดอร์",//50
      "ค่าส่ง",
      "ส่วนลดค่าขนส่งจาก seller",
      "ส่วนลดค่าขนส่งจาก platofrm",
      "Vat",
      "ยอด VAT",
      "รวมทั้งหมด",
      "ค่าขนส่ง",
      "ค่าพื้นที่ห่างไกล", 
      "ค่าบริการ COD", 
      "ค่ากล่อง", //60
      "ค่าแพ็ค",
      "ส่วนลด", 
      "ค่าบับเบิ้ล",
      "ค่าเชื่อมต่อ",
      "เลขอ้างอิง", 
      "หมายเหตุ", 
      "รายการโอน", 
      "ชื่อกล่อง",
    ]],

    inv_dialog_printinvoice: false,
    dialog_printcoverpage: false,

    invoice_print: {
      ShopID: localStorage.getItem("shop_id"),
      invorder : [],
      titleprint_main: null,
      titleprint_secondary: null,
      date: new Date().toISOString().substr(0, 10)
    },
    coverpages_print:{
      sizepage: "a4",
      ShopID:localStorage.getItem("shop_id"),
      OrderID:[]
    },
    inv_titleprintselect: [
      {text: '---กรุณาเลือก---', value: 0},
      {text: 'ใบเสร็จรับเงิน', value: 1},
      {text: 'ใบกำกับภาษี', value: 2},
      {text: 'ใบส่งของ', value: 3},
      {text: 'ใบวางบิล', value: 4},
      {text: 'ใบแจ้งหนี้', value: 5},
    ],

    invorderhead:[
      { align: 'center', text: 'OrderID', value: 'orderid', sortable: false  },
      { align: 'center', text: 'วันที่', value: 'dateString', sortable: false },
      { align: 'center', text: 'หมายเลขเอกสาร', value: 'invoiceno', sortable: false },
      { align: 'center', text: '', value: 'delete', sortable: false },

    ],
    sizepage: "a4", 
    //

    printAwsDataSet: {
      dataset: [],
      datasetError: [],
      loading: false,
      loadingPercent: false,
      success: 0,
      error: 0,

      header: [
        {
          text: "ออเดอร์ ",
          align: "left",
          sortable: false,
          value: "orderMasterID",
        },
        {
          text: "เลขอ้างอิง",
          align: "left",
          sortable: false,
          value: "referenceNumber",
        },
        {
          text: "ช่องทาง",
          align: "center",
          sortable: false,
          value: "referenceSourceLogo",
          width: "80px",
        },
        {
          text: "บริการขนส่ง",
          align: "left",
          sortable: false,
          value: "expressCompanyLogo",
        },
        {
          text: "TrackingNo ",
          align: "left",
          sortable: false,
          value: "trackingNo",
          width: "120px",
        },
        {
          text: "สถานะ",
          align: "left",
          sortable: false,
          value: "processing",
        },
      ],
    },
    printAwsPopup: {
      dialog: false,
      MaxWidth: "60%",
      MaxHeight: 70,
      tab_list: [
        { id: 0, name: "ดำเนินการ" },
        { id: 1, name: "ข้อมูลที่ไม่สำเร็จ" },
      ],
      tab: 0,
    },
    isNeedItemWhenPrint:false,
    refreshTab: '',

    //
    header_token: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('authorization_token') },
    use_language: null,
    set_language: null,
    page_loading: true,
    loading: false,

    //ส่วนของ Tab
    tabListHeaders: [],
    tabListSubHeaders: [],
    set_TabListHeader: "0",
    set_TabListSubHeader: null,

    // แสดงภาพ
    ShowPhoto: false,
    ShowPhotoLink: "",

    //loadding Percent
    percentLoadingText: "",
    percentLoading: 0,

    datasetOrderALL: [],
    headerOrderALL: [
      { text: 'ลำดับ', align: 'center', sortable: false, value: 'num' },
      { text: 'OrderID', align: 'center', sortable: false, value: 'id', width: '120px' },
      { text: 'เวลาที่สร้าง', align: 'left', value: 'createdDatetime', sortable: false, width: '170px' },
      { text: 'ชื่อผู้รับ', align: 'left', value: 'receiverName', sortable: false, width: '250px' },
      { text: 'ช่องทาง', align: 'center', value: 'channelLogo', sortable: false },
      { text: 'ขนส่ง', align: 'center', value: 'expressCompanyLogo', sortable: false },
      { text: 'ยอดขาย', align: 'right', value: 'orderTotalAmount', sortable: false, width: '120px' },
      { text: 'ประเภทการส่ง', align: 'center', value: 'isCod', sortable: false },
      { text: 'ยอด COD', align: 'right', value: 'codamount', sortable: false, width: '120px' },
      { text: 'สถานะออเดอร์', align: 'center', value: 'shippingStatusName', sortable: false, width: '130px' },
      { text: 'สถานะพัสดุ', align: 'center', value: 'parcelStatusName', sortable: false, width: '110px' },
      { text: 'เลขอ้างอิง', align: 'left', value: 'referenceNumber', sortable: false, width: '130px' },
      { text: 'TrackingNo', align: 'left', value: 'trackingNo', sortable: false, width: '130px' },
      { text: 'สร้างโดย', align: 'left', value: 'createdByStaffName', sortable: false, width: '130px' },
      { text: 'พนักงานขาย', align: 'left', value: 'salemanName', sortable: false, width: '130px' },
    ],

    warehouse_list: null,
    warehouse_select: null,

    dataTable: {
      headers: [
        {
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          width: "70px",
        },
        {
          text: "ออเดอร์",
          align: "center",
          sortable: false,
          value: "id",
          width: "110px",
        },
        {
          text: "วันที่สร้าง",
          align: "center",
          sortable: false,
          value: "createdDatetime",
          width: "160px",
        },
        {
          text: "วันที่พร้อมแพ็ค",
          align: "center",
          sortable: false,
          value: "readyToPackDate",
          width: "160px",
        },
        {
          text: "ร้านค้า",
          align: "center",
          sortable: false,
          value: "shopName",
          width: "90px",
        },
        {
          text: "จำนวน SKU",
          align: "center",
          sortable: false,
          value: "skuCount",
          width: "90px",
        },
        {
          text: "จำนวนชิ้น",
          align: "center",
          sortable: false,
          value: "productCount",
          width: "90px",
        },
        {
          text: "ช่องทาง",
          align: "center",
          sortable: false,
          value: "channelName",
          width: "90px",
        },
        {
          text: "ขนส่ง",
          align: "center",
          sortable: false,
          value: "expressCompanyName",
          width: "90px",
        },
        {
          text: "เลขอ้างอิง",
          align: "left",
          sortable: false,
          value: "referenceNumber",
          width: "110px",
        },
        {
          text: "TrackingNo",
          align: "left",
          sortable: false,
          value: "trackingNo",
          width: "110px",
        },
        {
          text: "นัดรับ",
          align: "center",
          sortable: false,
          value: "isInit",
          width: "50px",
        },
        {
          text: "ใบแพ็ค",
          align: "center",
          sortable: false,
          value: "isPrintPackingList",
          width: "70px",
        },
        {
          text: "ใบปะหน้า",
          align: "center",
          sortable: false,
          value: "isPrintAWB",
          width: "70px",
        },
        {
          text: "น้ำหนัก",
          align: "center",
          sortable: false,
          value: "weight",
          width: "90px",
        },
        {
          text: "ขนาด",
          align: "center",
          sortable: false,
          value: "boxSize",
          width: "100px",
        },
        {
          text: "กล่อง",
          align: "center",
          sortable: false,
          value: "boxName",
          width: "100px",
        },
        {
          text: "กำลังแพ็ค",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "แพ็คเสร็จโดย",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "เริ่มแพ็ค",
          align: "center",
          sortable: false,
          value: "startPackingDate",
          width: "160px",
        },
        {
          text: "แพ็คเสร็จ",
          align: "center",
          sortable: false,
          value: "endPackingDate",
          width: "160px",
        },
        {
          text: "ลูกค้า",
          align: "left",
          sortable: false,
          value: "receiverName",
          width: "200px",
        },
      ],





    },

    set_TabListSubHeader_wait_payment: '1',

    dataAllOrderList: null,
    dataset_all: {
      headers: [
        {
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          width: "70px",
        },
        {
          text: "ออเดอร์",
          align: "center",
          sortable: false,
          value: "id",
          width: "110px",
        },
        {
          text: "วันที่สร้าง",
          align: "center",
          sortable: false,
          value: "createdDatetime",
          width: "160px",
        },
        {
          text: "วันที่พร้อมแพ็ค",
          align: "center",
          sortable: false,
          value: "readyToPackDate",
          width: "160px",
        },
        {
          text: "ร้านค้า",
          align: "center",
          sortable: false,
          value: "shopName",
          width: "90px",
        },
        {
          text: "จำนวน SKU",
          align: "center",
          sortable: false,
          value: "skuCount",
          width: "90px",
        },
        {
          text: "จำนวนชิ้น",
          align: "center",
          sortable: false,
          value: "productCount",
          width: "90px",
        },
        {
          text: "ช่องทาง",
          align: "center",
          sortable: false,
          value: "channelName",
          width: "90px",
        },
        {
          text: "ขนส่ง",
          align: "center",
          sortable: false,
          value: "expressCompanyName",
          width: "90px",
        },
        {
          text: "เลขอ้างอิง",
          align: "left",
          sortable: false,
          value: "referenceNumber",
          width: "110px",
        },
        {
          text: "TrackingNo",
          align: "left",
          sortable: false,
          value: "trackingNo",
          width: "110px",
        },
        {
          text: "นัดรับ",
          align: "center",
          sortable: false,
          value: "isInit",
          width: "50px",
        },
        {
          text: "ใบแพ็ค",
          align: "center",
          sortable: false,
          value: "isPrintPackingList",
          width: "70px",
        },
        {
          text: "ใบปะหน้า",
          align: "center",
          sortable: false,
          value: "isPrintAWB",
          width: "70px",
        },
        {
          text: "น้ำหนัก",
          align: "center",
          sortable: false,
          value: "weight",
          width: "90px",
        },
        {
          text: "ขนาด",
          align: "center",
          sortable: false,
          value: "boxSize",
          width: "100px",
        },
        {
          text: "กล่อง",
          align: "center",
          sortable: false,
          value: "boxName",
          width: "100px",
        },
        {
          text: "กำลังแพ็ค",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "แพ็คเสร็จโดย",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "เริ่มแพ็ค",
          align: "center",
          sortable: false,
          value: "startPackingDate",
          width: "160px",
        },
        {
          text: "แพ็คเสร็จ",
          align: "center",
          sortable: false,
          value: "endPackingDate",
          width: "160px",
        },
        {
          text: "ลูกค้า",
          align: "left",
          sortable: false,
          value: "receiverName",
          width: "200px",
        },
      ],
      dataTable: [],
      page: 1,
      manageMenuListType: [{ name: "รายงาน", btn: "btnViewReport" },],
      ManageChangeStatusList: [],
      ManageBackStatusList: [],
      offsets: 0,
      limits: 10,
      pageLength: 1,
      total: 0,
      selected: [],
      keyword: {},
      search: {
        text_topic: "",
        select_topic: { id: 1, name: "เลขออเดอร์", value: "orderId" },
        select_header: [
          { id: 1, name: "เลขออเดอร์", value: "orderId" },
          { id: 2, name: "เลขอ้างอิง", value: "referenceNumber" },
          { id: 3, name: "TrackingNo", value: "trackingNo" },
        ],

        select_per_page: 10,
        select_per: [
          { value: 5, name: "5 คำสั่งซื้อ" },
          { value: 10, name: "10 คำสั่งซื้อ" },
          { value: 25, name: "25 คำสั่งซื้อ" },
          { value: 50, name: "50 คำสั่งซื้อ" },
          { value: 100, name: "100 คำสั่งซื้อ" },
          { value: 999999, name: "ทั้งหมด" },
        ],
        
        searchData: null,
        SHselectStatus: 9999,
        SHitemsStatus: [
          {text: 'ทั้งหมด', value: 9999},
          {text: 'เปิดออร์เดอร์', value: 10},
          {text: 'รอตรวจยอด', value: 20},
          {text: 'รอแพ็ค', value: 30},
          {text: 'กำลังแพ็ค', value: 40},
          {text: 'แพ็คเรียบร้อย', value: 50},
          {text: 'เตรียมจัดส่ง', value: 60},
          {text: 'จัดส่งแล้ว', value: 70},
          {text: 'ยกเลิก', value: 90},
        ],

        select_search_parcel_status_id: null,
        search_parcel_status_list: [],

        select_search_paid_status_id: null,
        search_paid_status_list: [
          {text: 'ทุกแบบ', value: 0}, 
          {text: 'โอนแล้ว', value: 1}, 
          {text: 'ยังไม่ได้โอน', value: 2}
        ],

        SHshipName: null,
        SHchanelFackbook: null,
        SHchanelLine: null,
        SHchanelEmail: null,
        SHtrackingNo: null,
        SHorderID: null,
        SH_shop_staff: null,
        SH_phone : null,
        SH_ReferenceNumber : null,
        SH_shop_staff_items: [],
      }
    },
    search_menu_date_range: false,
    search_date_range: [],
    menu_search_sent_date: false,
    sent_date_range: [],
    menu_search_tranferred_date: false,
    transferred_date_range: [],
    channelListSeleced:[],
    channelList:[],

    dataset_wait_payment_wait_payment: {
      headers: [
        {
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          width: "70px",
        },
        {
          text: "ออเดอร์",
          align: "center",
          sortable: false,
          value: "id",
          width: "110px",
        },
        {
          text: "วันที่สร้าง",
          align: "center",
          sortable: false,
          value: "createdDatetime",
          width: "160px",
        },
        {
          text: "วันที่พร้อมแพ็ค",
          align: "center",
          sortable: false,
          value: "readyToPackDate",
          width: "160px",
        },
        {
          text: "ร้านค้า",
          align: "center",
          sortable: false,
          value: "shopName",
          width: "90px",
        },
        {
          text: "จำนวน SKU",
          align: "center",
          sortable: false,
          value: "skuCount",
          width: "90px",
        },
        {
          text: "จำนวนชิ้น",
          align: "center",
          sortable: false,
          value: "productCount",
          width: "90px",
        },
        {
          text: "ช่องทาง",
          align: "center",
          sortable: false,
          value: "channelName",
          width: "90px",
        },
        {
          text: "ขนส่ง",
          align: "center",
          sortable: false,
          value: "expressCompanyName",
          width: "90px",
        },
        {
          text: "เลขอ้างอิง",
          align: "left",
          sortable: false,
          value: "referenceNumber",
          width: "110px",
        },
        {
          text: "TrackingNo",
          align: "left",
          sortable: false,
          value: "trackingNo",
          width: "110px",
        },
        {
          text: "นัดรับ",
          align: "center",
          sortable: false,
          value: "isInit",
          width: "50px",
        },
        {
          text: "ใบแพ็ค",
          align: "center",
          sortable: false,
          value: "isPrintPackingList",
          width: "70px",
        },
        {
          text: "ใบปะหน้า",
          align: "center",
          sortable: false,
          value: "isPrintAWB",
          width: "70px",
        },
        {
          text: "น้ำหนัก",
          align: "center",
          sortable: false,
          value: "weight",
          width: "90px",
        },
        {
          text: "ขนาด",
          align: "center",
          sortable: false,
          value: "boxSize",
          width: "100px",
        },
        {
          text: "กล่อง",
          align: "center",
          sortable: false,
          value: "boxName",
          width: "100px",
        },
        {
          text: "กำลังแพ็ค",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "แพ็คเสร็จโดย",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "เริ่มแพ็ค",
          align: "center",
          sortable: false,
          value: "startPackingDate",
          width: "160px",
        },
        {
          text: "แพ็คเสร็จ",
          align: "center",
          sortable: false,
          value: "endPackingDate",
          width: "160px",
        },
        {
          text: "ลูกค้า",
          align: "left",
          sortable: false,
          value: "receiverName",
          width: "200px",
        },
      ],
      dataTable: [],
      page: 1,
      manageMenuListType: [
        { name: "อนุมัติ", btn: "btnApproveBalanceCheck" },
        { name: "รายงาน", btn: "btnViewReport" }],
      ManageChangeStatusList: [{ ID: "30", Name: "รอนัดรับ" },
      { ID: "40", Name: "รอแพ็ก" },
      { ID: "90", Name: "กำลังจัดส่ง" },
      { ID: "130", Name: "จัดส่งสำเร็จ" },
      { ID: "140", Name: "ยกเลิก" },
      ],
      ManageBackStatusList: [],
      offsets: 0,
      limits: 10,
      pageLength: 1,
      total: 0,
      selected: [],
      keyword: {},
      search: {
        select_per_page: 10,
        select_per: [
          { value: 5, name: "5 คำสั่งซื้อ" },
          { value: 10, name: "10 คำสั่งซื้อ" },
          { value: 25, name: "25 คำสั่งซื้อ" },
          { value: 50, name: "50 คำสั่งซื้อ" },
          { value: 100, name: "100 คำสั่งซื้อ" },
          { value: 999999, name: "ทั้งหมด" },
        ],
        search_by: '',
        select_channel: { channelID: 0, channelName: "--ทุกช่องทาง--" },
        list_channel: [{ channelID: 0, channelName: "--ทุกช่องทาง--" }],
        select_express: { expressCompanyID: null, expressCompanyName: " -- ทั้งหมด --" },
        list_express: [{ expressCompanyID: null, expressCompanyName: " -- ทั้งหมด --", },],
        select_print: { id: null, name: " -- ทั้งหมด --" },
        list_print: [{ id: null, name: " -- ทั้งหมด --" }],
        select_topic: { id: 1, name: "เลขออเดอร์", value: "orderId" },
        select_header: [
          { id: 0, name: "เลขออเดอร์", value: "orderId" },
          { id: 1, name: "เลขอ้างอิง", value: "referenceNumber" },
          { id: 2, name: "ชื่อผู้รับ", value: "receiverName" },
        ],
        text_topic: "",

      }
    },
    dataset_wait_payment_check_payment: {
      headers: [
        {
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          width: "70px",
        },
        {
          text: "ออเดอร์",
          align: "center",
          sortable: false,
          value: "id",
          width: "110px",
        },
        {
          text: "วันที่สร้าง",
          align: "center",
          sortable: false,
          value: "createdDatetime",
          width: "160px",
        },
        {
          text: "วันที่พร้อมแพ็ค",
          align: "center",
          sortable: false,
          value: "readyToPackDate",
          width: "160px",
        },
        {
          text: "ร้านค้า",
          align: "center",
          sortable: false,
          value: "shopName",
          width: "90px",
        },
        {
          text: "จำนวน SKU",
          align: "center",
          sortable: false,
          value: "skuCount",
          width: "90px",
        },
        {
          text: "จำนวนชิ้น",
          align: "center",
          sortable: false,
          value: "productCount",
          width: "90px",
        },
        {
          text: "ช่องทาง",
          align: "center",
          sortable: false,
          value: "channelName",
          width: "90px",
        },
        {
          text: "ขนส่ง",
          align: "center",
          sortable: false,
          value: "expressCompanyName",
          width: "90px",
        },
        {
          text: "เลขอ้างอิง",
          align: "left",
          sortable: false,
          value: "referenceNumber",
          width: "110px",
        },
        {
          text: "TrackingNo",
          align: "left",
          sortable: false,
          value: "trackingNo",
          width: "110px",
        },
        {
          text: "นัดรับ",
          align: "center",
          sortable: false,
          value: "isInit",
          width: "50px",
        },
        {
          text: "ใบแพ็ค",
          align: "center",
          sortable: false,
          value: "isPrintPackingList",
          width: "70px",
        },
        {
          text: "ใบปะหน้า",
          align: "center",
          sortable: false,
          value: "isPrintAWB",
          width: "70px",
        },
        {
          text: "น้ำหนัก",
          align: "center",
          sortable: false,
          value: "weight",
          width: "90px",
        },
        {
          text: "ขนาด",
          align: "center",
          sortable: false,
          value: "boxSize",
          width: "100px",
        },
        {
          text: "กล่อง",
          align: "center",
          sortable: false,
          value: "boxName",
          width: "100px",
        },
        {
          text: "กำลังแพ็ค",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "แพ็คเสร็จโดย",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "เริ่มแพ็ค",
          align: "center",
          sortable: false,
          value: "startPackingDate",
          width: "160px",
        },
        {
          text: "แพ็คเสร็จ",
          align: "center",
          sortable: false,
          value: "endPackingDate",
          width: "160px",
        },
        {
          text: "ลูกค้า",
          align: "left",
          sortable: false,
          value: "receiverName",
          width: "200px",
        },
      ],
      dataTable: [],
      page: 1,
      manageMenuListType: [{ name: "ผูกสินค้า", btn: "btnMappedProduct" },
      { name: "เปลี่ยนสถานะ", btn: "btnPackageChangeStatus" },
      { name: "รายงาน", btn: "btnViewReport" }],
      ManageChangeStatusList: [{ ID: "30", Name: "รอนัดรับ" },
      { ID: "40", Name: "รอแพ็ก" },
      { ID: "90", Name: "กำลังจัดส่ง" },
      { ID: "130", Name: "จัดส่งสำเร็จ" },
      { ID: "140", Name: "ยกเลิก" },
      ],
      ManageBackStatusList: [],
      offsets: 0,
      limits: 10,
      pageLength: 1,
      total: 0,
      selected: [],
      keyword: {},
      search: {
        select_per_page: 10,
        select_per: [
          { value: 5, name: "5 คำสั่งซื้อ" },
          { value: 10, name: "10 คำสั่งซื้อ" },
          { value: 25, name: "25 คำสั่งซื้อ" },
          { value: 50, name: "50 คำสั่งซื้อ" },
          { value: 100, name: "100 คำสั่งซื้อ" },
          { value: 999999, name: "ทั้งหมด" },
        ],
        search_by: '',
        select_channel: { channelID: 0, channelName: "--ทุกช่องทาง--" },
        list_channel: [{ channelID: 0, channelName: "--ทุกช่องทาง--" }],
        select_express: { expressCompanyID: null, expressCompanyName: " -- ทั้งหมด --" },
        list_express: [{ expressCompanyID: null, expressCompanyName: " -- ทั้งหมด --", },],
        select_print: { id: null, name: " -- ทั้งหมด --" },
        list_print: [{ id: null, name: " -- ทั้งหมด --" }],
        select_topic: { id: 1, name: "เลขออเดอร์", value: "orderId" },
        select_header: [
          { id: 0, name: "เลขออเดอร์", value: "orderId" },
          { id: 1, name: "เลขอ้างอิง", value: "referenceNumber" },
          { id: 2, name: "ชื่อผู้รับ", value: "receiverName" },
        ],
        text_topic: "",

      }
    },

    set_TabListSubHeader_Process: '10',
    dataset_process_map_product: {
      headers: [
        {
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          width: "70px",
        },
        {
          text: "ออเดอร์",
          align: "center",
          sortable: false,
          value: "id",
          width: "110px",
        },
        {
          text: "วันที่สร้าง",
          align: "center",
          sortable: false,
          value: "createdDatetime",
          width: "160px",
        },
        {
          text: "วันที่พร้อมแพ็ค",
          align: "center",
          sortable: false,
          value: "readyToPackDate",
          width: "160px",
        },
        {
          text: "ร้านค้า",
          align: "center",
          sortable: false,
          value: "shopName",
          width: "90px",
        },
        {
          text: "จำนวน SKU",
          align: "center",
          sortable: false,
          value: "skuCount",
          width: "90px",
        },
        {
          text: "จำนวนชิ้น",
          align: "center",
          sortable: false,
          value: "productCount",
          width: "90px",
        },
        {
          text: "ช่องทาง",
          align: "center",
          sortable: false,
          value: "channelName",
          width: "90px",
        },
        {
          text: "ขนส่ง",
          align: "center",
          sortable: false,
          value: "expressCompanyName",
          width: "90px",
        },
        {
          text: "เลขอ้างอิง",
          align: "left",
          sortable: false,
          value: "referenceNumber",
          width: "110px",
        },
        {
          text: "TrackingNo",
          align: "left",
          sortable: false,
          value: "trackingNo",
          width: "110px",
        },
        {
          text: "นัดรับ",
          align: "center",
          sortable: false,
          value: "isInit",
          width: "50px",
        },
        {
          text: "ใบแพ็ค",
          align: "center",
          sortable: false,
          value: "isPrintPackingList",
          width: "70px",
        },
        {
          text: "ใบปะหน้า",
          align: "center",
          sortable: false,
          value: "isPrintAWB",
          width: "70px",
        },
        {
          text: "น้ำหนัก",
          align: "center",
          sortable: false,
          value: "weight",
          width: "90px",
        },
        {
          text: "ขนาด",
          align: "center",
          sortable: false,
          value: "boxSize",
          width: "100px",
        },
        {
          text: "กล่อง",
          align: "center",
          sortable: false,
          value: "boxName",
          width: "100px",
        },
        {
          text: "กำลังแพ็ค",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "แพ็คเสร็จโดย",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "เริ่มแพ็ค",
          align: "center",
          sortable: false,
          value: "startPackingDate",
          width: "160px",
        },
        {
          text: "แพ็คเสร็จ",
          align: "center",
          sortable: false,
          value: "endPackingDate",
          width: "160px",
        },
        {
          text: "ลูกค้า",
          align: "left",
          sortable: false,
          value: "receiverName",
          width: "200px",
        },
      ],
      dataTable: [],
      page: 1,
      manageMenuListType: [{ name: "ผูกสินค้า", btn: "btnMappedProduct" },
      { name: "เปลี่ยนสถานะ", btn: "btnPackageChangeStatus" },
      { name: "รายงาน", btn: "btnViewReport" }],
      ManageChangeStatusList: [
        { ID: "40", Name: "รอแพ็ก" },
        { ID: "90", Name: "กำลังจัดส่ง" },
        { ID: "130", Name: "จัดส่งสำเร็จ" },
        { ID: "140", Name: "ยกเลิก" },
      ],
      ManageBackStatusList: [],
      offsets: 0,
      limits: 10,
      pageLength: 1,
      total: 0,
      selected: [],
      keyword: {},
      search: {
        select_per_page: 10,
        select_per: [
          { value: 5, name: "5 คำสั่งซื้อ" },
          { value: 10, name: "10 คำสั่งซื้อ" },
          { value: 25, name: "25 คำสั่งซื้อ" },
          { value: 50, name: "50 คำสั่งซื้อ" },
          { value: 100, name: "100 คำสั่งซื้อ" },
          { value: 999999, name: "ทั้งหมด" },
        ],
        search_by: '',
        select_channel: { channelID: 0, channelName: "--ทุกช่องทาง--" },
        list_channel: [{ channelID: 0, channelName: "--ทุกช่องทาง--" }],
        select_express: { expressCompanyID: null, expressCompanyName: " -- ทั้งหมด --" },
        list_express: [{ expressCompanyID: null, expressCompanyName: " -- ทั้งหมด --", },],
        select_print: { id: null, name: " -- ทั้งหมด --" },
        list_print: [{ id: null, name: " -- ทั้งหมด --" }],
        select_topic: { id: 1, name: "เลขออเดอร์", value: "orderId" },
        select_header: [
          { id: 0, name: "เลขออเดอร์", value: "orderId" },
          { id: 1, name: "เลขอ้างอิง", value: "referenceNumber" },
          { id: 2, name: "ชื่อผู้รับ", value: "receiverName" },
        ],
        text_topic: "",

      }
    },
    dataset_process_allocate_stock: {
      headers: [
        {
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          width: "70px",
        },
        {
          text: "ออเดอร์",
          align: "center",
          sortable: false,
          value: "id",
          width: "110px",
        },
        {
          text: "วันที่สร้าง",
          align: "center",
          sortable: false,
          value: "createdDatetime",
          width: "160px",
        },
        {
          text: "วันที่พร้อมแพ็ค",
          align: "center",
          sortable: false,
          value: "readyToPackDate",
          width: "160px",
        },
        {
          text: "ร้านค้า",
          align: "center",
          sortable: false,
          value: "shopName",
          width: "90px",
        },
        {
          text: "จำนวน SKU",
          align: "center",
          sortable: false,
          value: "skuCount",
          width: "90px",
        },
        {
          text: "จำนวนชิ้น",
          align: "center",
          sortable: false,
          value: "productCount",
          width: "90px",
        },
        {
          text: "ช่องทาง",
          align: "center",
          sortable: false,
          value: "channelName",
          width: "90px",
        },
        {
          text: "ขนส่ง",
          align: "center",
          sortable: false,
          value: "expressCompanyName",
          width: "90px",
        },
        {
          text: "เลขอ้างอิง",
          align: "left",
          sortable: false,
          value: "referenceNumber",
          width: "110px",
        },
        {
          text: "TrackingNo",
          align: "left",
          sortable: false,
          value: "trackingNo",
          width: "110px",
        },
        {
          text: "นัดรับ",
          align: "center",
          sortable: false,
          value: "isInit",
          width: "50px",
        },
        {
          text: "ใบแพ็ค",
          align: "center",
          sortable: false,
          value: "isPrintPackingList",
          width: "70px",
        },
        {
          text: "ใบปะหน้า",
          align: "center",
          sortable: false,
          value: "isPrintAWB",
          width: "70px",
        },
        {
          text: "น้ำหนัก",
          align: "center",
          sortable: false,
          value: "weight",
          width: "90px",
        },
        {
          text: "ขนาด",
          align: "center",
          sortable: false,
          value: "boxSize",
          width: "100px",
        },
        {
          text: "กล่อง",
          align: "center",
          sortable: false,
          value: "boxName",
          width: "100px",
        },
        {
          text: "กำลังแพ็ค",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "แพ็คเสร็จโดย",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "เริ่มแพ็ค",
          align: "center",
          sortable: false,
          value: "startPackingDate",
          width: "160px",
        },
        {
          text: "แพ็คเสร็จ",
          align: "center",
          sortable: false,
          value: "endPackingDate",
          width: "160px",
        },
        {
          text: "ลูกค้า",
          align: "left",
          sortable: false,
          value: "receiverName",
          width: "200px",
        },
      ],
      dataTable: [],
      page: 1,
      manageMenuListType: [{ name: "ผูกสินค้า", btn: "btnMappedProduct" },
      { name: "เปลี่ยนสถานะ", btn: "btnPackageChangeStatus" },
      { name: "รายงาน", btn: "btnViewReport" }],
      ManageChangeStatusList: [{ ID: "30", Name: "รอนัดรับ" },
      { ID: "40", Name: "รอแพ็ก" },
      { ID: "90", Name: "กำลังจัดส่ง" },
      { ID: "130", Name: "จัดส่งสำเร็จ" },
      { ID: "140", Name: "ยกเลิก" },
      ],
      ManageBackStatusList: [],
      offsets: 0,
      limits: 10,
      pageLength: 1,
      total: 0,
      selected: [],
      keyword: {},
      search: {
        select_per_page: 10,
        select_per: [
          { value: 5, name: "5 คำสั่งซื้อ" },
          { value: 10, name: "10 คำสั่งซื้อ" },
          { value: 25, name: "25 คำสั่งซื้อ" },
          { value: 50, name: "50 คำสั่งซื้อ" },
          { value: 100, name: "100 คำสั่งซื้อ" },
          { value: 999999, name: "ทั้งหมด" },
        ],
        search_by: '',
        select_channel: { channelID: 0, channelName: "--ทุกช่องทาง--" },
        list_channel: [{ channelID: 0, channelName: "--ทุกช่องทาง--" }],
        select_express: { expressCompanyID: null, expressCompanyName: " -- ทั้งหมด --" },
        list_express: [{ expressCompanyID: null, expressCompanyName: " -- ทั้งหมด --", },],
        select_print: { id: null, name: " -- ทั้งหมด --" },
        list_print: [{ id: null, name: " -- ทั้งหมด --" }],
        select_topic: { id: 1, name: "เลขออเดอร์", value: "orderId" },
        select_header: [
          { id: 0, name: "เลขออเดอร์", value: "orderId" },
          { id: 1, name: "เลขอ้างอิง", value: "referenceNumber" },
          { id: 2, name: "ชื่อผู้รับ", value: "receiverName" },
        ],
        text_topic: "",

      }
    },
    dataset_process_ready_to_ship: {
      headers: [
        {
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          width: "70px",
        },
        {
          text: "ออเดอร์",
          align: "center",
          sortable: false,
          value: "id",
          width: "110px",
        },
        {
          text: "วันที่สร้าง",
          align: "center",
          sortable: false,
          value: "createdDatetime",
          width: "160px",
        },
        {
          text: "วันที่พร้อมแพ็ค",
          align: "center",
          sortable: false,
          value: "readyToPackDate",
          width: "160px",
        },
        {
          text: "ร้านค้า",
          align: "center",
          sortable: false,
          value: "shopName",
          width: "90px",
        },
        {
          text: "จำนวน SKU",
          align: "center",
          sortable: false,
          value: "skuCount",
          width: "90px",
        },
        {
          text: "จำนวนชิ้น",
          align: "center",
          sortable: false,
          value: "productCount",
          width: "90px",
        },
        {
          text: "ช่องทาง",
          align: "center",
          sortable: false,
          value: "channelName",
          width: "90px",
        },
        {
          text: "ขนส่ง",
          align: "center",
          sortable: false,
          value: "expressCompanyName",
          width: "90px",
        },
        {
          text: "เลขอ้างอิง",
          align: "left",
          sortable: false,
          value: "referenceNumber",
          width: "110px",
        },
        {
          text: "TrackingNo",
          align: "left",
          sortable: false,
          value: "trackingNo",
          width: "110px",
        },
        {
          text: "นัดรับ",
          align: "center",
          sortable: false,
          value: "isInit",
          width: "50px",
        },
        {
          text: "ใบแพ็ค",
          align: "center",
          sortable: false,
          value: "isPrintPackingList",
          width: "70px",
        },
        {
          text: "ใบปะหน้า",
          align: "center",
          sortable: false,
          value: "isPrintAWB",
          width: "70px",
        },
        {
          text: "น้ำหนัก",
          align: "center",
          sortable: false,
          value: "weight",
          width: "90px",
        },
        {
          text: "ขนาด",
          align: "center",
          sortable: false,
          value: "boxSize",
          width: "100px",
        },
        {
          text: "กล่อง",
          align: "center",
          sortable: false,
          value: "boxName",
          width: "100px",
        },
        {
          text: "กำลังแพ็ค",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "แพ็คเสร็จโดย",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "เริ่มแพ็ค",
          align: "center",
          sortable: false,
          value: "startPackingDate",
          width: "160px",
        },
        {
          text: "แพ็คเสร็จ",
          align: "center",
          sortable: false,
          value: "endPackingDate",
          width: "160px",
        },
        {
          text: "ลูกค้า",
          align: "left",
          sortable: false,
          value: "receiverName",
          width: "200px",
        },
      ],
      dataTable: [],
      page: 1,
      manageMenuListType: [{ name: "พร้อมจัดส่งไปยัง Marketplace", btn: "btnUpdateToMarketplace" },
      { name: "เปลี่ยนสถานะ", btn: "btnPackageChangeStatus" },
      { name: "ย้อนสถานะ", btn: "btnPackageBackStatus" },
      { name: "รายงาน", btn: "btnViewReport" }],
      ManageChangeStatusList: [{ ID: "30", Name: "รอนัดรับ" },
      { ID: "40", Name: "รอแพ็ก" },
      { ID: "90", Name: "กำลังจัดส่ง" },
      { ID: "130", Name: "จัดส่งสำเร็จ" },
      { ID: "140", Name: "ยกเลิก" },
      ],
      ManageBackStatusList: [],
      offsets: 0,
      limits: 10,
      pageLength: 1,
      total: 0,
      selected: [],
      keyword: {},
      search: {
        select_per_page: 10,
        select_per: [
          { value: 5, name: "5 คำสั่งซื้อ" },
          { value: 10, name: "10 คำสั่งซื้อ" },
          { value: 25, name: "25 คำสั่งซื้อ" },
          { value: 50, name: "50 คำสั่งซื้อ" },
          { value: 100, name: "100 คำสั่งซื้อ" },
          { value: 999999, name: "ทั้งหมด" },
        ],
        search_by: '',
        select_channel: { channelID: 0, channelName: "--ทุกช่องทาง--" },
        list_channel: [{ channelID: 0, channelName: "--ทุกช่องทาง--" }],
        select_express: { expressCompanyID: null, expressCompanyName: " -- ทั้งหมด --" },
        list_express: [{ expressCompanyID: null, expressCompanyName: " -- ทั้งหมด --", },],
        select_print: { id: null, name: " -- ทั้งหมด --" },
        list_print: [{ id: null, name: " -- ทั้งหมด --" }],
        select_topic: { id: 1, name: "เลขออเดอร์", value: "orderId" },
        select_header: [
          { id: 0, name: "เลขออเดอร์", value: "orderId" },
          { id: 1, name: "เลขอ้างอิง", value: "referenceNumber" },
          { id: 2, name: "ชื่อผู้รับ", value: "receiverName" },
        ],
        text_topic: "",

      }
    },
    dataset_process_to_pack: {
      headers: [
        {
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          width: "70px",
        },
        {
          text: "ออเดอร์",
          align: "center",
          sortable: false,
          value: "id",
          width: "110px",
        },
        {
          text: "วันที่สร้าง",
          align: "center",
          sortable: false,
          value: "createdDatetime",
          width: "160px",
        },
        {
          text: "วันที่พร้อมแพ็ค",
          align: "center",
          sortable: false,
          value: "readyToPackDate",
          width: "160px",
        },
        {
          text: "ร้านค้า",
          align: "center",
          sortable: false,
          value: "shopName",
          width: "90px",
        },
        {
          text: "จำนวน SKU",
          align: "center",
          sortable: false,
          value: "skuCount",
          width: "90px",
        },
        {
          text: "จำนวนชิ้น",
          align: "center",
          sortable: false,
          value: "productCount",
          width: "90px",
        },
        {
          text: "ช่องทาง",
          align: "center",
          sortable: false,
          value: "channelName",
          width: "90px",
        },
        {
          text: "ขนส่ง",
          align: "center",
          sortable: false,
          value: "expressCompanyName",
          width: "90px",
        },
        {
          text: "เลขอ้างอิง",
          align: "left",
          sortable: false,
          value: "referenceNumber",
          width: "110px",
        },
        {
          text: "TrackingNo",
          align: "left",
          sortable: false,
          value: "trackingNo",
          width: "110px",
        },
        {
          text: "นัดรับ",
          align: "center",
          sortable: false,
          value: "isInit",
          width: "50px",
        },
        {
          text: "ใบแพ็ค",
          align: "center",
          sortable: false,
          value: "isPrintPackingList",
          width: "70px",
        },
        {
          text: "ใบปะหน้า",
          align: "center",
          sortable: false,
          value: "isPrintAWB",
          width: "70px",
        },
        {
          text: "น้ำหนัก",
          align: "center",
          sortable: false,
          value: "weight",
          width: "90px",
        },
        {
          text: "ขนาด",
          align: "center",
          sortable: false,
          value: "boxSize",
          width: "100px",
        },
        {
          text: "กล่อง",
          align: "center",
          sortable: false,
          value: "boxName",
          width: "100px",
        },
        {
          text: "กำลังแพ็ค",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "แพ็คเสร็จโดย",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "เริ่มแพ็ค",
          align: "center",
          sortable: false,
          value: "startPackingDate",
          width: "160px",
        },
        {
          text: "แพ็คเสร็จ",
          align: "center",
          sortable: false,
          value: "endPackingDate",
          width: "160px",
        },
        {
          text: "ลูกค้า",
          align: "left",
          sortable: false,
          value: "receiverName",
          width: "200px",
        },
      ],
      dataTable: [],
      page: 1,
      manageMenuListType: [{ name: "กรองสินค้า", btn: "btnFilterOrder" },
      { name: "ปริ้นใบแพ็ค", btn: "btnPrintBarcode" },
      { name: "ปริ้นใบปะหน้า", btn: "btnPrintAwb" },
      { name: "เปลี่ยนสถานะ", btn: "btnPackageChangeStatus" },
      { name: "รายงาน", btn: "btnViewReport" },],
      ManageChangeStatusList: [{ ID: "80", Name: "รอขนส่งเข้ารับ" },
      { ID: "90", Name: "กำลังจัดส่ง" },
      { ID: "130", Name: "จัดส่งสำเร็จ" },
      { ID: "140", Name: "ยกเลิก" },],
      ManageBackStatusList: [{ ID: "30", Name: "รอนัดรับ" }],
      offsets: 0,
      limits: 10,
      pageLength: 1,
      total: 0,
      selected: [],
      keyword: {},
      search: {
        select_per_page: 10,
        select_per: [
          { value: 5, name: "5 คำสั่งซื้อ" },
          { value: 10, name: "10 คำสั่งซื้อ" },
          { value: 25, name: "25 คำสั่งซื้อ" },
          { value: 50, name: "50 คำสั่งซื้อ" },
          { value: 100, name: "100 คำสั่งซื้อ" },
          { value: 999999, name: "ทั้งหมด" },
        ],
        search_by: '',
        select_channel: { channelID: 0, channelName: "--ทุกช่องทาง--" },
        list_channel: [{ channelID: 0, channelName: "--ทุกช่องทาง--" }],
        select_express: { expressCompanyID: null, expressCompanyName: " -- ทั้งหมด --" },
        list_express: [{ expressCompanyID: null, expressCompanyName: " -- ทั้งหมด --", },],
        select_print: { id: null, name: " -- ทั้งหมด --" },
        list_print: [{ id: null, name: " -- ทั้งหมด --" }],
        select_topic: { id: 1, name: "เลขออเดอร์", value: "orderId" },
        select_header: [
          { id: 0, name: "เลขออเดอร์", value: "orderId" },
          { id: 1, name: "เลขอ้างอิง", value: "referenceNumber" },
          { id: 2, name: "ชื่อผู้รับ", value: "receiverName" },
        ],
        text_topic: "",

      }
    },
    dataset_process_packing: {
      headers: [
        {
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          width: "70px",
        },
        {
          text: "ออเดอร์",
          align: "center",
          sortable: false,
          value: "id",
          width: "110px",
        },
        {
          text: "วันที่สร้าง",
          align: "center",
          sortable: false,
          value: "createdDatetime",
          width: "160px",
        },
        {
          text: "วันที่พร้อมแพ็ค",
          align: "center",
          sortable: false,
          value: "readyToPackDate",
          width: "160px",
        },
        {
          text: "ร้านค้า",
          align: "center",
          sortable: false,
          value: "shopName",
          width: "90px",
        },
        {
          text: "จำนวน SKU",
          align: "center",
          sortable: false,
          value: "skuCount",
          width: "90px",
        },
        {
          text: "จำนวนชิ้น",
          align: "center",
          sortable: false,
          value: "productCount",
          width: "90px",
        },
        {
          text: "ช่องทาง",
          align: "center",
          sortable: false,
          value: "channelName",
          width: "90px",
        },
        {
          text: "ขนส่ง",
          align: "center",
          sortable: false,
          value: "expressCompanyName",
          width: "90px",
        },
        {
          text: "เลขอ้างอิง",
          align: "left",
          sortable: false,
          value: "referenceNumber",
          width: "110px",
        },
        {
          text: "TrackingNo",
          align: "left",
          sortable: false,
          value: "trackingNo",
          width: "110px",
        },
        {
          text: "นัดรับ",
          align: "center",
          sortable: false,
          value: "isInit",
          width: "50px",
        },
        {
          text: "ใบแพ็ค",
          align: "center",
          sortable: false,
          value: "isPrintPackingList",
          width: "70px",
        },
        {
          text: "ใบปะหน้า",
          align: "center",
          sortable: false,
          value: "isPrintAWB",
          width: "70px",
        },
        {
          text: "น้ำหนัก",
          align: "center",
          sortable: false,
          value: "weight",
          width: "90px",
        },
        {
          text: "ขนาด",
          align: "center",
          sortable: false,
          value: "boxSize",
          width: "100px",
        },
        {
          text: "กล่อง",
          align: "center",
          sortable: false,
          value: "boxName",
          width: "100px",
        },
        {
          text: "กำลังแพ็ค",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "แพ็คเสร็จโดย",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "เริ่มแพ็ค",
          align: "center",
          sortable: false,
          value: "startPackingDate",
          width: "160px",
        },
        {
          text: "แพ็คเสร็จ",
          align: "center",
          sortable: false,
          value: "endPackingDate",
          width: "160px",
        },
        {
          text: "ลูกค้า",
          align: "left",
          sortable: false,
          value: "receiverName",
          width: "200px",
        },
      ],
      dataTable: [],
      page: 1,
      manageMenuListType: [{ name: "กรองสินค้า", btn: "btnFilterOrder" },
      { name: "ปริ้นใบแพ็ค", btn: "btnPrintBarcode" },
      { name: "ปริ้นใบปะหน้า", btn: "btnPrintAwb" },
      { name: "เปลี่ยนสถานะ", btn: "btnPackageChangeStatus" },
      { name: "รายงาน", btn: "btnViewReport" },],
      ManageChangeStatusList: [{ ID: "80", Name: "รอขนส่งเข้ารับ" },
      { ID: "90", Name: "กำลังจัดส่ง" },
      { ID: "130", Name: "จัดส่งสำเร็จ" },
      { ID: "140", Name: "ยกเลิก" },],
      ManageBackStatusList: [{ ID: "30", Name: "รอนัดรับ" }],
      offsets: 0,
      limits: 10,
      pageLength: 1,
      total: 0,
      selected: [],
      keyword: {},
      search: {
        select_per_page: 10,
        select_per: [
          { value: 5, name: "5 คำสั่งซื้อ" },
          { value: 10, name: "10 คำสั่งซื้อ" },
          { value: 25, name: "25 คำสั่งซื้อ" },
          { value: 50, name: "50 คำสั่งซื้อ" },
          { value: 100, name: "100 คำสั่งซื้อ" },
          { value: 999999, name: "ทั้งหมด" },
        ],
        search_by: '',
        select_channel: { channelID: 0, channelName: "--ทุกช่องทาง--" },
        list_channel: [{ channelID: 0, channelName: "--ทุกช่องทาง--" }],
        select_express: { expressCompanyID: null, expressCompanyName: " -- ทั้งหมด --" },
        list_express: [{ expressCompanyID: null, expressCompanyName: " -- ทั้งหมด --", },],
        select_print: { id: null, name: " -- ทั้งหมด --" },
        list_print: [{ id: null, name: " -- ทั้งหมด --" }],
        select_topic: { id: 1, name: "เลขออเดอร์", value: "orderId" },
        select_header: [
          { id: 0, name: "เลขออเดอร์", value: "orderId" },
          { id: 1, name: "เลขอ้างอิง", value: "referenceNumber" },
          { id: 2, name: "ชื่อผู้รับ", value: "receiverName" },
        ],
        text_topic: "",

      }
    },
    dataset_process_wait_pickup: {
      headers: [
        {
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          width: "70px",
        },
        {
          text: "ออเดอร์",
          align: "center",
          sortable: false,
          value: "id",
          width: "110px",
        },
        {
          text: "วันที่สร้าง",
          align: "center",
          sortable: false,
          value: "createdDatetime",
          width: "160px",
        },
        {
          text: "วันที่พร้อมแพ็ค",
          align: "center",
          sortable: false,
          value: "readyToPackDate",
          width: "160px",
        },
        {
          text: "ร้านค้า",
          align: "center",
          sortable: false,
          value: "shopName",
          width: "90px",
        },
        {
          text: "จำนวน SKU",
          align: "center",
          sortable: false,
          value: "skuCount",
          width: "90px",
        },
        {
          text: "จำนวนชิ้น",
          align: "center",
          sortable: false,
          value: "productCount",
          width: "90px",
        },
        {
          text: "ช่องทาง",
          align: "center",
          sortable: false,
          value: "channelName",
          width: "90px",
        },
        {
          text: "ขนส่ง",
          align: "center",
          sortable: false,
          value: "expressCompanyName",
          width: "90px",
        },
        {
          text: "เลขอ้างอิง",
          align: "left",
          sortable: false,
          value: "referenceNumber",
          width: "110px",
        },
        {
          text: "TrackingNo",
          align: "left",
          sortable: false,
          value: "trackingNo",
          width: "110px",
        },
        {
          text: "นัดรับ",
          align: "center",
          sortable: false,
          value: "isInit",
          width: "50px",
        },
        {
          text: "ใบแพ็ค",
          align: "center",
          sortable: false,
          value: "isPrintPackingList",
          width: "70px",
        },
        {
          text: "ใบปะหน้า",
          align: "center",
          sortable: false,
          value: "isPrintAWB",
          width: "70px",
        },
        {
          text: "น้ำหนัก",
          align: "center",
          sortable: false,
          value: "weight",
          width: "90px",
        },
        {
          text: "ขนาด",
          align: "center",
          sortable: false,
          value: "boxSize",
          width: "100px",
        },
        {
          text: "กล่อง",
          align: "center",
          sortable: false,
          value: "boxName",
          width: "100px",
        },
        {
          text: "กำลังแพ็ค",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "แพ็คเสร็จโดย",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "เริ่มแพ็ค",
          align: "center",
          sortable: false,
          value: "startPackingDate",
          width: "160px",
        },
        {
          text: "แพ็คเสร็จ",
          align: "center",
          sortable: false,
          value: "endPackingDate",
          width: "160px",
        },
        {
          text: "ลูกค้า",
          align: "left",
          sortable: false,
          value: "receiverName",
          width: "200px",
        },
      ],
      dataTable: [],
      page: 1,
      manageMenuListType: [{ name: "ปริ้นใบปะหน้า", btn: "btnPrintAwb" },
      { name: "เปลี่ยนสถานะ", btn: "btnPackageChangeStatus" },
      { name: "ย้อนสถานะ", btn: "btnPackageBackStatus" },
      { name: "รายงาน", btn: "btnViewReport" }],
      ManageChangeStatusList: [{ ID: "90", Name: "กำลังจัดส่ง" },
      { ID: "130", Name: "จัดส่งสำเร็จ" },
      { ID: "140", Name: "ยกเลิก" },],
      ManageBackStatusList: [{ ID: 50, Name: "รอแพ็ค" }],
      offsets: 0,
      limits: 10,
      pageLength: 1,
      total: 0,
      selected: [],
      keyword: {},
      search: {
        select_per_page: 10,
        select_per: [
          { value: 5, name: "5 คำสั่งซื้อ" },
          { value: 10, name: "10 คำสั่งซื้อ" },
          { value: 25, name: "25 คำสั่งซื้อ" },
          { value: 50, name: "50 คำสั่งซื้อ" },
          { value: 100, name: "100 คำสั่งซื้อ" },
          { value: 999999, name: "ทั้งหมด" },
        ],
        search_by: '',
        select_channel: { channelID: 0, channelName: "--ทุกช่องทาง--" },
        list_channel: [{ channelID: 0, channelName: "--ทุกช่องทาง--" }],
        select_express: { expressCompanyID: null, expressCompanyName: " -- ทั้งหมด --" },
        list_express: [{ expressCompanyID: null, expressCompanyName: " -- ทั้งหมด --", },],
        select_print: { id: null, name: " -- ทั้งหมด --" },
        list_print: [{ id: null, name: " -- ทั้งหมด --" }],
        select_topic: { id: 1, name: "เลขออเดอร์", value: "orderId" },
        select_header: [
          { id: 0, name: "เลขออเดอร์", value: "orderId" },
          { id: 1, name: "เลขอ้างอิง", value: "referenceNumber" },
          { id: 2, name: "ชื่อผู้รับ", value: "receiverName" },
        ],
        text_topic: "",

      }
    },

    dataset_delivering: {
      headers: [
        {
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          width: "70px",
        },
        {
          text: "ออเดอร์",
          align: "center",
          sortable: false,
          value: "id",
          width: "110px",
        },
        {
          text: "วันที่สร้าง",
          align: "center",
          sortable: false,
          value: "createdDatetime",
          width: "160px",
        },
        {
          text: "วันที่พร้อมแพ็ค",
          align: "center",
          sortable: false,
          value: "readyToPackDate",
          width: "160px",
        },
        {
          text: "ร้านค้า",
          align: "center",
          sortable: false,
          value: "shopName",
          width: "90px",
        },
        {
          text: "จำนวน SKU",
          align: "center",
          sortable: false,
          value: "skuCount",
          width: "90px",
        },
        {
          text: "จำนวนชิ้น",
          align: "center",
          sortable: false,
          value: "productCount",
          width: "90px",
        },
        {
          text: "ช่องทาง",
          align: "center",
          sortable: false,
          value: "channelName",
          width: "90px",
        },
        {
          text: "ขนส่ง",
          align: "center",
          sortable: false,
          value: "expressCompanyName",
          width: "90px",
        },
        {
          text: "เลขอ้างอิง",
          align: "left",
          sortable: false,
          value: "referenceNumber",
          width: "110px",
        },
        {
          text: "TrackingNo",
          align: "left",
          sortable: false,
          value: "trackingNo",
          width: "110px",
        },
        {
          text: "นัดรับ",
          align: "center",
          sortable: false,
          value: "isInit",
          width: "50px",
        },
        {
          text: "ใบแพ็ค",
          align: "center",
          sortable: false,
          value: "isPrintPackingList",
          width: "70px",
        },
        {
          text: "ใบปะหน้า",
          align: "center",
          sortable: false,
          value: "isPrintAWB",
          width: "70px",
        },
        {
          text: "น้ำหนัก",
          align: "center",
          sortable: false,
          value: "weight",
          width: "90px",
        },
        {
          text: "ขนาด",
          align: "center",
          sortable: false,
          value: "boxSize",
          width: "100px",
        },
        {
          text: "กล่อง",
          align: "center",
          sortable: false,
          value: "boxName",
          width: "100px",
        },
        {
          text: "กำลังแพ็ค",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "แพ็คเสร็จโดย",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "เริ่มแพ็ค",
          align: "center",
          sortable: false,
          value: "startPackingDate",
          width: "160px",
        },
        {
          text: "แพ็คเสร็จ",
          align: "center",
          sortable: false,
          value: "endPackingDate",
          width: "160px",
        },
        {
          text: "ลูกค้า",
          align: "left",
          sortable: false,
          value: "receiverName",
          width: "200px",
        },
      ],
      dataTable: [],
      page: 1,
      manageMenuListType: [{ name: "เปลี่ยนสถานะ", btn: "btnPackageChangeStatus" },
      { name: "ย้อนสถานะ", btn: "btnPackageBackStatus" },
      { name: "รายงาน", btn: "btnViewReport" },],
      ManageChangeStatusList: [{ ID: "100", Name: "กำลังตีกลับ" },
      { ID: "110", Name: "ตีกลับแล้วรอคืนสต๊อก" },
      { ID: "130", Name: "จัดส่งสำเร็จ" },
      { ID: "140", Name: "ยกเลิก" },],
      ManageBackStatusList: [{ ID: "80", Name: "รอขนส่งเข้ารับ" },],
      offsets: 0,
      limits: 10,
      pageLength: 1,
      total: 0,
      selected: [],
      keyword: {},
      search: {
        select_per_page: 10,
        select_per: [
          { value: 5, name: "5 คำสั่งซื้อ" },
          { value: 10, name: "10 คำสั่งซื้อ" },
          { value: 25, name: "25 คำสั่งซื้อ" },
          { value: 50, name: "50 คำสั่งซื้อ" },
          { value: 100, name: "100 คำสั่งซื้อ" },
          { value: 999999, name: "ทั้งหมด" },
        ],
        search_by: '',
        select_channel: { channelID: 0, channelName: "--ทุกช่องทาง--" },
        list_channel: [{ channelID: 0, channelName: "--ทุกช่องทาง--" }],
        select_express: { expressCompanyID: null, expressCompanyName: " -- ทั้งหมด --" },
        list_express: [{ expressCompanyID: null, expressCompanyName: " -- ทั้งหมด --", },],
        select_print: { id: null, name: " -- ทั้งหมด --" },
        list_print: [{ id: null, name: " -- ทั้งหมด --" }],
        select_topic: { id: 1, name: "เลขออเดอร์", value: "orderId" },
        select_header: [
          { id: 0, name: "เลขออเดอร์", value: "orderId" },
          { id: 1, name: "เลขอ้างอิง", value: "referenceNumber" },
          { id: 2, name: "ชื่อผู้รับ", value: "receiverName" },
        ],
        text_topic: "",

      }
    },
    dataset_success: {
      headers: [
        {
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          width: "70px",
        },
        {
          text: "ออเดอร์",
          align: "center",
          sortable: false,
          value: "id",
          width: "110px",
        },
        {
          text: "วันที่สร้าง",
          align: "center",
          sortable: false,
          value: "createdDatetime",
          width: "160px",
        },
        {
          text: "วันที่พร้อมแพ็ค",
          align: "center",
          sortable: false,
          value: "readyToPackDate",
          width: "160px",
        },
        {
          text: "ร้านค้า",
          align: "center",
          sortable: false,
          value: "shopName",
          width: "90px",
        },
        {
          text: "จำนวน SKU",
          align: "center",
          sortable: false,
          value: "skuCount",
          width: "90px",
        },
        {
          text: "จำนวนชิ้น",
          align: "center",
          sortable: false,
          value: "productCount",
          width: "90px",
        },
        {
          text: "ช่องทาง",
          align: "center",
          sortable: false,
          value: "channelName",
          width: "90px",
        },
        {
          text: "ขนส่ง",
          align: "center",
          sortable: false,
          value: "expressCompanyName",
          width: "90px",
        },
        {
          text: "เลขอ้างอิง",
          align: "left",
          sortable: false,
          value: "referenceNumber",
          width: "110px",
        },
        {
          text: "TrackingNo",
          align: "left",
          sortable: false,
          value: "trackingNo",
          width: "110px",
        },
        {
          text: "นัดรับ",
          align: "center",
          sortable: false,
          value: "isInit",
          width: "50px",
        },
        {
          text: "ใบแพ็ค",
          align: "center",
          sortable: false,
          value: "isPrintPackingList",
          width: "70px",
        },
        {
          text: "ใบปะหน้า",
          align: "center",
          sortable: false,
          value: "isPrintAWB",
          width: "70px",
        },
        {
          text: "น้ำหนัก",
          align: "center",
          sortable: false,
          value: "weight",
          width: "90px",
        },
        {
          text: "ขนาด",
          align: "center",
          sortable: false,
          value: "boxSize",
          width: "100px",
        },
        {
          text: "กล่อง",
          align: "center",
          sortable: false,
          value: "boxName",
          width: "100px",
        },
        {
          text: "กำลังแพ็ค",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "แพ็คเสร็จโดย",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "เริ่มแพ็ค",
          align: "center",
          sortable: false,
          value: "startPackingDate",
          width: "160px",
        },
        {
          text: "แพ็คเสร็จ",
          align: "center",
          sortable: false,
          value: "endPackingDate",
          width: "160px",
        },
        {
          text: "ลูกค้า",
          align: "left",
          sortable: false,
          value: "receiverName",
          width: "200px",
        },
      ],
      dataTable: [],
      page: 1,
      manageMenuListType: [{ name: "รายงาน", btn: "btnViewReport" },],
      ManageChangeStatusList: [],
      ManageBackStatusList: [],
      offsets: 0,
      limits: 10,
      pageLength: 1,
      total: 0,
      selected: [],
      keyword: {},
      search: {
        select_per_page: 10,
        select_per: [
          { value: 5, name: "5 คำสั่งซื้อ" },
          { value: 10, name: "10 คำสั่งซื้อ" },
          { value: 25, name: "25 คำสั่งซื้อ" },
          { value: 50, name: "50 คำสั่งซื้อ" },
          { value: 100, name: "100 คำสั่งซื้อ" },
          { value: 999999, name: "ทั้งหมด" },
        ],
        search_by: '',
        select_channel: { channelID: 0, channelName: "--ทุกช่องทาง--" },
        list_channel: [{ channelID: 0, channelName: "--ทุกช่องทาง--" }],
        select_express: { expressCompanyID: null, expressCompanyName: " -- ทั้งหมด --" },
        list_express: [{ expressCompanyID: null, expressCompanyName: " -- ทั้งหมด --", },],
        select_print: { id: null, name: " -- ทั้งหมด --" },
        list_print: [{ id: null, name: " -- ทั้งหมด --" }],
        select_topic: { id: 1, name: "เลขออเดอร์", value: "orderId" },
        select_header: [
          { id: 0, name: "เลขออเดอร์", value: "orderId" },
          { id: 1, name: "เลขอ้างอิง", value: "referenceNumber" },
          { id: 2, name: "ชื่อผู้รับ", value: "receiverName" },
        ],
        text_topic: "",

      }
    },


    set_TabListSubHeader_Return: '100',
    dataset_Return_Returnning: {
      headers: [
        {
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          width: "70px",
        },
        {
          text: "ออเดอร์",
          align: "center",
          sortable: false,
          value: "id",
          width: "110px",
        },
        {
          text: "วันที่สร้าง",
          align: "center",
          sortable: false,
          value: "createdDatetime",
          width: "160px",
        },
        {
          text: "วันที่พร้อมแพ็ค",
          align: "center",
          sortable: false,
          value: "readyToPackDate",
          width: "160px",
        },
        {
          text: "ร้านค้า",
          align: "center",
          sortable: false,
          value: "shopName",
          width: "90px",
        },
        {
          text: "จำนวน SKU",
          align: "center",
          sortable: false,
          value: "skuCount",
          width: "90px",
        },
        {
          text: "จำนวนชิ้น",
          align: "center",
          sortable: false,
          value: "productCount",
          width: "90px",
        },
        {
          text: "ช่องทาง",
          align: "center",
          sortable: false,
          value: "channelName",
          width: "90px",
        },
        {
          text: "ขนส่ง",
          align: "center",
          sortable: false,
          value: "expressCompanyName",
          width: "90px",
        },
        {
          text: "เลขอ้างอิง",
          align: "left",
          sortable: false,
          value: "referenceNumber",
          width: "110px",
        },
        {
          text: "TrackingNo",
          align: "left",
          sortable: false,
          value: "trackingNo",
          width: "110px",
        },
        {
          text: "นัดรับ",
          align: "center",
          sortable: false,
          value: "isInit",
          width: "50px",
        },
        {
          text: "ใบแพ็ค",
          align: "center",
          sortable: false,
          value: "isPrintPackingList",
          width: "70px",
        },
        {
          text: "ใบปะหน้า",
          align: "center",
          sortable: false,
          value: "isPrintAWB",
          width: "70px",
        },
        {
          text: "น้ำหนัก",
          align: "center",
          sortable: false,
          value: "weight",
          width: "90px",
        },
        {
          text: "ขนาด",
          align: "center",
          sortable: false,
          value: "boxSize",
          width: "100px",
        },
        {
          text: "กล่อง",
          align: "center",
          sortable: false,
          value: "boxName",
          width: "100px",
        },
        {
          text: "กำลังแพ็ค",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "แพ็คเสร็จโดย",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "เริ่มแพ็ค",
          align: "center",
          sortable: false,
          value: "startPackingDate",
          width: "160px",
        },
        {
          text: "แพ็คเสร็จ",
          align: "center",
          sortable: false,
          value: "endPackingDate",
          width: "160px",
        },
        {
          text: "ลูกค้า",
          align: "left",
          sortable: false,
          value: "receiverName",
          width: "200px",
        },
      ],
      dataTable: [],
      page: 1,
      manageMenuListType: [{ name: "เปลี่ยนสถานะ", btn: "btnPackageChangeStatus" },
      { name: "ย้อนสถานะ", btn: "btnPackageBackStatus" },
      { name: "รายงาน", btn: "btnViewReport" },],
      ManageChangeStatusList: [{ ID: "110", Name: "ตีกลับแล้วรอคืนสต๊อก" },],
      ManageBackStatusList: [],
      offsets: 0,
      limits: 10,
      pageLength: 1,
      total: 0,
      selected: [],
      keyword: {},
      search: {
        select_per_page: 10,
        select_per: [
          { value: 5, name: "5 คำสั่งซื้อ" },
          { value: 10, name: "10 คำสั่งซื้อ" },
          { value: 25, name: "25 คำสั่งซื้อ" },
          { value: 50, name: "50 คำสั่งซื้อ" },
          { value: 100, name: "100 คำสั่งซื้อ" },
          { value: 999999, name: "ทั้งหมด" },
        ],
        search_by: '',
        select_channel: { channelID: 0, channelName: "--ทุกช่องทาง--" },
        list_channel: [{ channelID: 0, channelName: "--ทุกช่องทาง--" }],
        select_express: { expressCompanyID: null, expressCompanyName: " -- ทั้งหมด --" },
        list_express: [{ expressCompanyID: null, expressCompanyName: " -- ทั้งหมด --", },],
        select_print: { id: null, name: " -- ทั้งหมด --" },
        list_print: [{ id: null, name: " -- ทั้งหมด --" }],
        select_topic: { id: 1, name: "เลขออเดอร์", value: "orderId" },
        select_header: [
          { id: 0, name: "เลขออเดอร์", value: "orderId" },
          { id: 1, name: "เลขอ้างอิง", value: "referenceNumber" },
          { id: 2, name: "ชื่อผู้รับ", value: "receiverName" },
        ],
        text_topic: "",

      }
    },
    dataset_Return_To_Return_Stock: {
      headers: [
        {
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          width: "70px",
        },
        {
          text: "ออเดอร์",
          align: "center",
          sortable: false,
          value: "id",
          width: "110px",
        },
        {
          text: "วันที่สร้าง",
          align: "center",
          sortable: false,
          value: "createdDatetime",
          width: "160px",
        },
        {
          text: "วันที่พร้อมแพ็ค",
          align: "center",
          sortable: false,
          value: "readyToPackDate",
          width: "160px",
        },
        {
          text: "ร้านค้า",
          align: "center",
          sortable: false,
          value: "shopName",
          width: "90px",
        },
        {
          text: "จำนวน SKU",
          align: "center",
          sortable: false,
          value: "skuCount",
          width: "90px",
        },
        {
          text: "จำนวนชิ้น",
          align: "center",
          sortable: false,
          value: "productCount",
          width: "90px",
        },
        {
          text: "ช่องทาง",
          align: "center",
          sortable: false,
          value: "channelName",
          width: "90px",
        },
        {
          text: "ขนส่ง",
          align: "center",
          sortable: false,
          value: "expressCompanyName",
          width: "90px",
        },
        {
          text: "เลขอ้างอิง",
          align: "left",
          sortable: false,
          value: "referenceNumber",
          width: "110px",
        },
        {
          text: "TrackingNo",
          align: "left",
          sortable: false,
          value: "trackingNo",
          width: "110px",
        },
        {
          text: "นัดรับ",
          align: "center",
          sortable: false,
          value: "isInit",
          width: "50px",
        },
        {
          text: "ใบแพ็ค",
          align: "center",
          sortable: false,
          value: "isPrintPackingList",
          width: "70px",
        },
        {
          text: "ใบปะหน้า",
          align: "center",
          sortable: false,
          value: "isPrintAWB",
          width: "70px",
        },
        {
          text: "น้ำหนัก",
          align: "center",
          sortable: false,
          value: "weight",
          width: "90px",
        },
        {
          text: "ขนาด",
          align: "center",
          sortable: false,
          value: "boxSize",
          width: "100px",
        },
        {
          text: "กล่อง",
          align: "center",
          sortable: false,
          value: "boxName",
          width: "100px",
        },
        {
          text: "กำลังแพ็ค",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "แพ็คเสร็จโดย",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "เริ่มแพ็ค",
          align: "center",
          sortable: false,
          value: "startPackingDate",
          width: "160px",
        },
        {
          text: "แพ็คเสร็จ",
          align: "center",
          sortable: false,
          value: "endPackingDate",
          width: "160px",
        },
        {
          text: "ลูกค้า",
          align: "left",
          sortable: false,
          value: "receiverName",
          width: "200px",
        },
      ],
      dataTable: [],
      page: 1,
      manageMenuListType: [{ name: "รายงาน", btn: "btnViewReport" },
      { name: "สต๊อก", btn: "" }],
      ManageChangeStatusList: [],
      ManageBackStatusList: [],
      offsets: 0,
      limits: 10,
      pageLength: 1,
      total: 0,
      selected: [],
      keyword: {},
      search: {
        select_per_page: 10,
        select_per: [
          { value: 5, name: "5 คำสั่งซื้อ" },
          { value: 10, name: "10 คำสั่งซื้อ" },
          { value: 25, name: "25 คำสั่งซื้อ" },
          { value: 50, name: "50 คำสั่งซื้อ" },
          { value: 100, name: "100 คำสั่งซื้อ" },
          { value: 999999, name: "ทั้งหมด" },
        ],
        search_by: '',
        select_channel: { channelID: 0, channelName: "--ทุกช่องทาง--" },
        list_channel: [{ channelID: 0, channelName: "--ทุกช่องทาง--" }],
        select_express: { expressCompanyID: null, expressCompanyName: " -- ทั้งหมด --" },
        list_express: [{ expressCompanyID: null, expressCompanyName: " -- ทั้งหมด --", },],
        select_print: { id: null, name: " -- ทั้งหมด --" },
        list_print: [{ id: null, name: " -- ทั้งหมด --" }],
        select_topic: { id: 1, name: "เลขออเดอร์", value: "orderId" },
        select_header: [
          { id: 0, name: "เลขออเดอร์", value: "orderId" },
          { id: 1, name: "เลขอ้างอิง", value: "referenceNumber" },
          { id: 2, name: "ชื่อผู้รับ", value: "receiverName" },
        ],
        text_topic: "",

      }
    },
    dataset_Return_Stock_Returned: {
      headers: [
        {
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          width: "70px",
        },
        {
          text: "ออเดอร์",
          align: "center",
          sortable: false,
          value: "id",
          width: "110px",
        },
        {
          text: "วันที่สร้าง",
          align: "center",
          sortable: false,
          value: "createdDatetime",
          width: "160px",
        },
        {
          text: "วันที่พร้อมแพ็ค",
          align: "center",
          sortable: false,
          value: "readyToPackDate",
          width: "160px",
        },
        {
          text: "ร้านค้า",
          align: "center",
          sortable: false,
          value: "shopName",
          width: "90px",
        },
        {
          text: "จำนวน SKU",
          align: "center",
          sortable: false,
          value: "skuCount",
          width: "90px",
        },
        {
          text: "จำนวนชิ้น",
          align: "center",
          sortable: false,
          value: "productCount",
          width: "90px",
        },
        {
          text: "ช่องทาง",
          align: "center",
          sortable: false,
          value: "channelName",
          width: "90px",
        },
        {
          text: "ขนส่ง",
          align: "center",
          sortable: false,
          value: "expressCompanyName",
          width: "90px",
        },
        {
          text: "เลขอ้างอิง",
          align: "left",
          sortable: false,
          value: "referenceNumber",
          width: "110px",
        },
        {
          text: "TrackingNo",
          align: "left",
          sortable: false,
          value: "trackingNo",
          width: "110px",
        },
        {
          text: "นัดรับ",
          align: "center",
          sortable: false,
          value: "isInit",
          width: "50px",
        },
        {
          text: "ใบแพ็ค",
          align: "center",
          sortable: false,
          value: "isPrintPackingList",
          width: "70px",
        },
        {
          text: "ใบปะหน้า",
          align: "center",
          sortable: false,
          value: "isPrintAWB",
          width: "70px",
        },
        {
          text: "น้ำหนัก",
          align: "center",
          sortable: false,
          value: "weight",
          width: "90px",
        },
        {
          text: "ขนาด",
          align: "center",
          sortable: false,
          value: "boxSize",
          width: "100px",
        },
        {
          text: "กล่อง",
          align: "center",
          sortable: false,
          value: "boxName",
          width: "100px",
        },
        {
          text: "กำลังแพ็ค",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "แพ็คเสร็จโดย",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "เริ่มแพ็ค",
          align: "center",
          sortable: false,
          value: "startPackingDate",
          width: "160px",
        },
        {
          text: "แพ็คเสร็จ",
          align: "center",
          sortable: false,
          value: "endPackingDate",
          width: "160px",
        },
        {
          text: "ลูกค้า",
          align: "left",
          sortable: false,
          value: "receiverName",
          width: "200px",
        },
      ],
      dataTable: [],
      page: 1,
      manageMenuListType: [{ name: "รายงาน", btn: "btnViewReport" }],
      ManageChangeStatusList: [],
      ManageBackStatusList: [],
      offsets: 0,
      limits: 10,
      pageLength: 1,
      total: 0,
      selected: [],
      keyword: {},
      search: {
        select_per_page: 10,
        select_per: [
          { value: 5, name: "5 คำสั่งซื้อ" },
          { value: 10, name: "10 คำสั่งซื้อ" },
          { value: 25, name: "25 คำสั่งซื้อ" },
          { value: 50, name: "50 คำสั่งซื้อ" },
          { value: 100, name: "100 คำสั่งซื้อ" },
          { value: 999999, name: "ทั้งหมด" },
        ],
        search_by: '',
        select_channel: { channelID: 0, channelName: "--ทุกช่องทาง--" },
        list_channel: [{ channelID: 0, channelName: "--ทุกช่องทาง--" }],
        select_express: { expressCompanyID: null, expressCompanyName: " -- ทั้งหมด --" },
        list_express: [{ expressCompanyID: null, expressCompanyName: " -- ทั้งหมด --", },],
        select_print: { id: null, name: " -- ทั้งหมด --" },
        list_print: [{ id: null, name: " -- ทั้งหมด --" }],
        select_topic: { id: 1, name: "เลขออเดอร์", value: "orderId" },
        select_header: [
          { id: 0, name: "เลขออเดอร์", value: "orderId" },
          { id: 1, name: "เลขอ้างอิง", value: "referenceNumber" },
          { id: 2, name: "ชื่อผู้รับ", value: "receiverName" },
        ],
        text_topic: "",

      }
    },
    dataset_cancel: {
      headers: [
        {
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          width: "70px",
        },
        {
          text: "ออเดอร์",
          align: "center",
          sortable: false,
          value: "id",
          width: "110px",
        },
        {
          text: "วันที่สร้าง",
          align: "center",
          sortable: false,
          value: "createdDatetime",
          width: "160px",
        },
        {
          text: "วันที่พร้อมแพ็ค",
          align: "center",
          sortable: false,
          value: "readyToPackDate",
          width: "160px",
        },
        {
          text: "ร้านค้า",
          align: "center",
          sortable: false,
          value: "shopName",
          width: "90px",
        },
        {
          text: "จำนวน SKU",
          align: "center",
          sortable: false,
          value: "skuCount",
          width: "90px",
        },
        {
          text: "จำนวนชิ้น",
          align: "center",
          sortable: false,
          value: "productCount",
          width: "90px",
        },
        {
          text: "ช่องทาง",
          align: "center",
          sortable: false,
          value: "channelName",
          width: "90px",
        },
        {
          text: "ขนส่ง",
          align: "center",
          sortable: false,
          value: "expressCompanyName",
          width: "90px",
        },
        {
          text: "เลขอ้างอิง",
          align: "left",
          sortable: false,
          value: "referenceNumber",
          width: "110px",
        },
        {
          text: "TrackingNo",
          align: "left",
          sortable: false,
          value: "trackingNo",
          width: "110px",
        },
        {
          text: "นัดรับ",
          align: "center",
          sortable: false,
          value: "isInit",
          width: "50px",
        },
        {
          text: "ใบแพ็ค",
          align: "center",
          sortable: false,
          value: "isPrintPackingList",
          width: "70px",
        },
        {
          text: "ใบปะหน้า",
          align: "center",
          sortable: false,
          value: "isPrintAWB",
          width: "70px",
        },
        {
          text: "น้ำหนัก",
          align: "center",
          sortable: false,
          value: "weight",
          width: "90px",
        },
        {
          text: "ขนาด",
          align: "center",
          sortable: false,
          value: "boxSize",
          width: "100px",
        },
        {
          text: "กล่อง",
          align: "center",
          sortable: false,
          value: "boxName",
          width: "100px",
        },
        {
          text: "กำลังแพ็ค",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "แพ็คเสร็จโดย",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "เริ่มแพ็ค",
          align: "center",
          sortable: false,
          value: "startPackingDate",
          width: "160px",
        },
        {
          text: "แพ็คเสร็จ",
          align: "center",
          sortable: false,
          value: "endPackingDate",
          width: "160px",
        },
        {
          text: "ลูกค้า",
          align: "left",
          sortable: false,
          value: "receiverName",
          width: "200px",
        },
      ],
      dataTable: [],
      page: 1,
      manageMenuListType: [{ name: "ย้อนสถานะ", btn: "btnPackageBackStatus" },
      { name: "รายงาน", btn: "btnViewReport" }],
      ManageChangeStatusList: [],
      ManageBackStatusList: [{ ID: 50, Name: "รอแพ็ค" }],
      offsets: 0,
      limits: 10,
      pageLength: 1,
      total: 0,
      selected: [],
      keyword: {},
      search: {
        select_per_page: 10,
        select_per: [
          { value: 5, name: "5 คำสั่งซื้อ" },
          { value: 10, name: "10 คำสั่งซื้อ" },
          { value: 25, name: "25 คำสั่งซื้อ" },
          { value: 50, name: "50 คำสั่งซื้อ" },
          { value: 100, name: "100 คำสั่งซื้อ" },
          { value: 999999, name: "ทั้งหมด" },
        ],
        search_by: '',
        select_channel: { channelID: 0, channelName: "--ทุกช่องทาง--" },
        list_channel: [{ channelID: 0, channelName: "--ทุกช่องทาง--" }],
        select_express: { expressCompanyID: null, expressCompanyName: " -- ทั้งหมด --" },
        list_express: [{ expressCompanyID: null, expressCompanyName: " -- ทั้งหมด --", },],
        select_print: { id: null, name: " -- ทั้งหมด --" },
        list_print: [{ id: null, name: " -- ทั้งหมด --" }],
        select_topic: { id: 1, name: "เลขออเดอร์", value: "orderId" },
        select_header: [
          { id: 0, name: "เลขออเดอร์", value: "orderId" },
          { id: 1, name: "เลขอ้างอิง", value: "referenceNumber" },
          { id: 2, name: "ชื่อผู้รับ", value: "receiverName" },
        ],
        text_topic: "",

      }
    },


    //กรองสินค้า
    filteOrderDataSet: {
      selected: [],
      filteOrderList: [],
      dataset: [],
      productList: [],
      mapProductList: [],
      tab_list: [
        { id: 0, name: "กรองสินค้า" },
        { id: 1, name: "จำนวน SKU ในออเดอร์" },
      ],
      tab: 0,
      header: [
        {
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          width: "70px",
        },
        {
          text: "ชื่อ",
          align: "left",
          sortable: false,
          value: "productName",
          width: "250px",
        },
        {
          text: "Barcode",
          align: "center",
          sortable: false,
          value: "barcode",
          width: "75px",
        },
        {
          text: "จำนวนออเดอร์ ",
          align: "center",
          sortable: false,
          value: "orderCount",
          width: "85px",
        },
        {
          text: "จำนวนต้องแพ็ค ",
          align: "center",
          sortable: false,
          value: "quantityToPack",
          width: "85px",
        },
        {
          text: "สต๊อกที่มี",
          align: "center",
          sortable: false,
          value: "currentQuantity",
          width: "85px",
        },
        {
          text: "ขาดสต๊อก",
          align: "center",
          sortable: false,
          value: "outStock",
          width: "85px",
        },
        {
          text: "SKU",
          align: "center",
          sortable: false,
          value: "sku",
          width: "90px",
        },
        {
          text: "รหัส CF",
          align: "center",
          sortable: false,
          value: "cfCode",
          width: "90px",
        },
        {
          text: "ชั้นวาง",
          align: "center",
          sortable: false,
          value: "rackNo",
          width: "90px",
        },
      ],
      headerExcel: [
        { label: "No", field: "no", width: "25" },
        { label: "ชื่อ", field: "productName", width: "10" },
        { label: "Barcode", field: "barcode", width: "10" },
        { label: "จำนวนออเดอร์", field: "orderCount", width: "10" },
        { label: "จำนวนต้องแพ็ค", field: "quantityToPack", width: "10" },
        { label: "สต๊อกที่มี", field: "currentQuantity", width: "10" },
        { label: "ขาดสต๊อก", field: "outStockShow", width: "10" },
        { label: "SKU", field: "sku", width: "10" },
        { label: "รหัส CF", field: "cfCode", width: "10" },
        { label: "ชั้นวาง", field: "rackNo", width: "10" },
      ],
      headerMapProductList: [
        {
          text: "จำนวน SKU",
          align: "center",
          sortable: false,
          value: "skuCount",
          width: "70px",
        },
        {
          text: "จำนวน ออเดอร์",
          align: "right",
          sortable: false,
          value: "orderCount",
          width: "100px",
        },
        {
          text: "ปริ้นใบหยิบแล้ว",
          align: "right",
          sortable: false,
          value: "PrintY",
          width: "100px",
        },
        {
          text: "ยังไม่ปริ้น",
          align: "right",
          sortable: false,
          value: "PrintN",
          width: "100px",
        },
      ],
      radio: [
        { id: 0, name: "ทั้งหมด" },
        { id: 1, name: "จาก" },
      ],
      radio_sku: 0,
      radio_item: 0,
      sku_start: 1,
      sku_end: 1,
      item_start: 1,
      item_end: 1,
    },
    filterOrderPopup: {
      dialog: false,
      MaxWidth: "80%",
      MaxHeight: 70,
    },
    //เปลี่ยนสถานะ
    changeStatusDataSet: {
      dataset: [],
      datasetError: [],
      datasetState: [],
      selected: [],
      productList: [],
      mapProductList: [],
      loading: false,
      loadingPercent: false,
      changeStatus: null,
      success: 0,
      error: 0,
      header: [
        {
          text: "เลขออเดอร์ ",
          align: "left",
          sortable: false,
          value: "orderIdText",
          width: "120px",
        },
        {
          text: "เลขอ้างอิง",
          align: "left",
          sortable: false,
          value: "referenceNumber",
          width: "120px",
        },
        {
          text: "ช่องทาง",
          align: "center",
          sortable: false,
          value: "referenceSourceLogo",
          width: "80px",
        },
        {
          text: "ขนส่ง",
          align: "center",
          sortable: false,
          value: "expressCompanyLogo",
          width: "80px",
        },
        {
          text: "TrackingNo ",
          align: "left",
          sortable: false,
          value: "trackingNo",
          width: "120px",
        },
        {
          text: "สถานะ",
          align: "left",
          sortable: false,
          value: "statusName",
          width: "100px",
        },
        {
          text: "การดำเนินงาน",
          align: "left",
          sortable: false,
          value: "processing",
          width: "150px",
        },
      ],
      headerState: [
        {
          text: "ลำดับ",
          align: "center",
          sortable: false,
          value: "no",
          width: "70px",
        },
        {
          text: "Order ID",
          align: "left",
          sortable: false,
          value: "orderMasterID",
          width: "80px",
        },
        {
          text: "สถานะดำเนินการ",
          align: "left",
          sortable: false,
          value: "name",
          width: "120px",
        },
        {
          text: "trackingNo",
          align: "center",
          sortable: false,
          value: "trackingNo",
          width: "75px",
        },
      ],
    },
    changeStatusPopup: {
      dialog: false,
      MaxWidth: "80%",
      MaxHeight: 70,
      tab_list: [
        { id: 0, name: "ดำเนินการ" },
        { id: 1, name: "ข้อมูลที่ไม่สำเร็จ" },
      ],
      tab: 0,
    },
    //ย้อนสถานะ
    backStatusDataSet: {
      dataset: [],
      datasetError: [],
      datasetState: [],
      selected: [],
      productList: [],
      mapProductList: [],
      loading: false,
      loadingPercent: false,
      changeStatus: null,
      success: 0,
      error: 0,
      header: [
        {
          text: "เลขออเดอร์ ",
          align: "left",
          sortable: false,
          value: "orderIdText",
          width: "120px",
        },
        {
          text: "เลขอ้างอิง",
          align: "left",
          sortable: false,
          value: "referenceNumber",
          width: "120px",
        },
        {
          text: "ช่องทาง",
          align: "center",
          sortable: false,
          value: "referenceSourceLogo",
          width: "80px",
        },
        {
          text: "ขนส่ง",
          align: "center",
          sortable: false,
          value: "expressCompanyLogo",
          width: "80px",
        },
        {
          text: "TrackingNo ",
          align: "left",
          sortable: false,
          value: "trackingNo",
          width: "120px",
        },
        {
          text: "สถานะ",
          align: "left",
          sortable: false,
          value: "statusName",
          width: "100px",
        },
        {
          text: "การดำเนินงาน",
          align: "left",
          sortable: false,
          value: "processing",
          width: "150px",
        },
      ],
      headerState: [
        {
          text: "ลำดับ",
          align: "center",
          sortable: false,
          value: "no",
          width: "70px",
        },
        {
          text: "Order ID",
          align: "left",
          sortable: false,
          value: "orderMasterID",
          width: "80px",
        },
        {
          text: "สถานะดำเนินการ",
          align: "left",
          sortable: false,
          value: "name",
          width: "120px",
        },
        {
          text: "trackingNo",
          align: "center",
          sortable: false,
          value: "trackingNo",
          width: "75px",
        },
      ],
    },
    backStatusPopup: {
      dialog: false,
      MaxWidth: "60%",
      MaxHeight: 70,
      tab_list: [
        { id: 0, name: "ดำเนินการ" },
        { id: 1, name: "ข้อมูลที่ไม่สำเร็จ" },
      ],
      tab: 0,
    },
    //อัพเดทไปmarketplace 
    UpdateToMarketplaceDataSet: {
      dataset: [],
      datasetError: [],
      datasetState: [],
      selected: [],
      productList: [],
      mapProductList: [],
      loading: false,
      loadingPercent: false,
      changeStatus: null,
      dropoff: null,
      pickup: null,
      addressID: null,
      selectedAddress: [],
      selectedpickupTime: [],
      pickupTimeID: [],
      shopeeType: 0,
      tiktokType: 0,
      success: 0,
      error: 0,
      handover_method: null,
      header: [
        {
          text: "ออเดอร์ ",
          align: "left",
          sortable: false,
          value: "orderMasterID",
        },
        {
          text: "เลขอ้างอิง",
          align: "left",
          sortable: false,
          value: "referenceNumber",
        },
        {
          text: "ช่องทาง",
          align: "left",
          sortable: false,
          value: "referenceSourceLogo",
        },
        {
          text: "บริการขนส่ง",
          align: "left",
          sortable: false,
          value: "expressCompanyLogo",
        },
        {
          text: "TrackingNo",
          align: "left",
          sortable: false,
          value: "trackingNo",
        },
        {
          text: "สถานะ",
          align: "left",
          sortable: false,
          value: "processing",
        },
      ],
    },
    UpdateToMarketplacePopup: {
      dialog: false,
      MaxWidth: "60%",
      MaxHeight: 70,
      marketplaceName: null,
      marketplaceType: 0,
      tab_list: [
        { id: 0, name: "ดำเนินการ" },
        { id: 1, name: "ข้อมูลที่ไม่สำเร็จ" },
      ],
      tab: 0,
    },
    //รายงาน
    reportOrderDataSet: {
      dataset: [],
      selected: [],
      productList: [],
      mapProductList: [],
      headerIN: [
        {
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          width: "70px",
          label: "No",
          field: "no",
        },
        {
          text: "รูป",
          align: "left",
          sortable: false,
          value: "photoLink",

          label: "รูป",
          field: "photoLink",
        },
        {
          text: "ชื่อ",
          align: "left",
          sortable: false,
          value: "name",

          label: "ชื่อ",
          field: "name",
        },
        {
          text: "จำนวน",
          align: "center",
          sortable: false,
          value: "quantity",
          width: "75px",
          label: "จำนวน",
          field: "quantity",
        },
        {
          text: "SKU",
          align: "center",
          sortable: false,
          value: "sku",
          width: "85px",
          label: "SKU",
          field: "sku",
        },
        {
          text: "barcode",
          align: "center",
          sortable: false,
          value: "barcode",
          width: "85px",
          label: "barcode",
          field: "barcode",
        },
        {
          text: "รหัส CF",
          align: "center",
          sortable: false,
          value: "cfCode",
          width: "90px",
          label: "รหัส CF",
          field: "cfCode",
        },
        {
          text: "ชั้นวาง",
          align: "center",
          sortable: false,
          value: "rackNo",
          width: "90px",
          label: "ชั้นวาง",
          field: "rackNo",
        },
      ],
      header: [],
    },
    reportOrderPopup: {
      dialog: false,
      MaxWidth: "50%",
      MaxHeight: 70,
    },
  }),
  computed: {
    computed_search_date() {
      return this.formatDate(this.search_date_range)
    },
    computed_search_sent_date() {
      return this.formatDate(this.sent_date_range)
    },
    computed_search_tranferred_date() {
      return this.formatDate(this.transferred_date_range)
    },

    showSubheader() {
      var data = this.tabListSubHeaders.find(s => s.id == this.set_TabListHeader)
      if (data != undefined) {
        return data.subItems
      } else {
        return []
      }

    },
    countreportOrderDataSet() {
      if (this.reportOrderDataSet.dataset.length > 0) {
        const totalQuantity = this.reportOrderDataSet.dataset.reduce((total, product) => {
          if (product.quantity !== undefined && product.quantity !== null) {
            return total + product.quantity;
          } else {
            return total;
          }
        }, 0);

        return totalQuantity;
      } else {
        return 0;
      }
    },
  },
  watch: {
    startDate: async function(newVal, oldVal) {
      if (new Date(newVal)>new Date(this.endDate)){
        this.checkStart = false
        this.startDate = oldVal
      }else if(this.checkStart){
        try {
          this.$refs.menuStartDate.save(this.startDate)
        }catch(err){
          console.log(err);
        }
      }else{
        this.checkStart = true
      }
    },
    endDate: async function(newVal, oldVal) {
      if (new Date(newVal)<new Date(this.startDate) || new Date(newVal)>new Date()){
        this.checkEnd = false
        this.endDate = oldVal
      }else if(this.checkEnd){
        try {
          this.$refs.menuEndDate.save(this.endDate)
        }catch(err){
          console.log(err);
        }
      }else{
        this.checkEnd = true
      }
    },
  },

  async created() {

    this.use_language = await this.get_languages(await localStorage.getItem("set_language"));
    await this.Load_Data();
    await this.map_tab_list_herder();
    await this.getShopStaff()
    await this.getShopChannel()
    await this.getPackageStatus()
    // await this.getAllOrderList(null)
    await this.btnSearchOrder("0")
    JSON.stringify
    var localReload = JSON.parse(localStorage.getItem("isReloadTab_order"))
    if (localReload != null) {
      this.isReloadTab = localReload
    }

    this.page_loading = false
    // this.page_loading= false
  },
  methods: {
    btnOpenAdvanceSearchDialog() {
      this.advanceSearchDialog = true;
    },
    isReloadData() {
      localStorage.setItem('isReloadTab_order', this.isReloadTab)
    },
    dialog_export_excel () {
      this.dialog_excel = true
      this.startDate = new Date().toISOString().substr(0, 10)
      this.endDate = new Date().toISOString().substr(0, 10)
    },
    async getShopStaff() {
      let response = await axios.post(
        shopService_dotnet + 'Shop/get-shop-staff',
        { "shopID": localStorage.getItem("shop_id") }, 
        { headers: this.header_token}
      )
      this.dataset_all.search.SH_shop_staff_items.push({'text': 'ทั้งหมด', 'value': null})
      response.data.forEach(e => {
        this.dataset_all.search.SH_shop_staff_items.push({'text': e.name, 'value': e.id})
      });
    },
    async getShopChannel(){
      let response = await axios.get(
        orderService_dotnet + 'Order/get-shop-all-channel?ShopID=' + localStorage.getItem("shop_id"),
        { headers: this.header_token}
      );
      this.channelList = response.data.items;
    },
    async getPackageStatus() {
      let response = await axios.post(
        orderService_dotnet + 'Order/get-package-status',
        { headers: this.header_token}
      );
      this.dataset_all.search.search_parcel_status_list = response.data
      
    },
    async getAllOrderList(offset) {
      var search_data = this.dataset_all.search
      if(search_data != null){
        
        var shipping_status_id = null
        var shipping_name = search_data.SHshipName
        var tracking_no = search_data.SHtrackingNo
        var SHorderID = null
        if(search_data.SHorderID != null && search_data.SHorderID != ''){
          if (search_data.SHorderID.substring(0, 2).toUpperCase() == 'PA'){
            var str_tmp = search_data.SHorderID.toUpperCase()
            str_tmp = str_tmp.replace("PA", "");
            let isnum = /^\d+$/.test(str_tmp);
            if(isnum){
              SHorderID = str_tmp
            }else {
              SHorderID = 0
            }
          } else {
            let isnum = /^\d+$/.test(search_data.SHorderID);
            if(isnum){
              SHorderID = search_data.SHorderID
            }else {
              SHorderID = 0
            }
          }
        }
        var SH_shop_staff = search_data.SH_shop_staff != null ? search_data.SH_shop_staff['value'] : null
        var SH_phone = search_data.SH_phone != null ? search_data.SH_phone : null
        var SH_ReferenceNumber = search_data.SH_ReferenceNumber != null ? search_data.SH_ReferenceNumber : null

        var created_date_range = this.search_date_range
        var dateFrom = null
        var dateTo = null
        if(created_date_range.length != 0){
          if(created_date_range.length == 1) {
            dateFrom = created_date_range[0] + " 00:00:00"
            dateTo   = created_date_range[0] + " 23:59:59"
          } else {
            dateFrom = created_date_range[0] + " 00:00:00"
            dateTo   = created_date_range[1] + " 23:59:59"
          }
            
        }

        // var chanel_fackbook = search_data.chanel_fackbook
        // var chanel_email = search_data.chanel_email
        // var chanel_line = search_data.chanel_line
        var paid_status_id = 0
        var parcel_status_id = search_data.select_search_parcel_status_id
        var sent_date_range = this.sent_date_range
        var sent_dateFrom = null
        var sent_dateTo = null
        if(sent_date_range.length != 0){
          if(sent_date_range.length == 1) {
            sent_dateFrom = sent_date_range[0] + " 00:00:00"
            sent_dateTo   = sent_date_range[0] + " 23:59:59"
          } else {
            sent_dateFrom = sent_date_range[0] + " 00:00:00"
            sent_dateTo   = sent_date_range[1] + " 23:59:59"
          }
            
        }
        var transferred_date_range = this.transferred_date_range
        var transferred_dateFrom = null
        var transferred_dateTo = null
        if(transferred_date_range.length != 0){
          if(transferred_date_range.length == 1) {
            transferred_dateFrom = transferred_date_range[0] + " 00:00:00"
            transferred_dateTo   = transferred_date_range[0] + " 23:59:59"
          } else {
            transferred_dateFrom = transferred_date_range[0] + " 00:00:00"
            transferred_dateTo   = transferred_date_range[1] + " 23:59:59"
          }
            
        }
      } else {
        {
            dateFrom = null
            dateTo = null
            sent_dateFrom = null
            sent_dateTo = null
            transferred_dateFrom = null
            transferred_dateTo = null
            SH_shop_staff = null
            SH_phone = null
            paid_status_id = 0
            shipping_name = null
            // chanel_email = null
            // chanel_fackbook = null
            // chanel_line = null
            SH_ReferenceNumber = null
            tracking_no = null
            parcel_status_id = null

        }
      }

      if(offset == null){
        offset = 0
      }

      let response = await axios.post(
        orderService_dotnet + 'Order/shop-get-order-list',
        { 
          "orderIDText":( search_data == null ? null : search_data.SHorderID), 
          "shopID": localStorage.getItem("shop_id"),
          "referenceNumberText": SH_ReferenceNumber, 
          "dateFrom": dateFrom,
          "dateTo": dateTo,
          "sendToExpressDatetime_From": sent_dateFrom,
          "sendToExpressDatetime_To": sent_dateTo,
          "packDateFrom": transferred_dateFrom,
          "packDateTo": transferred_dateTo,
          "receiverPhone": SH_phone,
          "receiverName": shipping_name,
          "trackingNo": tracking_no,
          "shippingStatusID": shipping_status_id,
          "paidStatusID": paid_status_id,
          "parcelStatusID": parcel_status_id,
          "createdByStaffID": this.dataset_all.search.SH_shop_staff != null ? this.dataset_all.search.SH_shop_staff['value'] : null,
          "isNeedStatusCount": true,
          "channelIDList": this.channelListSeleced.map(function(item){return item.uniqueID;}),
          "skip": offset,
          "take": this.dataset_all.limits,
          "orderby": 2
        }, 
        { headers: this.header_token}
      )
      this.dataAllOrderList = await response.data
      this.dataset_all.dataTable = await response.data.orderList
      this.setDataTable_wait_all()
    },
    async get_count_order_excel_new () {
      this.loadingExcel = 1
      let res1 = await axios.post(orderService_dotnet+'Order/get-count-order-to-excel',{
        "shopID": localStorage.getItem("shop_id"),
        "shippingStatusID": this.dialog_excel_select,
        "dateFrom": this.startDate +  ' ' + this.time1,
        "dateTo": this.endDate + ' ' + this.time2 
        
      } , { headers: this.header_token})
      var fileCounter=1;
      const d = new Date();
      let dateExported =d.getFullYear().toString()+(d.getMonth()+1).toString() +d.getDate().toString() + d.getHours().toString()+d.getMinutes().toString()+d.getSeconds().toString();
      this.ExcelFileName = 'รายงานข้อมูลออเดอร์_'+dateExported+"_"+fileCounter;
      var alertMessage='กรุณาลองใหม่อีกครั้งหรือติดต่อฝ่าย IT !!';
      var isError=false;
      try {
        this.datasetExcel_new = []

        var percent = 1;
        var limits = 2000
        var total = res1.data
        var offsets = 0
        var loadingExcel = 0

        var Round =parseInt( total / limits);
        if(limits*Round < total){
          Round=Round+1;
        }

        for (var i =1; i <=Round; i++) {
          percent =parseInt(i / Round * 100)
          if(percent<1){
            percent=1;
          }

          var isThisRoundPass=false;
          while (!isThisRoundPass)
          {
            let response = await axios.post(orderService_dotnet+'Order/get-order-export-to-excel',{
              "shopID": localStorage.getItem("shop_id"),
              "shippingStatusID": this.dialog_excel_select,
              "dateFrom": this.startDate +  ' ' + this.time1,
              "dateTo": this.endDate + ' ' + this.time2,
              "limits" : limits ,
              "offsets" : offsets,
              "isSkipSecondRow":!this.IsSkipSecondRow
            } , { headers: this.header_token})
            if(response.status==200)
            {
              if(response.data.status=="error")
              {
                if(!response.data.isDatabaseNetwork){ 
                  // show error and stop
                  isThisRoundPass=true;
                  alertMessage = response.data.error_description;
                  i=Round+1;
                  isError=true;
                }
              }
              else
              {
                for (var index in response.data.items){
                  this.datasetExcel_new.push(response.data.items[index])
                }
                this.loadingExcel = percent
                offsets = offsets + limits;
                isThisRoundPass=true;
              }
            } 
            else 
            {
              i=Round+1;
              isError=true;
            }
          }
        } 
        const ws = utils.json_to_sheet([]);
        utils.sheet_add_aoa(ws, this.excel_row_header);
        utils.sheet_add_json(ws, this.datasetExcel_new, { origin: 'A2', skipHeader: true });
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "sheet1"); 
      
        writeFileXLSX(wb,   'รายงานข้อมูลออเดอร์_'+dateExported+".xlsx");
        this.loadingExcel = 0
      } 
      catch (e) {
        isError=true;
      
      }

      if(isError){
        this.$swal({
          type: 'error',
          title: 'เกิดข้อผิดพลาด',
          text:alertMessage,
        })
        this.loadingExcel = 0
        this.datasetExcel_new = []
      }
    },
    async inv_pre_print(){
      if (this.selected_order.length > 0) {
        try {
          var order_list = []
          for (var o = 0; o < this.selected_order.length; o++) {
            order_list.push(this.selected_order[o]['id'])
          }
          let response = await axios.post(reportService_dotnet+'ShopInvoiceOrder/get-invoice-no-before-print', {
            "orderList":order_list,
            "ShopID": this.shop_id
          },
          { headers: this.header_token})

          this.invoice_print.invorder = []
          for (var i=0; i<response.data.length; i++ ) {

            var splitedText=response.data[i]['dateString'].split('-')
            this.invoice_print.invorder.push(
              {
                "orderId":response.data[i]['orderMasterID'],
                "invoiceNo":response.data[i]['invoiceNo'],
                "dateString":response.data[i]['dateString'],
                "date": splitedText[2]+'-'+splitedText[1]+'-'+splitedText[0],
                  "startDateMenu":false,

              })
          }
          this.inv_dialog_printinvoice = true
        } catch (error) {
          this.$swal({
            type: 'warning',
            title: "ดำเนินการสำเร็จ",
          })
        }
      }
      else{
        this.$swal({
          type: 'warning',
          title: "กรุณาเลือกออเดอร์เพื่อดำเนินการ",
        })
      }
    },
    async inv_printinvoice(){
      if(this.invoice_print.titleprint_main == null || this.invoice_print.titleprint_main.length == 0){
        this.$refs.refinvtitleprintmain.focus()
        return
      }

      if(this.invoice_print.invorder.length == 0){
        return
      }
      if(this.invoice_print.titleprint_secondary != null){
        if(this.invoice_print.titleprint_secondary.length == 0 ){
          this.invoice_print.titleprint_secondary = null
        }
      }


      const today = new Date();
      const date = today.getFullYear()+'-'+(this.set_zero(today.getMonth()+1))+'-'+this.set_zero(today.getDate());
      this.invoice_print.date = date


      if(this.invoice_print.titleprint_secondary == null){
          this.invoice_print.titleprint_secondary = {'text':null,'value':null}
        }
      localStorage.setItem('INVPrint',  JSON.stringify(this.invoice_print))
      localStorage.setItem('Sizepage',  this.sizepage)
      window.open('/print-tax-invoice');
      this.clear_printinvoice()
      this.inv_dialog_printinvoice = false

    },
    clear_printinvoice(){
      this.invoice_print = {
        "ShopID" : localStorage.getItem("shop_id"),
        "printorder":[{"invorder": [],
        "titleprint_main": [],
        "titleprint_secondary": [],
        "date": new Date().toISOString().substr(0, 10)
      }]
      }
    },
    cover_pages(){
      if (this.selected_order.length > 0) {
        for (var i=0; i<this.selected_order.length; i++ ) {
          this.coverpages_print.OrderID.push({"OrderId":this.selected_order[i]['id']})
        }
        this.dialog_printcoverpage = true
      }
      else{
        this.$swal({
          type: 'warning',
          title: "กรุณาเลือกออเดอร์เพื่อดำเนินการ",
        })
      }
    },
    remove_data_invoice (index) {
      this.invoice_print.invorder.splice(index, 1);
    },
    printcoverpage(){
      localStorage.setItem('Print_Coverpage',  JSON.stringify(this.coverpages_print))
      window.open('/print-cover-pages');
      this.clear_printcoverpage()
      this.dialog_printcoverpage = false
    },
    clear_printcoverpage(){
      this.coverpages_print = {
        sizepage: "a4",
        ShopID:localStorage.getItem("shop_id"),
        OrderID:[]
      }
    },
    set_zero (val) {
      val = val.toString()
      if(val < 10){
        return "0"+val
      }
      return val
    },

    //
    OpenPhoto(Photo) {
      this.ShowPhotoLink = Photo;
      this.ShowPhoto = true;
    },
    // เรียกใช้
    ClosePhoto(value) {
      this.ShowPhoto = value;
      this.ShowPhotoLink = "";
    },
    ShowDetailOrder(orderToken) {
      window.open("/order-detail?token=" + orderToken);
    },

    //ส่วนของ API
    async Load_Data() {
      await axios
        .all([
          axios.post(
            shopService_dotnet + "Shop/get-warehouse-list",
            { shopId: parseInt(localStorage.getItem("shop_id")) },
            { headers: this.header_token }
          ),
          axios.post(
            branchService_dotnet + "Branch/get-branch-single",
            { branchId: parseInt(localStorage.getItem("branch_id")) },
            { headers: this.header_token }
          ),
        ])
        .then(
          axios.spread((warehouseID, getBranchID) => {

            if (warehouseID.status == 200 && getBranchID.status == 200) {
              // ดึง warehouseID จาก BranchID
              this.warehouse_list = [{ id: 0, name: "--ทั้งหมด--" }];
              this.warehouse_list.push(...warehouseID.data);
              this.warehouse_select = { id: 0, name: "--ทั้งหมด--" };
              // ดึง branch จาก BranchID

            } else {
              this.AlertError();
            }
          })
        );
    },
    async get_tab_list(warehouse_select) {
      return await axios.post(
      orderService_dotnet + 'OrderPackage/get-tab-list',
      {
        warehouseIDList: warehouse_select,
        shopId: parseInt(localStorage.getItem("shop_id")),
      }, { headers: this.header_token })
      .then(res => {
        return res.data
      }).catch(error => {
        return error;
      })
    },
    async SearchOrder(index, limits, packageIDList, keyword, warehouseIDList) {
      // console.log(this.search.keyword);
      return await axios
        .post(
          orderService_dotnet + "OrderPackage/search-package-packing-list",
          {
            shopId: parseInt(localStorage.getItem("shop_id")),
            skip: index,
            take: limits,
            keyword: keyword,
            packageIDList: packageIDList,
            isNeedChannelGroup: index == 0 ? true : false,
            warehouseIDList: warehouseIDList,
          },
          { headers: this.header_token }
        )
        .then((res) => {
          if (res.data.status) {
            return res.data
          } else {
            this.loading = false;
            this.AlertError();
            // this.SearchOrder(index,limits,changeTab)
          }

          return null;
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return error;
        });


    },
    async search_package_packing_list() {
      return await axios.post(orderService_dotnet + 'OrderPackage/search-package-packing-list', {
        warehouseIDList: this.warehouse_select,
        shopId: parseInt(localStorage.getItem("shop_id")),
      }, { headers: this.header_token })
        .then(res => {
          return res.data
        }).catch(error => {
          return error;
        })
    },
    async getFilterOrder(packageIDList) {
      return await axios
        .post(
          orderService_dotnet + "Package/get-product-tobe-filter",
          {
            packageIDList: packageIDList,
          },
          { headers: this.header_token }
        )
        .then((res) => {
          return (res.data.data = res.data.productList);
        })
        .catch((error) => {
          return null;
        });
    },
    async getPreparePackageBeforeChangeStatus(packageIDList) {
      return await axios
        .post(
          orderService_dotnet + "Package/prepare-package-before-change-status",
          {
            packageIDList: packageIDList,
          },
          { headers: this.header_token }
        )
        .then(async (res) => {
          if (res.data.status == "success" && res.data.error_description == null) {
            return await res.data;
          } else {
            this.AlertError(res.data.error_description);
            return null;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return null;
        });
    },
    async changePackageStatus(packageID, packageStatusID) {
      return await axios
        .post(
          orderService_dotnet + "Package/change-package-status",
          {
            packageID: packageID,
            packageStatusID: packageStatusID,
            staffBranchID: this.staffBranchId,
          },
          { headers: this.header_token }
        )
        .then(async (res) => {
          if (res.data.status == "success" && res.data.error_description == null) {
            return await res.data;
          } else {
            //  this.AlertError(res.data.error_description);
            return res.data;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return null;
        });
    },
    async prepareUpdateToMarketplace(packageIDList) {
      return await axios
        .post(
          ecomService_dotnet + "Order/prepare-rts",
          {
            packageIDList: packageIDList,
          },
          { headers: this.header_token }
        )
        .then(async (res) => {
          if (res.data.status == "success" && res.data.error_description == null) {
            return await res.data;
          } else {
            this.AlertError(await res.data.error_description);
            return res.data;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return null;
        });
    },
    async getAllReportMapProduct(packageIDList) {
      return await axios
        .post(
          orderService_dotnet + "Package/get-all-to-be-mapped-product",
          {
            packageIDList: packageIDList,
          },
          { headers: this.header_token }
        )
        .then(async (res) => {
          if (res.data.status == "success" && res.data.error_message == null) {
            return await res.data;
          } else {
            this.AlertError(res.data.error_message);
            return null;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return null;
        });
    },
    async getAllViewReportPackd(body) {
      return await axios
        .post(
          orderService_dotnet + "Package/view-product-to-be-packed",
          {
            packageIDList: body.packageIDList,
            selectedStockShopIDList: body.selectedStockShopIDList,
            notSelectedStockShopIDList: body.notSelectedStockShopIDList,
            skuFrom: body.skuFrom,
            skuTo: body.skuTo,
            quantityFrom: body.quantityFrom,
            quantityTo: body.quantityTo,
          },
          { headers: this.header_token }
        )
        .then(async (res) => {
          if (res.data.status == "success" && res.data.error_message == null) {
            return await res.data;
          } else {
            this.AlertError(res.data.error_message);
            return null;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return null;
        });
    },

    // ส่วนของ function
    async map_tab_list_herder() {
      var warehouse_select
      if (this.warehouse_select.id == 0) {
        var warehouse_listNotZero = this.warehouse_list.filter(w => w.id != 0)
        warehouse_select = warehouse_listNotZero.map(w => w.id)
      } else {
        warehouse_select = [this.warehouse_select.id]
      }
      var tab = await this.get_tab_list(warehouse_select);
      this.tabListHeaders = tab.masterItems
      this.tabListSubHeaders = []
      for (var item of tab.masterItems) {
        var subItem = [];

        if (item.subItems != null) {


          subItem.id = item.id
          subItem.subItems = item.subItems
          this.tabListSubHeaders.push(subItem);

        }

      }
    },
    async map_tab_list_herder_1() {
      var warehouse_select
      if (this.warehouse_select.id == 0) {
        var warehouse_listNotZero = this.warehouse_list.filter(w => w.id != 0)
        return warehouse_listNotZero.map(w => w.id)
      } else {
        return [this.warehouse_select.id]
      }
    },
    async ChangeTab(tab) {
      var tab_select = this.tabListHeaders.find(t => t.id == tab)
      var data, keyword;

      var warehouse_select = await this.map_tab_list_herder_1();

      if ((tab == '0' && !tab_select.isLoaded) || (tab == '0' && this.isReloadTab == true)) {
        this.resetDefaultTab('dataset_all');

        tab_select.isLoaded = true;
        keyword = { packageStatusID: null };
        this.dataset_all.keyword = keyword;

        // this.getAllOrderList()

        data = await this.SearchOrder(0, 10, null, keyword, warehouse_select);
        this.setDataTable_all(data, '0');
        this.isTrueIsSubloaded(tab_select, "0");
      }
      else if ((tab == '1-5' && !tab_select.isLoaded) || (tab == '1-5' && this.isReloadTab == true)) {
        this.resetDefaultTab('dataset_wait_payment_wait_payment');

        tab_select.isLoaded = true;
        keyword = { packageStatusID: "1" };
        this.dataset_wait_payment_wait_payment.keyword = keyword;

        data = await this.SearchOrder(0, 10, null, keyword, warehouse_select);
        this.setDataTable_wait_payment_wait_payment(data, '100');
        this.isTrueIsSubloaded(tab_select, "1");

      }
      else if ((tab == '10-20-30-40-60' && !tab_select.isLoaded) || (tab == '10-20-30-40-60' && this.isReloadTab == true)) {
        this.resetDefaultTab('dataset_process_map_product');

        tab_select.isLoaded = true;
        keyword = { packageStatusID: "10" };
        this.dataset_process_map_product.keyword = keyword;

        data = await this.SearchOrder(0, 10, null, keyword, warehouse_select);
        this.setDataTable_process_map_product(data, '10');
        this.isTrueIsSubloaded(tab_select, "10");

      }
      else if ((tab == '80' && !tab_select.isLoaded) || (tab == '80' && this.isReloadTab == true)) {
        this.resetDefaultTab('dataset_process_wait_pickup');

        keyword = { packageStatusID: "80" };
        data = await this.SearchOrder(0, 10, null, keyword, warehouse_select);
        this.setDataTable_process_wait_pickup(data, '5');
        tab_select.isLoaded = true
        this.dataset_process_wait_pickup.keyword = keyword;

      }
      else if ((tab == '90' && !tab_select.isLoaded ) || (tab == '90' && this.isReloadTab == true)) {
        this.resetDefaultTab('dataset_delivering');

        tab_select.isLoaded = true;
        keyword = { packageStatusID: "90" };
        this.dataset_delivering.keyword = keyword;

        data = await this.SearchOrder(0, 10, null, keyword, warehouse_select);
        this.setDataTable_dataset_delivering(data, '90');
      }
      else if ((tab == '130' && !tab_select.isLoaded) || (tab == '130' && this.isReloadTab == true)) {
        this.resetDefaultTab('dataset_success');

        tab_select.isLoaded = true;
        keyword = { packageStatusID: "130" };
        this.dataset_success.keyword = keyword;

        data = await this.SearchOrder(0, 10, null, keyword, warehouse_select);
        this.setDataTable_dataset_success(data, '130');
      }
      else if ((tab == '100-110-120' && !tab_select.isLoaded) || (tab == '100-110-120' && this.isReloadTab == true)) {
        this.resetDefaultTab('dataset_Return_Returnning');

        tab_select.isLoaded = true;
        keyword = { packageStatusID: "100" };
        this.dataset_Return_Returnning.keyword = keyword;

        data = await this.SearchOrder(0, 10, null, keyword, warehouse_select);
        this.setDataTable_Return_Returnning(data, '100');
        this.isTrueIsSubloaded(tab_select, "100");
      }
      else if ((tab == '140' && !tab_select.isLoaded) || (tab == '140' && this.isReloadTab == true)) {
        this.resetDefaultTab('dataset_cancel');

        tab_select.isLoaded = true;
        keyword = { packageStatusID: "140" };
        this.dataset_cancel.keyword = keyword;

        data = await this.SearchOrder(0, 10, null, keyword, warehouse_select);
        this.setDataTable_dataset_cancel(data, '140');
      }

    },
    async ChangeTabSub(tab) {
      var tab_select = this.set_TabListHeader;
      var tabSub = await this.tabListSubHeaders.find(s => s.id == tab_select);
      var tabSub_select = await tabSub.subItems.find(ts => ts.id == tab)
      var data, keyword;

      var warehouse_select = await this.map_tab_list_herder_1();

      if ((tab == '1' && !tabSub_select.isLoaded) || (tab == '1' && this.isReloadTab == true)) {
        this.resetDefaultTab('dataset_wait_payment_wait_payment');

        keyword = { packageStatusID: "1" };
        data = await this.SearchOrder(0, 10, null, keyword, warehouse_select);
        this.setDataTable_wait_payment_wait_payment(data, '1');
        tabSub_select.isLoaded = true
        this.dataset_wait_payment_wait_payment.keyword = keyword;

      }
      else if ((tab == '5' && !tabSub_select.isLoaded) || (tab == '5' && this.isReloadTab == true)) {
        this.resetDefaultTab('dataset_wait_payment_check_payment');

        keyword = { packageStatusID: "5" };
        data = await this.SearchOrder(0, 10, null, keyword, warehouse_select);
        this.setDataTable_wait_payment_check_payment(data, '5');
        tabSub_select.isLoaded = true
        this.dataset_wait_payment_check_payment.keyword = keyword;

      }
      else if ((tab == '10' && !tabSub_select.isLoaded) || (tab == '10' && this.isReloadTab == true)) {
        this.resetDefaultTab('dataset_process_map_product');

        keyword = { packageStatusID: "10" };
        data = await this.SearchOrder(0, 10, null, keyword, warehouse_select);
        this.setDataTable_process_map_product(data, '10');
        tabSub_select.isLoaded = true
        this.dataset_process_map_product.keyword = keyword;

      }
      else if ((tab == '20' && !tabSub_select.isLoaded) || (tab == '20' && this.isReloadTab == true)) {
        this.resetDefaultTab('dataset_process_allocate_stock');

        keyword = { packageStatusID: "20" };
        data = await this.SearchOrder(0, 10, null, keyword, warehouse_select);
        this.setDataTable_process_allocate_stock(data, '20');
        tabSub_select.isLoaded = true
        this.dataset_process_allocate_stock.keyword = keyword;

      }
      else if ((tab == '30' && !tabSub_select.isLoaded) || (tab == '30' && this.isReloadTab == true)) {
        this.resetDefaultTab('dataset_process_ready_to_ship');

        keyword = { packageStatusID: "30" };
        data = await this.SearchOrder(0, 10, null, keyword, warehouse_select);
        this.setDataTable_process_ready_to_ship(data, '30');
        tabSub_select.isLoaded = true
        this.dataset_process_ready_to_ship.keyword = keyword;

      }
      else if ((tab == '40' && !tabSub_select.isLoaded) || (tab == '40' && this.isReloadTab == true)) {
        this.resetDefaultTab('dataset_process_to_pack');

        keyword = { packageStatusID: "40" };
        data = await this.SearchOrder(0, 10, null, keyword, warehouse_select);
        this.setDataTable_process_to_pack(data, '40');
        tabSub_select.isLoaded = true
        this.dataset_process_to_pack.keyword = keyword;

      }
      else if ((tab == '60' && !tabSub_select.isLoaded) || (tab == '60' && this.isReloadTab == true)) {
        this.resetDefaultTab('dataset_process_packing');

        keyword = { packageStatusID: "60" };
        data = await this.SearchOrder(0, 10, null, keyword, warehouse_select);
        this.setDataTable_process_packing(data, '60');
        tabSub_select.isLoaded = true
        this.dataset_process_packing.keyword = keyword;

      }
      else if ((tab == '100' && !tabSub_select.isLoaded) || (tab == '100' && this.isReloadTab == true)) {
        this.resetDefaultTab('dataset_Return_Returnning');

        keyword = { packageStatusID: "100" };
        data = await this.SearchOrder(0, 10, null, keyword, warehouse_select);
        this.setDataTable_Return_Returnning(data, '100');
        tabSub_select.isLoaded = true
        this.dataset_Return_Returnning.keyword = keyword;

      }
      else if ((tab == '110' && !tabSub_select.isLoaded) || (tab == '110' && this.isReloadTab == true)) {
        this.resetDefaultTab('dataset_Return_To_Return_Stock');

        keyword = { packageStatusID: "110" };
        data = await this.SearchOrder(0, 10, null, keyword, warehouse_select);
        this.setDataTable_Return_To_Return_Stock(data, '100');
        tabSub_select.isLoaded = true
        this.dataset_Return_To_Return_Stock.keyword = keyword;

      }
      else if ((tab == '120' && !tabSub_select.isLoaded) || (tab == '120' && this.isReloadTab == true)) {
        this.resetDefaultTab('dataset_Return_Stock_Returned');

        keyword = { packageStatusID: "120" };
        data = await this.SearchOrder(0, 10, null, keyword, warehouse_select);
        this.setDataTable_Return_Stock_Returned(data, '120');
        tabSub_select.isLoaded = true
        this.dataset_Return_Stock_Returned.keyword = keyword;

      }




    },
    async isTrueIsSubloaded(tab_select, id) {
      var tabSub = await this.tabListSubHeaders.find(s => s.id == tab_select.id);
      var tabSub_select = await tabSub.subItems.find(ts => ts.id == id)
      tabSub_select.isLoaded = true;
    },
    async handleClickOutside(item, tab) {

      if (item.btn && typeof this[item.btn] === "function") {
        this[item.btn](tab);
      }
    },
    async resetDefaultTab(NameTab) {
      // ใช้ชื่อที่รับเป็นตัวชี้
      this[NameTab].page = 1;
      this[NameTab].offsets = 0;
      this[NameTab].limits = 10;
      this[NameTab].search.text_topic = "";
      this[NameTab].search.select_topic = { id: 1, name: "เลขออเดอร์", value: "orderId" };
      this[NameTab].selected = [];
      if (NameTab == 'dataset_all') {
        
        this.dataset_all.dataTable = []
        this.dataset_all.pageLength = 1
        this.dataset_all.total = 0
        this.dataset_all.search.select_per_page = 10
        this.dataset_all.search.searchData = null
        this.dataset_all.search.SHselectStatus = 9999
        this.resetSearchAll()
      }
    },
    async resetSearchAll() {
      this.dataset_all.search.text_topic = ""
      this.dataset_all.search.select_topic = { id: 1, name: "เลขออเดอร์", value: "orderId" }
      this.dataset_all.search.SHselectStatus = 9999
      this.dataset_all.search.select_search_parcel_status_id = null
      this.dataset_all.search.select_search_paid_status_id = null
      this.dataset_all.search.SHshipName = null
      this.dataset_all.search.SHtrackingNo = null
      this.dataset_all.search.SHorderID = null
      this.dataset_all.search.SH_shop_staff = null
      this.dataset_all.search.SH_phone  = null
      this.dataset_all.search.SH_ReferenceNumber = null

      this.search_menu_date_range = false
      this.search_date_range = []
      this.menu_search_sent_date = false
      this.sent_date_range = []
      this.menu_search_tranferred_date = false
      this.transferred_date_range = []
      this.channelListSeleced = []

      this.dialog_excel = false
      this.dialog_excel_select = null
      this.startDate = null
      this.menuStartDate = false
      this.checkStart = true
      this.endDate = null
      this.menuEndDate = false
      this.checkEnd = true
      this.singleSelect = false
      this.selected_order = []
      // time
      this.time1 = "00:00"
      this.time2 = "23:59"
      this.menutime1 = false
      this.menutime2 = false
      this.IsSkipSecondRow =false
      this.datasetExcel_new = []
      this.ExcelFileName = ''
      this.inv_dialog_printinvoice = false
      this.dialog_printcoverpage = false
      this.invoice_print = {
        ShopID: localStorage.getItem("shop_id"),
        invorder : [],
        titleprint_main: null,
        titleprint_secondary: null,
        date: new Date().toISOString().substr(0, 10)
      }
      this.coverpages_print = {
        sizepage: "a4",
        ShopID:localStorage.getItem("shop_id"),
        OrderID:[]
      }
    },
    async getPercentLoading(Round, countLoop) {
      this.percentLoading = 0;
      // let countLoop = 0; //จำนวนที่วนรอบ
      // let Round  =0; //จำนวนปัจจุบัน
      this.percentLoading = parseInt((Round / countLoop) * 100);
      if (this.percentLoading == 100) {
        this.percentLoadingText = "สำเร็จ";
      } else if (this.percentLoading > 0) {
        this.percentLoadingText = "กำลังโหลด";
      } else {
        this.percentLoadingText = "รอโหลด";
      }
    },
    async switchSubCaseChngeDataTable(id) {
      switch (id) {
        case '1':
          return this.dataset_wait_payment_wait_payment

        case '5':
          return this.dataset_wait_payment_check_payment

        case '10':
          return this.dataset_process_map_product

        case '20':
          return this.dataset_process_allocate_stock

        case '30':
          return this.dataset_process_ready_to_ship

        case '40':
          return this.dataset_process_to_pack

        case '60':
          return this.dataset_process_packing

        case '80':
          return this.dataset_process_wait_pickup

        case '100':
          return this.dataset_Return_Returnning

        case '110':
          return this.dataset_Return_To_Return_Stock

        case '120':
          return this.dataset_Return_Stock_Returned


      }
    },
    async getDataTableFilterSetSelected(tab) {

      var data, keyword, item, total, tabDataset;

      var warehouse_select = await this.map_tab_list_herder_1();

      if (tab == '1') { return tabDataset = this.dataset_wait_payment_wait_payment.selected }
      else if (tab == '5') { return tabDataset = this.dataset_wait_payment_check_payment.selected }
      else if (tab == '10') { return tabDataset = this.dataset_process_map_product.selected }
      else if (tab == '20') { return tabDataset = this.dataset_process_allocate_stock.selected }
      else if (tab == '30') { return tabDataset = this.dataset_process_ready_to_ship.selected }
      else if (tab == '40') { return tabDataset = this.dataset_process_to_pack.selected }
      else if (tab == '60') { return tabDataset = this.dataset_process_packing.selected }
      else if (tab == '80') { return tabDataset = this.dataset_process_wait_pickup.selected }
      else if (tab == '90') { return tabDataset = this.dataset_delivering.selected }
      else if (tab == '130') { return tabDataset = this.dataset_success.selected }
      else if (tab == '100') { return tabDataset = this.dataset_Return_Returnning.selected }
      else if (tab == '110') { return tabDataset = this.dataset_Return_To_Return_Stock.selected }
      else if (tab == '120') { return tabDataset = this.dataset_Return_Stock_Returned.selected }
      else if (tab == '140') { return tabDataset = this.dataset_cancel.selected }

      return tabDataset;

    },
    async getNameTab() {
      var checkMainTab = this.tabListHeaders.find(t => t.id == this.set_TabListHeader)
      var checkSubTab, tab, tabName;

      if (checkMainTab.subItems == null) {
        tab = checkMainTab.id;
      } else {
        if (checkMainTab.id == '1-5') {
          checkSubTab = await checkMainTab.subItems.find(sub => sub.id = this.set_TabListSubHeader_wait_payment);
          tab = checkSubTab.id;
        }
        else if (checkMainTab.id == '10-20-30-40-60') {
          checkSubTab = await checkMainTab.subItems.find(s => s.id == this.set_TabListSubHeader_Process);
          tab = checkSubTab.id;
        }
        else if (checkMainTab.id == '100-110-120') {
          checkSubTab = await checkMainTab.subItems.find(sub => sub.id = this.set_TabListSubHeader_Return);
          tab = checkSubTab.id;
        }
      }
      if (tab == '1') { tabName = "dataset_wait_payment_wait_payment" }
      else if (tab == '5') { tabName = "dataset_wait_payment_check_payment" }
      else if (tab == '10') { tabName = "dataset_process_map_product" }
      else if (tab == '20') { tabName = "dataset_process_allocate_stock" }
      else if (tab == '30') { tabName = "dataset_process_ready_to_ship" }
      else if (tab == '40') { tabName = "dataset_process_to_pack" }
      else if (tab == '60') { tabName = "dataset_process_packing" }
      else if (tab == '80') { tabName = "dataset_process_wait_pickup" }
      else if (tab == '90') { tabName = "dataset_delivering" }
      else if (tab == '100') { tabName = "dataset_Return_Returnning" }
      else if (tab == '110') { tabName = "dataset_Return_To_Return_Stock" }
      else if (tab == '120') { tabName = "dataset_Return_Stock_Returned" }
      else if (tab == '130') { tabName = "dataset_success" }
      else if (tab == '140') { tabName = "dataset_cancel" }
      return tabName;
    },
    //
    async setDataTable_wait_all (data) {
      this.dataset_all.total = this.dataAllOrderList.searchResultCount;
      this.dataset_all.pageLength = Math.ceil(this.dataset_all.total / this.dataset_all.limits);
    },
    async setDataTable_all(data, id) {
      if (data.resultData != null) {
        this.dataset_all.dataTable = data.resultData
        this.dataset_alltotal = data.resultCount;
        this.dataset_all.pageLength = Math.ceil(this.dataset_all.total / this.dataset_all.limits);
      }
    },

    //dataset_wait_payment
    async setDataTable_wait_payment_wait_payment(data, id) {

      // groupExpress
      if (data.groupExpress != null) {
        var exprees = [];
        this.dataset_wait_payment_wait_payment.search.list_express = [];
        this.dataset_wait_payment_wait_payment.search.select_express = ({ expressCompanyID: undefined, expressCompanyName: " -- ทั้งหมด --", });
        exprees.push({ expressCompanyID: undefined, expressCompanyName: " -- ทั้งหมด --", });
        exprees.push(...data.groupExpress);
        this.dataset_wait_payment_wait_payment.search.list_express = exprees;
      }
      // channelSourceList
      if (data.groupChannel != null) {
        var channelSource = [];
        this.dataset_wait_payment_wait_payment.search.list_channel = [];
        this.dataset_wait_payment_wait_payment.search.select_channel = { channelID: null, channelName: "--ทุกช่องทาง--", }
        channelSource.push({ channelID: null, channelName: "--ทุกช่องทาง--", });
        channelSource.push(...data.groupChannel)
        this.dataset_wait_payment_wait_payment.search.list_channel = channelSource;

      }
      // // groupPrint
      if (data.groupPrint != null) {
        var groupPrintList = []
        this.dataset_wait_payment_wait_payment.search.list_print = [];
        this.dataset_wait_payment_wait_payment.search.select_print = { id: null, name: " -- ทั้งหมด --" };
        groupPrintList.push({ id: null, name: " -- ทั้งหมด --", });
        groupPrintList.push(...data.groupPrint);
        this.dataset_wait_payment_wait_payment.search.list_print = groupPrintList;
      }
      if (data.resultData != null) {
        this.dataset_wait_payment_wait_payment.dataTable = data.resultData
        this.dataset_wait_payment_wait_payment.total = data.resultCount;
        this.dataset_wait_payment_wait_payment.pageLength = Math.ceil(this.dataset_wait_payment_wait_payment.total / this.dataset_process_map_product.limits);
      }

    },
    async setDataTable_wait_payment_check_payment(data, id) {

      // groupExpress
      if (data.groupExpress != null) {
        var exprees = [];
        this.dataset_wait_payment_check_payment.search.list_express = [];
        this.dataset_wait_payment_check_payment.search.select_express = ({ expressCompanyID: undefined, expressCompanyName: " -- ทั้งหมด --", });
        exprees.push({ expressCompanyID: undefined, expressCompanyName: " -- ทั้งหมด --", });
        exprees.push(...data.groupExpress);
        this.dataset_wait_payment_check_payment.search.list_express = exprees;
      }
      // channelSourceList
      if (data.groupChannel != null) {
        var channelSource = [];
        this.dataset_wait_payment_check_payment.search.list_channel = [];
        this.dataset_wait_payment_check_payment.search.select_channel = { channelID: null, channelName: "--ทุกช่องทาง--", }
        channelSource.push({ channelID: null, channelName: "--ทุกช่องทาง--", });
        channelSource.push(...data.groupChannel)
        this.dataset_wait_payment_check_payment.search.list_channel = channelSource;

      }
      // // groupPrint
      if (data.groupPrint != null) {
        var groupPrintList = []
        this.dataset_wait_payment_check_payment.search.list_print = [];
        this.dataset_wait_payment_check_payment.search.select_print = { id: null, name: " -- ทั้งหมด --" };
        groupPrintList.push({ id: null, name: " -- ทั้งหมด --", });
        groupPrintList.push(...data.groupPrint);
        this.dataset_wait_payment_check_payment.search.list_print = groupPrintList;
      }
      if (data.resultData != null) {
        this.dataset_wait_payment_check_payment.dataTable = data.resultData
        this.dataset_wait_payment_check_payment.total = data.resultCount;
        this.dataset_wait_payment_check_payment.pageLength = Math.ceil(this.dataset_wait_payment_check_payment.total / this.dataset_wait_payment_check_payment.limits);
      }

    },
    //dataset_process
    async setDataTable_process_map_product(data, id) {

      // groupExpress
      if (data.groupExpress != null) {
        var exprees = [];
        this.dataset_process_map_product.search.list_express = [];
        this.dataset_process_map_product.search.select_express = ({ expressCompanyID: undefined, expressCompanyName: " -- ทั้งหมด --", });
        exprees.push({ expressCompanyID: undefined, expressCompanyName: " -- ทั้งหมด --", });
        exprees.push(...data.groupExpress);
        this.dataset_process_map_product.search.list_express = exprees;
      }
      // channelSourceList
      if (data.groupChannel != null) {
        var channelSource = [];
        this.dataset_process_map_product.search.list_channel = [];
        this.dataset_process_map_product.search.select_channel = { channelID: null, channelName: "--ทุกช่องทาง--", }
        channelSource.push({ channelID: null, channelName: "--ทุกช่องทาง--", });
        channelSource.push(...data.groupChannel)
        this.dataset_process_map_product.search.list_channel = channelSource;

      }
      // // groupPrint
      if (data.groupPrint != null) {
        var groupPrintList = []
        this.dataset_process_map_product.search.list_print = [];
        this.dataset_process_map_product.search.select_print = { id: null, name: " -- ทั้งหมด --" };
        groupPrintList.push({ id: null, name: " -- ทั้งหมด --", });
        groupPrintList.push(...data.groupPrint);
        this.dataset_process_map_product.search.list_print = groupPrintList;
      }
      if (data.resultData != null) {
        this.dataset_process_map_product.dataTable = data.resultData
        this.dataset_process_map_product.total = data.resultCount;
        this.dataset_process_map_product.pageLength = Math.ceil(this.dataset_process_map_product.total / this.dataset_process_map_product.limits);
      }

    },
    async setDataTable_process_allocate_stock(data, id) {

      // groupExpress
      if (data.groupExpress != null) {
        var exprees = [];
        this.dataset_process_allocate_stock.search.list_express = [];
        this.dataset_process_allocate_stock.search.select_express = ({ expressCompanyID: undefined, expressCompanyName: " -- ทั้งหมด --", });
        exprees.push({ expressCompanyID: undefined, expressCompanyName: " -- ทั้งหมด --", });
        exprees.push(...data.groupExpress);
        this.dataset_process_allocate_stock.search.list_express = exprees;
      }
      // channelSourceList
      if (data.groupChannel != null) {
        var channelSource = [];
        this.dataset_process_allocate_stock.search.list_channel = [];
        this.dataset_process_allocate_stock.search.select_channel = { channelID: null, channelName: "--ทุกช่องทาง--", }
        channelSource.push({ channelID: null, channelName: "--ทุกช่องทาง--", });
        channelSource.push(...data.groupChannel)
        this.dataset_process_allocate_stock.search.list_channel = channelSource;

      }
      // // groupPrint
      if (data.groupPrint != null) {
        var groupPrintList = []
        this.dataset_process_allocate_stock.search.list_print = [];
        this.dataset_process_allocate_stock.search.select_print = { id: null, name: " -- ทั้งหมด --" };
        groupPrintList.push({ id: null, name: " -- ทั้งหมด --", });
        groupPrintList.push(...data.groupPrint);
        this.dataset_process_allocate_stock.search.list_print = groupPrintList;
      }
      if (data.resultData != null) {
        this.dataset_process_allocate_stock.dataTable = data.resultData
        this.dataset_process_allocate_stock.total = data.resultCount;
        this.dataset_process_allocate_stock.pageLength = Math.ceil(this.dataset_process_allocate_stock.total / this.dataset_process_allocate_stock.limits);
      }

    },
    async setDataTable_process_ready_to_ship(data, id) {

      // groupExpress
      if (data.groupExpress != null) {
        var exprees = [];
        this.dataset_process_ready_to_ship.search.list_express = [];
        this.dataset_process_ready_to_ship.search.select_express = ({ expressCompanyID: undefined, expressCompanyName: " -- ทั้งหมด --", });
        exprees.push({ expressCompanyID: undefined, expressCompanyName: " -- ทั้งหมด --", });
        exprees.push(...data.groupExpress);
        this.dataset_process_ready_to_ship.search.list_express = exprees;
      }
      // channelSourceList
      if (data.groupChannel != null) {
        var channelSource = [];
        this.dataset_process_ready_to_ship.search.list_channel = [];
        this.dataset_process_ready_to_ship.search.select_channel = { channelID: null, channelName: "--ทุกช่องทาง--", }
        channelSource.push({ channelID: null, channelName: "--ทุกช่องทาง--", });
        channelSource.push(...data.groupChannel)
        this.dataset_process_ready_to_ship.search.list_channel = channelSource;

      }
      // // groupPrint
      if (data.groupPrint != null) {
        var groupPrintList = []
        this.dataset_process_ready_to_ship.search.list_print = [];
        this.dataset_process_ready_to_ship.search.select_print = { id: null, name: " -- ทั้งหมด --" };
        groupPrintList.push({ id: null, name: " -- ทั้งหมด --", });
        groupPrintList.push(...data.groupPrint);
        this.dataset_process_ready_to_ship.search.list_print = groupPrintList;
      }
      if (data.resultData != null) {
        this.dataset_process_ready_to_ship.dataTable = data.resultData
        this.dataset_process_ready_to_ship.total = data.resultCount;
        this.dataset_process_ready_to_ship.pageLength = Math.ceil(this.dataset_process_ready_to_ship.total / this.dataset_process_ready_to_ship.limits);
      }

    },
    async setDataTable_process_to_pack(data, id) {

      // groupExpress
      if (data.groupExpress != null) {
        var exprees = [];
        this.dataset_process_to_pack.search.list_express = [];
        this.dataset_process_to_pack.search.select_express = ({ expressCompanyID: undefined, expressCompanyName: " -- ทั้งหมด --", });
        exprees.push({ expressCompanyID: undefined, expressCompanyName: " -- ทั้งหมด --", });
        exprees.push(...data.groupExpress);
        this.dataset_process_to_pack.search.list_express = exprees;
      }
      // channelSourceList
      if (data.groupChannel != null) {
        var channelSource = [];
        this.dataset_process_to_pack.search.list_channel = [];
        this.dataset_process_to_pack.search.select_channel = { channelID: null, channelName: "--ทุกช่องทาง--", }
        channelSource.push({ channelID: null, channelName: "--ทุกช่องทาง--", });
        channelSource.push(...data.groupChannel)
        this.dataset_process_to_pack.search.list_channel = channelSource;

      }
      // // groupPrint
      if (data.groupPrint != null) {
        var groupPrintList = []
        this.dataset_process_to_pack.search.list_print = [];
        this.dataset_process_to_pack.search.select_print = { id: null, name: " -- ทั้งหมด --" };
        groupPrintList.push({ id: null, name: " -- ทั้งหมด --", });
        groupPrintList.push(...data.groupPrint);
        this.dataset_process_to_pack.search.list_print = groupPrintList;
      }
      if (data.resultData != null) {
        this.dataset_process_to_pack.dataTable = data.resultData
        this.dataset_process_to_pack.total = data.resultCount;
        this.dataset_process_to_pack.pageLength = Math.ceil(this.dataset_process_to_pack.total / this.dataset_process_to_pack.limits);
      }

    },
    async setDataTable_process_packing(data, id) {

      // groupExpress
      if (data.groupExpress != null) {
        var exprees = [];
        this.dataset_process_packing.search.list_express = [];
        this.dataset_process_packing.search.select_express = ({ expressCompanyID: undefined, expressCompanyName: " -- ทั้งหมด --", });
        exprees.push({ expressCompanyID: undefined, expressCompanyName: " -- ทั้งหมด --", });
        exprees.push(...data.groupExpress);
        this.dataset_process_packing.search.list_express = exprees;
      }
      // channelSourceList
      if (data.groupChannel != null) {
        var channelSource = [];
        this.dataset_process_packing.search.list_channel = [];
        this.dataset_process_packing.search.select_channel = { channelID: null, channelName: "--ทุกช่องทาง--", }
        channelSource.push({ channelID: null, channelName: "--ทุกช่องทาง--", });
        channelSource.push(...data.groupChannel)
        this.dataset_process_packing.search.list_channel = channelSource;

      }
      // // groupPrint
      if (data.groupPrint != null) {
        var groupPrintList = []
        this.dataset_process_packing.search.list_print = [];
        this.dataset_process_packing.search.select_print = { id: null, name: " -- ทั้งหมด --" };
        groupPrintList.push({ id: null, name: " -- ทั้งหมด --", });
        groupPrintList.push(...data.groupPrint);
        this.dataset_process_packing.search.list_print = groupPrintList;
      }
      if (data.resultData != null) {
        this.dataset_process_packing.dataTable = data.resultData
        this.dataset_process_packing.total = data.resultCount;
        this.dataset_process_packing.pageLength = Math.ceil(this.dataset_process_packing.total / this.dataset_process_packing.limits);
      }

    },
    async setDataTable_process_wait_pickup(data, id) {

      // groupExpress
      if (data.groupExpress != null) {
        var exprees = [];
        this.dataset_process_wait_pickup.search.list_express = [];
        this.dataset_process_wait_pickup.search.select_express = ({ expressCompanyID: undefined, expressCompanyName: " -- ทั้งหมด --", });
        exprees.push({ expressCompanyID: undefined, expressCompanyName: " -- ทั้งหมด --", });
        exprees.push(...data.groupExpress);
        this.dataset_process_wait_pickup.search.list_express = exprees;
      }
      // channelSourceList
      if (data.groupChannel != null) {
        var channelSource = [];
        this.dataset_process_wait_pickup.search.list_channel = [];
        this.dataset_process_wait_pickup.search.select_channel = { channelID: null, channelName: "--ทุกช่องทาง--", }
        channelSource.push({ channelID: null, channelName: "--ทุกช่องทาง--", });
        channelSource.push(...data.groupChannel)
        this.dataset_process_wait_pickup.search.list_channel = channelSource;

      }
      // // groupPrint
      if (data.groupPrint != null) {
        var groupPrintList = []
        this.dataset_process_wait_pickup.search.list_print = [];
        this.dataset_process_wait_pickup.search.select_print = { id: null, name: " -- ทั้งหมด --" };
        groupPrintList.push({ id: null, name: " -- ทั้งหมด --", });
        groupPrintList.push(...data.groupPrint);
        this.dataset_process_wait_pickup.search.list_print = groupPrintList;
      }
      if (data.resultData != null) {
        this.dataset_process_wait_pickup.dataTable = data.resultData
        this.dataset_process_wait_pickup.total = data.resultCount;
        this.dataset_process_wait_pickup.pageLength = Math.ceil(this.dataset_process_wait_pickup.total / this.dataset_process_wait_pickup.limits);
      }

    },
    //dataset_Return_Returnning:
    async setDataTable_Return_Returnning(data, id) {

      // groupExpress
      if (data.groupExpress != null) {
        var exprees = [];
        this.dataset_Return_Returnning.search.list_express = [];
        this.dataset_Return_Returnning.search.select_express = ({ expressCompanyID: undefined, expressCompanyName: " -- ทั้งหมด --", });
        exprees.push({ expressCompanyID: undefined, expressCompanyName: " -- ทั้งหมด --", });
        exprees.push(...data.groupExpress);
        this.dataset_Return_Returnning.search.list_express = exprees;
      }
      // channelSourceList
      if (data.groupChannel != null) {
        var channelSource = [];
        this.dataset_Return_Returnning.search.list_channel = [];
        this.dataset_Return_Returnning.search.select_channel = { channelID: null, channelName: "--ทุกช่องทาง--", }
        channelSource.push({ channelID: null, channelName: "--ทุกช่องทาง--", });
        channelSource.push(...data.groupChannel)
        this.dataset_Return_Returnning.search.list_channel = channelSource;

      }
      // // groupPrint
      if (data.groupPrint != null) {
        var groupPrintList = []
        this.dataset_Return_Returnning.search.list_print = [];
        this.dataset_Return_Returnning.search.select_print = { id: null, name: " -- ทั้งหมด --" };
        groupPrintList.push({ id: null, name: " -- ทั้งหมด --", });
        groupPrintList.push(...data.groupPrint);
        this.dataset_Return_Returnning.search.list_print = groupPrintList;
      }
      if (data.resultData != null) {
        this.dataset_Return_Returnning.dataTable = data.resultData
        this.dataset_Return_Returnning.total = data.resultCount;
        this.dataset_Return_Returnning.pageLength = Math.ceil(this.dataset_Return_Returnning.total / this.dataset_Return_Returnning.limits);
      }
    },
    async setDataTable_Return_To_Return_Stock(data, id) {

      // groupExpress
      if (data.groupExpress != null) {
        var exprees = [];
        this.dataset_Return_To_Return_Stock.search.list_express = [];
        this.dataset_Return_To_Return_Stock.search.select_express = ({ expressCompanyID: undefined, expressCompanyName: " -- ทั้งหมด --", });
        exprees.push({ expressCompanyID: undefined, expressCompanyName: " -- ทั้งหมด --", });
        exprees.push(...data.groupExpress);
        this.dataset_Return_To_Return_Stock.search.list_express = exprees;
      }
      // channelSourceList
      if (data.groupChannel != null) {
        var channelSource = [];
        this.dataset_Return_To_Return_Stock.search.list_channel = [];
        this.dataset_Return_To_Return_Stock.search.select_channel = { channelID: null, channelName: "--ทุกช่องทาง--", }
        channelSource.push({ channelID: null, channelName: "--ทุกช่องทาง--", });
        channelSource.push(...data.groupChannel)
        this.dataset_Return_To_Return_Stock.search.list_channel = channelSource;

      }
      // // groupPrint
      if (data.groupPrint != null) {
        var groupPrintList = []
        this.dataset_Return_To_Return_Stock.search.list_print = [];
        this.dataset_Return_To_Return_Stock.search.select_print = { id: null, name: " -- ทั้งหมด --" };
        groupPrintList.push({ id: null, name: " -- ทั้งหมด --", });
        groupPrintList.push(...data.groupPrint);
        this.dataset_Return_To_Return_Stock.search.list_print = groupPrintList;
      }
      if (data.resultData != null) {
        this.dataset_Return_To_Return_Stock.dataTable = data.resultData
        this.dataset_Return_To_Return_Stock.total = data.resultCount;
        this.dataset_Return_To_Return_Stock.pageLength = Math.ceil(this.dataset_Return_To_Return_Stock.total / this.dataset_Return_To_Return_Stock.limits);
      }

    },
    async setDataTable_Return_Stock_Returned(data, id) {

      // groupExpress
      if (data.groupExpress != null) {
        var exprees = [];
        this.dataset_Return_Stock_Returned.search.list_express = [];
        this.dataset_Return_Stock_Returned.search.select_express = ({ expressCompanyID: undefined, expressCompanyName: " -- ทั้งหมด --", });
        exprees.push({ expressCompanyID: undefined, expressCompanyName: " -- ทั้งหมด --", });
        exprees.push(...data.groupExpress);
        this.dataset_Return_Stock_Returned.search.list_express = exprees;
      }
      // channelSourceList
      if (data.groupChannel != null) {
        var channelSource = [];
        this.dataset_Return_Stock_Returned.search.list_channel = [];
        this.dataset_Return_Stock_Returned.search.select_channel = { channelID: null, channelName: "--ทุกช่องทาง--", }
        channelSource.push({ channelID: null, channelName: "--ทุกช่องทาง--", });
        channelSource.push(...data.groupChannel)
        this.dataset_Return_Stock_Returned.search.list_channel = channelSource;

      }
      // // groupPrint
      if (data.groupPrint != null) {
        var groupPrintList = []
        this.dataset_Return_Stock_Returned.search.list_print = [];
        this.dataset_Return_Stock_Returned.search.select_print = { id: null, name: " -- ทั้งหมด --" };
        groupPrintList.push({ id: null, name: " -- ทั้งหมด --", });
        groupPrintList.push(...data.groupPrint);
        this.dataset_Return_Stock_Returned.search.list_print = groupPrintList;
      }
      if (data.resultData != null) {
        this.dataset_Return_Stock_Returned.dataTable = data.resultData
        this.dataset_Return_Stock_Returned.total = data.resultCount;
        this.dataset_Return_Stock_Returned.pageLength = Math.ceil(this.dataset_Return_Stock_Returned.total / this.dataset_Return_Stock_Returned.limits);
      }

    },

    //dataset_delivering:
    async setDataTable_dataset_delivering(data, id) {

      // groupExpress
      if (data.groupExpress != null) {
        var exprees = [];
        this.dataset_delivering.search.list_express = [];
        this.dataset_delivering.search.select_express = ({ expressCompanyID: undefined, expressCompanyName: " -- ทั้งหมด --", });
        exprees.push({ expressCompanyID: undefined, expressCompanyName: " -- ทั้งหมด --", });
        exprees.push(...data.groupExpress);
        this.dataset_delivering.search.list_express = exprees;
      }
      // channelSourceList
      if (data.groupChannel != null) {
        var channelSource = [];
        this.dataset_delivering.search.list_channel = [];
        this.dataset_delivering.search.select_channel = { channelID: null, channelName: "--ทุกช่องทาง--", }
        channelSource.push({ channelID: null, channelName: "--ทุกช่องทาง--", });
        channelSource.push(...data.groupChannel)
        this.dataset_delivering.search.list_channel = channelSource;

      }
      // // groupPrint
      if (data.groupPrint != null) {
        var groupPrintList = []
        this.dataset_delivering.search.list_print = [];
        this.dataset_delivering.search.select_print = { id: null, name: " -- ทั้งหมด --" };
        groupPrintList.push({ id: null, name: " -- ทั้งหมด --", });
        groupPrintList.push(...data.groupPrint);
        this.dataset_delivering.search.list_print = groupPrintList;
      }
      if (data.resultData != null) {
        this.dataset_delivering.dataTable = data.resultData
        this.dataset_delivering.total = data.resultCount;
        this.dataset_delivering.pageLength = Math.ceil(this.dataset_delivering.total / this.dataset_delivering.limits);
      }
    },
    //dataset_dataset_success:
    async setDataTable_dataset_success(data, id) {

      // groupExpress
      if (data.groupExpress != null) {
        var exprees = [];
        this.dataset_success.search.list_express = [];
        this.dataset_success.search.select_express = ({ expressCompanyID: undefined, expressCompanyName: " -- ทั้งหมด --", });
        exprees.push({ expressCompanyID: undefined, expressCompanyName: " -- ทั้งหมด --", });
        exprees.push(...data.groupExpress);
        this.dataset_success.search.list_express = exprees;
      }
      // channelSourceList
      if (data.groupChannel != null) {
        var channelSource = [];
        this.dataset_success.search.list_channel = [];
        this.dataset_success.search.select_channel = { channelID: null, channelName: "--ทุกช่องทาง--", }
        channelSource.push({ channelID: null, channelName: "--ทุกช่องทาง--", });
        channelSource.push(...data.groupChannel)
        this.dataset_success.search.list_channel = channelSource;

      }
      // // groupPrint
      if (data.groupPrint != null) {
        var groupPrintList = []
        this.dataset_success.search.list_print = [];
        this.dataset_success.search.select_print = { id: null, name: " -- ทั้งหมด --" };
        groupPrintList.push({ id: null, name: " -- ทั้งหมด --", });
        groupPrintList.push(...data.groupPrint);
        this.dataset_success.search.list_print = groupPrintList;
      }
      if (data.resultData != null) {
        this.dataset_success.dataTable = data.resultData
        this.dataset_success.total = data.resultCount;
        this.dataset_success.pageLength = Math.ceil(this.dataset_success.total / this.dataset_success.limits);
      }
    },
    //dataset_cancel:
    async setDataTable_dataset_cancel(data, id) {

      // groupExpress
      if (data.groupExpress != null) {
        var exprees = [];
        this.dataset_cancel.search.list_express = [];
        this.dataset_cancel.search.select_express = ({ expressCompanyID: undefined, expressCompanyName: " -- ทั้งหมด --", });
        exprees.push({ expressCompanyID: undefined, expressCompanyName: " -- ทั้งหมด --", });
        exprees.push(...data.groupExpress);
        this.dataset_cancel.search.list_express = exprees;
      }
      // channelSourceList
      if (data.groupChannel != null) {
        var channelSource = [];
        this.dataset_cancel.search.list_channel = [];
        this.dataset_cancel.search.select_channel = { channelID: null, channelName: "--ทุกช่องทาง--", }
        channelSource.push({ channelID: null, channelName: "--ทุกช่องทาง--", });
        channelSource.push(...data.groupChannel)
        this.dataset_cancel.search.list_channel = channelSource;

      }
      // // groupPrint
      if (data.groupPrint != null) {
        var groupPrintList = []
        this.dataset_cancel.search.list_print = [];
        this.dataset_cancel.search.select_print = { id: null, name: " -- ทั้งหมด --" };
        groupPrintList.push({ id: null, name: " -- ทั้งหมด --", });
        groupPrintList.push(...data.groupPrint);
        this.dataset_cancel.search.list_print = groupPrintList;
      }
      if (data.resultData != null) {
        this.dataset_cancel.dataTable = data.resultData
        this.dataset_cancel.total = data.resultCount;
        this.dataset_cancel.pageLength = Math.ceil(this.dataset_cancel.total / this.dataset_cancel.limits);
      }
    },



    // ส่วนของ btn
    async btn_warehouse() {
      this.set_TabListHeader = '0'
      this.set_TabListSubHeader_wait_payment = '1';
      this.set_TabListSubHeader_Process = '10';
      this.set_TabListSubHeader_Return = '100';
      this.map_tab_list_herder();


    },
    async btnSearchOrder(tab) {
      var data, keyword, item;

      var warehouse_select = await this.map_tab_list_herder_1();

      if (tab == '0') {
        this.advanceSearchDialog = false
        // this.getAllOrderList(null)

        this.dataset_all.offsets = 0;
        this.dataset_all.limits = 10;
        this.dataset_all.page = 1;
        keyword = { packageStatusID: null };

        for (item in this.dataset_all.search.select_header) {
          if (this.dataset_all.search.select_header[item].value == this.dataset_all.search.select_topic.value) {
            keyword[this.dataset_all.search.select_topic.value] = this.dataset_all.search.text_topic;
          } else {
            keyword[this.dataset_all.search.select_header[item].value] = "";
          }
        }

        var search_data = this.dataset_all.search
      if(search_data != null){
        
        var shipping_status_id = null
        var shipping_name = search_data.SHshipName
        var tracking_no = search_data.SHtrackingNo
        var SHorderID = null
        if(search_data.SHorderID != null && search_data.SHorderID != ''){
          if (search_data.SHorderID.substring(0, 2).toUpperCase() == 'PA'){
            var str_tmp = search_data.SHorderID.toUpperCase()
            str_tmp = str_tmp.replace("PA", "");
            let isnum = /^\d+$/.test(str_tmp);
            if(isnum){
              SHorderID = str_tmp
            }else {
              SHorderID = 0
            }
          } else {
            let isnum = /^\d+$/.test(search_data.SHorderID);
            if(isnum){
              SHorderID = search_data.SHorderID
            }else {
              SHorderID = 0
            }
          }
        }
        var SH_shop_staff = search_data.SH_shop_staff != null ? search_data.SH_shop_staff['value'] : null
        var SH_phone = search_data.SH_phone != null ? search_data.SH_phone : null
        var SH_ReferenceNumber = search_data.SH_ReferenceNumber != null ? search_data.SH_ReferenceNumber : null

        var created_date_range = this.search_date_range
        var dateFrom = null
        var dateTo = null
        if(created_date_range.length != 0){
          if(created_date_range.length == 1) {
            dateFrom = created_date_range[0] + " 00:00:00"
            dateTo   = created_date_range[0] + " 23:59:59"
          } else {
            dateFrom = created_date_range[0] + " 00:00:00"
            dateTo   = created_date_range[1] + " 23:59:59"
          }
            
        }
        var paid_status_id = 0
        var parcel_status_id = search_data.select_search_parcel_status_id
        var sent_date_range = this.sent_date_range
        var sent_dateFrom = null
        var sent_dateTo = null
        if(sent_date_range.length != 0){
          if(sent_date_range.length == 1) {
            sent_dateFrom = sent_date_range[0] + " 00:00:00"
            sent_dateTo   = sent_date_range[0] + " 23:59:59"
          } else {
            sent_dateFrom = sent_date_range[0] + " 00:00:00"
            sent_dateTo   = sent_date_range[1] + " 23:59:59"
          }
            
        }
        var transferred_date_range = this.transferred_date_range
        var transferred_dateFrom = null
        var transferred_dateTo = null
        if(transferred_date_range.length != 0){
          if(transferred_date_range.length == 1) {
            transferred_dateFrom = transferred_date_range[0] + " 00:00:00"
            transferred_dateTo   = transferred_date_range[0] + " 23:59:59"
          } else {
            transferred_dateFrom = transferred_date_range[0] + " 00:00:00"
            transferred_dateTo   = transferred_date_range[1] + " 23:59:59"
          }
            
        }
      } else {
        dateFrom = null
        dateTo = null
        sent_dateFrom = null
        sent_dateTo = null
        transferred_dateFrom = null
        transferred_dateTo = null
        SH_shop_staff = null
        SH_phone = null
        paid_status_id = 0
        shipping_name = null
        SH_ReferenceNumber = null
        tracking_no = null
        parcel_status_id = null
      }

        keyword.createdFrom = dateFrom
        keyword.createdTo = dateTo
        keyword.sendDateFrom = sent_dateFrom
        keyword.sendDateTo = sent_dateTo
        keyword.packedDateFrom = transferred_dateFrom
        keyword.packedDateTo = transferred_dateTo
        keyword.receiverName = shipping_name
        keyword.receiverPhone = SH_phone
        keyword.referenceSourceIDList = this.channelListSeleced.map(function(item){return item.uniqueID;})
        keyword.createdByName = this.dataset_all.search.SH_shop_staff != null ? this.dataset_all.search.SH_shop_staff['value'] : null

        console.log("keyword all: ", keyword);
        

        this.dataset_all.keyword = keyword;

        data = await this.SearchOrder(this.dataset_all.offsets, this.dataset_all.limits, null, keyword, warehouse_select);
        this.setDataTable_all(data, '0');

      }
      else if (tab == '1') {

        this.dataset_wait_payment_wait_payment.offsets = 0;
        this.dataset_wait_payment_wait_payment.limits = 10;
        this.dataset_wait_payment_wait_payment.page = 1;
        keyword = { packageStatusID: "1" };


        for (item in this.dataset_wait_payment_wait_payment.search.select_header) {
          if (this.dataset_wait_payment_wait_payment.search.select_header[item].value == this.dataset_wait_payment_wait_payment.search.select_topic.value) {
            keyword[this.dataset_wait_payment_wait_payment.search.select_topic.value] = this.dataset_wait_payment_wait_payment.search.text_topic;
          } else {
            keyword[this.dataset_wait_payment_wait_payment.search.select_header[item].value] = "";
          }
        }

        this.dataset_wait_payment_wait_payment.keyword = keyword;

        data = await this.SearchOrder(this.dataset_wait_payment_wait_payment.offsets, this.dataset_wait_payment_wait_payment.limits, null, keyword, warehouse_select);
        this.setDataTable_wait_payment_wait_payment(data, '1');
      }
      else if (tab == '5') {
        this.dataset_wait_payment_check_payment.offsets = 0;
        this.dataset_wait_payment_check_payment.limits = 10;
        this.dataset_wait_payment_check_payment.page = 1;
        keyword = { packageStatusID: "5" };

        for (item in this.dataset_wait_payment_check_payment.search.select_header) {
          if (this.dataset_wait_payment_check_payment.search.select_header[item].value == this.dataset_wait_payment_check_payment.search.select_topic.value) {
            keyword[this.dataset_wait_payment_check_payment.search.select_topic.value] = this.dataset_wait_payment_check_payment.search.text_topic;
          } else {
            keyword[this.dataset_wait_payment_check_payment.search.select_header[item].value] = "";
          }
        }

        this.dataset_wait_payment_check_payment.keyword = keyword;

        data = await this.SearchOrder(this.dataset_wait_payment_check_payment.offsets, this.dataset_wait_payment_check_payment.limits, null, keyword, warehouse_select);
        this.setDataTable_wait_payment_check_payment(data, '5');

      }
      else if (tab == '10') {
        this.dataset_process_map_product.offsets = 0;
        this.dataset_process_map_product.limits = 10;
        this.dataset_process_map_product.page = 1;
        keyword = { packageStatusID: "10" };


        for (item in this.dataset_process_map_product.search.select_header) {
          if (this.dataset_process_map_product.search.select_header[item].value == this.dataset_process_map_product.search.select_topic.value) {
            keyword[this.dataset_process_map_product.search.select_topic.value] = this.dataset_process_map_product.search.text_topic;
          } else {
            keyword[this.dataset_process_map_product.search.select_header[item].value] = "";
          }
        }

        this.dataset_process_map_product.keyword = keyword;

        data = await this.SearchOrder(this.dataset_process_map_product.offsets, this.dataset_process_map_product.limits, null, keyword, warehouse_select);
        this.setDataTable_process_map_product(data, '10');

      }
      else if (tab == '20') {
        this.dataset_process_allocate_stock.offsets = 0;
        this.dataset_process_allocate_stock.limits = 10;
        this.dataset_process_allocate_stock.page = 1;
        keyword = { packageStatusID: "20" };


        for (item in this.dataset_process_allocate_stock.search.select_header) {
          if (this.dataset_process_allocate_stock.search.select_header[item].value == this.dataset_process_allocate_stock.search.select_topic.value) {
            keyword[this.dataset_process_allocate_stock.search.select_topic.value] = this.dataset_process_allocate_stock.search.text_topic;
          } else {
            keyword[this.dataset_process_allocate_stock.search.select_header[item].value] = "";
          }
        }

        this.dataset_process_allocate_stock.keyword = keyword;
        data = await this.SearchOrder(this.dataset_process_allocate_stock.offsets, this.dataset_process_allocate_stock.limits, null, keyword, warehouse_select);
        this.setDataTable_process_allocate_stock(data, '20');

      }
      else if (tab == '30') {
        this.dataset_process_ready_to_ship.offsets = 0;
        this.dataset_process_ready_to_ship.limits = 10;
        this.dataset_process_ready_to_ship.page = 1;
        keyword = { packageStatusID: "30" };


        for (item in this.dataset_process_ready_to_ship.search.select_header) {
          if (this.dataset_process_ready_to_ship.search.select_header[item].value == this.dataset_process_ready_to_ship.search.select_topic.value) {
            keyword[this.dataset_process_ready_to_ship.search.select_topic.value] = this.dataset_process_ready_to_ship.search.text_topic;
          } else {
            keyword[this.dataset_process_ready_to_ship.search.select_header[item].value] = "";
          }
        }

        this.dataset_process_ready_to_ship.keyword = keyword;
        data = await this.SearchOrder(this.dataset_process_ready_to_ship.offsets, this.dataset_process_ready_to_ship.limits, null, keyword, warehouse_select);
        this.setDataTable_process_ready_to_ship(data, '30');

      }
      else if (tab == '40') {
        this.dataset_process_to_pack.offsets = 0;
        this.dataset_process_to_pack.limits = 10;
        this.dataset_process_to_pack.page = 1;
        keyword = { packageStatusID: "40" };


        for (item in this.dataset_process_to_pack.search.select_header) {
          if (this.dataset_process_to_pack.search.select_header[item].value == this.dataset_process_to_pack.search.select_topic.value) {
            keyword[this.dataset_process_to_pack.search.select_topic.value] = this.dataset_process_to_pack.search.text_topic;
          } else {
            keyword[this.dataset_process_to_pack.search.select_header[item].value] = "";
          }
        }

        this.dataset_process_to_pack.keyword = keyword;
        data = await this.SearchOrder(this.dataset_process_to_pack.offsets, this.dataset_process_to_pack.limits, null, keyword, warehouse_select);
        this.setDataTable_process_to_pack(data, '40');

      }
      else if (tab == '60') {
        this.dataset_process_packing.offsets = 0;
        this.dataset_process_packing.limits = 10;
        this.dataset_process_packing.page = 1;
        keyword = { packageStatusID: "60" };


        for (item in this.dataset_process_packing.search.select_header) {
          if (this.dataset_process_packing.search.select_header[item].value == this.dataset_process_packing.search.select_topic.value) {
            keyword[this.dataset_process_packing.search.select_topic.value] = this.dataset_process_packing.search.text_topic;
          } else {
            keyword[this.dataset_process_packing.search.select_header[item].value] = "";
          }
        }

        this.dataset_process_packing.keyword = keyword;
        data = await this.SearchOrder(this.dataset_process_packing.offsets, this.dataset_process_packing.limits, null, keyword, warehouse_select);
        this.setDataTable_process_packing(data, '60');

      }
      else if (tab == '80') {
        this.dataset_process_wait_pickup.offsets = 0;
        this.dataset_process_wait_pickup.limits = 10;
        this.dataset_process_wait_pickup.page = 1;
        keyword = { packageStatusID: "80" };


        for (item in this.dataset_process_wait_pickup.search.select_header) {
          if (this.dataset_process_wait_pickup.search.select_header[item].value == this.dataset_process_wait_pickup.search.select_topic.value) {
            keyword[this.dataset_process_wait_pickup.search.select_topic.value] = this.dataset_process_wait_pickup.search.text_topic;
          } else {
            keyword[this.dataset_process_wait_pickup.search.select_header[item].value] = "";
          }
        }

        this.dataset_process_wait_pickup.keyword = keyword;
        data = await this.SearchOrder(this.dataset_process_wait_pickup.offsets, this.dataset_process_wait_pickup.limits, null, keyword, warehouse_select);
        this.setDataTable_process_wait_pickup(data, '5');

      }
      else if (tab == '90') {
        this.dataset_delivering.offsets = 0;
        this.dataset_delivering.limits = 10;
        this.dataset_delivering.page = 1;
        keyword = { packageStatusID: "90" };


        for (item in this.dataset_delivering.search.select_header) {
          if (this.dataset_delivering.search.select_header[item].value == this.dataset_delivering.search.select_topic.value) {
            keyword[this.dataset_delivering.search.select_topic.value] = this.dataset_delivering.search.text_topic;
          } else {
            keyword[this.dataset_delivering.search.select_header[item].value] = "";
          }
        }

        this.dataset_delivering.keyword = keyword;
        data = await this.SearchOrder(this.dataset_delivering.offsets, this.dataset_delivering.limits, null, keyword, warehouse_select);
        this.setDataTable_dataset_delivering(data, '90');

      }
      else if (tab == '130') {
        this.dataset_success.offsets = 0;
        this.dataset_success.limits = 10;
        this.dataset_success.page = 1;
        keyword = { packageStatusID: "130" };


        for (item in this.dataset_success.search.select_header) {
          if (this.dataset_success.search.select_header[item].value == this.dataset_success.search.select_topic.value) {
            keyword[this.dataset_success.search.select_topic.value] = this.dataset_success.search.text_topic;
          } else {
            keyword[this.dataset_success.search.select_header[item].value] = "";
          }
        }

        this.dataset_success.keyword = keyword;
        data = await this.SearchOrder(this.dataset_success.offsets, this.dataset_success.limits, null, keyword, warehouse_select);
        this.setDataTable_dataset_success(data, '130');

      }
      else if (tab == '100') {
        this.dataset_Return_Returnning.offsets = 0;
        this.dataset_Return_Returnning.limits = 10;
        this.dataset_Return_Returnning.page = 1;
        keyword = { packageStatusID: "100" };


        for (item in this.dataset_Return_Returnning.search.select_header) {
          if (this.dataset_Return_Returnning.search.select_header[item].value == this.dataset_Return_Returnning.search.select_topic.value) {
            keyword[this.dataset_Return_Returnning.search.select_topic.value] = this.dataset_Return_Returnning.search.text_topic;
          } else {
            keyword[this.dataset_Return_Returnning.search.select_header[item].value] = "";
          }
        }

        this.dataset_Return_Returnning.keyword = keyword;
        data = await this.SearchOrder(this.dataset_Return_Returnning.offsets, this.dataset_Return_Returnning.limits, null, keyword, warehouse_select);
        this.setDataTable_Return_Returnning(data, '100');

      }
      else if (tab == '110') {
        this.dataset_Return_To_Return_Stock.offsets = 0;
        this.dataset_Return_To_Return_Stock.limits = 10;
        this.dataset_Return_To_Return_Stock.page = 1;
        keyword = { packageStatusID: "110" };


        for (item in this.dataset_Return_To_Return_Stock.search.select_header) {
          if (this.dataset_Return_To_Return_Stock.search.select_header[item].value == this.dataset_Return_To_Return_Stock.search.select_topic.value) {
            keyword[this.dataset_Return_To_Return_Stock.search.select_topic.value] = this.dataset_Return_To_Return_Stock.search.text_topic;
          } else {
            keyword[this.dataset_Return_To_Return_Stock.search.select_header[item].value] = "";
          }
        }

        this.dataset_Return_To_Return_Stock.keyword = keyword;
        data = await this.SearchOrder(this.dataset_Return_To_Return_Stock.offsets, this.dataset_Return_To_Return_Stock.limits, null, keyword, warehouse_select);
        this.setDataTable_Return_To_Return_Stock(data, '110');

      }
      else if (tab == '120') {
        this.dataset_Return_Stock_Returned.offsets = 0;
        this.dataset_Return_Stock_Returned.limits = 10;
        this.dataset_Return_Stock_Returned.page = 1;
        keyword = { packageStatusID: "120" };


        for (item in this.dataset_Return_Stock_Returned.search.select_header) {
          if (this.dataset_Return_Stock_Returned.search.select_header[item].value == this.dataset_Return_Stock_Returned.search.select_topic.value) {
            keyword[this.dataset_Return_Stock_Returned.search.select_topic.value] = this.dataset_Return_Stock_Returned.search.text_topic;
          } else {
            keyword[this.dataset_Return_Stock_Returned.search.select_header[item].value] = "";
          }
        }

        this.dataset_Return_Stock_Returned.keyword = keyword;
        data = await this.SearchOrder(this.dataset_Return_Stock_Returned.offsets, this.dataset_Return_Stock_Returned.limits, null, keyword, warehouse_select);
        this.setDataTable_Return_Stock_Returned(data, '120');

      }
      else if (tab == '140') {
        this.dataset_cancel.offsets = 0;
        this.dataset_cancel.limits = 10;
        this.dataset_cancel.page = 1;
        keyword = { packageStatusID: "140" };


        for (item in this.dataset_cancel.search.select_header) {
          if (this.dataset_cancel.search.select_header[item].value == this.dataset_cancel.search.select_topic.value) {
            keyword[this.dataset_cancel.search.select_topic.value] = this.dataset_cancel.search.text_topic;
          } else {
            keyword[this.dataset_cancel.search.select_header[item].value] = "";
          }
        }

        this.dataset_cancel.keyword = keyword;
        data = await this.SearchOrder(this.dataset_cancel.offsets, this.dataset_cancel.limits, null, keyword, warehouse_select);
        this.setDataTable_dataset_cancel(data, '140');

      }

    },
    async btnChangeLimits(tab) {
      var data, keyword;
      var warehouse_select = await this.map_tab_list_herder_1();

      if (tab == '0') {
        // await this.getAllOrderList(this.dataset_all.offsets);
        this.dataset_all.page = 1

        keyword = this.dataset_all.keyword;
        data = await this.SearchOrder(this.dataset_all.offsets, this.dataset_all.limits, null, keyword, warehouse_select);
        this.setDataTable_all(data, '0');

      }
      else if (tab == '1') {
        keyword = this.dataset_wait_payment_wait_payment.keyword;
        data = await this.SearchOrder(this.dataset_wait_payment_wait_payment.offsets, this.dataset_wait_payment_wait_payment.limits, null, keyword, warehouse_select);
        this.setDataTable_wait_payment_wait_payment(data, '1');

      }
      else if (tab == '5') {
        keyword = this.dataset_wait_payment_check_payment.keyword;
        data = await this.SearchOrder(this.dataset_wait_payment_check_payment.offsets, this.dataset_wait_payment_check_payment.limits, null, keyword, warehouse_select);
        this.setDataTable_wait_payment_check_payment(data, '5');

      }
      else if (tab == '10') {
        keyword = this.dataset_process_map_product.keyword;
        data = await this.SearchOrder(this.dataset_process_map_product.offsets, this.dataset_process_map_product.limits, null, keyword, warehouse_select);
        this.setDataTable_process_map_product(data, '10');

      }
      else if (tab == '20') {
        keyword = this.dataset_process_allocate_stock.keyword;
        data = await this.SearchOrder(this.dataset_process_allocate_stock.offsets, this.dataset_process_allocate_stock.limits, null, keyword, warehouse_select);
        this.setDataTable_process_allocate_stock(data, '20');

      }
      else if (tab == '30') {
        keyword = this.dataset_process_ready_to_ship.keyword;
        data = await this.SearchOrder(this.dataset_process_ready_to_ship.offsets, this.dataset_process_ready_to_ship.limits, null, keyword, warehouse_select);
        this.setDataTable_process_ready_to_ship(data, '30');

      }
      else if (tab == '40') {
        keyword = this.dataset_process_to_pack.keyword;
        data = await this.SearchOrder(this.dataset_process_to_pack.offsets, this.dataset_process_to_pack.limits, null, keyword, warehouse_select);
        this.setDataTable_process_to_pack(data, '40');

      }
      else if (tab == '60') {
        keyword = this.dataset_process_packing.keyword;
        data = await this.SearchOrder(this.dataset_process_packing.offsets, this.dataset_process_packing.limits, null, keyword, warehouse_select);
        this.setDataTable_process_packing(data, '60');

      }
      else if (tab == '80') {
        keyword = this.dataset_process_wait_pickup.keyword;
        data = await this.SearchOrder(this.dataset_process_wait_pickup.offsets, this.dataset_process_wait_pickup.limits, null, keyword, warehouse_select);
        this.setDataTable_process_wait_pickup(data, '5');

      }
      else if (tab == '90') {
        keyword = this.dataset_delivering.keyword;
        data = await this.SearchOrder(this.dataset_delivering.offsets, this.dataset_delivering.limits, null, keyword, warehouse_select);
        this.setDataTable_dataset_delivering(data, '90');

      }
      else if (tab == '130') {
        keyword = this.dataset_success.keyword;
        data = await this.SearchOrder(this.dataset_success.offsets, this.dataset_success.limits, null, keyword, warehouse_select);
        this.setDataTable_dataset_success(data, '130');

      }
      else if (tab == '100') {
        keyword = this.dataset_Return_Returnning.keyword;
        data = await this.SearchOrder(this.dataset_Return_Returnning.offsets, this.dataset_Return_Returnning.limits, null, keyword, warehouse_select);
        this.setDataTable_Return_Returnning(data, '100');

      }
      else if (tab == '110') {
        keyword = this.dataset_Return_To_Return_Stock.keyword;
        data = await this.SearchOrder(this.dataset_Return_To_Return_Stock.offsets, this.dataset_Return_To_Return_Stock.limits, null, keyword, warehouse_select);
        this.setDataTable_Return_To_Return_Stock(data, '100');

      }
      else if (tab == '120') {
        keyword = this.dataset_Return_To_Return_Stock.keyword;
        data = await this.SearchOrder(this.dataset_Return_To_Return_Stock.offsets, this.dataset_Return_To_Return_Stock.limits, null, keyword, warehouse_select);
        this.setDataTable_Return_Stock_Returned(data, '120');

      }

      else if (tab == '140') {
        keyword = this.dataset_cancel.keyword;
        data = await this.SearchOrder(this.dataset_cancel.offsets, this.dataset_cancel.limits, null, keyword, warehouse_select);
        this.setDataTable_dataset_cancel(data, '140');

      }


    },
    async btnChangePage(tab) {
      var data, keyword;

      var warehouse_select = await this.map_tab_list_herder_1();

      if (tab == '0') {
        // this.dataset_all.offsets = (this.dataset_all.page * this.dataset_all.limits) - this.dataset_all.limits
        // this.getAllOrderList(this.dataset_all.offsets)

        this.dataset_all.offsets = (this.dataset_all.page * this.dataset_all.limits) - this.dataset_all.limits
        keyword = this.dataset_all.keyword;
        data = await this.SearchOrder(this.dataset_all.offsets, this.dataset_all.limits, null, keyword, warehouse_select);
        this.setDataTable_all(data, '0');

      }
      else if (tab == '1') {
        this.dataset_wait_payment_wait_payment.offsets = (this.dataset_wait_payment_wait_payment.page * this.dataset_wait_payment_wait_payment.limits) - this.dataset_wait_payment_wait_payment.limits
        keyword = this.dataset_wait_payment_wait_payment.keyword;
        data = await this.SearchOrder(this.dataset_wait_payment_wait_payment.offsets, this.dataset_wait_payment_wait_payment.limits, null, keyword, warehouse_select);
        this.setDataTable_wait_payment_wait_payment(data, '1');

      }
      else if (tab == '5') {
        this.dataset_wait_payment_check_payment.offsets = (this.dataset_wait_payment_check_payment.page * this.dataset_wait_payment_check_payment.limits) - this.dataset_wait_payment_check_payment.limits
        keyword = this.dataset_wait_payment_check_payment.keyword;
        data = await this.SearchOrder(this.dataset_wait_payment_check_payment.offsets, this.dataset_wait_payment_check_payment.limits, null, keyword, warehouse_select);
        this.setDataTable_wait_payment_check_payment(data, '5');

      }
      else if (tab == '10') {
        this.dataset_process_map_product.offsets = (this.dataset_process_map_product.page * this.dataset_process_map_product.limits) - this.dataset_process_map_product.limits
        keyword = this.dataset_process_map_product.keyword;
        data = await this.SearchOrder(this.dataset_process_map_product.offsets, this.dataset_process_map_product.limits, null, keyword, warehouse_select);
        this.setDataTable_process_map_product(data, '10');

      }
      else if (tab == '20') {
        this.dataset_process_allocate_stock.offsets = (this.dataset_process_allocate_stock.page * this.dataset_process_allocate_stock.limits) - this.dataset_process_allocate_stock.limits
        keyword = this.dataset_process_allocate_stock.keyword;
        data = await this.SearchOrder(this.dataset_process_allocate_stock.offsets, this.dataset_process_allocate_stock.limits, null, keyword, warehouse_select);
        this.setDataTable_process_allocate_stock(data, '20');

      }
      else if (tab == '30') {
        this.dataset_process_ready_to_ship.offsets = (this.dataset_process_ready_to_ship.page * this.dataset_process_ready_to_ship.limits) - this.dataset_process_ready_to_ship.limits
        keyword = this.dataset_process_ready_to_ship.keyword;
        data = await this.SearchOrder(this.dataset_process_ready_to_ship.offsets, this.dataset_process_ready_to_ship.limits, null, keyword, warehouse_select);
        this.setDataTable_process_ready_to_ship(data, '30');

      }
      else if (tab == '40') {
        this.dataset_process_to_pack.offsets = (this.dataset_process_to_pack.page * this.dataset_process_to_pack.limits) - this.dataset_process_to_pack.limits
        keyword = this.dataset_process_to_pack.keyword;
        data = await this.SearchOrder(this.dataset_process_to_pack.offsets, this.dataset_process_to_pack.limits, null, keyword, warehouse_select);
        this.setDataTable_process_to_pack(data, '40');

      }
      else if (tab == '60') {
        this.dataset_process_packing.offsets = (this.dataset_process_packing.page * this.dataset_process_packing.limits) - this.dataset_process_packing.limits
        keyword = this.dataset_process_packing.keyword;
        data = await this.SearchOrder(this.dataset_process_packing.offsets, this.dataset_process_packing.limits, null, keyword, warehouse_select);
        this.setDataTable_process_packing(data, '60');

      }
      else if (tab == '80') {
        this.dataset_process_wait_pickup.offsets = (this.dataset_process_wait_pickup.page * this.dataset_process_wait_pickup.limits) - this.dataset_process_wait_pickup.limits
        keyword = this.dataset_process_wait_pickup.keyword;
        data = await this.SearchOrder(this.dataset_process_wait_pickup.offsets, this.dataset_process_wait_pickup.limits, null, keyword, warehouse_select);
        this.setDataTable_process_wait_pickup(data, '5');

      }
      else if (tab == '90') {
        this.dataset_delivering.offsets = (this.dataset_delivering.page * this.dataset_delivering.limits) - this.dataset_delivering.limits

        keyword = this.dataset_delivering.keyword;
        data = await this.SearchOrder(this.dataset_delivering.offsets, this.dataset_delivering.limits, null, keyword, warehouse_select);
        this.setDataTable_dataset_delivering(data, '90');

      }
      else if (tab == '130') {
        this.dataset_success.offsets = (this.dataset_success.page * this.dataset_success.limits) - this.dataset_success.limits

        keyword = this.dataset_success.keyword;
        data = await this.SearchOrder(this.dataset_success.offsets, this.dataset_success.limits, null, keyword, warehouse_select);
        this.setDataTable_dataset_success(data, '130');

      }
      else if (tab == '100') {
        this.dataset_Return_Returnning.offsets = (this.dataset_Return_Returnning.page * this.dataset_Return_Returnning.limits) - this.dataset_Return_Returnning.limits

        keyword = this.dataset_Return_Returnning.keyword;
        data = await this.SearchOrder(this.dataset_Return_Returnning.offsets, this.dataset_Return_Returnning.limits, null, keyword, warehouse_select);
        this.setDataTable_Return_Returnning(data, '100');

      }
      else if (tab == '110') {
        this.dataset_Return_To_Return_Stock.offsets = (this.dataset_Return_To_Return_Stock.page * this.dataset_Return_To_Return_Stock.limits) - this.dataset_Return_To_Return_Stock.limits

        keyword = this.dataset_Return_To_Return_Stock.keyword;
        data = await this.SearchOrder(this.dataset_Return_To_Return_Stock.offsets, this.dataset_Return_To_Return_Stock.limits, null, keyword, warehouse_select);
        this.setDataTable_Return_To_Return_Stock(data, '110');

      }
      else if (tab == '120') {
        this.dataset_Return_Stock_Returned.offsets = (this.dataset_Return_Stock_Returned.page * this.dataset_Return_Stock_Returned.limits) - this.dataset_Return_Stock_Returned.limits

        keyword = this.dataset_Return_Stock_Returned.keyword;
        data = await this.SearchOrder(this.dataset_Return_Stock_Returned.offsets, this.dataset_Return_Stock_Returned.limits, null, keyword, warehouse_select);
        this.setDataTable_Return_Stock_Returned(data, '120');

      }
      else if (tab == '140') {
        this.dataset_cancel.offsets = (this.dataset_cancel.page * this.dataset_cancel.limits) - this.dataset_cancel.limits

        keyword = this.dataset_cancel.keyword;
        data = await this.SearchOrder(this.dataset_cancel.offsets, this.dataset_cancel.limits, null, keyword, warehouse_select);
        this.setDataTable_dataset_cancel(data, '140');

      }
    },
    async btnChannelSource(tab, channelID) {
      var data, keyword;

      var warehouse_select = await this.map_tab_list_herder_1();

      if (tab == '1') {
        this.dataset_wait_payment_wait_payment.page = 1;
        this.dataset_wait_payment_wait_payment.dataTable = [];
        this.dataset_wait_payment_wait_payment.selected = [];
        this.dataset_wait_payment_wait_payment.offsets = 0;
        keyword = this.dataset_wait_payment_wait_payment.keyword;
        keyword.channelID = this.dataset_wait_payment_wait_payment.search.select_channel.channelID;
        keyword.expressCompanyID = null;
        keyword.printType = null;
        keyword.isSelectAll = false;
        keyword.packageStatusID = "1";

        this.dataset_wait_payment_wait_payment.keyword = keyword;
        this.dataset_wait_payment_wait_payment.offsets = 0
        this.dataset_wait_payment_wait_payment.limits = 10

        data = await this.SearchOrder(this.dataset_wait_payment_wait_payment.offsets, this.dataset_wait_payment_wait_payment.limits, null, keyword, warehouse_select);
        this.setDataTable_wait_payment_wait_payment(data, '1');

      }
      else if (tab == '5') {
        this.dataset_wait_payment_check_payment.page = 1;
        this.dataset_wait_payment_check_payment.dataTable = [];
        this.dataset_wait_payment_check_payment.selected = [];
        this.dataset_wait_payment_check_payment.offsets = 0;
        keyword = this.dataset_wait_payment_check_payment.keyword;
        keyword.channelID = this.dataset_wait_payment_check_payment.search.select_channel.channelID;
        keyword.expressCompanyID = null;
        keyword.printType = null;
        keyword.isSelectAll = false;
        keyword.packageStatusID = "5";

        this.dataset_wait_payment_check_payment.keyword = keyword;
        this.dataset_wait_payment_check_payment.offsets = 0
        this.dataset_wait_payment_check_payment.limits = 10

        data = await this.SearchOrder(this.dataset_wait_payment_check_payment.offsets, this.dataset_wait_payment_check_payment.limits, null, keyword, warehouse_select);
        this.setDataTable_wait_payment_check_payment(data, '5');

      }
      else if (tab == '10') {
        this.dataset_process_map_product.page = 1;
        this.dataset_process_map_product.dataTable = [];
        this.dataset_process_map_product.selected = [];
        this.dataset_process_map_product.offsets = 0;
        keyword = this.dataset_process_map_product.keyword;
        keyword.channelID = this.dataset_process_map_product.search.select_channel.channelID;
        keyword.expressCompanyID = null;
        keyword.printType = null;
        keyword.isSelectAll = false;
        keyword.packageStatusID = "10";

        this.dataset_process_map_product.keyword = keyword;
        this.dataset_process_map_product.offsets = 0
        this.dataset_process_map_product.limits = 10

        data = await this.SearchOrder(this.dataset_process_map_product.offsets, this.dataset_process_map_product.limits, null, keyword, warehouse_select);
        this.setDataTable_process_map_product(data, '10');

      }
      else if (tab == '20') {
        this.dataset_process_allocate_stock.page = 1;
        this.dataset_process_allocate_stock.dataTable = [];
        this.dataset_process_allocate_stock.selected = [];
        this.dataset_process_allocate_stock.offsets = 0;
        keyword = this.dataset_process_allocate_stock.keyword;
        keyword.channelID = this.dataset_process_allocate_stock.search.select_channel.channelID;
        keyword.expressCompanyID = null;
        keyword.printType = null;
        keyword.isSelectAll = false;
        keyword.packageStatusID = "20";

        this.dataset_process_allocate_stock.keyword = keyword;
        this.dataset_process_allocate_stock.offsets = 0
        this.dataset_process_allocate_stock.limits = 10

        data = await this.SearchOrder(this.dataset_process_allocate_stock.offsets, this.dataset_process_allocate_stock.limits, null, keyword, warehouse_select);
        this.setDataTable_process_allocate_stock(data, '20');

      }
      else if (tab == '30') {
        this.dataset_process_ready_to_ship.page = 1;
        this.dataset_process_ready_to_ship.dataTable = [];
        this.dataset_process_ready_to_ship.selected = [];
        this.dataset_process_ready_to_ship.offsets = 0;
        keyword = this.dataset_process_ready_to_ship.keyword;
        keyword.channelID = this.dataset_process_ready_to_ship.search.select_channel.channelID;
        keyword.expressCompanyID = null;
        keyword.printType = null;
        keyword.isSelectAll = false;
        keyword.packageStatusID = "30";

        this.dataset_process_ready_to_ship.keyword = keyword;
        this.dataset_process_ready_to_ship.offsets = 0
        this.dataset_process_ready_to_ship.limits = 10

        data = await this.SearchOrder(this.dataset_process_ready_to_ship.offsets, this.dataset_process_ready_to_ship.limits, null, keyword, warehouse_select);
        this.setDataTable_process_ready_to_ship(data, '30');

      }
      else if (tab == '40') {
        this.dataset_process_to_pack.page = 1;
        this.dataset_process_to_pack.dataTable = [];
        this.dataset_process_to_pack.selected = [];
        this.dataset_process_to_pack.offsets = 0;
        keyword = this.dataset_process_to_pack.keyword;
        keyword.channelID = this.dataset_process_to_pack.search.select_channel.channelID;
        keyword.expressCompanyID = null;
        keyword.printType = null;
        keyword.isSelectAll = false;
        keyword.packageStatusID = "40";

        this.dataset_process_to_pack.keyword = keyword;
        this.dataset_process_to_pack.offsets = 0
        this.dataset_process_to_pack.limits = 10

        data = await this.SearchOrder(this.dataset_process_to_pack.offsets, this.dataset_process_to_pack.limits, null, keyword, warehouse_select);
        this.setDataTable_process_to_pack(data, '40');

      }
      else if (tab == '60') {
        this.dataset_process_packing.page = 1;
        this.dataset_process_packing.dataTable = [];
        this.dataset_process_packing.selected = [];
        this.dataset_process_packing.offsets = 0;
        keyword = this.dataset_process_packing.keyword;
        keyword.channelID = this.dataset_process_packing.search.select_channel.channelID;
        keyword.expressCompanyID = null;
        keyword.printType = null;
        keyword.isSelectAll = false;
        keyword.packageStatusID = "60";

        this.dataset_process_packing.keyword = keyword;
        this.dataset_process_packing.offsets = 0
        this.dataset_process_packing.limits = 10

        data = await this.SearchOrder(this.dataset_process_packing.offsets, this.dataset_process_packing.limits, null, keyword, warehouse_select);
        this.setDataTable_process_packing(data, '60');

      }
      else if (tab == '80') {
        this.dataset_process_wait_pickup.page = 1;
        this.dataset_process_wait_pickup.dataTable = [];
        this.dataset_process_wait_pickup.selected = [];
        this.dataset_process_wait_pickup.offsets = 0;
        keyword = this.dataset_process_wait_pickup.keyword;
        keyword.channelID = this.dataset_process_wait_pickup.search.select_channel.channelID;
        keyword.expressCompanyID = null;
        keyword.printType = null;
        keyword.isSelectAll = false;
        keyword.packageStatusID = "80";

        this.dataset_process_wait_pickup.keyword = keyword;
        this.dataset_process_wait_pickup.offsets = 0
        this.dataset_process_wait_pickup.limits = 10

        data = await this.SearchOrder(this.dataset_process_wait_pickup.offsets, this.dataset_process_wait_pickup.limits, null, keyword, warehouse_select);
        this.setDataTable_process_wait_pickup(data, '5');

      }
      else if (tab == '90') {
        this.dataset_delivering.page = 1;
        this.dataset_delivering.dataTable = [];
        this.dataset_delivering.selected = [];
        this.dataset_delivering.offsets = 0;
        keyword = this.dataset_delivering.keyword;
        keyword.channelID = this.dataset_delivering.search.select_channel.channelID;
        keyword.expressCompanyID = null;
        keyword.printType = null;
        keyword.isSelectAll = false;
        keyword.packageStatusID = "90";

        this.dataset_delivering.keyword = keyword;
        this.dataset_delivering.offsets = 0
        this.dataset_delivering.limits = 10

        data = await this.SearchOrder(this.dataset_delivering.offsets, this.dataset_delivering.limits, null, keyword, warehouse_select);
        this.setDataTable_dataset_delivering(data, '90');

      }
      else if (tab == '130') {
        this.dataset_success.page = 1;
        this.dataset_success.dataTable = [];
        this.dataset_success.selected = [];
        this.dataset_success.offsets = 0;
        keyword = this.dataset_success.keyword;
        keyword.channelID = this.dataset_success.search.select_channel.channelID;
        keyword.expressCompanyID = null;
        keyword.printType = null;
        keyword.isSelectAll = false;
        keyword.packageStatusID = "130";

        this.dataset_success.keyword = keyword;
        this.dataset_success.offsets = 0
        this.dataset_success.limits = 10

        data = await this.SearchOrder(this.dataset_success.offsets, this.dataset_success.limits, null, keyword, warehouse_select);
        this.setDataTable_dataset_success(data, '130');

      }
      else if (tab == '100') {
        this.dataset_Return_Returnning.page = 1;
        this.dataset_Return_Returnning.dataTable = [];
        this.dataset_Return_Returnning.selected = [];
        this.dataset_Return_Returnning.offsets = 0;
        keyword = this.dataset_Return_Returnning.keyword;
        keyword.channelID = this.dataset_Return_Returnning.search.select_channel.channelID;
        keyword.expressCompanyID = null;
        keyword.printType = null;
        keyword.isSelectAll = false;
        keyword.packageStatusID = "100";

        this.dataset_Return_Returnning.keyword = keyword;
        this.dataset_Return_Returnning.offsets = 0
        this.dataset_Return_Returnning.limits = 10

        data = await this.SearchOrder(this.dataset_Return_Returnning.offsets, this.dataset_Return_Returnning.limits, null, keyword, warehouse_select);
        this.setDataTable_Return_Returnning(data, '100');

      }
      else if (tab == '110') {
        this.dataset_Return_To_Return_Stock.page = 1;
        this.dataset_Return_To_Return_Stock.dataTable = [];
        this.dataset_Return_To_Return_Stock.selected = [];
        this.dataset_Return_To_Return_Stock.offsets = 0;
        keyword = this.dataset_Return_To_Return_Stock.keyword;
        keyword.channelID = this.dataset_Return_To_Return_Stock.search.select_channel.channelID;
        keyword.expressCompanyID = null;
        keyword.printType = null;
        keyword.isSelectAll = false;
        keyword.packageStatusID = "110";

        this.dataset_Return_To_Return_Stock.keyword = keyword;
        this.dataset_Return_To_Return_Stock.offsets = 0
        this.dataset_Return_To_Return_Stock.limits = 10

        data = await this.SearchOrder(this.dataset_Return_To_Return_Stock.offsets, this.dataset_Return_To_Return_Stock.limits, null, keyword, warehouse_select);
        this.setDataTable_Return_To_Return_Stock(data, '110');

      }
      else if (tab == '120') {
        this.dataset_Return_Stock_Returned.page = 1;
        this.dataset_Return_Stock_Returned.dataTable = [];
        this.dataset_Return_Stock_Returned.selected = [];
        this.dataset_Return_Stock_Returned.offsets = 0;
        keyword = this.dataset_Return_Stock_Returned.keyword;
        keyword.channelID = this.dataset_Return_Stock_Returned.search.select_channel.channelID;
        keyword.expressCompanyID = null;
        keyword.printType = null;
        keyword.isSelectAll = false;
        keyword.packageStatusID = "120";

        this.dataset_Return_Stock_Returned.keyword = keyword;
        this.dataset_Return_Stock_Returned.offsets = 0
        this.dataset_Return_Stock_Returned.limits = 10

        data = await this.SearchOrder(this.dataset_Return_Stock_Returned.offsets, this.dataset_Return_Stock_Returned.limits, null, keyword, warehouse_select);
        this.setDataTable_Return_Stock_Returned(data, '120');

      }
      else if (tab == '140') {
        this.dataset_cancel.page = 1;
        this.dataset_cancel.dataTable = [];
        this.dataset_cancel.selected = [];
        this.dataset_cancel.offsets = 0;
        keyword = this.dataset_cancel.keyword;
        keyword.channelID = this.dataset_cancel.search.select_channel.channelID;
        keyword.expressCompanyID = null;
        keyword.printType = null;
        keyword.isSelectAll = false;
        keyword.packageStatusID = "110";

        this.dataset_cancel.keyword = keyword;
        this.dataset_cancel.offsets = 0
        this.dataset_cancel.limits = 10

        data = await this.SearchOrder(this.dataset_cancel.offsets, this.dataset_cancel.limits, null, keyword, warehouse_select);
        this.setDataTable_dataset_cancel(data, '140');

      }

    },
    async btnExprees(tab, expressCompanyID) {
      var data, keyword;

      var warehouse_select = await this.map_tab_list_herder_1();

      if (tab == '1') {
        this.dataset_wait_payment_wait_payment.page = 1;
        this.dataset_wait_payment_wait_payment.dataTable = [];
        this.dataset_wait_payment_wait_payment.selected = [];
        this.dataset_wait_payment_wait_payment.offsets = 0;
        keyword = this.dataset_wait_payment_wait_payment.keyword;
        keyword.expressCompanyID = this.dataset_wait_payment_wait_payment.search.select_express.expressCompanyID;
        keyword.printType = null;
        keyword.isSelectAll = false;


        this.dataset_wait_payment_wait_payment.keyword = keyword;
        this.dataset_wait_payment_wait_payment.offsets = 0
        this.dataset_wait_payment_wait_payment.limits = 10

        data = await this.SearchOrder(this.dataset_wait_payment_wait_payment.offsets, this.dataset_wait_payment_wait_payment.limits, null, keyword, warehouse_select);
        this.setDataTable_wait_payment_wait_payment(data, '1');

      }
      else if (tab == '5') {
        this.dataset_wait_payment_check_payment.page = 1;
        this.dataset_wait_payment_check_payment.dataTable = [];
        this.dataset_wait_payment_check_payment.selected = [];
        this.dataset_wait_payment_check_payment.offsets = 0;
        keyword = this.dataset_wait_payment_check_payment.keyword;
        keyword.expressCompanyID = this.dataset_wait_payment_check_payment.search.select_express.expressCompanyID;
        keyword.printType = null;
        keyword.isSelectAll = false;

        this.dataset_wait_payment_check_payment.keyword = keyword;
        this.dataset_wait_payment_check_payment.offsets = 0
        this.dataset_wait_payment_check_payment.limits = 10

        data = await this.SearchOrder(this.dataset_wait_payment_check_payment.offsets, this.dataset_wait_payment_check_payment.limits, null, keyword, warehouse_select);
        this.setDataTable_wait_payment_check_payment(data, '5');

      }
      else if (tab == '10') {
        this.dataset_process_map_product.page = 1;
        this.dataset_process_map_product.dataTable = [];
        this.dataset_process_map_product.selected = [];
        this.dataset_process_map_product.offsets = 0;
        keyword = this.dataset_process_map_product.keyword;
        keyword.expressCompanyID = this.dataset_process_map_product.search.select_express.expressCompanyID;
        keyword.printType = null;
        keyword.isSelectAll = false;

        this.dataset_process_map_product.keyword = keyword;
        this.dataset_process_map_product.offsets = 0
        this.dataset_process_map_product.limits = 10

        data = await this.SearchOrder(this.dataset_process_map_product.offsets, this.dataset_process_map_product.limits, null, keyword, warehouse_select);
        this.setDataTable_process_map_product(data, '10');

      }
      else if (tab == '20') {
        this.dataset_process_allocate_stock.page = 1;
        this.dataset_process_allocate_stock.dataTable = [];
        this.dataset_process_allocate_stock.selected = [];
        this.dataset_process_allocate_stock.offsets = 0;
        keyword = this.dataset_process_allocate_stock.keyword;
        keyword.expressCompanyID = this.dataset_process_allocate_stock.search.select_express.expressCompanyID;
        keyword.printType = null;
        keyword.isSelectAll = false;

        this.dataset_process_allocate_stock.keyword = keyword;
        this.dataset_process_allocate_stock.offsets = 0
        this.dataset_process_allocate_stock.limits = 10

        data = await this.SearchOrder(this.dataset_process_allocate_stock.offsets, this.dataset_process_allocate_stock.limits, null, keyword, warehouse_select);
        this.setDataTable_process_allocate_stock(data, '20');

      }
      else if (tab == '30') {
        this.dataset_process_ready_to_ship.page = 1;
        this.dataset_process_ready_to_ship.dataTable = [];
        this.dataset_process_ready_to_ship.selected = [];
        this.dataset_process_ready_to_ship.offsets = 0;
        keyword = this.dataset_process_ready_to_ship.keyword;
        keyword.expressCompanyID = this.dataset_process_ready_to_ship.search.select_express.expressCompanyID;
        keyword.printType = null;
        keyword.isSelectAll = false;

        this.dataset_process_ready_to_ship.keyword = keyword;
        this.dataset_process_ready_to_ship.offsets = 0
        this.dataset_process_ready_to_ship.limits = 10

        data = await this.SearchOrder(this.dataset_process_ready_to_ship.offsets, this.dataset_process_ready_to_ship.limits, null, keyword, warehouse_select);
        this.setDataTable_process_ready_to_ship(data, '30');

      }
      else if (tab == '40') {
        this.dataset_process_to_pack.page = 1;
        this.dataset_process_to_pack.dataTable = [];
        this.dataset_process_to_pack.selected = [];
        this.dataset_process_to_pack.offsets = 0;
        keyword = this.dataset_process_to_pack.keyword;
        keyword.expressCompanyID = this.dataset_process_to_pack.search.select_express.expressCompanyID;
        keyword.printType = null;
        keyword.isSelectAll = false;

        this.dataset_process_to_pack.keyword = keyword;
        this.dataset_process_to_pack.offsets = 0
        this.dataset_process_to_pack.limits = 10

        data = await this.SearchOrder(this.dataset_process_to_pack.offsets, this.dataset_process_to_pack.limits, null, keyword, warehouse_select);
        this.setDataTable_process_to_pack(data, '40');

      }
      else if (tab == '60') {
        this.dataset_process_packing.page = 1;
        this.dataset_process_packing.dataTable = [];
        this.dataset_process_packing.selected = [];
        this.dataset_process_packing.offsets = 0;
        keyword = this.dataset_process_packing.keyword;
        keyword.expressCompanyID = this.dataset_process_packing.search.select_express.expressCompanyID;
        keyword.printType = null;
        keyword.isSelectAll = false;

        this.dataset_process_packing.keyword = keyword;
        this.dataset_process_packing.offsets = 0
        this.dataset_process_packing.limits = 10

        data = await this.SearchOrder(this.dataset_process_packing.offsets, this.dataset_process_packing.limits, null, keyword, warehouse_select);
        this.setDataTable_process_packing(data, '60');

      }
      else if (tab == '80') {
        this.dataset_process_wait_pickup.page = 1;
        this.dataset_process_wait_pickup.dataTable = [];
        this.dataset_process_wait_pickup.selected = [];
        this.dataset_process_wait_pickup.offsets = 0;
        keyword = this.dataset_process_wait_pickup.keyword;
        keyword.expressCompanyID = this.dataset_process_wait_pickup.search.select_express.expressCompanyID;
        keyword.printType = null;
        keyword.isSelectAll = false;

        this.dataset_process_wait_pickup.keyword = keyword;
        this.dataset_process_wait_pickup.offsets = 0
        this.dataset_process_wait_pickup.limits = 10

        data = await this.SearchOrder(this.dataset_process_wait_pickup.offsets, this.dataset_process_wait_pickup.limits, null, keyword, warehouse_select);
        this.setDataTable_process_wait_pickup(data, '5');

      }
      else if (tab == '90') {
        this.dataset_delivering.page = 1;
        this.dataset_delivering.dataTable = [];
        this.dataset_delivering.selected = [];
        this.dataset_delivering.offsets = 0;
        keyword = this.dataset_delivering.keyword;
        keyword.expressCompanyID = this.dataset_delivering.search.select_express.expressCompanyID;
        keyword.printType = null;
        keyword.isSelectAll = false;

        this.dataset_delivering.keyword = keyword;
        this.dataset_delivering.offsets = 0
        this.dataset_delivering.limits = 10

        data = await this.SearchOrder(this.dataset_delivering.offsets, this.dataset_delivering.limits, null, keyword, warehouse_select);
        this.setDataTable_dataset_delivering(data, '90');

      }
      else if (tab == '130') {
        this.dataset_success.page = 1;
        this.dataset_success.dataTable = [];
        this.dataset_success.selected = [];
        this.dataset_success.offsets = 0;
        keyword = this.dataset_success.keyword;
        keyword.expressCompanyID = this.dataset_success.search.select_express.expressCompanyID;
        keyword.printType = null;
        keyword.isSelectAll = false;

        this.dataset_success.keyword = keyword;
        this.dataset_success.offsets = 0
        this.dataset_success.limits = 10

        data = await this.SearchOrder(this.dataset_success.offsets, this.dataset_success.limits, null, keyword, warehouse_select);
        this.setDataTable_dataset_success(data, '130');

      }
      else if (tab == '100') {
        this.dataset_Return_Returnning.page = 1;
        this.dataset_Return_Returnning.dataTable = [];
        this.dataset_Return_Returnning.selected = [];
        this.dataset_Return_Returnning.offsets = 0;
        keyword = this.dataset_Return_Returnning.keyword;
        keyword.expressCompanyID = this.dataset_Return_Returnning.search.select_express.expressCompanyID;
        keyword.printType = null;
        keyword.isSelectAll = false;

        this.dataset_Return_Returnning.keyword = keyword;
        this.dataset_Return_Returnning.offsets = 0
        this.dataset_Return_Returnning.limits = 10

        data = await this.SearchOrder(this.dataset_Return_Returnning.offsets, this.dataset_Return_Returnning.limits, null, keyword, warehouse_select);
        this.setDataTable_Return_Returnning(data, '100');

      }
      else if (tab == '110') {
        this.dataset_Return_To_Return_Stock.page = 1;
        this.dataset_Return_To_Return_Stock.dataTable = [];
        this.dataset_Return_To_Return_Stock.selected = [];
        this.dataset_Return_To_Return_Stock.offsets = 0;
        keyword = this.dataset_Return_To_Return_Stock.keyword;
        keyword.expressCompanyID = this.dataset_Return_To_Return_Stock.search.select_express.expressCompanyID;
        keyword.printType = null;
        keyword.isSelectAll = false;


        this.dataset_Return_To_Return_Stock.keyword = keyword;
        this.dataset_Return_To_Return_Stock.offsets = 0
        this.dataset_Return_To_Return_Stock.limits = 10

        data = await this.SearchOrder(this.dataset_Return_To_Return_Stock.offsets, this.dataset_Return_To_Return_Stock.limits, null, keyword, warehouse_select);
        this.setDataTable_Return_To_Return_Stock(data, '110');

      }
      else if (tab == '120') {
        this.dataset_Return_Stock_Returned.page = 1;
        this.dataset_Return_Stock_Returned.dataTable = [];
        this.dataset_Return_Stock_Returned.selected = [];
        this.dataset_Return_Stock_Returned.offsets = 0;
        keyword = this.dataset_Return_Stock_Returned.keyword;
        keyword.expressCompanyID = this.dataset_Return_Stock_Returned.search.select_express.expressCompanyID;
        keyword.printType = null;
        keyword.isSelectAll = false;

        this.dataset_Return_Stock_Returned.keyword = keyword;
        this.dataset_Return_Stock_Returned.offsets = 0
        this.dataset_Return_Stock_Returned.limits = 10

        data = await this.SearchOrder(this.dataset_Return_Stock_Returned.offsets, this.dataset_Return_Stock_Returned.limits, null, keyword, warehouse_select);
        this.setDataTable_Return_Stock_Returned(data, '120');

      }
      else if (tab == '140') {
        this.dataset_cancel.page = 1;
        this.dataset_cancel.dataTable = [];
        this.dataset_cancel.selected = [];
        this.dataset_cancel.offsets = 0;
        keyword = this.dataset_cancel.keyword;
        keyword.expressCompanyID = this.dataset_cancel.search.select_express.expressCompanyID;
        keyword.printType = null;
        keyword.isSelectAll = false;

        this.dataset_cancel.keyword = keyword;
        this.dataset_cancel.offsets = 0
        this.dataset_cancel.limits = 10

        data = await this.SearchOrder(this.dataset_cancel.offsets, this.dataset_cancel.limits, null, keyword, warehouse_select);
        this.setDataTable_dataset_cancel(data, '140');

      }

    },
    async btnSearchPrintType(tab, expressCompanyID) {
      var data, keyword;

      var warehouse_select = await this.map_tab_list_herder_1();

      if (tab == '1') {
        this.dataset_wait_payment_wait_payment.page = 1;
        this.dataset_wait_payment_wait_payment.dataTable = [];
        this.dataset_wait_payment_wait_payment.selected = [];
        this.dataset_wait_payment_wait_payment.offsets = 0;
        keyword = this.dataset_wait_payment_wait_payment.keyword;
        keyword.printType = this.dataset_wait_payment_wait_payment.search.select_print.id;
        keyword.isSelectAll = false;

        this.dataset_wait_payment_wait_payment.keyword = keyword;
        this.dataset_wait_payment_wait_payment.offsets = 0
        this.dataset_wait_payment_wait_payment.limits = 10

        data = await this.SearchOrder(this.dataset_wait_payment_wait_payment.offsets, this.dataset_wait_payment_wait_payment.limits, null, keyword, warehouse_select);
        this.setDataTable_wait_payment_wait_payment(data, '1');

      }
      else if (tab == '5') {
        this.dataset_wait_payment_check_payment.page = 1;
        this.dataset_wait_payment_check_payment.dataTable = [];
        this.dataset_wait_payment_check_payment.selected = [];
        this.dataset_wait_payment_check_payment.offsets = 0;
        keyword = this.dataset_wait_payment_check_payment.keyword;
        keyword.printType = this.dataset_wait_payment_check_payment.search.select_print.id;
        keyword.isSelectAll = false;

        this.dataset_wait_payment_check_payment.keyword = keyword;
        this.dataset_wait_payment_check_payment.offsets = 0
        this.dataset_wait_payment_check_payment.limits = 10

        data = await this.SearchOrder(this.dataset_wait_payment_check_payment.offsets, this.dataset_wait_payment_check_payment.limits, null, keyword, warehouse_select);
        this.setDataTable_wait_payment_check_payment(data, '5');

      }
      else if (tab == '10') {
        this.dataset_process_map_product.page = 1;
        this.dataset_process_map_product.dataTable = [];
        this.dataset_process_map_product.selected = [];
        this.dataset_process_map_product.offsets = 0;
        keyword = this.dataset_process_map_product.keyword;
        keyword.printType = this.dataset_process_map_product.search.select_print.id;
        keyword.isSelectAll = false;

        this.dataset_process_map_product.keyword = keyword;
        this.dataset_process_map_product.offsets = 0
        this.dataset_process_map_product.limits = 10

        data = await this.SearchOrder(this.dataset_process_map_product.offsets, this.dataset_process_map_product.limits, null, keyword, warehouse_select);
        this.setDataTable_process_map_product(data, '10');

      }
      else if (tab == '20') {
        this.dataset_process_allocate_stock.page = 1;
        this.dataset_process_allocate_stock.dataTable = [];
        this.dataset_process_allocate_stock.selected = [];
        this.dataset_process_allocate_stock.offsets = 0;
        keyword = this.dataset_process_allocate_stock.keyword;
        keyword.printType = this.dataset_process_allocate_stock.search.select_print.id;
        keyword.isSelectAll = false;

        this.dataset_process_allocate_stock.keyword = keyword;
        this.dataset_process_allocate_stock.offsets = 0
        this.dataset_process_allocate_stock.limits = 10

        data = await this.SearchOrder(this.dataset_process_allocate_stock.offsets, this.dataset_process_allocate_stock.limits, null, keyword, warehouse_select);
        this.setDataTable_process_allocate_stock(data, '20');

      }
      else if (tab == '30') {
        this.dataset_process_ready_to_ship.page = 1;
        this.dataset_process_ready_to_ship.dataTable = [];
        this.dataset_process_ready_to_ship.selected = [];
        this.dataset_process_ready_to_ship.offsets = 0;
        keyword = this.dataset_process_ready_to_ship.keyword;
        keyword.printType = this.dataset_process_ready_to_ship.search.select_print.id;
        keyword.isSelectAll = false;

        this.dataset_process_ready_to_ship.keyword = keyword;
        this.dataset_process_ready_to_ship.offsets = 0
        this.dataset_process_ready_to_ship.limits = 10

        data = await this.SearchOrder(this.dataset_process_ready_to_ship.offsets, this.dataset_process_ready_to_ship.limits, null, keyword, warehouse_select);
        this.setDataTable_process_ready_to_ship(data, '30');

      }
      else if (tab == '40') {
        this.dataset_process_to_pack.page = 1;
        this.dataset_process_to_pack.dataTable = [];
        this.dataset_process_to_pack.selected = [];
        this.dataset_process_to_pack.offsets = 0;
        keyword = this.dataset_process_to_pack.keyword;
        keyword.printType = this.dataset_process_to_pack.search.select_print.id;
        keyword.isSelectAll = false;

        this.dataset_process_to_pack.keyword = keyword;
        this.dataset_process_to_pack.offsets = 0
        this.dataset_process_to_pack.limits = 10

        data = await this.SearchOrder(this.dataset_process_to_pack.offsets, this.dataset_process_to_pack.limits, null, keyword, warehouse_select);
        this.setDataTable_process_to_pack(data, '40');

      }
      else if (tab == '60') {
        this.dataset_process_packing.page = 1;
        this.dataset_process_packing.dataTable = [];
        this.dataset_process_packing.selected = [];
        this.dataset_process_packing.offsets = 0;
        keyword = this.dataset_process_packing.keyword;
        keyword.printType = this.dataset_process_packing.search.select_print.id;
        keyword.isSelectAll = false;

        this.dataset_process_packing.keyword = keyword;
        this.dataset_process_packing.offsets = 0
        this.dataset_process_packing.limits = 10

        data = await this.SearchOrder(this.dataset_process_packing.offsets, this.dataset_process_packing.limits, null, keyword, warehouse_select);
        this.setDataTable_process_packing(data, '60');

      }
      else if (tab == '80') {
        this.dataset_process_wait_pickup.page = 1;
        this.dataset_process_wait_pickup.dataTable = [];
        this.dataset_process_wait_pickup.selected = [];
        this.dataset_process_wait_pickup.offsets = 0;
        keyword = this.dataset_process_wait_pickup.keyword;
        keyword.printType = this.dataset_process_wait_pickup.search.select_print.id;
        keyword.isSelectAll = false;

        this.dataset_process_wait_pickup.keyword = keyword;
        this.dataset_process_wait_pickup.offsets = 0
        this.dataset_process_wait_pickup.limits = 10

        data = await this.SearchOrder(this.dataset_process_wait_pickup.offsets, this.dataset_process_wait_pickup.limits, null, keyword, warehouse_select);
        this.setDataTable_process_wait_pickup(data, '5');

      }
      else if (tab == '90') {
        this.dataset_delivering.page = 1;
        this.dataset_delivering.dataTable = [];
        this.dataset_delivering.selected = [];
        this.dataset_delivering.offsets = 0;
        keyword = this.dataset_delivering.keyword;
        keyword.printType = this.dataset_delivering.search.select_print.id;
        keyword.isSelectAll = false;

        this.dataset_delivering.keyword = keyword;
        this.dataset_delivering.offsets = 0
        this.dataset_delivering.limits = 10

        data = await this.SearchOrder(this.dataset_delivering.offsets, this.dataset_delivering.limits, null, keyword, warehouse_select);
        this.setDataTable_dataset_delivering(data, '90');

      }
      else if (tab == '130') {
        this.dataset_success.page = 1;
        this.dataset_success.dataTable = [];
        this.dataset_success.selected = [];
        this.dataset_success.offsets = 0;
        keyword = this.dataset_success.keyword;
        keyword.printType = this.dataset_success.search.select_print.id;
        keyword.isSelectAll = false;

        this.dataset_success.keyword = keyword;
        this.dataset_success.offsets = 0
        this.dataset_success.limits = 10

        data = await this.SearchOrder(this.dataset_success.offsets, this.dataset_success.limits, null, keyword, warehouse_select);
        this.setDataTable_dataset_success(data, '130');

      }
      else if (tab == '100') {
        this.dataset_Return_Returnning.page = 1;
        this.dataset_Return_Returnning.dataTable = [];
        this.dataset_Return_Returnning.selected = [];
        this.dataset_Return_Returnning.offsets = 0;
        keyword = this.dataset_Return_Returnning.keyword;
        keyword.printType = this.dataset_Return_Returnning.search.select_print.id;
        keyword.isSelectAll = false;

        this.dataset_Return_Returnning.keyword = keyword;
        this.dataset_Return_Returnning.offsets = 0
        this.dataset_Return_Returnning.limits = 10

        data = await this.SearchOrder(this.dataset_Return_Returnning.offsets, this.dataset_Return_Returnning.limits, null, keyword, warehouse_select);
        this.setDataTable_Return_Returnning(data, '100');

      }
      else if (tab == '110') {
        this.dataset_Return_To_Return_Stock.page = 1;
        this.dataset_Return_To_Return_Stock.dataTable = [];
        this.dataset_Return_To_Return_Stock.selected = [];
        this.dataset_Return_To_Return_Stock.offsets = 0;
        keyword = this.dataset_Return_To_Return_Stock.keyword;
        keyword.printType = this.dataset_Return_To_Return_Stock.search.select_print.id;
        keyword.isSelectAll = false;


        this.dataset_Return_To_Return_Stock.keyword = keyword;
        this.dataset_Return_To_Return_Stock.offsets = 0
        this.dataset_Return_To_Return_Stock.limits = 10

        data = await this.SearchOrder(this.dataset_Return_To_Return_Stock.offsets, this.dataset_Return_To_Return_Stock.limits, null, keyword, warehouse_select);
        this.setDataTable_Return_To_Return_Stock(data, '110');

      }
      else if (tab == '120') {
        this.dataset_Return_Stock_Returned.page = 1;
        this.dataset_Return_Stock_Returned.dataTable = [];
        this.dataset_Return_Stock_Returned.selected = [];
        this.dataset_Return_Stock_Returned.offsets = 0;
        keyword = this.dataset_Return_Stock_Returned.keyword;
        keyword.printType = this.dataset_Return_Stock_Returned.search.select_print.id;
        keyword.isSelectAll = false;

        this.dataset_Return_Stock_Returned.keyword = keyword;
        this.dataset_Return_Stock_Returned.offsets = 0
        this.dataset_Return_Stock_Returned.limits = 10

        data = await this.SearchOrder(this.dataset_Return_Stock_Returned.offsets, this.dataset_Return_Stock_Returned.limits, null, keyword, warehouse_select);
        this.setDataTable_Return_Stock_Returned(data, '120');

      }
      else if (tab == '140') {
        this.dataset_cancel.page = 1;
        this.dataset_cancel.dataTable = [];
        this.dataset_cancel.selected = [];
        this.dataset_cancel.offsets = 0;
        keyword = this.dataset_cancel.keyword;
        keyword.printType = this.dataset_cancel.search.select_print.id;
        keyword.isSelectAll = false;


        this.dataset_cancel.keyword = keyword;
        this.dataset_cancel.offsets = 0
        this.dataset_cancel.limits = 10

        data = await this.SearchOrder(this.dataset_cancel.offsets, this.dataset_cancel.limits, null, keyword, warehouse_select);
        this.setDataTable_dataset_cancel(data, '140');

      }

    },
    async btnSelectedTarget(tab) {

      var data, keyword, item, total;

      var warehouse_select = await this.map_tab_list_herder_1();


      if (tab == '1') {


        this.dataset_wait_payment_wait_payment.selected = [];

        total = this.dataset_wait_payment_wait_payment.total
        keyword = { ...this.dataset_wait_payment_wait_payment.keyword };
        keyword.isSelectAll = true;

        data = await this.SearchOrder(0, total, null, keyword, warehouse_select);

        for (item of data.orderMasterPackageInfoList) {
          this.dataset_wait_payment_wait_payment.selected.push({
            packageID: item,
          });
        }

      }
      else if (tab == '5') {

        this.dataset_wait_payment_check_payment.selected = [];

        total = this.dataset_wait_payment_check_payment.total
        keyword = { ...this.dataset_wait_payment_check_payment.keyword };
        keyword.isSelectAll = true;

        data = await this.SearchOrder(0, total, null, keyword, warehouse_select);

        for (item of data.orderMasterPackageInfoList) {
          this.dataset_wait_payment_check_payment.selected.push({
            packageID: item,
          });
        }

      }
      else if (tab == '10') {

        this.dataset_process_map_product.selected = [];

        total = this.dataset_process_map_product.total
        keyword = { ...this.dataset_process_map_product.keyword };
        keyword.isSelectAll = true;

        data = await this.SearchOrder(0, total, null, keyword, warehouse_select);

        for (item of data.orderMasterPackageInfoList) {
          this.dataset_process_map_product.selected.push({
            packageID: item,
          });
        }

      }
      else if (tab == '20') {

        this.dataset_process_allocate_stock.selected = [];

        total = this.dataset_process_allocate_stock.total
        keyword = { ...this.dataset_process_allocate_stock.keyword };
        keyword.isSelectAll = true;

        data = await this.SearchOrder(0, total, null, keyword, warehouse_select);

        for (item of data.orderMasterPackageInfoList) {
          this.dataset_process_allocate_stock.selected.push({
            packageID: item,
          });
        }

      }
      else if (tab == '30') {

        this.dataset_process_ready_to_ship.selected = [];

        total = this.dataset_process_ready_to_ship.total
        keyword = { ...this.dataset_process_ready_to_ship.keyword };
        keyword.isSelectAll = true;

        data = await this.SearchOrder(0, total, null, keyword, warehouse_select);

        for (item of data.orderMasterPackageInfoList) {
          this.dataset_process_ready_to_ship.selected.push({
            packageID: item,
          });
        }

      }
      else if (tab == '40') {

        this.dataset_process_to_pack.selected = [];

        total = this.dataset_process_to_pack.total
        keyword = { ...this.dataset_process_to_pack.keyword };
        keyword.isSelectAll = true;

        data = await this.SearchOrder(0, total, null, keyword, warehouse_select);

        for (item of data.orderMasterPackageInfoList) {
          this.dataset_process_to_pack.selected.push({
            packageID: item,
          });
        }

      }
      else if (tab == '60') {

        this.dataset_process_packing.selected = [];

        total = this.dataset_process_packing.total
        keyword = { ...this.dataset_process_packing.keyword };
        keyword.isSelectAll = true;

        data = await this.SearchOrder(0, total, null, keyword, warehouse_select);

        for (item of data.orderMasterPackageInfoList) {
          this.dataset_process_packing.selected.push({
            packageID: item,
          });
        }

      }
      else if (tab == '80') {

        this.dataset_process_wait_pickup.selected = [];

        total = this.dataset_process_wait_pickup.total
        keyword = { ...this.dataset_process_wait_pickup.keyword };
        keyword.isSelectAll = true;

        data = await this.SearchOrder(0, total, null, keyword, warehouse_select);

        for (item of data.orderMasterPackageInfoList) {
          this.dataset_process_wait_pickup.selected.push({
            packageID: item,
          });
        }

      }
      else if (tab == '90') {

        this.dataset_delivering.selected = [];

        total = this.dataset_delivering.total
        keyword = { ...this.dataset_delivering.keyword };
        keyword.isSelectAll = true;

        data = await this.SearchOrder(0, total, null, keyword, warehouse_select);

        for (item of data.orderMasterPackageInfoList) {
          this.dataset_delivering.selected.push({
            packageID: item,
          });
        }

      }
      else if (tab == '130') {

        this.dataset_success.selected = [];

        total = this.dataset_success.total
        keyword = { ...this.dataset_success.keyword };
        keyword.isSelectAll = true;

        data = await this.SearchOrder(0, total, null, keyword, warehouse_select);

        for (item of data.orderMasterPackageInfoList) {
          this.dataset_success.selected.push({
            packageID: item,
          });
        }

      }
      else if (tab == '100') {

        this.dataset_Return_Returnning.selected = [];

        total = this.dataset_Return_Returnning.total
        keyword = { ...this.dataset_Return_Returnning.keyword };
        keyword.isSelectAll = true;

        data = await this.SearchOrder(0, total, null, keyword, warehouse_select);

        for (item of data.orderMasterPackageInfoList) {
          this.dataset_Return_Returnning.selected.push({
            packageID: item,
          });
        }

      }
      else if (tab == '110') {

        this.dataset_Return_To_Return_Stock.selected = [];

        total = this.dataset_Return_To_Return_Stock.total
        keyword = { ...this.dataset_Return_To_Return_Stock.keyword };
        keyword.isSelectAll = true;

        data = await this.SearchOrder(0, total, null, keyword, warehouse_select);

        for (item of data.orderMasterPackageInfoList) {
          this.dataset_Return_To_Return_Stock.selected.push({
            packageID: item,
          });
        }

      }
      else if (tab == '120') {

        this.dataset_Return_Stock_Returned.selected = [];

        total = this.dataset_Return_Stock_Returned.total
        keyword = { ...this.dataset_Return_Stock_Returned.keyword };
        keyword.isSelectAll = true;

        data = await this.SearchOrder(0, total, null, keyword, warehouse_select);

        for (item of data.orderMasterPackageInfoList) {
          this.dataset_Return_Stock_Returned.selected.push({
            packageID: item,
          });
        }

      }
      else if (tab == '140') {

        this.dataset_cancel.selected = [];

        total = this.dataset_cancel.total
        keyword = { ...this.dataset_cancel.keyword };
        keyword.isSelectAll = true;

        data = await this.SearchOrder(0, total, null, keyword, warehouse_select);

        for (item of data.orderMasterPackageInfoList) {
          this.dataset_cancel.selected.push({
            packageID: item,
          });
        }

      }



    },

    // ส่วนของ btn ที่เป็น dialog
    async btnMappedProduct(tab) {
      console.log("btnMappedProduct", tab);

    },
    async btnFilterOrder(tab) {

      this.filteOrderDataSet.tab = 0;
      this.filteOrderDataSet.sku_start = 1;
      this.filteOrderDataSet.sku_end = 1;
      this.filteOrderDataSet.item_start = 1;
      this.filteOrderDataSet.item_end = 1;
      this.filteOrderDataSet.item_end = 1;
      this.filteOrderDataSet.radio_item = 0;
      this.filteOrderDataSet.radio_sku = 0;
      this.filteOrderDataSet.productList = [];
      this.filteOrderDataSet.dataset = [];
      this.filteOrderDataSet.mapProductList = [];
      let dataTableFilterSetSelected = await this.getDataTableFilterSetSelected(tab);

      if (dataTableFilterSetSelected.length > 0) {
        // this.loading = true;
        let selectedBody = dataTableFilterSetSelected;
        const packageIDList = selectedBody
          .map((item) => item.packageID)
          .map((packageID) => Number(packageID));

        let amount_of_data_each = 50; // จำนวนข้อมูลที่มีในแต่ละก้อน
        let countObject = packageIDList.length;
        let loopCountFetch = Math.ceil(countObject / amount_of_data_each); // จำนวนที่ต้องวนข้อมูล
        let bodyOut = [];
        let objList = [];
        // if (this.filteOrderDataSet.filteOrderList.length > 0) {
        //   const packageIDArray = Object.values(packageIDList);
        //   this.filteOrderDataSet.filteOrderList.forEach((packageIDList) => {
        //     const filterSelected = packageIDArray.find(
        //       (item) => item == packageIDList.packageID
        //     );
        //     if (filterSelected) {
        //       objList.push(packageIDList);
        //     }
        //   });
        // } else {
        for (let i = 0; i < loopCountFetch; i++) {
          bodyOut[i] = packageIDList.splice(0, amount_of_data_each);
          var data_obj = await this.getFilterOrder(bodyOut[i]);
          await this.getPercentLoading(i + 1, loopCountFetch);
          if (data_obj != null) {
            objList.push(...data_obj);
          }
        }
        // }
        // เช็คว่ามี SKU ซ้ำไหม
        var indexCount = 1;
        const newData = objList.reduce((acc, curr) => {
          const found = acc.find((item) => item.stockShopID === curr.stockShopID);
          if (found) {
            found.orderCount += 1;
            found.quantityToPack += curr.quantityToPack;
            found.outStock -= curr.quantityToPack;
            found.outStockShow = found.outStock < 0 ? found.outStock : "";
          } else {
            curr.orderCount = 1;
            curr.outStock = curr.currentQuantity - curr.quantityToPack;
            curr.outStockShow = curr.outStock < 0 ? curr.outStock : "";
            curr.no = indexCount;
            acc.push({ ...curr });
            indexCount = indexCount + 1;
          }
          return acc;
        }, []);
        // map ข้อมูล ที่ orderMasterID เหมือนกันอยู่ใน arry เดียวกัน
        const mapData = [];

        objList.forEach((order) => {
          const existingOrder = mapData.find(
            (item) => item.orderMasterID === order.orderMasterID
          );
          if (existingOrder) {
            existingOrder.item.push(order);
            existingOrder.itemCount = existingOrder.itemCount + order.quantityToPack;
            existingOrder.skuCount += 1;
          } else {
            mapData.push({
              orderMasterID: order.orderMasterID,
              item: [order],
              itemCount: order.quantityToPack,
              printpackinglistTime: order.printpackinglistTime,
              skuCount: 1,
              packageID: order.packageID,
            });
          }
        });
        const mapSkuCount = [];
        //จัดกลุ่มตามจำนวน SKU เท่ากัน
        const skuCountsGroup = [...new Set(mapData.map((item) => item.skuCount))].sort(
          (a, b) => a - b
        );

        //วนข้อมูลเพื่อดึงมา
        skuCountsGroup.forEach((skuCount) => {
          //กรองข้อมูลที่มาค่าเท่ากัน
          const filteredData = mapData.filter((item) => item.skuCount === skuCount);
          const orderCount = filteredData.length;
          const printY = filteredData.filter((item) => item.printpackinglistTime !== null)
            .length;
          const printN = filteredData.filter((item) => item.printpackinglistTime === null)
            .length;

          mapSkuCount.push({
            orderCount,
            PrintY: printY,
            PrintN: printN,
            skuCount,
          });
        });

        this.filteOrderDataSet.filteOrderList = objList; // ไว้สำหรับแสดง จำนวน SKU ในออเดอร์
        this.filteOrderDataSet.mapProductList = mapSkuCount; // ไว้สำหรับแสดง จำนวน SKU ในออเดอร์
        this.filteOrderDataSet.productList = mapData; // ไว้สำหรับค้นหาต้อนใส่ข้อมูล
        this.filteOrderDataSet.dataset = newData; //  ไว้แสดงข้อมูล
        this.filteOrderDataSet.selected = newData; // ไว้เลิอกในการแสดง
        // this.loading = false;
        this.filterOrderPopup.dialog = true;
      }



    },
    async btnSumitFilteOrder() {

      var NameTab = await this.getNameTab();

      // this.loading = true;
      var warehouse_select = await this.map_tab_list_herder_1();

      var clone = [...this.filteOrderDataSet.selected];

      var data = [...this.filteOrderDataSet.dataset];

      var dataList = [...this.filteOrderDataSet.productList];

      // กรองข้อมูลที่มี stockShopID ไม่ซ้ำกัน  ระหว่าง clone กับ data คือข้อมูลที่ไม่ถูกเลือกออกมา
      const filteredData = data.filter((obj1) => {
        return !clone.some((obj2) => {
          return obj1.stockShopID == obj2.stockShopID;
        });
      });

      //แปลงเป็นเลขโดยนำจากข้อมูลที่เลือก
      const itemStockShopIdSelected = clone.map((item) => item.stockShopID);
      //แปลงเป็นเลขโดยนำจากข้อมูลที่ไม่เลือก
      const itemStockShopIdNotSelected = filteredData.map((item) => item.stockShopID);
      //แปลงเป็นเลขโดยนำจากข้อมูลทั้งหมด โดยเอา packageID แต่ละ Iiem
      const itemStockPackageIDList = [
        ...new Set(dataList.flatMap((data) => data.item.map((item) => item.packageID))),
      ];

      const PackageIDList = [];
      for (let index = 0; index < dataList.length; index++) {
        //เช็คว่าต้องเช็คจากจำนวน sku มั้ย
        if (this.filteOrderDataSet.radio_sku) {
          if (
            dataList[index].skuCount >= this.filteOrderDataSet.sku_start &&
            dataList[index].skuCount <= this.filteOrderDataSet.sku_end
          ) {
            //ไม่ต้องทำอะไร
          } else {
            //ถ้าจำนวน sku ไม่อยู๋ใน range ที่เลือก ให้ข้ามไปเลย
            continue;
          }
        }

        //เช็คว่าต้องเช็คจากจำนวน สินค้า มั้ย
        if (this.filteOrderDataSet.radio_item) {
          if (
            dataList[index].itemCount >= this.filteOrderDataSet.item_start &&
            dataList[index].itemCount <= this.filteOrderDataSet.item_end
          ) {
            //ไม่ต้องทำอะไร
          } else {
            //ถ้าจำนวนสินค้าไม่อยู๋ใน range ที่เลือก ให้ข้ามไปเลย
            continue;
          }
        }
        if (itemStockShopIdNotSelected.length > 0) {
          const existingOrder = dataList[index].item.find((item) =>
            itemStockShopIdNotSelected.includes(item.stockShopID)
          );
          if (existingOrder) {
            continue;
          }
        }
        PackageIDList.push(dataList[index].packageID);
      }

      var keyword = this[NameTab].keyword; //ใช้ชื่อ [NameTab] จากตอนส่ง
      keyword.isSelectAll = false;
      this[NameTab].keyword = keyword; //เก็บค่าไว้

      let amount_of_data_each = 50; // จำนวนข้อมูลที่มีในแต่ละก้อน
      let TotalRoundToFetchData = Math.ceil(PackageIDList.length / amount_of_data_each); // จำนวนที่ต้องวนข้อมูล
      let objList = [];



      var indexNo = 1;
      try {
        this.loading = true;
        for (let i = 0; i < TotalRoundToFetchData; i++) {
          var TempPackageIDList = PackageIDList.splice(0, amount_of_data_each);
          let data_obj = await this.SearchOrder(
            0,
            amount_of_data_each,
            TempPackageIDList,
            keyword,
            warehouse_select
          );
          for (let index = 0; index < data_obj.resultData.length; index++) {
            data_obj.resultData[index].no = indexNo;
            indexNo = indexNo + 1;
          }
          await this.getPercentLoading(i + 1, TotalRoundToFetchData);
          objList.push(...data_obj.resultData);
        }
      } catch (error) {
        this.loading = false;
        Swal.fire({
          icon: "warning",
          title: "ไม่สามารถทำรายการได้ กรุณาทำรายการอีกครั้ง",
          showConfirmButton: true,
          confirmButtonText: "OK",
        });
        return;
      }



      this[NameTab].limits = 999999; //กำหนด [NameTab] คือชื่อของค่า 
      this[NameTab].dataTable = objList;
      this[NameTab].total = objList.resultCount;
      this[NameTab].pageLength = 1;
      this[NameTab].selected = [];
      this.filteOrderDataSet.dataset = [];
      this.loading = false;
      this.filterOrderPopup.dialog = false;
    },
    async btnExportExcelFilteOrder() {
      return this.filteOrderDataSet.headerExcel;
    },
    async btnAllocateStock(tab) {
      // ไม่มีในส่วนนี้
      console.log("btnAllocateStock", tab);
    },
    async btnUpdateToMarketplace() {
      var NameTab = await this.getNameTab();

      const packageIDList = this[NameTab].selected.map((item) => item.packageID);

      let data = await this.prepareUpdateToMarketplace(packageIDList);

      if (data.error_description == null) {
        this.UpdateToMarketplacePopup.marketplaceName =
          (await data.marketplaceName) != null ? data.marketplaceName : null;
        this.UpdateToMarketplaceDataSet.dataset = data.orderList;
        this.UpdateToMarketplaceDataSet.datasetError = [];
        this.UpdateToMarketplaceDataSet.success = 0;
        this.UpdateToMarketplaceDataSet.error = 0;
        this.UpdateToMarketplacePopup.tab = 0;

        if (data.marketplaceName == "shopee") {
          this.UpdateToMarketplaceDataSet.shopeeType = 0;
          this.UpdateToMarketplaceDataSet.pickupTimeID = null;

          if (data.shopeeInitData.response.pickup.address_list) {
            this.UpdateToMarketplaceDataSet.pickup = await data.shopeeInitData.response
              .pickup.address_list;

            //หา ตำแหน่งที่ให้เลือกเริ่มต้น
            const index = await this.UpdateToMarketplaceDataSet.pickup.findIndex((item) =>
              item.address_flag.includes("pickup_address")
            );

            //นำค่ามาใส่ เริ่มต้น และ ใส่เวลา
            this.UpdateToMarketplaceDataSet.selectedAddress = index;
            this.UpdateToMarketplaceDataSet.selectedpickupTime = this.UpdateToMarketplaceDataSet.pickup[
              index
            ];
          }
        }
        if (data.marketplaceName == "tiktok") {
          this.UpdateToMarketplaceDataSet.tiktokType = 0;
        }
        this.UpdateToMarketplaceDataSet.loading = false;
        this.UpdateToMarketplaceDataSet.loadingPercent = false;
        this.UpdateToMarketplacePopup.dialog = true;
      }

    },
    async btnSubmitToMarketPlace(marketplaceName) {
      let Round = 0;

      let countLoop = this.UpdateToMarketplaceDataSet.dataset.map(
        (item) => item.orderMasterID
      ).length;
      this.UpdateToMarketplaceDataSet.loading = true;
      this.UpdateToMarketplaceDataSet.loadingPercent = true;

      if (marketplaceName == "shopee") {
        for (const item of this.UpdateToMarketplaceDataSet.dataset) {
          Round++;
          let body = {
            packageID: item.packageID,
            orderMasterID: item.orderMasterID,
            addressID:
              this.UpdateToMarketplaceDataSet.pickupTimeID == null
                ? null
                : this.UpdateToMarketplaceDataSet.selectedpickupTime.address_id,
            pickupTimeID: this.UpdateToMarketplaceDataSet.pickupTimeID,
          };
          this.getPercentLoading(Round, countLoop);
          if (body.addressID != null) {
            body.addressID = body.addressID.toString();
          }
          let data = await this.RTS_shopee_package(body);
          if (data.status == "success") {
            this.$set(item, "processing", data.status);
            this.$set(item, "message", data.message["th"]);
            this.UpdateToMarketplaceDataSet.success++;
          } else {
            this.$set(item, "processing", "error");
            this.$set(
              item,
              "error_description",
              data.message["th"] + data.error_description
            );
            this.UpdateToMarketplaceDataSet.datasetError.push(item);
            this.UpdateToMarketplaceDataSet.error++;
          }
        }
      }
      if (marketplaceName == "lazada") {
        for (const item of this.UpdateToMarketplaceDataSet.dataset) {
          Round++;
          let body = { packageID: item.packageID };
          this.getPercentLoading(Round, countLoop);
          let data = await this.RTS_lazada_package(body);
          if (data.status == "success") {
            this.$set(item, "processing", data.status);
            this.$set(item, "message", data.message["th"]);
            this.UpdateToMarketplaceDataSet.success++;
          } else {
            this.$set(item, "processing", "error");
            this.$set(
              item,
              "error_description",
              data.message["th"] + data.error_description
            );
            this.UpdateToMarketplaceDataSet.datasetError.push(item);
            this.UpdateToMarketplaceDataSet.error++;
          }
        }
      }
      if (marketplaceName == "tiktok") {
        for (const item of this.UpdateToMarketplaceDataSet.dataset) {
          Round++;
          let body = {
            packageID: item.packageID,
            handover_method: this.UpdateToMarketplaceDataSet.handover_method,
          };
          this.getPercentLoading(Round, countLoop);
          let data = await this.RTS_tiktok_order(body);
          if (data.status == "success") {
            this.$set(item, "processing", data.status);
            this.$set(item, "message", data.message["th"]);
            this.UpdateToMarketplaceDataSet.success++;
          } else {
            this.$set(item, "processing", "error");
            this.$set(
              item,
              "error_description",
              data.message["th"] + data.error_description
            );
            this.UpdateToMarketplaceDataSet.datasetError.push(item);
            this.UpdateToMarketplaceDataSet.error++;
          }
        }
      }
    },
    async btnPrintBarcode(tab) {
      var packageIDList = []
      if (tab == "40") {
        packageIDList = this.dataset_process_to_pack.selected;
      } else if (tab == "60") {
        packageIDList = this.dataset_process_packing.selected;
      } else if (tab == "80") {
        packageIDList = this.dataset_process_wait_pickup.selected;
      }
      
      if (packageIDList.length > 0) {
        // this.loading = true;
        let selectedBody = [...packageIDList];
        const GenToURL = selectedBody.map((item) => item.packageID);
        // .map((id) => Number(id));
        let query = JSON.stringify(GenToURL);
        let encodedQuery = encodeURIComponent(query);

        var key = Math.floor(Math.random() * 1000000 + 1).toString();
        sessionStorage.setItem("packageIDList", encodedQuery);
        sessionStorage.setItem("encodedQueryKey", key);
        window.open(`/printPackingList?orderID=${key}`);

        this.loading = false;
      }
    },
    async btnPrintAwb(tab) {
      this.refreshTab = tab
      var packageIDList = []
      if (tab == "40") {
        packageIDList = this.dataset_process_to_pack.selected.map((item) => item.packageID);
      } else if (tab == "60") {
        packageIDList = this.dataset_process_packing.selected.map((item) => item.packageID);
      } else if (tab == "80") {
        packageIDList = this.dataset_process_wait_pickup.selected.map((item) => item.packageID);
      }
      
      if (packageIDList.length > 0) {
        let data = await this.preparePrintAwb(packageIDList);
        if (data.orderList != null) {
          this.printAwsDataSet.dataset = await data.orderList;
          this.printAwsDataSet.success = 0;
          this.printAwsDataSet.error = 0;
          this.printAwsDataSet.datasetError = [];
          this.printAwsPopup.tab = 0;
          if(this.printAwsDataSet.dataset != null && this.printAwsDataSet.dataset.length>0)
            this.isNeedItemWhenPrint = this.printAwsDataSet.dataset[0].isNeedItemWhenPrint;
          this.printAwsPopup.dialog = true;
        }
      }
    },
    async preparePrintAwb(packageIDList) {
      return await axios
        .post(
          ecomService_dotnet + "Order/prepare-print-awb",
          {
            packageIDList: packageIDList,
          },
          { headers: this.header_token }
        )
        .then(async (res) => {
          if (res.data.status == "success" && res.data.error_description == null) {
            return await res.data;
          } else {
            this.AlertError(await res.data.error_description);
            return res.data;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return null;
        });
    },
    async btnSubmitAwb() {
      let dataOUT = [];

      this.printAwsDataSet.loadingPercent = true;
      this.printAwsDataSet.loading = true;
      let Round = 1;
      let countLoop = this.printAwsDataSet.dataset.length + 2;
      this.getPercentLoading(Round, countLoop);

      for (const item of this.printAwsDataSet.dataset) {
        Round++;
        if (
          item.referenceSourceID == 3 ||
          item.referenceSourceID == 4 ||
          item.referenceSourceID == 11
        ) {
          let data = await this.getAwb(item);
          this.getPercentLoading(Round, countLoop);
          if (data.bytes != null) {
            dataOUT.push(data.bytes);
          } else if (data.base64HTML != null) {
            let bytes = await this.convertBase64HtmlToArrayBuffer(data.base64HTML);

            dataOUT.push(bytes);
          }

          if (data.status == "success") {
            this.$set(item, "processing", await data.status);
            this.printAwsDataSet.success++;
          } else {
            this.$set(item, "processing", "error");
            this.$set(item, "error_description", data.error_description);
            this.printAwsDataSet.datasetError.push(item);
            this.printAwsDataSet.error++;
          }
        } else {
          let data = await this.createShipment(item.orderMasterID, item.packageID);
          this.getPercentLoading(Round, countLoop);
          if (data.status == true && (data.message == null || data.message == "")) {
            dataOUT.push(data);
            this.$set(item, "processing", "success");
            this.printAwsDataSet.success++;
          } else {
            this.$set(item, "processing", "error");
            this.$set(item, "error_description", data.message);
            this.printAwsDataSet.datasetError.push(item);
            this.printAwsDataSet.error++;
          }
        }
      }
      Round++;
      if (
        this.printAwsDataSet.dataset[0].referenceSourceID == 3 ||
        this.printAwsDataSet.dataset[0].referenceSourceID == 4 ||
        this.printAwsDataSet.dataset[0].referenceSourceID == 11
      ) {
        if (dataOUT.length > 0) {
          this.convertBytesToPDF(dataOUT);
        }
      } else {
        var key = Math.floor(Math.random() * 1000000 + 1).toString();
        localStorage.setItem("printAws", JSON.stringify(dataOUT));
        sessionStorage.setItem("encodedQueryKey_printAws", key);
        window.open(`/printAws?key=${key}`);
      }
      this.getPercentLoading(Round, countLoop);
    },

    async btnPackageChangeStatus() {
      var NameTab = await this.getNameTab();

      const packageIDList = this[NameTab].selected.map((item) => item.packageID);

      this.changeStatusDataSet.showManageChangeStatusList = this[NameTab].ManageChangeStatusList;

      let data = await this.getPreparePackageBeforeChangeStatus(packageIDList);
      this.changeStatusDataSet.dataset = data.packageList;
      this.changeStatusDataSet.changeStatus = null;
      this.changeStatusDataSet.success = 0;
      this.changeStatusDataSet.error = 0;
      this.changeStatusDataSet.datasetError = [];
      this.changeStatusPopup.tab = 0;
      this.changeStatusPopup.dialog = true;

    },
    async btnSubmitChamgeStatus() {
      this.changeStatusDataSet.loadingPercent = true;
      this.changeStatusDataSet.loading = true;

      let Round = 0;
      let countLoop = this.changeStatusDataSet.dataset.length;

      for (const item of this.changeStatusDataSet.dataset) {
        Round++;
        this.getPercentLoading(Round, countLoop);
        let data = await this.changePackageStatus(
          item.packageID,
          this.changeStatusDataSet.changeStatus.ID
        );

        if (data.status == "success") {
          this.$set(item, "processing", data.status);
          this.changeStatusDataSet.success++;
        } else {
          this.$set(item, "processing", "error");
          this.$set(item, "error_description", data.error_description);
          this.changeStatusDataSet.datasetError.push(item);
          this.changeStatusDataSet.error++;
        }
      }
    },
    async btnPackageBackStatus(tab) {
      var NameTab = await this.getNameTab();

      const packageIDList = this[NameTab].selected.map((item) => item.packageID);

      this.backStatusDataSet.showManageBackStatusList = this[NameTab].ManageChangeStatusList;


      let data = await this.getPreparePackageBeforeChangeStatus(packageIDList);
      this.backStatusDataSet.dataset = data.packageList;
      this.backStatusDataSet.changeStatus = null;
      this.backStatusDataSet.success = 0;
      this.backStatusDataSet.error = 0;
      this.backStatusDataSet.datasetError = [];
      this.backStatusPopup.tab = 0;
      this.backStatusPopup.dialog = true;

    },
    async btnSubmitBackStatus() {
      this.backStatusDataSet.loadingPercent = true;
      this.backStatusDataSet.loading = true;
      let Round = 0;
      let countLoop = this.backStatusDataSet.dataset.length;

      for (const item of this.backStatusDataSet.dataset) {
        Round++;
        this.getPercentLoading(Round, countLoop);
        let data = await this.changePackageStatus(
          item.packageID,
          this.backStatusDataSet.changeStatus.ID
        );

        if (data.status == "success") {
          this.$set(item, "processing", data.status);
          this.backStatusDataSet.success++;
        } else {
          this.$set(item, "processing", "error");
          this.$set(item, "error_description", data.error_description);
          this.backStatusDataSet.datasetError.push(item);
          this.backStatusDataSet.error++;
        }
      }
    },
    async btnViewReport() {
      var NameTab = await this.getNameTab();
      const packageIDList = this[NameTab].selected.map((item) => item.packageID);

      let body = [];
      this.reportOrderDataSet.header = [...this.reportOrderDataSet.headerIN];
      var headerItem = this.reportOrderDataSet.header.find(
        (item) => item.text === "ชื่อ"
      );

      if (this.set_TabListSubHeader_Process == '10' && this.set_TabListHeader == '10-20-30-40-60') {
        headerItem.value = "name";
        headerItem.field = "name";
        body = await this.getAllReportMapProduct(packageIDList);
        if (body.length > 0) {
          this.reportOrderDataSet.header = this.reportOrderDataSet.header.filter((item) =>
            Object.keys(body.productListToMap[0]).includes(item.value)
          );
          this.reportOrderDataSet.dataset = await body.productListToMap;
        }
      } else {
        let bodyin = [];
        headerItem.value = "productName";
        headerItem.field = "productName";
        bodyin.packageIDList = packageIDList;
        body = await this.getAllViewReportPackd(bodyin);
        this.reportOrderDataSet.header = this.reportOrderDataSet.header.filter((item) =>
          Object.keys(body.productList[0]).includes(item.value)
        );
        this.reportOrderDataSet.dataset = await body.productList;
      }

      this.reportOrderPopup.dialog = true;

    },
    async btnExportExcel(tab) {
      console.log("btnExportExcel", tab);

    },
    async btnApproveBalanceCheck(tab) {
      console.log("btnExportExcel", tab);

    },

    async createShipment(orderMasterID, packageID) {
      return await axios
        .post(
          logistic_Service + "Logistic/create-shipment-V2",
          {
            orderMasterID: orderMasterID,
            packageID: packageID,
            staffBranchID: this.staffBranchId,
            isNeedAddress: true,
            isNeedProductList: this.isNeedItemWhenPrint,
            isForceNewTrack: false,
          },
          { headers: this.header_token }
        )
        .then(async (res) => {
          if (
            res.data.status == true &&
            (res.data.message == null || res.data.message == "")
          ) {
            return await res.data;
          } else {
            // this.AlertError(res.data.message);
            return res.data;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return null;
        });
    },
    async refreshWindow(page) {
      this.getPercentLoading(1, 2);
      await this.SearchOrder(this.offsets, this.limits);
      if (this.refreshTab == "40" || this.refreshTab == "60") {
        await this.ChangeTabSub(this.refreshTab)
      } else if (this.refreshTab == "80") {
        await this.ChangeTab(this.refreshTab)
      }

      switch (page) {

        case "UpdateToMarketplacePopup":
          this.UpdateToMarketplaceDataSet.loading = false;
          this.UpdateToMarketplaceDataSet.loadingPercent = false;
          this.UpdateToMarketplaceDataSet.success = 0;
          this.UpdateToMarketplaceDataSet.error = 0;
          this.UpdateToMarketplaceDataSet.datasetError = [];
          break;

        case "printAwsPopup":
          this.printAwsDataSet.loadingPercent = false;
          this.printAwsDataSet.loading = false;
          this.printAwsDataSet.success = 0;
          this.printAwsDataSet.error = 0;
          this.printAwsDataSet.datasetError = [];
          this.printAwsDataSet.dataset = [];
          break;

      }
      this.getPercentLoading(2, 2);
    },

    FormatOrder,
    length60,
    AlertError,
    AlertSuccess,
    formatMoney,
    formatDatetime,
    GetDate,
    get_languages,
    SetToDateTime,
    format_price,
    format_number,
    format_order_id,
    allowedDates: val => val <= SetToDateTime('date',new Date(),0),
    formatDate (date) {
      if (date.length == 0) return null

      if (date.length == 1) {
        const [year, month, day] = date[0].split('-')
        return `${day}-${month}-${year}`
      } else {
        const [year_from, month_from, day_from] = date[0].split('-')
        const [year_to, month_to, day_to] = date[1].split('-')
        if (date[0] < date[1]) {
          return `${day_from}-${month_from}-${year_from} ~ ${day_to}-${month_to}-${year_to}`
        } else {
          return `${day_to}-${month_to}-${year_to} ~ ${day_from}-${month_from}-${year_from}`
        }
      }
    },
    gen_num_data_table (index) {
      if(index != null && this.loading == false) {
        var start = null
        if(this.dataset_all.search.SHselectStatus == 30){
          start = 1 + this.dataset_all.offsets
          return format_number(start + index)
        }else {
          start = this.dataset_all.total - this.dataset_all.offsets
          return format_number(start - index )
        }
      } else {
        return ""
      }
    },
    set_format_date_time (val) {
      if(val != null){
        var date_tmp = val.substring(0, 10)
        const date_split = date_tmp.split('-');
        var time_tmp = val.substring(11, 19)
        var set_dattime = date_split[2]+'-'+date_split[1]+'-'+date_split[0] +' '+ time_tmp
        return set_dattime
      } else {
        return ""
      }
    },
    updateFormattedDate(item){
      if (!item.date) return null
      const [year, month, day] = item.date.split('-')
      item.dateString = `${day}-${month}-${year}`
    },
    async convertBase64HtmlToArrayBuffer(base64Html) {
      const htmlString = decodeURIComponent(escape(atob(base64Html)));
      let iframe = document.createElement("iframe");
      iframe.style.visibility = "hidden";
      document.body.appendChild(iframe);
      let iframedoc = iframe.contentDocument || iframe?.contentWindow?.document;
      if (!iframedoc) return;
      iframedoc.body.innerHTML = htmlString;
      let canvas = await html2canvas(iframedoc.body, {});
      let imgData = canvas.toDataURL("image/png");
      const doc = new jsPDF({
        format: "a4",
        unit: "mm",
      });
      doc.addImage(imgData, "PNG", 0, 0, 210, 297);
      document.body.removeChild(iframe);
      return doc.output("arraybuffer");
    },
    async convertBytesToPDF(byteArrays) {
      // สร้างเอกสาร PDF ใหม่
      var pdfDoc = await PDFDocument.create();

      for (let i = 0; i < byteArrays.length; i++) {
        var byteArray = byteArrays[i];
        var buffer = Buffer.from(byteArray, "base64");
        var uint8Array = new Uint8Array(buffer);

        // โหลดเอกสาร PDF
        var pdfDocToAdd = await PDFDocument.load(uint8Array);

        // คัดลอกหน้าจากเอกสาร PDF ที่โหลดมา
        var copiedPages = await pdfDoc.copyPages(
          pdfDocToAdd,
          pdfDocToAdd.getPageIndices()
        );

        // แทรกทุกหน้าลงในเอกสารใหม่
        for (var copiedPage of copiedPages) {
          pdfDoc.addPage(copiedPage);
        }
      }
      // แปลง PDF เป็น bytes
      var pdfBytes = await pdfDoc.save();

      //   // บันทึกเอกสาร PDF
      const blob = new Blob([pdfBytes], { type: "application/pdf" });
      const pdfUrl = URL.createObjectURL(blob);

      const newWindow = window.open(pdfUrl);

      newWindow.print();
    },
  },
};
</script>
<style scoped>
.box-shadow-picker {
  border: 1px solid;
  border-radius: 5px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%),
    0px 1px 5px 0px rgb(0 0 0 / 12%);
  background: white;
}
</style>
